import { AUTHSUCCESS, AUTHFAIL, AUTHCODESUCCESS } from "../types";

const authReducer = (state, action) => {
  switch (action.type) {
    case AUTHSUCCESS:
      return {
        ...state,
        authCode: null,
        hasAuthCode: false,
        isAuthenticated: action.payload.success,
        loading: false,
      };
    case AUTHFAIL:
      return {
        ...state,
        authCode: null,
        hasAuthCode: false,
        isAuthenticated: false,
        loading: true,
        userName: "",
      };
    case AUTHCODESUCCESS:
      return {
        ...state,
        hasAuthCode: action.payload.success,
        userName: action.payload.username,
      };
    default:
      return state;
  }
};

export default authReducer;
