import React from "react";
import PropTypes from "prop-types";

const ComposeNewAction = ({
  handleCancel,
  handleNewActionDueDateChange,
  newActionDueDate,
  handleDisplayChange,
  saveNewActionHandler,
}) => {
  return (
    <div className="popup" id="popup-new-action">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Compose New Action
          </p>
          <div onClick={handleCancel} id="hide-compose-new-action">
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="form-row">
            <p className="large-text medium-blue-text">Due Date:</p>
            <input
              type="date"
              name="action-date"
              className="form-field large-text medium-blue-green-text"
              id="action-date"
              onChange={handleNewActionDueDateChange}
              value={newActionDueDate && newActionDueDate}
            />
          </div>
          <div className="form-row">
            <button
              className="button-normal flex-grow margin-right-large large-text"
              name="view-edit-action-schedule"
              onClick={handleDisplayChange}
            >
              Back
            </button>
            <button
              className="button-elevated flex-grow large-text"
              onClick={saveNewActionHandler}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ComposeNewAction.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleNewActionDueDateChange: PropTypes.func.isRequired,
  newActionDueDate: PropTypes.string,
  handleDisplayChange: PropTypes.func.isRequired,
  saveNewActionHandler: PropTypes.func.isRequired,
};

export default ComposeNewAction;
