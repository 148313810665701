import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const ApptPerformanceViewer = ({ endDate, handleCancel, startDate }) => {
  const apptPerformanceViewRef = useRef();

  const [apptTally, setApptTally] = useState([]);
  const [apptTallyLoaded, setApptTallyLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!apptTallyLoaded) {
      loadApptPerformanceViewer();
    }
    // eslint-disable-next-line
  }, [apptTallyLoaded]);

  const loadApptPerformanceViewer = async () => {
    try {
      safeStateSetter(() => {
        setLoading(true);
      });

      const res = await axios.post(
        "/api/v1/dealership/get-appt-idx",
        { startDate, endDate },
        axiosConfig
      );

      if (res.data.success) {
        const names = Object.keys(res.data.apptIdx);

        names.sort((a, b) => {
          if (res.data.apptIdx[a].score > res.data.apptIdx[b].score) {
            return -1;
          } else {
            return 1;
          }
        });

        const localApptTally = [];
        names.forEach((name) => {
          localApptTally.push({
            name,
            made: res.data.apptIdx[name].made,
            shown: res.data.apptIdx[name].shown,
            score: res.data.apptIdx[name].score,
          });
        });

        // there's a strange bug here where mainBodyRef is null here, so safeStateSetter makes this not work...
        safeStateSetter(() => {
          setApptTally(localApptTally);
          setApptTallyLoaded(true);
          setLoading(false);
        });
      } else {
        throw new Error("could not get users of dealership...");
      }
    } catch (error) {
      console.log(
        "ApptPerformanceViewer.loadApptPerformanceViewer: ",
        error.message
      );
    }
  };

  const safeStateSetter = (fn) => {
    if (apptPerformanceViewRef.current) {
      fn();
    }
  };
  return (
    <div className="popup" ref={apptPerformanceViewRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Appointments
          </p>
          <div
            onClick={handleCancel}
            className="cursor-pointer"
            id="hide-appt-performance"
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        {loading ? (
          <div className="flex-column-center-center">
            <div className="lds-dual-ring"></div>
          </div>
        ) : (
          <div className="popup-body popup-body-evenly">
            <p className="small-medium-text medium-blue-green-text">
              (best first)
            </p>
            {apptTally &&
              apptTally.map((el, idx) => {
                return (
                  <div
                    key={idx}
                    className="medium-text flex-row-center-between three-quarter-width"
                  >
                    <p className="dark-blue-text">{el.name} :</p>{" "}
                    <p className="dark-blue-green-text">
                      {el.made} Made / {el.shown} Show
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

ApptPerformanceViewer.propTypes = {
  endDate: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default ApptPerformanceViewer;
