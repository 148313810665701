import React, { useReducer } from "react";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import DealershipContext from "./dealershipContext";
import DealershipReducer from "./dealershipReducer";
import isObject from "../../util/isObject";
import { SEARCHRESULTS, CLEARRESULTS } from "../types";

const DealershipState = (props) => {
  const initialState = {
    searchResults: null,
  };

  const [state, dispatch] = useReducer(DealershipReducer, initialState);

  const stockSearch = async (stockNumberString) => {
    try {
      // console.log("hello from stockSearch");
      const res = await axios.post(
        "/api/v1/dealership/get-vehicle-from-stock",
        { stockNumberString },
        axiosConfig
      );
      // console.log(res);
      if (!res.data.success) {
        return null;
      } else {
        // console.log("vehicle found!");
        return res.data.vehicle;
      }
    } catch (error) {
      // don't log this, because it generates a lot of (operational) errors
      // console.log("DealershipState.stockSearch: ", error.message);
    }
  };

  const vinSearch = async (vinString) => {
    try {
      // console.log("hello from vinSearch");
      const res = await axios.post(
        "/api/v1/dealership/get-vehicle-from-vin",
        { vinString },
        axiosConfig
      );
      // console.log(res);
      if (!res.data.success) {
        return null;
      } else {
        // console.log("vehicle found!");
        return res.data.vehicle;
      }
    } catch (error) {
      console.log("DealershipState.vinSearch: ", error.message);
    }
  };

  const findMatches = async (enteredCustomerObj) => {
    try {
      if (isObject(enteredCustomerObj)) {
        const res = await axios.post(
          "/api/v1/customer/search-duplicates",
          enteredCustomerObj,
          axiosConfig
        );

        if (Array.isArray(res.data.finalResults)) {
          dispatch({
            type: SEARCHRESULTS,
            payload: res.data.finalResults,
          });
          return res.data.finalResults;
        } else {
          // console.log(enteredCustomerObj);
          console.log(
            "ERROR: did not receive valid response array from the SERVER"
          );
        }
      } else {
        console.log(
          "ERROR: did not receive valid entered customer object from the CLIENT"
        );
      }
    } catch (error) {
      console.log("DealershipState.findMatches: ", error.message);
    }
  };

  const search = async (enteredCustomer) => {
    // looking at how this is called in CustomerSearch, I have no idea why i'd call this parameter "enteredCustomer".
    //
    // below is the data received here
    //
    // const search = async () => {
    //   try {
    //     let results;
    //     switch (searchType) {
    //       case "all-fields":
    //         results = await dealershipContext.search({
    //           allFields: searchText,
    //           searchType,
    //           activeCycleSelected,
    //         });
    //         break;
    //       case "first":
    //         results = await dealershipContext.search({
    //           firstName: searchText,
    //           searchType,
    //           activeCycleSelected,
    //         });
    //         break;
    try {
      // console.log(enteredCustomer);
      const matches = await axios.post(
        "/api/v1/customer/search-duplicates",
        enteredCustomer,
        axiosConfig
      );

      // console.log("matches: ", matches.data.finalResults);
      dispatch({
        type: SEARCHRESULTS,
        payload: matches.data.finalResults,
      });

      return matches.data.finalResults;
    } catch (error) {
      console.log("DealershipState.search: ", error.message);
    }
  };

  const setMatches = async (searchResults) => {
    try {
      dispatch({
        type: SEARCHRESULTS,
        payload: JSON.parse(searchResults),
      });
    } catch (error) {
      // console.log("DealershipState.setMatches: ", error.message);
    }
  };

  const purgeMerged = (customerIdArray) => {
    try {
      // here is where we send the ids to the server to get the customers merged
      const currentSearchResults = state.searchResults;
      for (let i = 0; i < currentSearchResults.length; i++) {
        if (customerIdArray.includes(currentSearchResults[i]._id)) {
          currentSearchResults.splice(i, 1);
          i--;
        }
      }
      dispatch({
        type: SEARCHRESULTS,
        payload: currentSearchResults,
      });
      return currentSearchResults;
    } catch (error) {
      // console.log("DealershipState.js: ", error.message);
    }
  };

  const clearMatches = () => {
    dispatch({
      type: CLEARRESULTS,
    });
    localStorage.setItem("searchResults", "");
  };

  const addDealershipCustomer = async (enteredCustomerObj, license, face) => {
    try {
      enteredCustomerObj.license = license;
      enteredCustomerObj.face = face;

      // console.log(enteredCustomerObj);

      const res = await axios.post(
        "/api/v1/customer/add-customer",
        enteredCustomerObj,
        axiosConfig
      );

      if (isObject(res.data.customer)) {
        // console.log("addDealershipCustomer: ", res.data.customer);
        return res.data.customer;
      } else {
        throw new Error(
          "Did not receive a valid customer object back from the SERVER"
        );
      }
    } catch (error) {
      console.log("DealershipState.addDealershipCustomer: ", error.message);
    }
  };

  const getBdcManager = async () => {
    try {
      // console.log("DealershipState.getBdcManager...");
      const res = await axios.post(
        "/api/v1/dealership/get-bdc-manager",
        {},
        axiosConfig
      );

      if (res.data.success) {
        return res.data.bdcManager;
      } else {
        console.log("Error: did not get bdc manager from server");
      }
    } catch (error) {
      console.log("DealershipState.getBdcManager", error.message);
    }
  };

  const getPerformanceData = async (startDate, endDate) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/get-performance-data",
        { startDate, endDate },
        axiosConfig
      );

      return res.data.performanceData;
    } catch (error) {
      console.log(error.message);
    }
  };

  const getSalesPeople = async (currentUser) => {
    try {
      const salesPeople = [];
      const { dealership } = currentUser;
      const { data } = await axios.post(
        "/api/v1/dealership/get-users",
        { dealership, query: "salespeople" },
        axiosConfig
      );

      // data.requestedUsers.forEach((salesperson) => {
      //   if (
      //     salesperson.fullName !==
      //     `${currentUser.firstName} ${currentUser.lastName}`
      //   ) {
      //     salesPeople.push({
      //       _id: salesperson._id,
      //       firstName: salesperson.firstName,
      //       lastName: salesperson.lastName,
      //     });
      //   }
      // });

      data.requestedUsers.forEach((salesperson) => {
        salesPeople.push({
          _id: salesperson._id,
          firstName: salesperson.firstName,
          lastName: salesperson.lastName,
        });
      });

      return salesPeople;
    } catch (error) {
      console.log("addCustomerApiCalls.getSalesPeople: ", error.message);
    }
  };

  const getStoreVisitsAndData = async (dateObj) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/get-store-visits-and-data",
        { dateObj },
        axiosConfig
      );

      if (res.data.success) {
        return res.data.todayStoreVisits;
      } else {
        return false;
      }
    } catch (error) {
      console.log("DealershipState.getStoreVisitsAndData", error.message);
    }
  };

  const getUsersOfDealership = async (dealerId) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/get-users-of-dealership",
        { dealerId },
        axiosConfig
      );

      if (res.data.success) {
        return res.data.usersOfDealership;
      }
    } catch (error) {
      console.log("DealershippState.getUsersOfDealership: ", error);
    }
  };

  const checkUsers = async () => {
    // don't need any parameters here because we use req.user on the server (authController.protect())
    const res = await axios.get(
      "/api/v1/dealership/check-users-of-dealership",
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      console.log(
        "DealershipState.checkUsers: did not receive users from SERVER"
      );
      return false;
    }
  };

  const checkCustomerNumber = async (customerNumberString, resultsArray) => {
    try {
      const res = await axios.post(
        "/api/v1/customer/search-customer-number",
        { customerNumber: customerNumberString },
        axiosConfig
      );

      const newResultsArray = [res.data.matchingCustomer];

      resultsArray.forEach((result) => {
        newResultsArray.push(result);
      });

      dispatch({
        type: SEARCHRESULTS,
        payload: newResultsArray,
      });

      return true;
    } catch (error) {
      console.log("DealershipState.checkCustomerNumber: ", error.message);
    }
  };

  const saveStoreVisit = async (sv) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/save-store-visit",
        { storeVisit: sv },
        axiosConfig
      );

      if (res.data.success) {
        return true;
      } else {
        // if (res.data.reason && res.data.reason === "already-sold-cycle") {
        //   alert(
        //     "This Cycle already has a Sold Store Visit.  If this Customer bought more than one vehicle, a new Cycle should be created for them and then a new Store Visit, which you should mark 'Sold'."
        //   );
        // }
        return false;
      }
    } catch (error) {
      console.log("DealershipState.saveStoreVisit: ", error.message);
    }
  };

  const vehicleSearch = async (
    vehicleSearchType,
    minPrice,
    maxPrice,
    searchCategory,
    searchText
  ) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/vehicle-search",
        {
          vehicleSearchType,
          minPrice,
          maxPrice,
          searchCategory,
          searchText,
        },
        axiosConfig
      );

      if (res.data.success) {
        // console.log(res.data.resultsArray);
        return res.data.resultsArray;
      } else {
        return false;
      }
    } catch (error) {
      console.log("DealershipState.vehicleSearch: ", error.message);
    }
  };

  const saveEmailTemplate = async (
    dealershipId,
    templateUUID,
    templateTitle,
    templateSubject,
    templateText
  ) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/save-email-template",
        {
          dealershipId,
          templateUUID,
          templateTitle,
          templateSubject,
          templateText,
        },
        axiosConfig
      );

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("DealershipState.saveEmailTemplate: ", error.message);
    }
  };
  //
  const saveNewEmailTemplate = async (
    dealershipId,
    templateUUID,
    templateTitle,
    templateSubject,
    templateText
  ) => {
    try {
      const res = await axios.post(
        "/api/v1/dealership/save-new-email-template",
        {
          dealershipId,
          templateUUID,
          templateTitle,
          templateSubject,
          templateText,
        },
        axiosConfig
      );

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("DealershipState.saveNewEmailTemplate: ", error.message);
    }
  };
  //
  return (
    <DealershipContext.Provider
      value={{
        searchResults: state.searchResults,
        findMatches,
        setMatches,
        purgeMerged,
        clearMatches,
        addDealershipCustomer,
        getSalesPeople,
        search,
        checkUsers,
        stockSearch,
        vinSearch,
        checkCustomerNumber,
        getBdcManager,
        getStoreVisitsAndData,
        saveStoreVisit,
        getPerformanceData,
        vehicleSearch,
        saveEmailTemplate,
        saveNewEmailTemplate,
        getUsersOfDealership,
      }}
    >
      {props.children}
    </DealershipContext.Provider>
  );
};

export default DealershipState;
