import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";
import { format } from "date-fns";
const CurrentActionViewer = ({
  commonMakes,
  currentAction,
  currentCustomer,
  currentMake,
  currentModel,
  currentModels,
  currentNotes,
  currentStock,
  currentVehicleType,
  currentVin,
  currentYear,
  dealershipInternetSources,
  handleCancel,
  handleDisplayChange,
  handleVehicleChange,
  handleViewCustomer,
  handleViewCycle,
  loadAppointmentInfo,
  modelsLoading,
  selectedType,
  selectedInternetSource,
  updateCycleType,
  updateInternetSource,
  userLoaded,
  viewEmailButton,
  viewPhoneButton,
  viewTextButton,
  years,
}) => {
  const [localModel, setLocalModel] = useState("");
  const [localModels, setLocalModels] = useState([]);

  useEffect(() => {
    if (localModels.length === 0 || localModels[0] !== currentModels[0]) {
      setLocalModels(currentModels);
    }

    if (currentModel && localModel !== currentModel) {
      setLocalModel(currentModel);
    }

    // eslint-disable-next-line
  }, [currentModel, currentModels]);

  const checkExisting = (e) => {
    (async () => {
      let exists = false;
      if (userLoaded) {
        userLoaded.incompleteActions.forEach((incAct) => {
          if (incAct._id === currentAction._id) {
            exists = true;
          }
        });
        if (!exists) {
          localStorage.setItem("localCurrentAction", null);
          window.location.reload();
        }
      } else {
        console.log("ERROR: could not check if this action still exists");
      }
    })();
  };

  return (
    <div
      className="popup-content popup-content-special"
      id="popup-special-action-info"
      onClick={checkExisting}
    >
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Action Info
        </p>
        <div onClick={handleCancel} id="current-action-cancel">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-between">
        <div className="medium-text dark-blue-text">Customer Information:</div>
        <div className="grid-item-body-section grid-item-body-section-special margin-none">
          {!currentCustomer || !currentCustomer.firstName ? (
            <div className="flex-column-center-center margin-top-large">
              <div className="lds-dual-ring"></div>
            </div>
          ) : (
            <ul className="list small-text dark-blue-text">
              <li>
                Name:{" "}
                {currentCustomer &&
                  currentCustomer.firstName &&
                  `${currentCustomer.firstName} ${currentCustomer.lastName}`}
              </li>
              <li>
                Email:{" "}
                {currentCustomer &&
                  currentCustomer.emailAddress &&
                  currentCustomer.emailAddress}
              </li>
              <li>
                Phone:{" "}
                {currentCustomer &&
                currentCustomer.phoneNumber &&
                typeof currentCustomer.phoneNumber === "string" &&
                parsePhoneNumber(currentCustomer.phoneNumber)
                  ? parsePhoneNumber(
                      currentCustomer.phoneNumber
                    ).formatNational("US")
                  : currentCustomer.phoneNumber}
              </li>
              <li>
                Address:{" "}
                {currentCustomer &&
                  currentCustomer.streetAddress &&
                  currentCustomer.streetAddress}
              </li>
              <li>
                City:{" "}
                {currentCustomer &&
                  currentCustomer.city &&
                  currentCustomer.city}
              </li>
              <li className="info-between">
                <span>
                  State:{" "}
                  {currentCustomer &&
                    currentCustomer.state &&
                    currentCustomer.state}
                </span>
                <select
                  className="form-select padding-tiny tiny-text dark-blue-green-text"
                  value={selectedType}
                  onChange={updateCycleType}
                >
                  <option value="type">type</option>
                  <option value="internet">Internet</option>
                  <option value="phone">Phone</option>
                  <option value="walkin">Walk-in</option>
                </select>
              </li>
              <li className="info-between">
                <span>
                  Zip:{" "}
                  {currentCustomer &&
                    currentCustomer.zipCode &&
                    currentCustomer.zipCode}
                </span>
                <select
                  className="form-select padding-tiny tiny-text dark-blue-green-text"
                  value={selectedInternetSource}
                  onChange={updateInternetSource}
                >
                  <option value="source">source</option>
                  {dealershipInternetSources &&
                    dealershipInternetSources.map((dis, idx) => (
                      <option value={dis} key={idx}>
                        {dis}
                      </option>
                    ))}
                </select>
              </li>
            </ul>
          )}
        </div>
        <div className="form-row margin-none">
          <button
            onClick={handleDisplayChange}
            name="view-edit-customer"
            className="button-normal button-normal-smaller flex-grow small-medium-text margin-right-large"
          >
            Edit Customer
          </button>
          {currentAction.cycle._id ? (
            <button
              onClick={handleViewCycle}
              className="button-normal button-normal-smaller flex-grow small-medium-text margin-none"
            >
              View Cycle
            </button>
          ) : (
            <button
              onClick={handleViewCustomer}
              className="button-normal button-normal-smaller flex-grow small-medium-text margin-none"
            >
              View Customer
            </button>
          )}
        </div>

        {currentAction.cycle._id && (
          <div className="flex-column-center-center full-width">
            <div className="medium-text dark-blue-text">Vehicle:</div>
            <div className="module module-full-width margin-none">
              <div className="flex-column-center-evenly flex-grow hide-overflow">
                <div className="form-row">
                  <input
                    className="form-field form-field-left dark-blue-green-text flex-grow"
                    type="text"
                    size="6"
                    name="stock"
                    placeholder="Stock"
                    value={currentStock && currentStock}
                    onChange={handleVehicleChange}
                  />
                  <select
                    className="form-field dark-blue-green-text flex-grow"
                    name="type"
                    value={currentVehicleType && currentVehicleType}
                    onChange={handleVehicleChange}
                  >
                    <option value="select-type">Select Type</option>
                    <option value="new">New</option>
                    <option value="used">Used</option>
                  </select>
                  <select
                    className="form-field dark-blue-green-text flex-grow"
                    name="make"
                    value={currentMake && currentMake}
                    onChange={handleVehicleChange}
                  >
                    <option value="select-make">Select Make</option>
                    {commonMakes &&
                      commonMakes.map((make) => {
                        return (
                          <option
                            key={make.toLowerCase()}
                            value={make.toLowerCase()}
                          >
                            {make.toLowerCase()}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-row form-row-bottom">
                  <select
                    className="form-field form-field-left dark-blue-green-text flex-grow"
                    name="year"
                    value={currentYear && currentYear}
                    onChange={handleVehicleChange}
                  >
                    <option value="select-year">Year</option>
                    {years.map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                    <option value="antique">Antique</option>
                  </select>
                  <select
                    className="form-field form-field-left dark-blue-green-text flex-grow"
                    name="model"
                    value={localModel && localModel}
                    onChange={handleVehicleChange}
                  >
                    {modelsLoading ? (
                      <option value="null">Loading...</option>
                    ) : (
                      <option value="select-model">Model</option>
                    )}

                    {localModels &&
                      localModels.map((cur) => {
                        return (
                          <option
                            key={cur.toLowerCase()}
                            value={cur.toLowerCase()}
                          >
                            {cur.toLowerCase()}
                          </option>
                        );
                      })}
                  </select>
                  <input
                    className="form-field dark-blue-green-text flex-grow"
                    type="text"
                    name="vin"
                    placeholder="VIN"
                    size="17"
                    value={currentVin && currentVin}
                    onChange={handleVehicleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {currentAction && currentAction.actionType === "sold-follow-up" ? (
          <div className="medium-text dark-blue-text">Customer Notes:</div>
        ) : (
          <div className="small-text dark-blue-text">
            <span className="margin-left-medium">Cycle Notes:</span>
          </div>
        )}

        <div className="popup-body-container popup-body-container-special">
          {currentNotes && !currentNotes[0] && (
            <div className="flex-column-center-center margin-top-large">
              <div className="lds-dual-ring"></div>
            </div>
          )}
          {currentNotes &&
            currentNotes[0] &&
            currentNotes.map((note, index) => {
              return (
                <div className="section-row" key={index}>
                  <div className="section-row-info">
                    <div className="section-row-info-row dark-blue-text tiny-text">
                      {format(new Date(note.createdAt), "P")}
                    </div>
                    <div className="section-row-info-row dark-blue-text small-text">
                      {format(new Date(note.createdAt), "p")}
                    </div>
                    <div className="section-row-info-row section-row-info-row-bottom dark-blue-text small-text">
                      {note.writtenBy}
                    </div>
                  </div>

                  {note.noteType === "emailreceived" && (
                    <div className="section-row-content note">
                      <div className="note-heading note-heading-elevated">
                        {note.noteType}
                      </div>
                      <div className="note-body">{note.body}</div>
                      {note.emailData && (
                        <button
                          className="button-normal margin-small small-text"
                          id={JSON.stringify(note)}
                          onClick={handleDisplayChange}
                          name="view-email-from-note"
                        >
                          View
                        </button>
                      )}
                    </div>
                  )}

                  {note.noteType === "weblead" && (
                    <div className="section-row-content note">
                      <div className="note-heading note-heading-elevated">
                        {note.noteType}
                      </div>
                      <div className="note-body">{note.body}</div>
                    </div>
                  )}

                  {note.noteType === "textreceived" &&
                    currentAction.actionType === "text-received" && (
                      <div className="section-row-content note">
                        <div className="note-heading note-heading-elevated">
                          {note.noteType}
                        </div>
                        <div className="note-body">{note.body}</div>
                        {
                          <button
                            className="button-normal margin-small small-text"
                            id={JSON.stringify(note)}
                            onClick={handleDisplayChange}
                            name="view-text-conversation"
                          >
                            View
                          </button>
                        }
                      </div>
                    )}

                  {note.noteType === "textreceived" &&
                    currentAction.actionType !== "text-received" && (
                      <div className="section-row-content note">
                        <div className="note-heading note-heading-elevated">
                          {note.noteType}
                        </div>
                        <div className="note-body">{note.body}</div>
                      </div>
                    )}

                  {note.noteType !== "emailreceived" &&
                    note.noteType !== "weblead" &&
                    note.noteType !== "textreceived" && (
                      <div className="section-row-content note">
                        {note.noteType === "actiondeleted" ||
                        note.noteType === "actionincomplete" ||
                        note.noteType === "actionfailure" ||
                        note.noteType === "appointmentdeleted" ||
                        note.noteType === "cycleinactive" ||
                        note.noteType === "cycledeleted" ||
                        note.noteType === "cyclenothot" ? (
                          <div className="note-heading note-heading-negative">
                            {note.noteType}
                          </div>
                        ) : (
                          <div className="note-heading note-heading-elevated">
                            {note.noteType}
                          </div>
                        )}

                        <div className="note-body">{note.body}</div>

                        {note.emailData && (
                          <button
                            className="button-normal margin-small small-text"
                            id={JSON.stringify(note)}
                            onClick={handleDisplayChange}
                            name="view-email-from-note"
                          >
                            View
                          </button>
                        )}
                      </div>
                    )}
                </div>
              );
            })}
        </div>
        <div className="form-row margin-none">
          {
            // I need to deal with this soon 6/30/21
            viewTextButton ? (
              <button
                className="button-normal button-normal-smaller medium-text margin-right-large flex-grow"
                name="view-text-conversation"
                onClick={handleDisplayChange}
              >
                Text
              </button>
            ) : (
              <button
                className="button-normal button-normal-disabled button-normal-smaller medium-text margin-right-large flex-grow"
                id="text-button"
              >
                Text
              </button>
            )
          }
          {viewPhoneButton ? (
            <button
              className="button-normal button-normal-smaller medium-text margin-right-large flex-grow"
              id="phone-button"
              onClick={handleDisplayChange}
              name="view-phone"
            >
              Phone
            </button>
          ) : (
            <button
              className="button-normal button-normal-disabled button-normal-smaller medium-text margin-right-large flex-grow"
              id="phone-button"
            >
              Phone
            </button>
          )}
          {viewEmailButton ? (
            <button
              className="button-normal button-normal-smaller medium-text margin-right-large flex-grow"
              id="email-button"
              onClick={handleDisplayChange}
              name="view-email"
            >
              E-Mail
            </button>
          ) : (
            <button
              className="button-normal button-normal-disabled button-normal-smaller medium-text margin-right-large flex-grow"
              id="email-button"
            >
              E-Mail
            </button>
          )}
          <button
            onClick={handleDisplayChange}
            name="new-note"
            className="button-normal button-normal-smaller medium-text flex-grow"
          >
            Note
          </button>
        </div>
        {currentAction && currentAction.actionType !== "sold-follow-up" && (
          <div className="form-row margin-none">
            <button
              className="button-elevated button-elevated-smaller medium-text flex-grow margin-top-small"
              id="appointment-button"
              onClick={loadAppointmentInfo}
            >
              Set / Edit Appointment
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

CurrentActionViewer.propTypes = {
  commonMakes: PropTypes.array.isRequired,
  currentAction: PropTypes.object.isRequired,
  currentCustomer: PropTypes.object.isRequired,
  currentMake: PropTypes.string,
  currentModel: PropTypes.string,
  currentModels: PropTypes.array,
  currentNotes: PropTypes.array,
  currentStock: PropTypes.string,
  currentVehicleType: PropTypes.string,
  currentVin: PropTypes.string,
  currentYear: PropTypes.string,
  dealershipInternetSources: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDisplayChange: PropTypes.func.isRequired,
  handleVehicleChange: PropTypes.func.isRequired,
  handleViewCustomer: PropTypes.func.isRequired,
  handleViewCycle: PropTypes.func.isRequired,
  loadAppointmentInfo: PropTypes.func.isRequired,
  modelsLoading: PropTypes.bool,
  selectedType: PropTypes.string,
  selectedInternetSource: PropTypes.string,
  userLoaded: PropTypes.object.isRequired,
  updateCycleType: PropTypes.func.isRequired,
  updateInternetSource: PropTypes.func.isRequired,
  viewEmailButton: PropTypes.bool.isRequired,
  viewPhoneButton: PropTypes.bool.isRequired,
  viewTextButton: PropTypes.bool.isRequired,
  years: PropTypes.array.isRequired,
};

export default CurrentActionViewer;
