import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../context/users/userContext";
import axios from "axios";

const SuperUser = () => {
  //
  const history = useHistory();

  const userContext = useContext(UserContext);
  const mainBodyRef = useRef();

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewMainColumn, setViewMainColumn] = useState(true);
  const [dealerships, setDealerships] = useState([]);

  useEffect(() => {
    if (loading) {
      loadSuperuser();
    }

    // eslint-disable-next-line
  }, [loading]);

  const handleDealershipSelect = (e) => {
    (async function (dealerId, dealerName) {
      try {
        const res = await axios.post(
          "/api/v1/user/set-dealership-of-superuser",
          { userId: currentUser._id, dealerId }
        );

        if (res.data.success) {
          alert(`Accessing: ${dealerName}`);
          history.push("/");
        } else {
          alert("problem setting dealership!");
          window.location.reload();
        }
      } catch (error) {
        console.log("SuperUser.handleDealershipSelect: ", error);
      }
    })(e.currentTarget.id, e.currentTarget.textContent);
  };

  const loadSuperuser = async () => {
    const loadedUser = await userContext.getUserOnly();

    if (loadedUser.user.role !== "superuser") {
      history.push("/dashboard");
    } else {
      setCurrentUser(loadedUser.user);

      const dealershipsOfSuperUser =
        await userContext.getDealershipsOfSuperUser(loadedUser.user._id);

      if (!dealershipsOfSuperUser) {
        alert("could not find any dealerships for this superuser!");
      } else {
        setDealerships(dealershipsOfSuperUser);
      }
      // debugging
      setViewMainColumn(true);
      //
      setLoading(false);
    }
  };

  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <div className="page-column page-column-100-70" ref={mainBodyRef}>
          {viewMainColumn && (
            <div className="flex-column-center-center blue-green-underline">
              <div className="margin-top-large"></div>
              <p className="main-title dark-blue-text">
                Superuser Dealership Select
              </p>
              <div className="margin-top-large"></div>
              {dealerships.map((d, idx) => (
                <div key={idx}>
                  <button
                    className="button-normal medium-text"
                    onClick={handleDealershipSelect}
                    id={d._id}
                  >
                    {d.name}
                  </button>
                  <div className="margin-top-large"></div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default SuperUser;
