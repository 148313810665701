import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import UserContext from "../context/users/userContext";

const Navbar = () => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  const [authenticated, setAuthenticated] = useState(false);
  //
  const mainBodyRef = useRef();
  const logoutButton = useRef();

  const loadNavbar = async () => {
    const status = await authContext.checkAuthenticated();

    if (status.authenticated && mainBodyRef.current) {
      setAuthenticated(true);

      // await userContext.getUser();
      // const { user } = await userContext.getUser();
      // currentUser = user;

      const {
        user: { active },
      } = await userContext.getUserOnly();

      // currentUser is available here if needed

      if (!active) {
        alert("Your access to Lead Zen has been revoked...");

        if (logoutButton.current) {
          logoutButton.current.click();
        }
      }
    }
  };

  useEffect(() => {
    loadNavbar();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <header
        ref={mainBodyRef}
        className={authenticated ? "navbar" : "navbar navbar-private"}
      >
        <Link
          to="/dashboard"
          className="flex-row-center-between navbar-group navbar-group-header"
        >
          <div className="flex-row-center-center">
            <h1 className="main-title main-title-glow dark-blue-text">
              Lead Zen
            </h1>
            {/* By Bayhawk Software https://www.bayhawk.cc */}
            <div className="tiny-text padding-small dark-blue-text">v1.1.1</div>
          </div>
        </Link>
        <nav className="flex-row-center-between navbar-group navbar-group-links">
          <NavLink
            to="/dashboard"
            className={
              window.location.href.endsWith("/dashboard")
                ? "link-item medium-text medium-blue-text"
                : "link-item medium-text medium-grey-text"
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/public-contact"
            className={
              window.location.href.endsWith("/support")
                ? "link-item medium-text medium-blue-text"
                : "link-item medium-text medium-grey-text"
            }
          >
            {authenticated ? "Support" : "Sales"}
          </NavLink>
          <NavLink
            to="/"
            onClick={authContext.logout}
            ref={logoutButton}
            className="link-item medium-text medium-grey-text"
          >
            Logout
          </NavLink>
          <NavLink
            to="/account"
            className={
              window.location.href.endsWith("/account")
                ? "link-item medium-text medium-blue-text"
                : "link-item medium-text medium-grey-text"
            }
          >
            Account
          </NavLink>
        </nav>
      </header>
    </Fragment>
  );
};

export default Navbar;
