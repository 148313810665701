export const LOGINPARTONESUCCESS = "LOGINPARTONESUCCESS";
export const LOGINPARTTWOSUCCESS = "LOGINPARTTWOSUCCESS";
export const AUTHSUCCESS = "AUTHSUCCESS";
export const AUTHFAIL = "AUTHFAIL";
export const AUTHCODESUCCESS = "AUTHCODESUCCESS";
export const SETUSER = "SETUSER";
export const GETCUSTOMERSOFUSER = "GETUSER";
export const SENDTEXT = "SENDTEXT";
export const GETCONVERSATION = "GETCONVERSATION";
export const GETEMAILCONVERSATION = "GETEMAILCONVERSATION";
export const SEARCHRESULTS = "SEARCHRESULTS";
export const SETSEARCHEDCUSTOMER = "SETSEARCHEDCUSTOMER";
export const SETSEARCHEDCUSTOMERAFTERMERGE = "SETSEARCHEDCUSTOMERAFTERMERGE";
export const SETCURRENTCUSTOMER = "SETCURRENTCUSTOMER";
export const CLEARSEARCHEDCUSTOMER = "CLEARSEARCHEDCUSTOMER";
export const CLEARSEARCHEDCUSTOMERTYPE = "CLEARSEARCHEDCUSTOMERTYPE";
export const CLEARRESULTS = "CLEARRESULTS";
export const SETNEXTAPPOINTMENT = "SETNEXTAPPOINTMENT";
export const SETCURRENTCYCLE = "SETCURRENTCYCLE";
export const CLEARCUSTOMER = "CLEARCUSTOMER";
export const CLEARALL = "CLEARALL";
export const UPDATEVEHICLE = "UPDATEVEHICLE";
export const SETACTIONS = "SETACTIONS";
export const SETINCOMPLETEACTIONS = "SETINCOMPLETEACTIONS";
export const SETSELECTEDACTION = "SETSELECTEDACTION";
export const ADDNOTE = "ADDNOTE";
