import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="flex-row-center-center full-width">
      <div className="not-found-text-box margin-large half-width flex-column-center-center">
        {" "}
        <h1 className="main-title margin-large dark-amber-text">
          Page Not Found
        </h1>
        <p className="large-text margin-large dark-blue-green-text">
          Sorry, this page does not exist
        </p>
        <Link to="/" className="button-normal large-text margin-large">
          Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
