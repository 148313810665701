import {
  SETUSER,
  SETNEXTAPPOINTMENT,
  SETACTIONS,
  SETINCOMPLETEACTIONS,
  SETSELECTEDACTION,
} from "../types";

const userReducer = (state, action) => {
  switch (action.type) {
    case SETUSER:
      // console.log("reducer: ", action.payload);
      return {
        ...state,
        user: action.payload,
      };

    case SETACTIONS:
      return {
        ...state,
        completeActions: action.payload.completeActions,
        incompleteActions: action.payload.incompleteActions,
      };

    case SETINCOMPLETEACTIONS:
      return {
        ...state,
        incompleteActions: action.payload,
      };

    case SETNEXTAPPOINTMENT:
      const updatedCustomer = action.payload.customer;
      updatedCustomer.nextAppointment = action.payload.date;
      return {
        ...state,
        currentCustomer: updatedCustomer,
      };
    case SETSELECTEDACTION:
      // console.log("userReducer: ", action.payload);
      return {
        ...state,
        selectedAction: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
