import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { addDays, format, parseISO, differenceInDays, getYear } from "date-fns";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import DealershipContext from "../context/dealership/dealershipContext.js";
import UserContext from "../context/users/userContext.js";
import { getModels, getCityStateFromZip } from "../util/addCustomerApiCalls";
import {
  addLicenseFrontToCustomer,
  addNote,
  addStoreVisit,
  completeAction,
  cycleTypeUpdate,
  deleteAppointment,
  deleteSelectedActions,
  editSalesPersonOfCycle,
  getSubdocumentsOfCustomer,
  getSubdocumentsOfCycle,
  internetSourceUpdate,
  markCycleHot,
  saveAppt,
  saveEditedCustomer,
  saveNewAction,
  sendReply,
  sendText,
  setCycleActive,
  setCycleInactive,
  updateVehicle,
} from "../functions/customerFunctions";
import commonMakes from "../util/commonMakes";
import emailValidator from "email-validator";
import ActionSchedule from "./popups/ActionSchedule.js";
import CycleAppointmentInfo from "./popups/CycleAppointmentInfo.js";
import CycleWebLeadResponder from "./popups/CycleWebLeadResponder.js";
import ComposeNewAction from "./popups/ComposeNewAction.js";
import NewEmailComposer from "./popups/NewEmailComposer.js";
import EmailReplyComposer from "./popups/EmailReplyComposer.js";
import NoteComposer from "./popups/NoteComposer.js";
import CustomerEditor from "./popups/CustomerEditor.js";
import EmailViewer from "./popups/EmailViewer.js";
import LogPhoneCall from "./popups/LogPhoneCall.js";
import LicenseViewer from "./popups/LicenseViewer.js";
import Resizer from "react-image-file-resizer";
import TextConversationViewer from "./popups/TextConversationViewer.js";
// import * as faceapi from "face-api.js";

//

// COMPONENT

//
const Cycle = () => {
  //

  // HOOKS

  //

  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);
  //
  const history = useHistory();
  //
  const mainBodyRef = useRef();
  const logStoreVisitButton = useRef();
  const topRow = useRef();
  const customerInfoGrid = useRef();
  const cycleStatusGrid = useRef();
  const contactGrid = useRef();
  const cycleNotesGrid = useRef();
  const cycleActionsGrid = useRef();
  const cycleApptsGrid = useRef();
  const salesPersonSelector = useRef();
  const scanLicenseFrontButton = useRef();
  //
  const [apptDate, setApptDate] = useState("");
  const [apptId, setApptId] = useState("");
  const [apptStatus, setApptStatus] = useState("");
  const [apptTime, setApptTime] = useState("");
  const [viewCompleteWebLead, setViewCompleteWebLead] = useState(false);
  const [currentActions, setCurrentActions] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [currentCycle, setCurrentCycle] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentEmailSubject, setCurrentEmailSubject] = useState("");
  const [currentMake, setCurrentMake] = useState("");
  const [currentModel, setCurrentModel] = useState("");
  const [currentModels, setCurrentModels] = useState([]);
  const [currentNote, setCurrentNote] = useState("");
  const [currentNotes, setCurrentNotes] = useState([]);
  const [currentSalesPeople, setCurrentSalesPeople] = useState([]);
  const [currentSalesPerson, setCurrentSalesPerson] = useState("");
  const [currentStock, setCurrentStock] = useState("");
  const [currentTextBody, setCurrentTextBody] = useState("");
  const [currentVehicleType, setCurrentVehicleType] = useState("");
  const [currentVin, setCurrentVin] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [customerPopulated, setCustomerPopulated] = useState(false);
  const [cycleNoteButtonsViewable, setCycleNoteButtonsViewable] =
    useState(true);
  const [cycleStatus, setCycleStatus] = useState("");
  const [cycleHotStatus, setCycleHotStatus] = useState(false);
  const [followUpTemplates, setFollowUpTemplates] = useState(null);
  const [dealershipInternetSources, setDealershipInternetSources] = useState([
    "source",
  ]);
  const [dealershipShortName, setDealershipShortName] = useState("");
  const [dialogueBool, setDialogueBool] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [editScheduleButtonViewable, setEditScheduleButtonViewable] =
    useState(true);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [face, setFace] = useState("");
  const [license, setLicense] = useState("");
  const [loading, setLoading] = useState(true);
  const [newActionDueDate, setNewActionDueDate] = useState("");
  const [phoneCallBody, setPhoneCallBody] = useState("");
  const [saveNoteButtonViewable, setSaveNoteButtonViewable] = useState(false);
  const [selectedFutureActionIds, setSelectedFutureActionIds] = useState([]);
  const [selectedType, setSelectedType] = useState("type");
  const [selectedInternetSource, setSelectedInternetSource] =
    useState("source");
  const [textCheckerStatus, setTextCheckerStatus] = useState(null);
  const [twilioAltPhone, setTwilioAltPhone] = useState("");
  const [twilioPhone, setTwilioPhone] = useState("");
  const [twilioAltEmail, setTwilioAltEmail] = useState("");
  const [twilioEmail, setTwilioEmail] = useState("");
  const [viewActionSchedule, setViewActionSchedule] = useState(false);
  const [viewAppointmentInfo, setViewAppointmentInfo] = useState(false);
  const [viewComposeNewAction, setViewComposeNewAction] = useState(false);
  const [viewComposeNewEmail, setViewComposeNewEmail] = useState(false);
  const [viewComposeReply, setViewComposeReply] = useState(false);
  const [viewCycleNotes, setViewCycleNotes] = useState(false);
  const [viewEditCustomer, setViewEditCustomer] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);
  const [viewLicense, setViewLicense] = useState(false);
  const [viewMainGrid, setViewMainGrid] = useState(true);
  const [viewPhoneCall, setViewPhoneCall] = useState(false);
  const [viewTextConversation, setViewTextConversation] = useState(false);

  //
  useEffect(() => {
    // anytime we change data, we must reload the customer
    if (!customerPopulated && !errorState) {
      loadCycle();
    }

    // eslint-disable-next-line
  }, [customerPopulated]);
  //
  // LOCAL VARIABLES
  //
  const years = [];
  //
  const thisYear = getYear(parseISO(new Date().toISOString()));
  //
  years.push(thisYear + 1);
  //
  for (let i = 0; i < 25; i++) {
    years.push(thisYear - i);
  }
  //
  let localCurrentCycle = JSON.parse(localStorage.getItem("localCurrentCycle"));
  //
  let localCurrentCustomer = JSON.parse(
    localStorage.getItem("localCurrentCustomer")
  );
  //

  // FUNCTIONS

  //
  const asyncAddStoreVisit = async () => {
    try {
      if (!currentCycle.active) {
        alert("cannot create store visit on an inactive cycle");
        return;
      }

      const userRole = await userContext.checkUserRole(currentSalesPerson);

      if (userRole === "bdc" || userRole === "bdcmanager") {
        alert("Please select a salesperson before adding a new Store Visit.");
      } else {
        const storeVisitAdded = await addStoreVisit(currentCycle._id);

        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(currentCustomer)
        );
        localStorage.setItem("localCurrentCycle", JSON.stringify(currentCycle));

        if (storeVisitAdded) {
          window.location.reload();
        } else {
          alert(
            "could not add store visit - this cycle may already have a store visit today"
          );
        }
      }
    } catch (error) {
      console.log("Cycle.asyncAddStoreVisit: ", error.message);
    }
  };
  //
  const asyncHandleCycleStatusChange = async (status) => {
    try {
      if (status === "active") {
        const changed = await setCycleActive(currentCycle);

        if (!changed) {
          alert(
            "could not set this cycle active, please create a new cycle for this customer"
          );
        }
      } else if (status === "not-active") {
        await setCycleInactive(currentCycle);
      } else {
        throw new Error("invalid cycle status");
      }
      setCustomerPopulated(false);
      window.location.reload();
    } catch (error) {
      console.log("Cycle.asyncHandleCycleStatusChange: ", error.message);
    }
  };
  //
  const asyncHandleSalesPersonChange = async (salesPersonId) => {
    try {
      let salesId = salesPersonId;
      if (salesId === "") {
        const salesObj = await dealershipContext.getBdcManager();
        salesId = salesObj._id;
      } else {
        console.log('salesId does not equal ""');
      }

      // change salesperson of cycle on both database and context
      const newCycle = await editSalesPersonOfCycle(salesId, currentCycle._id);

      if (newCycle) {
        safeStateSetter(() => {
          setCurrentSalesPerson(salesId);
          setCustomerPopulated(false);
        });
      } else {
        console.log("cycle not edited...");
      }
    } catch (error) {
      console.log("asyncHandleSalesPersonChange", error.message);
    }
  };
  //
  const asyncSaveEditedCustomer = async (editedCustomerObj) => {
    try {
      // edit database here

      const customerSaved = await saveEditedCustomer(editedCustomerObj);

      if (customerSaved) {
        disableAllView();
        safeStateSetter(() => {
          setCustomerPopulated(false);
        });
      } else {
        console.log("could not save edited customer...");
      }
    } catch (error) {
      console.log("Cycle.asyncSaveEditedCustomer: ", error.message);
    }
  };
  //
  const asyncSaveNewAction = async () => {
    try {
      if (!currentCycle.active) {
        alert(
          "cannot create action for inactive cycle; this can be done from the full customer profile"
        );
        return;
      }
      const newActionSaved = await saveNewAction(
        new Date(newActionDueDate),
        currentCustomer._id,
        currentCycle._id
      );

      if (newActionSaved) {
        disableAllView();
        window.location.reload();
      } else {
        console.log("could not save new action");
      }
    } catch (error) {
      console.log("Cycle.asyncSaveNewAction: ", error.message);
    }
  };
  //
  const asyncSaveNote = async (action) => {
    if (action) {
      await completeAction(action._id);
    }

    const savedNote = await addNote({
      customer: currentCustomer._id,
      noteType: "standard",
      body: currentNote,
      writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
      user: userContext.user._id,
      dialogue: dialogueBool,
    });

    if (savedNote) {
      safeStateSetter(() => {
        setCurrentNote("");
        setCustomerPopulated(false);
        setViewMainGrid(true);
        setViewCycleNotes(false);
        setViewPhoneCall(false);
      });
    }
  };
  //
  const asyncSendEmail = async (action, subject, body) => {
    const emailSent = await sendReply(currentCustomer, subject, body);

    if (emailSent) {
      let enteredNote;

      if (action) {
        if (
          action.actionType === "unsold-follow-up" ||
          action.actionType === "email-received" ||
          action.actionType === "web-lead"
        ) {
          enteredNote = {
            customer: currentCustomer._id,
            noteType: "emailreply",
            dialogue: true,
            body: "E-mail reply sent!",
            writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
            emailData: {
              from: `${dealershipShortName}-assistance@leadzen.cc`,
              subject: currentEmailSubject,
              emailBody: currentEmail,
            },
            user: userContext.user._id,
          };
        } else {
          console.log("invalid actionType");
        }
        const actionCompleted = await completeAction(action._id);
        if (actionCompleted) {
          console.log("action completed");
        } else {
          console.log("action not completed");
        }
      } else {
        enteredNote = {
          customer: currentCustomer._id,
          noteType: "newemail",
          dialogue: false,
          body: "New E-Mail Sent!",
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          emailData: {
            from: `${dealershipShortName}-assistance@leadzen.cc`,
            subject: currentEmailSubject,
            emailBody: currentEmail,
          },
          user: userContext.user._id,
        };
        console.log("Cycle enteredNote: ", enteredNote);
      }

      // console.log("enteredNote = ", enteredNote);

      const noteAdded = await addNote(enteredNote);
      if (noteAdded) {
        window.location.reload();
      } else {
        console.log("could not add note");
        window.location.reload();
      }
    } else {
      console.log("email not sent...");
      window.location.reload();
    }
  };
  //
  const asyncSendText = async (action, body) => {
    try {
      const textSent = await sendText(currentCustomer, body);

      if (textSent) {
        console.log("text was sent correctly");

        if (action) {
          const completedAction = await completeAction(action._id);
          if (completedAction) {
            console.log("action was completed correctly");
          }
        } else {
          console.log("no action received...");
        }

        safeStateSetter(() => {
          setCurrentTextBody("");
        });

        window.location.reload();
      } else {
        console.log("text was NOT sent correctly");
        window.location.reload();
      }
    } catch (error) {
      console.log("Cycle.asyncSendText: ", error.message);
    }
  };
  //
  const asyncSetResponseType = async (action) => {
    try {
      const matchingAction = await userContext.getSubdocumentsOfAction(
        action._id
      );

      // console.log(matchingAction);
      safeStateSetter(() => {
        switch (matchingAction.actionType) {
          case "web-lead":
            disableAllView();
            setViewCompleteWebLead(true);

            break;
          case "email-received":
            disableAllView();
            matchingAction.customer.emails.sort((a, b) => {
              if (
                new Date(a.createdAt).getTime() >
                new Date(b.createdAt).getTime()
              ) {
                // a is newer than b
                return -1;
              } else {
                return 1;
              }
            });
            // console.log(
            //   "about to setCurrentEmailSubject to: ",
            //   `RE: ${matchingAction.customer.emails[0].subject}`
            // );
            setCurrentEmailSubject(
              `RE: ${matchingAction.customer.emails[0].subject}`
            );
            setCurrentEmail(`
  
  __________
  
  ${matchingAction.customer.emails[0].body}
  `);

            setViewEmail(true);
            setViewMainGrid(false);

            break;
          case "text-received":
            disableAllView();
            setViewComposeNewEmail(true);

            break;
          case "unsold-follow-up":
            disableAllView();
            setViewComposeNewEmail(true);
            setCurrentEmail("");
            setCurrentEmailSubject("");
            setViewMainGrid(false);

            break;
          default:
            console.log("invalid actionType...");
            disableAllView();
            setViewComposeNewEmail(true);
            setCurrentEmail("");
            setCurrentEmailSubject("");
            setViewMainGrid(false);
            break;
        }
      });
    } catch (error) {
      console.log("Cycle.asyncSetResponseType: ", error.message);
    }
  };
  //
  const callGetCityStateFromZip = async (zip) => {
    try {
      const result = await getCityStateFromZip(zip);
      safeStateSetter(() => {
        if (result) {
          setEditedCustomer({
            ...editedCustomer,
            city: result.city,
            state: result.state,
            zipCode: zip,
          });
        } else {
          setEditedCustomer({
            ...editedCustomer,
            city: "",
            state: "",
            zipCode: zip,
          });
        }
      });
    } catch (error) {
      console.log("Cycle.callGetCityStateFromZip: ", error.message);
      safeStateSetter(() => {
        setEditedCustomer({ ...editedCustomer, city: "", state: "" });
      });
    }
  };
  //
  const callGetModels = async (year, make) => {
    try {
      const result = await getModels(year, make);

      const lowerCaseResult = [];

      result.forEach((res) => {
        lowerCaseResult.push(res);
      });

      safeStateSetter(() => {
        if (result) {
          setCurrentModels(lowerCaseResult);
          return true;
        }
      });
    } catch (error) {
      //
    }
  };
  //
  const asyncDeleteAppointment = async (appId) => {
    try {
      const appointmentDeleted = await deleteAppointment(appId);

      if (!appointmentDeleted) {
        console.log("appointment NOT deleted properly...");
      }

      disableAllView();
      window.location.reload();
    } catch (error) {
      console.log("Cycle.deleteAppointment: ", error.message);
    }
  };
  //
  const deleteAppointmentHandler = (e) => {
    // console.log(apptId);
    asyncDeleteAppointment(apptId);
  };
  //
  const deleteSelectedActionsHandler = async () => {
    // this changes the database
    try {
      const actionsDeleted = await deleteSelectedActions(
        selectedFutureActionIds
      );
      // here, the actions should have been deleted from the database and purged from the Customer.actions array
      if (!actionsDeleted) {
        console.log("actions NOT deleted properly...");
      } else {
        safeStateSetter(() => {
          setSelectedFutureActionIds([]);
          setCustomerPopulated(false);
        });
      }
    } catch (error) {
      console.log("Cycle.asyncDeleteSelectedActions: ", error.message);
    }
  };
  //
  const disableAllView = () => {
    safeStateSetter(() => {
      setViewActionSchedule(false);
      setViewAppointmentInfo(false);
      setViewCompleteWebLead(false);
      setViewComposeNewAction(false);
      setViewComposeNewEmail(false);
      setViewComposeReply(false);
      setViewCycleNotes(false);
      setViewEditCustomer(false);
      setViewEmail(false);
      setViewLicense(false);
      //***
      setViewMainGrid(true);
      //***
      setViewPhoneCall(false);
      setViewTextConversation(false);
    });
  };
  //
  const getCurrentAction = () => {
    let matchingAction = null;
    const pastActions = [];
    currentCustomer.actions.forEach((act) => {
      const millisecondsInTwelveHours = 43200000;
      if (
        new Date().getTime() >
        new Date(act.dueDate).getTime() - millisecondsInTwelveHours
      ) {
        pastActions.push(act);
      }
    });

    const relevantPastActions = [];

    if (pastActions[0]) {
      pastActions.forEach((pastAct) => {
        if (
          differenceInDays(new Date(), new Date(pastAct.dueDate)) === 0 &&
          pastAct.status === "incomplete"
        ) {
          relevantPastActions.push(pastAct);
        }
      });
    }

    if (relevantPastActions[0]) {
      relevantPastActions.forEach((relPastAct) => {
        if (relPastAct.actionType === "web-lead") {
          matchingAction = relPastAct;
        }
      });

      if (!matchingAction) {
        matchingAction = relevantPastActions[0];
      }
    }

    if (matchingAction) {
      return matchingAction;
    } else {
      return false;
    }
  };
  //
  const loadAppointmentInfo = () => {
    if (currentCycle["appointments"] && currentCycle["appointments"][0]) {
      currentCycle.appointments.forEach((app) => {
        safeStateSetter(() => {
          setApptId(app._id);
          setApptDate(
            `${format(new Date(app.date), "yyyy")}-${format(
              new Date(app.date),
              "MM"
            )}-${format(new Date(app.date), "dd")}`
          );
          setApptTime(
            `${format(new Date(app.date), "HH")}:${format(
              new Date(app.date),
              "mm"
            )}`
          );
          setApptStatus(app.status);
        });
      });
    }
    safeStateSetter(() => {
      setViewAppointmentInfo(true);
      setViewMainGrid(false);
    });
  };
  //
  const loadCycle = async () => {
    // LOADCYCLE NOTES //

    // we must ALWAYS delete the localStorage when we leave this page.

    // we must ALWAYS reset the localStorage when we edit any data.

    // we must ALWAYS clear the textChecker when we leave or reload this page.
    if (textCheckerStatus) {
      localStorage.setItem("localTextCheckerCount", "1");
      clearInterval(textCheckerStatus);
    }

    const loadedUser = await userContext.getUserOnly();

    if (loadedUser) {
      const dealership = await userContext.getDealershipOfUser(
        loadedUser.user._id
      );
      if (dealership) {
        // console.log(dealership);
        setDealershipInternetSources(dealership.possibleInternetSources);

        // User rights management

        switch (
          loadedUser.user.role
          // think about how to implement this
        ) {
        }
        safeStateSetter(() => {
          setFollowUpTemplates(dealership.followUpTemplates);
          setDealershipShortName(dealership.shortName);
          setEmailTemplates(dealership.emailTemplates);
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        console.log("could not get dealership of user, redirect to homepage");
        if (textCheckerStatus) {
          localStorage.setItem("localTextCheckerCount", "1");
          clearInterval(textCheckerStatus);
        }
        history.push("/");
      }
    } else {
      safeStateSetter(() => {
        setErrorState(true);
      });
      alert("could not get user, redirect to homepage");
      if (textCheckerStatus) {
        localStorage.setItem("localTextCheckerCount", "1");
        clearInterval(textCheckerStatus);
      }
      history.push("/");
    }

    if (
      localCurrentCycle &&
      localCurrentCustomer &&
      localCurrentCycle.customer === localCurrentCustomer._id
    ) {
      // DEBUGGING:
      // console.log("have localStorage");

      if (
        differenceInDays(new Date(), new Date(localCurrentCycle.createdAt)) >
          119 &&
        localCurrentCycle.active
      ) {
        await setCycleInactive(localCurrentCycle);
      }
      const fullCycle = await getSubdocumentsOfCycle(localCurrentCycle._id);
      // console.log(fullCycle);

      if (!fullCycle) {
        safeStateSetter(() => {
          setErrorState(true);
        });
        alert("could not get cycle, redirect to homepage");
        if (textCheckerStatus) {
          localStorage.setItem("localTextCheckerCount", "1");
          clearInterval(textCheckerStatus);
        }
        history.push("/");
      }

      const fullCustomer = await getSubdocumentsOfCustomer(
        localCurrentCustomer._id
      );

      if (!fullCustomer) {
        safeStateSetter(() => {
          setErrorState(true);
        });
        alert("could not get customer, redirect to homepage");
        if (textCheckerStatus) {
          localStorage.setItem("localTextCheckerCount", "1");
          clearInterval(textCheckerStatus);
        }
        history.push("/");
      } else {
        //

        //

        // console.log(fullCustomer, loadedUser);

        //

        //

        if (
          fullCustomer.dealership.toString() !==
          loadedUser.user.dealership.toString()
        ) {
          history.push("/");
        }

        userContext.addToLastTwoHundred(localCurrentCustomer._id);
      }

      // DEBUGGING
      // console.log(fullCycle);
      // console.log(fullCustomer);

      // here, we need to work on the fullCycle and the fullCustomer to only contain data relevant to THIS cycle
      const notesToKeep = [];
      fullCustomer.notes.forEach((fullNote) => {
        if (
          new Date(fullNote.createdAt).getTime() <
          new Date(addDays(new Date(fullCycle.createdAt), 119)).getTime()
        ) {
          notesToKeep.push(fullNote);
        }
      });
      fullCustomer.notes = notesToKeep;

      // why do we do this here?  is there no more data to modify?
      localStorage.setItem(
        "localCurrentCustomer",
        JSON.stringify(fullCustomer)
      );
      localStorage.setItem("localCurrentCycle", JSON.stringify(fullCycle));

      // why do we do this? because it's a global variable we're changing that's why
      localCurrentCustomer = fullCustomer;
      localCurrentCycle = fullCycle;

      // logic

      localCurrentCustomer.notes.sort((a, b) => {
        if (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) {
          return -1;
        } else {
          return 1;
        }
      });

      if (
        localCurrentCycle.vehicle.year !== "" &&
        localCurrentCycle.vehicle.make !== ""
      ) {
        const vehicleModelResults = await getModels(
          localCurrentCycle.vehicle.year,
          localCurrentCycle.vehicle.make
        );

        const lowerCaseResults = [];

        if (vehicleModelResults) {
          vehicleModelResults.forEach((el) => {
            lowerCaseResults.push(el);
          });

          safeStateSetter(() => {
            setCurrentModels(lowerCaseResults);
            setCurrentModel(localCurrentCycle.vehicle.model);
          });
        }
      } else {
        console.log("missing necessary information to get models");
      }

      let salesPersonData;

      salesPersonData = await dealershipContext.getSalesPeople(loadedUser.user);

      if (salesPersonData) {
        const salesPersonIds = [];

        salesPersonData.forEach((salesPersonObj) => {
          salesPersonIds.push(salesPersonObj._id);
        });

        const pastActions = [];
        const futureActions = [];
        localCurrentCustomer.actions.forEach((action) => {
          if (action.cycle === localCurrentCycle._id) {
            if (
              new Date().getTime() > new Date(action.dueDate).getTime() &&
              differenceInDays(new Date(), new Date(action.dueDate)) !== 0
            ) {
              pastActions.push(action);
            } else if (
              action.status === "complete" ||
              action.status === "failure"
            ) {
              pastActions.push(action);
            } else if (
              action.actionType === "unsold-follow-up" ||
              action.actionType === "web-lead" ||
              action.actionType === "text-received"
            ) {
              futureActions.push(action);
            }
          }
        });
        // past actions should be DESCENDING
        pastActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return -1;
          } else {
            return 1;
          }
        });
        // futureActions should be ASCENDING
        futureActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });

        safeStateSetter(() => {
          setCurrentActions([...futureActions, ...pastActions]);
          setCurrentCustomer(localCurrentCustomer);
          setCurrentCycle(localCurrentCycle);
          setCurrentMake(localCurrentCycle.vehicle.make);
          setCurrentModel(localCurrentCycle.vehicle.model);
          // console.log(localCurrentCustomer.notes);
          setCurrentNotes(localCurrentCustomer.notes);
          setCurrentSalesPeople(salesPersonData);
          setCurrentSalesPerson(localCurrentCycle.primarySales._id);
          setCurrentStock(localCurrentCycle.vehicle.stock || "");
          setCurrentVehicleType(localCurrentCycle.vehicle.type);
          setCurrentVin(localCurrentCycle.vehicle.vin);
          setCurrentYear(localCurrentCycle.vehicle.year);
          setCustomerPopulated(true);
          setCycleHotStatus(localCurrentCycle.hot ? true : false);
          setCycleStatus(localCurrentCycle.active);
          // console.log(fullCycle);
          setSelectedType(fullCycle.cycleType);
          setSelectedInternetSource(fullCycle.internetSource || "source");

          if (localCurrentCustomer.face && localCurrentCustomer.face !== "") {
            setFace(localCurrentCustomer.face);
          }
          if (
            localCurrentCustomer.license &&
            localCurrentCustomer.license !== ""
          ) {
            setLicense(localCurrentCustomer.license);
          }
          if (
            loadedUser &&
            loadedUser.user &&
            (loadedUser.user.role === "salesperson" ||
              loadedUser.user.role === "receptionist")
          ) {
            setEditScheduleButtonViewable(false);
          }
          setLoading(false);
        });

        // I want this block to execute after the above safeStateSetter block
        safeStateSetter(() => {
          if (!localCurrentCycle.active) {
            // this is not an active cycle
            // console.log(topRow.current);
            topRow.current.classList.add("fifty-opacity");
            topRow.current.classList.add("cursor-none");
            contactGrid.current.classList.add("fifty-opacity");
            contactGrid.current.classList.add("cursor-none");
            cycleActionsGrid.current.classList.add("fifty-opacity");
            cycleActionsGrid.current.classList.add("cursor-none");
            cycleApptsGrid.current.classList.add("fifty-opacity");
            cycleApptsGrid.current.classList.add("cursor-none");
            salesPersonSelector.current.classList.add("fifty-opacity");
            salesPersonSelector.current.classList.add("cursor-none");
            safeStateSetter(() => {
              setCycleNoteButtonsViewable(false);
            });
          }
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        alert("no salespeople at this dealership, redirect to homepage");
        if (textCheckerStatus) {
          localStorage.setItem("localTextCheckerCount", "1");
          clearInterval(textCheckerStatus);
        }
        history.push("/");
      }
    } else {
      // DEBUGGING:
      // console.log("localCurrentCycle: ", localCurrentCycle);
      // console.log("localCurrentCustomer: ", localCurrentCustomer);

      safeStateSetter(() => {
        setErrorState(true);
      });
      console.log("do not have necessary data to load this page");
      if (textCheckerStatus) {
        localStorage.setItem("localTextCheckerCount", "1");
        clearInterval(textCheckerStatus);
      }

      history.push("/");
    }
  };
  //
  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };
  //
  // EVENT HANDLERS
  //
  const handleActionClick = (e) => {
    let actionElement = e.target;
    while (actionElement.parentElement.getAttribute("id") !== "parent") {
      actionElement = actionElement.parentElement;
    }

    localStorage.setItem("localCurrentAction", actionElement.id);

    history.push("/actions");
  };
  //
  const handleActionSelect = (e) => {
    if (e.target.checked) {
      safeStateSetter(() => {
        setSelectedFutureActionIds([...selectedFutureActionIds, e.target.id]);
      });
    } else {
      if (selectedFutureActionIds.includes(e.target.id)) {
        const localActionIds = [];
        selectedFutureActionIds.forEach((actId) => {
          if (actId !== e.target.id) {
            localActionIds.push(actId);
          }
        });
        safeStateSetter(() => {
          setSelectedFutureActionIds(localActionIds);
        });
      }
    }
  };

  const selectAllActions = (e) => {
    if (currentActions && currentActions.length > 0) {
      const localActionIds = [];
      currentActions.forEach((curAct) => {
        if (curAct.status === "incomplete") {
          localActionIds.push(curAct._id);
        }
      });

      const checkboxes = Array.from(
        document.getElementsByClassName("checkbox-handle")
      );

      checkboxes.forEach((chkBx) => {
        chkBx.checked = true;
      });

      setSelectedFutureActionIds(localActionIds);
    }
  };
  //
  const handleAddLicenseFront = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    let reader = new FileReader();

    // let resizedFile;

    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        reader.readAsDataURL(uri);
      },
      "file"
    );

    reader.onloadend = async () => {
      const result = reader.result.split(",")[1];

      const hiddenLicenseFrontImg = document.getElementById(
        "hidden-license-front-img"
      );
      hiddenLicenseFrontImg.setAttribute("src", reader.result);

      // console.log(faceapi);

      // await faceapi.loadTinyFaceDetectorModel("/models");

      // const detection = await faceapi.detectSingleFace(
      //   hiddenLicenseFrontImg,
      //   new faceapi.TinyFaceDetectorOptions({ inputSize: 320 })
      // );

      // console.log("DETECTION", detection);

      setTimeout(() => {
        // if (!detection) {
        alert("could not detect face...");
        localStorage.setItem("localCurrentCycle", JSON.stringify(currentCycle));
        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(currentCustomer)
        );
        window.location.reload();
        // }
      }, 3000);

      // const regionsToExtract = [
      //   new faceapi.Rect(
      //     detection._box._x,
      //     detection._box._y,
      //     detection._box._width,
      //     detection._box._height
      //   ),
      // ];

      // const canvases = await faceapi.extractFaces(
      //   hiddenLicenseFrontImg,
      //   regionsToExtract
      // );

      // if (canvases) {
      //   addLicenseFrontToCustomer(
      //     result,
      //     canvases[0].toDataURL().split(",")[1],
      //     currentCycle,
      //     currentCustomer
      //   );
      // } else {
      alert("could not extract face...");
      localStorage.setItem("localCurrentCycle", JSON.stringify(currentCycle));
      localStorage.setItem(
        "localCurrentCustomer",
        JSON.stringify(currentCustomer)
      );
      window.location.reload();
      // }
    };
  };
  //
  const handleAddStoreVisit = (e) => {
    // console.log(currentSalesPerson);
    asyncAddStoreVisit();
  };
  //
  const handleCancel = (e) => {
    let elementToCancel = e.target;

    while (!elementToCancel.id) {
      elementToCancel = elementToCancel.parentElement;
    }

    safeStateSetter(() => {
      switch (elementToCancel.id) {
        case "hide-edit-customer":
          disableAllView();
          break;

        case "hide-cycle-notes":
          disableAllView();
          setCustomerPopulated(false);
          setLoading(true);
          break;

        case "hide-text-conversation":
          localStorage.setItem("localTextCheckerCount", "1");
          clearInterval(textCheckerStatus);
          disableAllView();
          localStorage.setItem(
            "localCurrentCustomer",
            JSON.stringify(currentCustomer)
          );
          localStorage.setItem(
            "localCurrentCycle",
            JSON.stringify(currentCycle)
          );
          window.location.reload();
          break;

        case "hide-compose-new-email":
          disableAllView();
          break;

        case "hide-view-email":
          disableAllView();
          break;

        case "hide-compose-email-reply":
          disableAllView();
          break;

        case "hide-phone-call":
          disableAllView();
          break;

        case "hide-edit-action-schedule":
          // must deselect all

          const checkboxes = Array.from(
            document.getElementsByClassName("checkbox-handle")
          );

          checkboxes.forEach((chkBx) => {
            chkBx.checked = false;
          });

          setSelectedFutureActionIds([]);

          disableAllView();
          break;

        case "hide-compose-new-action":
          disableAllView();
          break;

        case "hide-appointment-info":
          disableAllView();
          break;

        case "hide-view-license":
          disableAllView();
          break;

        case "view-complete-weblead-cancel":
          disableAllView();
          break;

        default:
          break;
      }
    });
  };
  //
  const handleCycleStatusChange = (e) => {
    if (e.target.id === "status-active") {
      safeStateSetter(() => {
        setCycleStatus(true);
      });
      asyncHandleCycleStatusChange("active");
    } else if (e.target.id === "status-not-active") {
      safeStateSetter(() => {
        setCycleStatus(false);
      });
      asyncHandleCycleStatusChange("not-active");
    } else {
      console.log("invalid input...");
    }
  };
  //
  const handleDialogueChange = (e) => {
    if (e.target.id === "contact-bool-true") {
      safeStateSetter(() => {
        setDialogueBool(true);
      });
    } else {
      safeStateSetter(() => {
        setDialogueBool(false);
      });
    }
  };
  //
  const handleEditCustomer = (e) => {
    switch (e.target.name) {
      case "first-name":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, firstName: e.target.value });
        });
        break;
      case "last-name":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, lastName: e.target.value });
        });
        break;
      case "phone-number":
        const initialPhoneNumber = e.target.value;
        const digits = initialPhoneNumber.match(/\d/g);
        let phoneNumber;
        digits
          ? (phoneNumber = digits.join(""))
          : (phoneNumber = initialPhoneNumber);
        let formattedNumber = "";
        if (
          // should be formatted
          (digits && digits.length === 10 && digits[0] !== "1") ||
          (digits && digits.length === 11 && digits[0] === "1")
        ) {
          formattedNumber = new AsYouType("US").input(phoneNumber);
          const phoneObject = parsePhoneNumber(formattedNumber, "US");
          if (phoneObject && phoneObject.isValid()) {
            safeStateSetter(() => {
              setTwilioPhone(phoneObject.number);
              setEditedCustomer({
                ...editedCustomer,
                phoneNumber: parsePhoneNumber(phoneObject.number)
                  ? parsePhoneNumber(phoneObject.number).formatNational("US")
                  : phoneNumber,
              });
            });
          } else {
            safeStateSetter(() => {
              setEditedCustomer({ ...editedCustomer, phoneNumber });
            });
          }
        } else {
          // should not be formatted
          safeStateSetter(() => {
            setEditedCustomer({ ...editedCustomer, phoneNumber });
          });
        }
        break;
      case "email":
        if (emailValidator.validate(e.target.value)) {
          safeStateSetter(() => {
            setTwilioEmail(e.target.value);
          });
        }
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            emailAddress: e.target.value,
          });
        });
        break;
      case "zip-code":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, zipCode: e.target.value });
        });
        if (e.target.value.length === 5) {
          callGetCityStateFromZip(e.target.value);
        }
        break;
      case "address":
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            streetAddress: e.target.value,
          });
        });
        break;
      case "city":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, city: e.target.value });
        });
        break;
      case "state":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, state: e.target.value });
        });
        break;
      case "alt-phone-number":
        const initialAltPhoneNumber = e.target.value;
        const altDigits = initialAltPhoneNumber.match(/\d/g);
        let altPhoneNumber;
        altDigits
          ? (altPhoneNumber = altDigits.join(""))
          : (altPhoneNumber = initialAltPhoneNumber);
        let formattedAltNumber = "";
        if (
          // should be formatted
          (altDigits && altDigits.length === 10 && altDigits[0] !== "1") ||
          (altDigits && altDigits.length === 11 && altDigits[0] === "1")
        ) {
          formattedAltNumber = new AsYouType("US").input(altPhoneNumber);
          const altPhoneObject = parsePhoneNumber(formattedAltNumber, "US");
          if (altPhoneObject && altPhoneObject.isValid()) {
            safeStateSetter(() => {
              setTwilioAltPhone(altPhoneObject.number);
              setEditedCustomer({
                ...editedCustomer,
                altPhoneNumber: parsePhoneNumber(altPhoneObject.number)
                  ? parsePhoneNumber(altPhoneObject.number).formatNational()
                  : altPhoneNumber,
              });
            });
          } else {
            safeStateSetter(() => {
              setEditedCustomer({ ...editedCustomer, altPhoneNumber });
            });
          }
        } else {
          // should not be formatted
          safeStateSetter(() => {
            setEditedCustomer({ ...editedCustomer, altPhoneNumber });
          });
        }
        break;
      case "alt-email":
        if (emailValidator.validate(e.target.value)) {
          safeStateSetter(() => {
            setTwilioAltEmail(e.target.value);
          });
        }
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            altEmailAddress: e.target.value,
          });
        });

        break;
      default:
        break;
    }
  };
  //
  const handleDisplayChange = (e) => {
    const element = e.target;
    safeStateSetter(() => {
      switch (element.name) {
        case "view-edit-customer":
          disableAllView();
          if (
            currentCustomer &&
            currentCustomer.phoneNumber &&
            typeof currentCustomer.phoneNumber === "string"
          ) {
            if (parsePhoneNumber(currentCustomer.phoneNumber)) {
              setEditedCustomer({
                ...currentCustomer,
                phoneNumber: parsePhoneNumber(
                  currentCustomer.phoneNumber
                ).formatNational("US"),
              });
            } else {
              setEditedCustomer(currentCustomer);
            }
          } else {
            setEditedCustomer(currentCustomer);
          }
          setViewMainGrid(false);
          setViewEditCustomer(true);
          break;
        case "view-compose-reply":
          setCurrentEmailSubject("");
          setCurrentEmail("");
          disableAllView();
          setViewMainGrid(false);
          setViewComposeReply(true);
          break;
        case "add-note-view":
          disableAllView();
          setViewMainGrid(false);
          setViewCycleNotes(true);
          break;
        case "view-edit-action-schedule":
          disableAllView();
          setViewActionSchedule(true);
          setViewMainGrid(false);

          break;
        case "view-phone":
          disableAllView();
          setPhoneCallBody("");
          setViewPhoneCall(true);
          setViewMainGrid(false);
          break;

        case "view-compose-new-action":
          disableAllView();
          setViewComposeNewAction(true);
          setViewMainGrid(false);
          break;

        case "view-license":
          disableAllView();
          setViewLicense(true);
          setViewMainGrid(false);
          break;

        case "view-email-from-note":
          const { emailData } = JSON.parse(e.currentTarget.id);
          setCurrentEmailSubject(emailData.subject);
          setCurrentEmail(emailData.emailBody);
          disableAllView();
          setViewEmail(true);
          setViewMainGrid(false);
          break;

        default:
          break;
      }
      // it may be the case that I can use this globally here, but I'm not sure yet so I commented it out
      // setViewMainGrid(false);
    });
  };
  //
  const handleHotSelect = (e) => {
    //
    if (!currentCycle || !currentCycle._id) {
      console.log("no cycle found; redirect home");
      history.push("/");
    }

    let success = false;

    if (!cycleHotStatus) {
      success = markCycleHot(true, currentCycle && currentCycle._id);
    } else {
      success = markCycleHot(false, currentCycle && currentCycle._id);
    }

    if (!success) {
      alert("could not update cycle...");
      return;
    }

    safeStateSetter(() => {
      if (!cycleHotStatus) {
        setCycleHotStatus(true);
      } else {
        setCycleHotStatus(false);
      }

      setCustomerPopulated(false);
    });
  };
  //
  const handleNewActionDueDateChange = (e) => {
    if (
      differenceInDays(
        new Date(e.target.value),
        new Date(currentCycle.createdAt)
      ) > 120
    ) {
      // have to figure out how to actually do this
      // I think the customer needs a pendingCycles array and getUser() needs to check it
      alert(
        `This action is scheduled for a date which is after the current Cycle expires.  If saved, a new Cycle will be created for this customer on ${e.target.value}.`
      );
    }

    safeStateSetter(() => {
      setNewActionDueDate(e.target.value);
    });
  };
  //
  const handleNoteChange = (e) => {
    if (e.target.value !== "") {
      safeStateSetter(() => {
        setSaveNoteButtonViewable(true);
      });
    } else {
      safeStateSetter(() => {
        setSaveNoteButtonViewable(false);
      });
    }
    safeStateSetter(() => {
      setCurrentNote(e.target.value);
    });
  };
  //
  const handleSalesPersonChange = (e) => {
    // change the database
    asyncHandleSalesPersonChange(e.target.value);
  };
  //
  const handleVehicleChange = (e) => {
    // handleVehicleChange changes data in the database

    const element = e.target;

    switch (element.name) {
      case "model":
        const asyncUpdateModel = async (element, cycle) => {
          const returned = await updateVehicle(
            cycle._id,
            currentYear,
            currentMake,
            element.value,
            currentVehicleType,
            "",
            ""
          );
          if (returned) {
            safeStateSetter(() => {
              setCurrentStock("");
              setCurrentVin("");

              setCurrentModel(element.value);

              setCustomerPopulated(false);
            });
          } else {
            console.log("updateVehicle failure - could not update model");
          }
        };

        asyncUpdateModel(element, currentCycle);

        break;

      case "type":
        safeStateSetter(() => {
          setCurrentVehicleType(element.value);
        });

        const asyncUpdateType = async (element, cycle) => {
          const returned = await updateVehicle(
            cycle._id,
            currentYear,
            currentMake,
            currentModel,
            element.value,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(() => {
              setCurrentStock("");
              setCurrentVin("");

              setCustomerPopulated(false);
            });
          } else {
            console.log("updateVehicle failure - could not update type");
          }
        };

        asyncUpdateType(element, currentCycle);

        break;

      case "year":
        safeStateSetter(() => {
          setCurrentYear(element.value);
        });

        const asyncUpdateYear = async (element, cycle) => {
          const returned = updateVehicle(
            cycle._id,
            element.value,
            currentMake,
            "",
            currentVehicleType,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(async () => {
              setCurrentModels([]);

              if (currentMake && currentMake !== "select-make") {
                const res = await callGetModels(element.value, currentMake);
                if (res) {
                  setCurrentModel("");

                  setCustomerPopulated(false);
                }
              }
            });
          } else {
            console.log("updateVehicle failure - could not update year");
          }
        };

        asyncUpdateYear(element, currentCycle);

        break;

      case "make":
        safeStateSetter(() => {
          setCurrentMake(element.value);
        });

        const asyncUpdateMake = async (element, cycle) => {
          const returned = updateVehicle(
            cycle._id,
            currentYear,
            element.value,
            "",
            currentVehicleType,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(async () => {
              setCurrentModels([]);
              if (currentYear && currentYear !== "select-year") {
                const res = await callGetModels(currentYear, element.value);
                if (res) {
                  setCurrentModel("");

                  setCustomerPopulated(false);
                }
              }
            });
          } else {
            console.log("updateVehicle failure - could not update make");
          }
        };

        asyncUpdateMake(element, currentCycle);

        break;

      case "stock":
        const stock = element.value;
        safeStateSetter(() => {
          setCurrentStock(stock || "");
        });

        const asyncStockSearch = async (stock) => {
          const vehicle = await dealershipContext.stockSearch(stock);
          if (vehicle) {
            const returned = await updateVehicle(
              currentCycle._id,
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.type,
              vehicle.stock,
              vehicle.vin
            );

            if (returned) {
              safeStateSetter(async () => {
                setCurrentVehicleType(vehicle.type);
                setCurrentYear(vehicle.year);
                setCurrentMake(vehicle.make);
                setCurrentVin(vehicle.vin);
                await callGetModels(vehicle.year, vehicle.make);
                console.log(vehicle.model);
                console.log(currentModels);
                setCurrentModel(vehicle.model);
                setCustomerPopulated(false);
              });
            } else {
              console.log(
                "updateVehicle failure - could not update based on stock number provided"
              );
            }
          } else {
            console.log("stock not found");
            safeStateSetter(() => {
              setCurrentVin("");
            });
          }
        };
        asyncStockSearch(stock);

        break;

      case "vin":
        const vin = element.value;
        safeStateSetter(() => {
          setCurrentVin(vin);
        });

        const asyncVinSearch = async (vin) => {
          const vehicle = await dealershipContext.vinSearch(vin);
          if (vehicle) {
            // console.log("cycle.js: ", vehicle);
            const returned = await updateVehicle(
              currentCycle._id,
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.type,
              vehicle.stock,
              vehicle.vin
            );

            if (returned) {
              safeStateSetter(async () => {
                setCurrentVehicleType(vehicle.type);
                setCurrentYear(vehicle.year);
                setCurrentMake(vehicle.make);
                setCurrentModels([]);
                const models = await callGetModels(vehicle.year, vehicle.make);
                if (models) {
                  setCurrentModel(vehicle.model);
                }

                setCustomerPopulated(false);
              });
            } else {
              console.log(
                "updateVehicle failure - could not update based on vin provided"
              );
            }
          } else {
            console.log("vin not found");
            safeStateSetter(() => {
              setCurrentStock("");
            });
          }
        };
        asyncVinSearch(vin);

        break;

      default:
        console.log("element.name does not match any of the cases");
        break;
    }
  };
  //
  const handleViewContact = (e) => {
    switch (e.target.name) {
      case "text":
        disableAllView();
        safeStateSetter(() => {
          setViewMainGrid(false);
          setViewTextConversation(true);
        });

        // alert(
        //   "texting is currently down for maintenance, sorry for any inconvenience!"
        // );
        break;

      case "view-email":
        disableAllView();

        if (currentCustomer && currentCustomer.emailAddress) {
          let matchingAction = getCurrentAction();
          safeStateSetter(() => {
            if (matchingAction) {
              asyncSetResponseType(matchingAction);
            } else {
              disableAllView();
              setViewMainGrid(false);
              setViewComposeNewEmail(true);
            }
          });
        }

        break;

      case "phone":
        disableAllView();
        setViewMainGrid(false);
        setViewPhoneCall(true);
        break;

      default:
        break;
    }
  };
  //
  const populateTemplate = (e) => {
    if (!e.currentTarget.value || e.currentTarget.value === "none") {
      safeStateSetter(() => {
        setCurrentEmailSubject("");
        setCurrentEmail("");
      });
    } else {
      const templateObj = JSON.parse(e.currentTarget.value);

      safeStateSetter(() => {
        setCurrentEmailSubject(templateObj.subject);
        setCurrentEmail(templateObj.text);
      });
    }
  };
  //
  const saveAppointment = async () => {
    if (apptDate || apptTime || apptStatus) {
      const trueDate = new Date(`${apptDate}T${apptTime}`).toUTCString();

      const matchingAction = await getCurrentAction();

      if (matchingAction) {
        await completeAction(matchingAction._id);
      }

      const apptSaved = await saveAppt(
        currentCustomer,
        {
          date: trueDate,
          time: apptTime,
          status: apptStatus,
          setter: userContext.user._id,
        },
        currentCycle
      );

      if (apptSaved) {
        safeStateSetter(() => {
          setApptDate("");
          setApptStatus("");
          setApptTime("");
          disableAllView();
          setCustomerPopulated(false);
        });
      } else {
        alert("could not save appointment");
        window.location.reload();
      }
      //
      //
      //
    }
  };
  //
  const saveEditedCustomerHandler = (e) => {
    e.preventDefault();
    const finalEditedCustomer = { ...editedCustomer };
    if (twilioPhone) {
      finalEditedCustomer["phoneNumber"] = twilioPhone;
    }
    if (twilioAltPhone) {
      finalEditedCustomer["altPhoneNumber"] = twilioAltPhone;
    }
    if (twilioEmail) {
      finalEditedCustomer["emailAddress"] = twilioEmail;
    }
    if (twilioAltEmail) {
      finalEditedCustomer["altEmailAddress"] = twilioAltEmail;
    }

    // I think this code lets me get rid of the twilio fields
    if (
      finalEditedCustomer["phoneNumber"] &&
      typeof finalEditedCustomer["phoneNumber"] === "string" &&
      !parsePhoneNumber(finalEditedCustomer["phoneNumber"])
    ) {
      const phoneObj = parsePhoneNumber(
        finalEditedCustomer["phoneNumber"],
        "US"
      );
      finalEditedCustomer["phoneNumber"] = phoneObj.number;
    }

    if (
      finalEditedCustomer["altPhoneNumber"] &&
      typeof finalEditedCustomer["altPhoneNumber"] === "string" &&
      !parsePhoneNumber(finalEditedCustomer["altPhoneNumber"])
    ) {
      const altPhoneObj = parsePhoneNumber(
        finalEditedCustomer["altPhoneNumber"],
        "US"
      );
      finalEditedCustomer["altPhoneNumber"] = altPhoneObj.number;
    }

    asyncSaveEditedCustomer(finalEditedCustomer);
  };
  //
  const saveNote = (e) => {
    const matchingAction = getCurrentAction();

    asyncSaveNote(matchingAction);
  };
  //
  const savePhoneCall = (e) => {
    // savePhoneCall changes data in the database
    if (phoneCallBody !== "") {
      const enteredNote = {
        customer: currentCustomer._id,
        noteType: "phonecall",
        dialogue: dialogueBool,
        body: phoneCallBody,
        writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
        user: userContext.user._id,
      };

      const asyncSaveNote = async () => {
        const noteAdded = await addNote(enteredNote);

        const action = getCurrentAction();

        if (action) {
          const actionCompleted = await completeAction(action._id);
          if (!actionCompleted) {
            console.log("could not complete action");
          }
        }

        safeStateSetter(() => {
          if (noteAdded) {
            disableAllView();
            setCustomerPopulated(false);
            setPhoneCallBody("");
          }
        });
      };
      asyncSaveNote();
    } else {
      console.log("ERROR: data missing, could not save note");
    }
  };
  //
  const sendEmail = (e) => {
    if (
      currentCustomer &&
      currentCustomer.emailAddress &&
      currentEmailSubject &&
      currentEmail
    ) {
      const matchingAction = getCurrentAction();

      // console.log(matchingAction);

      asyncSendEmail(matchingAction, currentEmailSubject, currentEmail);
    } else {
      console.log("data missing: could not send email...");
    }
  };
  //
  const saveNewActionHandler = (e) => {
    // still have to deal with case where action is far into the future - I think userContext.getUser is the place
    // I say don't display the far future action here, but create it on the customer and check if there are any incomplete actions to do today that don't have an active cycle paired.  In this case, we create a new cycle with new actions and delete the action that was created in this function (also from the customer's array).
    if (newActionDueDate !== "") {
      asyncSaveNewAction();
    }
  };
  //
  const sendTextHandler = (e, action) => {
    safeStateSetter(() => {
      setCurrentTextBody("");
    });

    if (currentCustomer && currentCustomer.phoneNumber) {
      asyncSendText(action, currentTextBody);
    } else {
      console.log("data missing: could not send text...");
    }
  };
  //
  const updateAppointment = (e) => {
    switch (e.target.name) {
      case "appt-date":
        setApptDate(e.target.value);

        break;
      case "appt-time":
        setApptTime(e.target.value);

        break;
      case "appt-status":
        // console.log(e.target.value);
        setApptStatus(e.target.value);

        break;

      default:
        break;
    }
  };
  //
  const updateCurrentEmail = (e) => {
    safeStateSetter(() => {
      setCurrentEmail(e.target.value);
    });
  };
  //
  const updateCurrentEmailSubject = (e) => {
    safeStateSetter(() => {
      setCurrentEmailSubject(e.target.value);
    });
  };
  //
  const updateCurrentTextBody = (e) => {
    safeStateSetter(() => {
      setCurrentTextBody(e.target.value);
    });
  };
  //
  const updateCycleType = (e) => {
    const type = e.currentTarget.value;

    cycleTypeUpdate(type, currentCycle, setSelectedType);
  };
  //
  const updateInternetSource = (e) => {
    const source = e.currentTarget.value;

    internetSourceUpdate(source, currentCycle, setSelectedInternetSource);
  };
  //
  const updatePhoneCall = (e) => {
    safeStateSetter(() => {
      setPhoneCallBody(e.target.value);
    });

    const savePhoneCallButton = document.getElementById("save-new-phone-call");
    if (e.target.value !== "") {
      savePhoneCallButton.classList.remove("button-normal-disabled");
    } else {
      savePhoneCallButton.classList.add("button-normal-disabled");
    }
  };
  //
  // JSX
  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <main
          className="flex-column-center-center page-column"
          ref={mainBodyRef}
        >
          {viewMainGrid && (
            <div className="grid">
              <div className="grid-row" ref={topRow}>
                <div className="grid-item grid-item-row grid-item-short">
                  <div className="infobar-row">
                    <div className="infobar-column infobar-column-first">
                      <h1 className="main-title main-title-smaller dark-blue-text  margin-bottom-tiny center-text">
                        Cycle
                        <span className="margin-left-tiny small-text dark-blue-text">
                          {" "}
                          {currentCycle && currentCycle.cycleNumber}
                        </span>
                      </h1>
                      <div className="medium-text dark-blue-text center-text margin-bottom-tiny">
                        {currentCustomer && currentCustomer.firstName}{" "}
                        {currentCustomer && currentCustomer.lastName}
                      </div>
                      <div className="small-text dark-blue-text ">
                        {currentCycle &&
                          `${differenceInDays(
                            new Date(),
                            new Date(currentCycle.createdAt)
                          )} days old`}
                      </div>
                    </div>

                    <div className="infobar-column">
                      <div className="form-row">
                        {/* <label
                          htmlFor="stock-number"
                          className="small-text medium-blue-text margin-right-small"
                        >
                          Stock:
                        </label> */}
                        <input
                          type="text"
                          size="6"
                          className="form-field flex-grow small-text dark-blue-green-text"
                          name="stock"
                          placeholder="Stock"
                          value={currentStock && currentStock}
                          onChange={handleVehicleChange}
                        />
                      </div>
                      <div className="form-row form-row-bottom form-row-bottom-special">
                        {/* <label
                          htmlFor="vin"
                          className="small-text medium-blue-text margin-right-small"
                        >
                          VIN: 
                        </label> */}
                        <input
                          type="text"
                          className="form-field flex-grow small-text dark-blue-green-text"
                          name="vin"
                          placeholder="VIN"
                          size="17"
                          value={currentVin && currentVin}
                          onChange={handleVehicleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="infobar-row">
                    <div className="infobar-column">
                      <div className="form-row">
                        {/* <label
                          htmlFor="type"
                          className="small-text medium-blue-text margin-right-small"
                        >
                          Type:
                        </label> */}
                        <select
                          className="small-text dark-blue-green-text form-select flex-grow"
                          name="type"
                          value={currentVehicleType && currentVehicleType}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-type">Type</option>
                          <option value="new">New</option>
                          <option value="used">Used</option>
                        </select>
                      </div>

                      <div className="form-row form-row-bottom">
                        {/* <label
                          htmlFor="year"
                          className="small-text medium-blue-text margin-right-small"
                        >
                          Year:
                        </label> */}
                        <select
                          className="small-text dark-blue-green-text form-select flex-grow"
                          name="year"
                          value={currentYear && currentYear}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-year">Year</option>
                          {years.map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                          <option value="antique">Antique</option>
                        </select>
                      </div>
                    </div>
                    <div className="infobar-column">
                      <div className="form-row">
                        {/* <label
                          htmlFor="make"
                          className="small-text medium-blue-text margin-right-small"
                        >
                          Make:
                        </label> */}

                        <select
                          name="make"
                          className="small-text dark-blue-green-text form-select flex-grow"
                          value={currentMake && currentMake}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-make">Make</option>
                          {commonMakes &&
                            commonMakes.map((make) => {
                              return (
                                <option
                                  key={make.toLowerCase()}
                                  value={make.toLowerCase()}
                                >
                                  {make.toLowerCase()}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-row form-row-bottom">
                        {/* <label
                          htmlFor="model"
                          className="small-text medium-blue-text margin-right-small"
                        >
                          Model:
                        </label> */}
                        <select
                          name="model"
                          className="form-select form-select-oversize flex-grow small-text dark-blue-green-text"
                          value={currentModel && currentModel}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-model">Model</option>
                          {currentModels &&
                            currentModels.map((cur) => {
                              return (
                                <option
                                  key={cur.toLowerCase()}
                                  value={cur.toLowerCase()}
                                >
                                  {cur.toLowerCase()}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row">
                <div
                  className="grid-item grid-item-titled"
                  ref={customerInfoGrid}
                >
                  <div className="grid-item-heading medium-large-text white-text">
                    Customer Information
                  </div>
                  <div className="grid-item-body">
                    <div className="flex-row-center-center full-width">
                      <input
                        className="hidden"
                        type="file"
                        name="license-front"
                        ref={scanLicenseFrontButton}
                        onChange={handleAddLicenseFront}
                      />
                      <img
                        className="hidden"
                        alt="hidden"
                        src=""
                        id="hidden-license-front-img"
                      />
                      {license && face ? (
                        <img
                          className="avatar margin-right-small"
                          src={`data:image/jpeg;base64,${face}`}
                          alt="customer_face"
                          name="view-license"
                          onClick={handleDisplayChange}
                        />
                      ) : (
                        <button
                          className="button button-normal button-normal-smaller margin-right-small smaller-text"
                          onClick={(e) => {
                            e.preventDefault();
                            scanLicenseFrontButton.current.click();
                          }}
                        >
                          Add License
                        </button>
                      )}
                      <div
                        className="grid-item-body-section"
                        style={{
                          maxHeight: "unset",
                          width: "100%",
                        }}
                      >
                        <ul className="list smaller-text dark-blue-text">
                          <li>
                            Name:{" "}
                            {currentCustomer &&
                              currentCustomer.firstName &&
                              currentCustomer.lastName &&
                              `${currentCustomer.firstName} ${currentCustomer.lastName}`}
                          </li>
                          <li>
                            Email:{" "}
                            {currentCustomer &&
                              currentCustomer.emailAddress &&
                              currentCustomer.emailAddress}
                          </li>
                          <li>
                            Phone:{" "}
                            {currentCustomer &&
                              currentCustomer.phoneNumber &&
                              typeof currentCustomer.phoneNumber === "string" &&
                              parsePhoneNumber(
                                currentCustomer.phoneNumber
                              ).formatNational("US")}
                          </li>
                          <li>
                            Address:{" "}
                            {currentCustomer &&
                              currentCustomer.streetAddress &&
                              currentCustomer.streetAddress}
                          </li>
                          <li>
                            City:{" "}
                            {currentCustomer &&
                              currentCustomer.city &&
                              currentCustomer.city}
                          </li>
                          <li className="info-between">
                            <span>
                              State:{" "}
                              {currentCustomer &&
                                currentCustomer.state &&
                                currentCustomer.state}
                            </span>
                            <select
                              className="form-select padding-tiny tiny-text dark-blue-green-text"
                              value={selectedType}
                              onChange={updateCycleType}
                            >
                              <option value="type">type</option>
                              <option value="internet">Internet</option>
                              <option value="phone">Phone</option>
                              <option value="walkin">Walk-in</option>
                            </select>
                          </li>
                          <li className="info-between">
                            <span>
                              Zip:{" "}
                              {currentCustomer &&
                                currentCustomer.zipCode &&
                                currentCustomer.zipCode}
                            </span>
                            {/* {console.log(selectedInternetSource)} */}
                            <select
                              className="form-select padding-tiny tiny-text dark-blue-green-text"
                              value={selectedInternetSource}
                              onChange={updateInternetSource}
                            >
                              <option value="source">source</option>
                              {dealershipInternetSources &&
                                dealershipInternetSources.map((dis, idx) => (
                                  <option value={dis} key={idx}>
                                    {dis}
                                  </option>
                                ))}
                            </select>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="form-row form-row-bottom">
                      <button
                        onClick={handleDisplayChange}
                        name="view-edit-customer"
                        className="button-normal button-normal-smaller flex-grow small-text margin-right-large"
                      >
                        Edit
                      </button>
                      {currentCustomer && currentCycle && (
                        <button
                          onClick={() => {
                            localStorage.setItem(
                              "localCurrentCustomer",
                              JSON.stringify(currentCustomer)
                            );
                            localStorage.setItem(
                              "localCurrentCycle",
                              JSON.stringify(currentCycle)
                            );
                            if (textCheckerStatus) {
                              localStorage.setItem(
                                "localTextCheckerCount",
                                "1"
                              );
                              clearInterval(textCheckerStatus);
                            }
                            history.push("/customer");
                          }}
                          className="button-neutral button-neutral-smaller flex-grow small-text"
                        >
                          View Full Profile
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="grid-item grid-item-titled"
                  ref={cycleStatusGrid}
                >
                  <div className="grid-item-heading large-text white-text">
                    Cycle Status
                  </div>
                  <div className="grid-item-body">
                    <div className="grid-item-body-column-three-fifths">
                      <div className="form-row">
                        <label
                          htmlFor="status-active"
                          className="large-text dark-blue-text"
                        >
                          Active
                        </label>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            name="status"
                            id="status-active"
                            checked={cycleStatus ? true : false}
                            onChange={handleCycleStatusChange}
                          />
                          <div className="radio-button"></div>
                        </div>
                      </div>
                      <div className="form-row">
                        <label
                          htmlFor="status-not-active"
                          className="large-text dark-blue-text"
                        >
                          Not Active
                        </label>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            name="status"
                            id="status-not-active"
                            checked={!cycleStatus ? true : false}
                            onChange={handleCycleStatusChange}
                          />
                          <div className="radio-button"></div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <label
                        htmlFor="salesperson-select"
                        className="medium-text medium-blue-text margin-right-small"
                      >
                        Salesperson:
                      </label>

                      <select
                        name="salesperson"
                        id="salesperson"
                        className="form-select flex-grow small-text dark-blue-green-text"
                        value={currentSalesPerson}
                        onChange={handleSalesPersonChange}
                        ref={salesPersonSelector}
                      >
                        <option value="">BDC</option>
                        {currentSalesPeople.map((salesperson) => {
                          return (
                            <option
                              key={salesperson._id}
                              value={salesperson._id}
                            >
                              {`${salesperson.firstName} ${salesperson.lastName}`}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-row">
                      <label
                        htmlFor="hot-select"
                        className="medium-text medium-blue-text margin-right-small cursor-pointer"
                      >
                        Hot&nbsp;Lead?
                      </label>

                      <div
                        className="custom-checkbox full-width cursor-pointer"
                        onClick={() => {
                          // weird hack i had to do
                          const i = document.getElementById("hot-select");
                          i.click();
                        }}
                      >
                        <input
                          className="hot-select-input"
                          type="checkbox"
                          name="hot-select"
                          id="hot-select"
                          onChange={handleHotSelect}
                          checked={cycleHotStatus}
                        />
                        <div className="checkbox margin-right-small small-text center-text"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-item grid-item-titled" ref={contactGrid}>
                  <div className="grid-item-heading large-text white-text">
                    Contact
                  </div>
                  <div className="grid-item-body">
                    <div className="flex-column-center-evenly full-height full-width">
                      <button
                        onClick={handleViewContact}
                        className="button-normal button-full-row large-text"
                        name="text"
                      >
                        Text
                      </button>

                      <button
                        className="button-normal button-full-row large-text"
                        id="email-button"
                        onClick={handleViewContact}
                        name="view-email"
                      >
                        E-Mail
                      </button>

                      <button
                        onClick={handleViewContact}
                        className="button-normal button-full-row large-text"
                        name="phone"
                      >
                        Phone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row">
                <div
                  className="grid-item grid-item-titled"
                  ref={cycleNotesGrid}
                >
                  <div className="grid-item-heading large-text white-text">
                    Cycle Notes
                  </div>
                  <div className="grid-item-body">
                    <div className="grid-item-body-section">
                      {currentNotes &&
                        currentNotes.map((note, index) => (
                          <div className="section-row" key={index}>
                            <div className="section-row-info">
                              <div className="section-row-info-row dark-blue-text tiny-text">
                                {format(new Date(note.createdAt), "P")}
                              </div>
                              <div className="section-row-info-row dark-blue-text small-text">
                                {format(new Date(note.createdAt), "p")}
                              </div>
                              <div className="section-row-info-row section-row-info-row-bottom dark-blue-text small-text">
                                {note.writtenBy}
                              </div>
                            </div>
                            <div className="section-row-content note">
                              {note.noteType === "actiondeleted" ||
                              note.noteType === "actionincomplete" ||
                              note.noteType === "actionfailure" ||
                              note.noteType === "appointmentdeleted" ||
                              note.noteType === "cycleinactive" ||
                              note.noteType === "cycledeleted" ||
                              note.noteType === "cyclenothot" ? (
                                <div className="note-heading note-heading-negative">
                                  {note.noteType}
                                </div>
                              ) : (
                                <div className="note-heading note-heading-elevated">
                                  {note.noteType}
                                </div>
                              )}
                              <div className="note-body">{note.body}</div>
                              {note.emailData && (
                                <button
                                  className="button-normal margin-small small-text"
                                  id={JSON.stringify(note)}
                                  onClick={handleDisplayChange}
                                  name="view-email-from-note"
                                >
                                  View
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="form-row form-row-bottom">
                      {cycleNoteButtonsViewable ? (
                        <div className="full-width flex-row-center-between">
                          <button
                            onClick={handleDisplayChange}
                            name="add-note-view"
                            className="button-normal flex-grow small-medium-text margin-right-large"
                          >
                            Add Note
                          </button>

                          <button
                            onClick={handleAddStoreVisit}
                            className="button-elevated flex-grow small-medium-text"
                            ref={logStoreVisitButton}
                          >
                            Log Store Visit
                          </button>
                        </div>
                      ) : (
                        <div className="full-width flex-row-center-between fifty-opacity cursor-none">
                          <button
                            onClick={handleDisplayChange}
                            name="add-note-view"
                            className="button-normal flex-grow small-medium-text margin-right-large"
                          >
                            Add Note
                          </button>

                          <button
                            onClick={handleAddStoreVisit}
                            className="button-elevated flex-grow small-medium-text"
                            ref={logStoreVisitButton}
                          >
                            Log Store Visit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="grid-item grid-item-titled"
                  ref={cycleActionsGrid}
                >
                  <div className="grid-item-heading large-text white-text">
                    Cycle Actions
                  </div>
                  <div className="grid-item-body">
                    <div className="grid-item-body-section" id="parent">
                      {currentActions &&
                        currentActions.map((action) => {
                          return (
                            <div
                              className="module module-bottom-margin"
                              key={action._id}
                              id={JSON.stringify(action)}
                              onClick={handleActionClick}
                            >
                              {action.status === "complete" && (
                                <div className="module-section module-section-elevated white-text center-text">
                                  {action.status}
                                </div>
                              )}
                              {action.status === "incomplete" && (
                                <div className="module-section module-section-normal white-text center-text">
                                  {action.status}
                                </div>
                              )}
                              {action.status === "failure" && (
                                <div className="module-section module-section-negative white-text center-text">
                                  {action.status}
                                </div>
                              )}

                              <div className="module-main module-main-right">
                                <div className="dark-blue-text">
                                  {action.actionType}
                                </div>
                                <div className="dark-blue-text">
                                  Due {format(new Date(action.dueDate), "P")}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="form-row form-row-bottom">
                      {editScheduleButtonViewable ? (
                        <button
                          onClick={handleDisplayChange}
                          className="button-neutral flex-grow small-medium-text"
                          name="view-edit-action-schedule"
                        >
                          Edit Schedule
                        </button>
                      ) : (
                        <button
                          onClick={handleDisplayChange}
                          className="button-neutral button-neutral-disabled flex-grow small-medium-text"
                          name="view-edit-action-schedule"
                        >
                          Edit Schedule
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="grid-item grid-item-titled"
                  ref={cycleApptsGrid}
                >
                  <div className="grid-item-heading medium-large-text white-text">
                    Cycle Appointments
                  </div>
                  <div className="grid-item-body">
                    <div className="grid-item-body-section">
                      <div className="flex-column-center-center">
                        {/* this too, like notes, needs to be more interactive while still maintaining a feeling of minimalism */}
                        {currentCycle &&
                          currentCycle.appointments &&
                          currentCycle.appointments.map(
                            (appointment, index) => {
                              return (
                                <button
                                  onClick={loadAppointmentInfo}
                                  className="button-neutral full-width tiny-text margin-bottom-small"
                                  key={index}
                                >
                                  {" "}
                                  {appointment.date &&
                                    format(
                                      new Date(appointment.date),
                                      "Pp"
                                    )}{" "}
                                  -{" "}
                                  {/* appointment.setter is an objectId here in the database, need to make sure we get the subdocument */}
                                  {`${appointment.setter.firstName} ${appointment.setter.lastName}`}{" "}
                                </button>
                              );
                            }
                          )}
                      </div>
                    </div>
                    <div className="form-row form-row-bottom">
                      <button
                        href="#popup-appointment-info"
                        className="button-normal flex-grow small-medium-text"
                        name="set-edit-appointment"
                        id="add-appointment-button"
                        onClick={loadAppointmentInfo}
                      >
                        Set / Edit Appointment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewActionSchedule && (
            <ActionSchedule
              currentActions={currentActions}
              currentCustomerId={currentCustomer._id}
              currentCycleId={currentCycle._id}
              deleteSelectedActionsHandler={deleteSelectedActionsHandler}
              followUpTemplates={followUpTemplates}
              handleActionSelect={handleActionSelect}
              handleCancel={handleCancel}
              handleDisplayChange={handleDisplayChange}
              selectAllActions={selectAllActions}
              selectedFutureActionIds={selectedFutureActionIds}
            />
          )}

          {viewAppointmentInfo && (
            <CycleAppointmentInfo
              apptDate={apptDate}
              apptId={apptId}
              apptStatus={apptStatus}
              apptTime={apptTime}
              currentCustomer={currentCustomer}
              currentCycle={currentCycle}
              deleteAppointmentHandler={deleteAppointmentHandler}
              handleCancel={handleCancel}
              saveAppointment={saveAppointment}
              updateAppointment={updateAppointment}
            />
          )}

          {viewCompleteWebLead && (
            <CycleWebLeadResponder
              currentCustomer={currentCustomer}
              currentEmailSubject={currentEmailSubject}
              currentEmail={currentEmail}
              emailTemplates={emailTemplates}
              handleCancel={handleCancel}
              populateTemplate={populateTemplate}
              sendEmail={sendEmail}
              updateCurrentEmail={updateCurrentEmail}
              updateCurrentEmailSubject={updateCurrentEmailSubject}
            />
          )}

          {viewComposeNewAction && (
            <ComposeNewAction
              handleCancel={handleCancel}
              handleDisplayChange={handleDisplayChange}
              handleNewActionDueDateChange={handleNewActionDueDateChange}
              newActionDueDate={newActionDueDate}
              saveNewActionHandler={saveNewActionHandler}
            />
          )}

          {viewComposeNewEmail && (
            <div className="popup">
              <NewEmailComposer
                currentCustomer={currentCustomer}
                currentNewEmail={currentEmail}
                currentNewEmailSubject={currentEmailSubject}
                emailTemplates={emailTemplates}
                handleCancel={handleCancel}
                populateTemplate={populateTemplate}
                sendNewEmail={sendEmail}
                special={false}
                updateCurrentNewEmail={updateCurrentEmail}
                updateCurrentNewEmailSubject={updateCurrentEmailSubject}
              />
            </div>
          )}

          {viewComposeReply && (
            <div className="popup">
              <EmailReplyComposer
                currentCustomer={currentCustomer}
                currentEmailReply={currentEmail}
                currentEmailReplySubject={currentEmailSubject}
                handleCancel={handleCancel}
                sendReplyHandler={sendEmail}
                special={false}
                updateCurrentEmailReply={updateCurrentEmail}
                updateCurrentEmailReplySubject={updateCurrentEmailSubject}
              />
            </div>
          )}

          {viewCycleNotes && (
            <div className="popup">
              <NoteComposer
                currentNote={currentNote}
                currentNotes={currentNotes}
                handleCancel={handleCancel}
                handleDialogueChange={handleDialogueChange}
                handleDisplayChange={handleDisplayChange}
                handleNoteChange={handleNoteChange}
                saveNote={saveNote}
                saveNoteButtonViewable={saveNoteButtonViewable}
              />
            </div>
          )}

          {viewEditCustomer && (
            <div className="popup">
              <CustomerEditor
                editedCustomer={editedCustomer}
                handleCancel={handleCancel}
                handleEditCustomer={handleEditCustomer}
                saveEditedCustomerHandler={saveEditedCustomerHandler}
                special={false}
              />
            </div>
          )}

          {viewEmail && (
            <div className="popup">
              <EmailViewer
                currentCustomer={currentCustomer}
                currentEmail={{
                  subject: currentEmailSubject,
                  body: currentEmail,
                }}
                handleCancel={handleCancel}
                handleDisplayChange={handleDisplayChange}
                special={false}
              />
            </div>
          )}

          {viewPhoneCall && (
            <div className="popup">
              <LogPhoneCall
                handleCancel={handleCancel}
                handleDialogueCall={handleDialogueChange}
                phoneCallBody={phoneCallBody}
                savePhoneCall={savePhoneCall}
                special={false}
                updatePhoneCall={updatePhoneCall}
              />
            </div>
          )}

          {viewTextConversation && (
            <TextConversationViewer
              currentCustomer={currentCustomer}
              currentTextBody={currentTextBody}
              handleCancel={handleCancel}
              sendTextHandler={sendTextHandler}
              setTextCheckerStatus={setTextCheckerStatus}
              textCheckerStatus={textCheckerStatus}
              updateCurrentTextBody={updateCurrentTextBody}
            />
          )}

          {viewLicense && (
            <LicenseViewer handleCancel={handleCancel} license={license} />
          )}
        </main>
      )}
    </Fragment>
  );
};

export default Cycle;
