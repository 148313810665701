import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO, differenceInDays, getYear } from "date-fns";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import emailValidator from "email-validator";
import UserContext from "../context/users/userContext";
import EmailViewer from "./popups/EmailViewer";
import NoteComposer from "./popups/NoteComposer";

import {
  addNote,
  addStoreVisit,
  checkTexts,
  completeAction,
  createNewCycle,
  deleteAppointment,
  deleteCustomer,
  deleteSelectedActions,
  getAppointmentsOfCustomer,
  getSubdocumentsOfCustomer,
  saveAppt,
  saveEditedCustomer,
  saveNewAction,
  sendReply,
  sendText,
} from "../functions/customerFunctions";
import DealershipContext from "../context/dealership/dealershipContext";
import { getModels, getCityStateFromZip } from "../util/addCustomerApiCalls";
import commonMakes from "../util/commonMakes";
import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";

const Customer = () => {
  //
  //
  //

  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);
  //
  const history = useHistory();
  //
  const bottomOfTexts = useRef();
  const enteredZip = useRef();
  const mainBodyRef = useRef();
  //
  const [activeCycle, setActiveCycle] = useState(null);
  const [activeCycleFound, setActiveCycleFound] = useState(false);
  const [appointmentButtonViewable, setAppointmentButtonViewable] =
    useState(true);
  const [apptDate, setApptDate] = useState("");
  const [apptId, setApptId] = useState("");
  const [apptStatus, setApptStatus] = useState("");
  const [apptTime, setApptTime] = useState("");
  const [contactButtonsViewable, setContactButtonsViewable] = useState(true);
  const [currentActions, setCurrentActions] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentEmailSubject, setCurrentEmailSubject] = useState("");
  const [currentMake, setCurrentMake] = useState("");
  const [currentModel, setCurrentModel] = useState("");
  const [currentModels, setCurrentModels] = useState();
  const [currentNote, setCurrentNote] = useState("");
  const [currentNotes, setCurrentNotes] = useState([]);
  const [currentDeals, setCurrentDeals] = useState([]);
  const [currentSalesPeople, setCurrentSalesPeople] = useState([]);
  const [currentSalesPerson, setCurrentSalesPerson] = useState("");
  const [currentStock, setCurrentStock] = useState("");
  const [currentStoreVisit, setCurrentStoreVisit] = useState(null);
  const [currentTextBody, setCurrentTextBody] = useState("");
  const [currentTexts, setCurrentTexts] = useState([]);
  const [currentVehicleType, setCurrentVehicleType] = useState("");
  const [currentVin, setCurrentVin] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [customerAppointments, setCustomerAppointments] = useState([]);
  const [customerPopulated, setCustomerPopulated] = useState(false);
  const [dealershipShortName, setDealershipShortName] = useState("");
  const [dialogueBool, setDialogueBool] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [editCustomerButtonViewable, setEditCustomerButtonViewable] =
    useState(true);
  const [editScheduleButtonViewable, setEditScheduleButtonViewable] =
    useState(true);
  const [enteredStock, setEnteredStock] = useState("");
  const [enteredVehicleType, setEnteredVehicleType] = useState("");
  const [enteredMake, setEnteredMake] = useState("");
  const [enteredYear, setEnteredYear] = useState("");
  const [enteredModel, setEnteredModel] = useState("");
  const [enteredVin, setEnteredVin] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newActionDueDate, setNewActionDueDate] = useState("");
  const [newCycleSalesPerson, setNewCycleSalesPerson] = useState(null);
  const [newCycleSalesPersonId, setNewCycleSalesPersonId] = useState("");
  const [newCycleType, setNewCycleType] = useState("walkin");
  const [phoneCallBody, setPhoneCallBody] = useState("");
  const [printReportButtonViewable, setPrintReportButtonViewable] =
    useState(true);
  const [removeButtonViewable, setRemoveButtonViewable] = useState(true);
  const [saveNoteButtonViewable, setSaveNoteButtonViewable] = useState(false);
  const [selectedFutureActionIds, setSelectedFutureActionIds] = useState([]);
  const [textCheckerStatus, setTextCheckerStatus] = useState(null);
  const [twilioAltEmail, setTwilioAltEmail] = useState("");
  const [twilioAltPhone, setTwilioAltPhone] = useState("");
  const [twilioEmail, setTwilioEmail] = useState("");
  const [twilioPhone, setTwilioPhone] = useState("");
  const [viewActionSchedule, setViewActionSchedule] = useState(false);
  const [viewAppointmentInfo, setViewAppointmentInfo] = useState(false);
  const [viewComposeNewAction, setViewComposeNewAction] = useState(false);
  const [viewComposeNewEmail, setViewComposeNewEmail] = useState(false);
  const [viewCreateNewCycle, setViewCreateNewCycle] = useState(false);
  const [viewCustomerNotes, setViewCustomerNotes] = useState(false);
  const [viewEditCustomer, setViewEditCustomer] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);
  const [viewMainGrid, setViewMainGrid] = useState(true);
  const [viewPhoneCall, setViewPhoneCall] = useState(false);
  const [viewStoreVisit, setViewStoreVisit] = useState(false);
  const [viewTextConversation, setViewTextConversation] = useState(false);
  //
  useEffect(() => {
    if (!errorState && !customerPopulated && loading) {
      loadCustomer();
    }

    if (bottomOfTexts.current) {
      bottomOfTexts.current.click();
    }
    //eslint-disable-next-line
  }, [customerPopulated]);
  //
  //

  //
  const years = [];
  //
  const thisYear = getYear(parseISO(new Date().toISOString()));
  //
  years.push(thisYear + 1);
  //
  for (let i = 0; i < 25; i++) {
    years.push(thisYear - i);
  }
  //
  //
  //
  const asyncAddStoreVisit = async (cycle, customer) => {
    try {
      const userRole = await userContext.checkUserRole(newCycleSalesPersonId);

      if (userRole === "bdc" || userRole === "bdcmanager") {
        alert("Please select a salesperson before adding a new Store Visit.");
      } else {
        const storeVisitAdded = await addStoreVisit(cycle._id);

        localStorage.setItem("localCurrentCustomer", JSON.stringify(customer));
        localStorage.setItem("localCurrentCycle", JSON.stringify(cycle));

        if (!storeVisitAdded) {
          alert("could not add store visit");
        }

        history.push("/cycle");
      }
    } catch (error) {
      console.log("Cycle.asyncAddStoreVisit: ", error.message);
    }
  };
  //
  const asyncCheckTexts = async () => {
    const newTextFound = await checkTexts(currentCustomer);

    safeStateSetter(() => {
      if (newTextFound) {
        newTextFound.sort((a, b) => {
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
          } else {
            return -1;
          }
        });
        setCurrentTexts(newTextFound);
      }
    });
  };
  //
  const asyncCreateNewCycle = async () => {
    try {
      const cycleCreated = await createNewCycle(
        currentCustomer,
        newCycleType,
        {
          stock: currentStock,
          type: currentVehicleType,
          make: currentMake,
          year: currentYear,
          model: currentModel,
          vin: currentVin,
        },
        newCycleSalesPersonId
      );

      if (cycleCreated) {
        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(currentCustomer)
        );
        localStorage.setItem(
          "localCurrentCycle",
          JSON.stringify(cycleCreated.cycle)
        );
        if (newCycleType === "phone") {
          history.push("/appointment-conditional");
        } else {
          asyncAddStoreVisit(cycleCreated.cycle, currentCustomer);
        }

        history.push("/cycle");
      } else {
        throw new Error("could not create new cycle...");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  //
  const asyncDeleteAppointment = async (appId) => {
    try {
      const appointmentDeleted = await deleteAppointment(appId);

      if (!appointmentDeleted) {
        console.log("appointment NOT deleted properly...");
      }

      disableAllView();
      window.location.reload();
    } catch (error) {
      console.log("Cycle.deleteAppointment: ", error.message);
    }
  };
  //
  const asyncDeleteCycle = async (cycleId) => {
    try {
      const res = await axios.post(
        "/api/v1/customer/delete-cycle-by-id",
        { cycleId },
        axiosConfig
      );

      if (res.data.success && currentCustomer) {
        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(currentCustomer)
        );
        safeStateSetter(() => {
          setCustomerPopulated(false);
        });
      } else {
        throw new Error("could not delete cycle and associated data");
      }
    } catch (error) {
      console.log("Customer.asyncDeleteCycle: ", error.message);
    }
  };
  //
  const asyncGetBdcManager = async () => {
    try {
      const bdcManager = await dealershipContext.getBdcManager();
      setNewCycleSalesPerson("BDC");
      return bdcManager;
    } catch (error) {
      console.log("AddCustomer.asyncGetBdcManager: ", error.message);
    }
  };
  //
  const asyncSaveEditedCustomer = async (editedCustomerObj) => {
    try {
      // edit database here

      const customerSaved = await saveEditedCustomer(editedCustomerObj);

      if (customerSaved) {
        disableAllView();
        safeStateSetter(() => {
          setCustomerPopulated(false);
        });
      } else {
        console.log("could not save edited customer...");
      }
    } catch (error) {
      console.log("Cycle.asyncSaveEditedCustomer: ", error.message);
    }
  };
  //
  const asyncSaveNewAction = async () => {
    try {
      let currentActiveCycleId = "";

      currentCustomer.cycles.forEach((cycle) => {
        if (cycle.active) {
          currentActiveCycleId = cycle._id;
        }
      });

      let mostRecentDeal = null;

      let actionType;

      if (
        !currentActiveCycleId ||
        currentActiveCycleId === "" ||
        currentActiveCycleId.length !== 0
      ) {
        // console.log("supposed to set most recent deal");
        if (currentDeals[0]) {
          // console.log("there is a deal");
          currentDeals.forEach((curDeal) => {
            if (mostRecentDeal) {
              // console.log("there is already a mostRecentDeal");
              if (
                new Date(curDeal.createdAt).getTime() >
                new Date(mostRecentDeal.createdAt).getTime()
              ) {
                // console.log(
                //   "current deal createdAt is newer than existing mostRecentDeal"
                // );
                mostRecentDeal = curDeal;
              } else {
                console.log(
                  "possible parsing error: ",
                  new Date(curDeal.createdAt).getTime(),
                  new Date(mostRecentDeal.createdAt).getTime()
                );
              }
            } else {
              // console.log("mostRecentDeal = curDeal = ", curDeal);
              mostRecentDeal = curDeal;
            }
          });
        }

        if (mostRecentDeal) {
          actionType = "sold-follow-up";
        } else {
          actionType = "unsold-follow-up";
        }
      } else {
        // console.log(
        //   "exists and is not an empty string",
        //   currentActiveCycleId,
        //   typeof currentActiveCycleId,
        //   currentActiveCycleId.length
        // );
      }

      const newActionSaved = await saveNewAction(
        new Date(newActionDueDate),
        currentCustomer._id,
        currentActiveCycleId,
        actionType
      );

      if (newActionSaved) {
        disableAllView();
        window.location.reload();
      } else {
        console.log("could not save new action");
      }
    } catch (error) {
      console.log("Cycle.asyncSaveNewAction: ", error.message);
    }
  };
  //
  const saveNote = (e) => {
    safeStateSetter(() => {
      setLoading(true);
    });

    (async function () {
      const savedNote = await addNote({
        customer: currentCustomer._id,
        noteType: "standard",
        body: currentNote,
        writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
        user: userContext.user._id,
        dialogue: dialogueBool,
      });

      if (savedNote) {
        disableAllView();
        setCurrentNote("");
        setCustomerPopulated(false);
      } else {
        console.log("no saved note received");
      }
    })();
  };
  //
  const asyncSendEmail = async (action, subject, body) => {
    const emailSent = await sendReply(currentCustomer, subject, body);

    if (emailSent) {
      let enteredNote;

      if (action) {
        if (
          action.actionType === "unsold-follow-up" ||
          action.actionType === "email-received"
        ) {
          enteredNote = {
            customer: currentCustomer._id,
            noteType: "emailreply",
            dialogue: true,
            body: "E-mail reply sent!",
            writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
            emailData: {
              from: `${dealershipShortName}-assistance@leadzen.cc`,
              subject: currentEmailSubject,
              emailBody: currentEmail,
            },
            user: userContext.user._id,
          };
        } else {
          console.log("invalid actionType");
        }
        const actionCompleted = await completeAction(action._id);
        if (actionCompleted) {
          // console.log("action completed");
        } else {
          console.log("action not completed");
        }
      } else {
        enteredNote = {
          customer: currentCustomer._id,
          noteType: "newemail",
          dialogue: false,
          body: "New E-Mail Sent!",
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          emailData: {
            from: `${dealershipShortName}-assistance@leadzen.cc`,
            subject: currentEmailSubject,
            emailBody: currentEmail,
          },
          user: userContext.user._id,
        };
        console.log("Customer enteredNote: ", enteredNote);
      }

      const noteAdded = await addNote(enteredNote);
      if (noteAdded) {
        // console.log("note added");
      } else {
        console.log("could not add note");
      }

      window.location.reload();
    } else {
      console.log("email not sent...");
    }
  };
  //
  const asyncSendText = async (action, body) => {
    try {
      const textSent = await sendText(currentCustomer, body);

      if (textSent) {
        // console.log("text was sent correctly");

        const completedAction = await completeAction(action._id);

        if (completedAction) {
          // console.log("action was completed correctly");
          safeStateSetter(() => {
            disableAllView();
            localStorage.setItem("localTextCheckerCount", "1");
            clearInterval(textCheckerStatus);
            setCurrentTextBody("");
            setViewMainGrid(true);
            setCustomerPopulated(false);
          });
        } else {
          console.log("action was NOT completed correctly");
          window.location.reload();
        }
        window.location.reload();
      } else {
        console.log("text was NOT sent correctly");
        window.location.reload();
      }
    } catch (error) {
      console.log("Cycle.asyncSendText: ", error.message);
    }
  };
  //
  const asyncSetFullStoreVisit = async (sv) => {
    try {
      safeStateSetter(() => {
        setLoading(true);
      });

      const dateIso = `${
        new Date(sv.date).toISOString().split("T")[0]
      }T00:00:00Z`;

      const storeVisitsWithData = await dealershipContext.getStoreVisitsAndData(
        new Date(dateIso)
      );

      if (!storeVisitsWithData) {
        window.location.reload();
      }

      storeVisitsWithData.forEach((fullVisit) => {
        if (fullVisit._id === sv._id) {
          safeStateSetter(() => {
            setCurrentStoreVisit(fullVisit);
            setCurrentSalesPerson(fullVisit.cycle.primarySales._id);
            setEnteredStock(
              (fullVisit.cycle.vehicle && fullVisit.cycle.vehicle.stock) || ""
            );
            setEnteredVehicleType(
              (fullVisit.cycle.vehicle && fullVisit.cycle.vehicle.type) || ""
            );
            setEnteredMake(
              (fullVisit.cycle.vehicle && fullVisit.cycle.vehicle.make) || ""
            );
            setEnteredYear(
              (fullVisit.cycle.vehicle && fullVisit.cycle.vehicle.year) || ""
            );
            setEnteredModel(
              (fullVisit.cycle.vehicle && fullVisit.cycle.vehicle.model) || ""
            );
            setEnteredVin(
              (fullVisit.cycle.vehicle && fullVisit.cycle.vehicle.vin) || ""
            );
          });
        }
      });

      safeStateSetter(() => {
        setViewMainGrid(false);
        setViewStoreVisit(true);
        setLoading(false);
      });
    } catch (error) {
      console.log(error.message);
    }
  };
  //
  const callGetCityStateFromZip = async (zip) => {
    try {
      const result = await getCityStateFromZip(zip);
      safeStateSetter(() => {
        if (result) {
          setEditedCustomer({
            ...editedCustomer,
            city: result.city,
            state: result.state,
            zipCode: zip,
          });
        } else {
          setEditedCustomer({
            ...editedCustomer,
            city: "",
            state: "",
            zipCode: zip,
          });
        }
      });
    } catch (error) {
      console.log("Cycle.callGetCityStateFromZip: ", error.message);
      safeStateSetter(() => {
        setEditedCustomer({ ...editedCustomer, city: "", state: "" });
      });
    }
  };
  //
  const callGetModels = async (year, make) => {
    try {
      const result = await getModels(year, make);
      safeStateSetter(() => {
        if (result) {
          setCurrentModels(result);
        }
      });
      if (result) {
        return true;
      }
    } catch (error) {
      //
    }
  };
  //
  const createNewCycleHandler = (e) => {
    if (newCycleType === "walkin" && !newCycleSalesPersonId) {
      alert("a walk-in must have a salesperson!");
      return;
    } else {
      asyncCreateNewCycle();
    }
  };
  //
  const deleteAppointmentHandler = (e) => {
    asyncDeleteAppointment(apptId);
  };
  //
  const deleteCustomerHandler = (e) => {
    // ONLY OWNERS SHOULD BE ALLOWED TO DO THIS
    const confirmed = window.confirm(
      "This customer and all associated data will be lost forever - are you sure?"
    );

    const asyncDeleteCustomer = async () => {
      try {
        const deleted = await deleteCustomer(currentCustomer._id);

        if (deleted) {
          history.push("/");
        }
      } catch (error) {
        alert("could not delete customer");
      }
    };

    if (confirmed) {
      asyncDeleteCustomer();
    }
  };
  //
  const deleteSelectedActionsHandler = async () => {
    // this changes the database
    try {
      const actionsDeleted = await deleteSelectedActions(
        selectedFutureActionIds
      );
      // here, the actions should have been deleted from the database and purged from the Customer.actions array
      if (!actionsDeleted) {
        console.log("actions NOT deleted properly...");
      }
      disableAllView();
      window.location.reload();
    } catch (error) {
      console.log("Cycle.asyncDeleteSelectedActions: ", error.message);
    }
  };
  //
  const disableAllView = () => {
    // safeStateSetter(() => {
    setViewActionSchedule(false);
    setViewAppointmentInfo(false);
    setViewComposeNewAction(false);
    setViewComposeNewEmail(false);
    setViewCreateNewCycle(false);
    setViewCustomerNotes(false);
    setViewEditCustomer(false);
    setViewEmail(false);
    //***
    setViewMainGrid(true);
    //***
    setViewPhoneCall(false);
    setViewStoreVisit(false);
    setViewTextConversation(false);
    // });
  };
  //
  const getCurrentAction = () => {
    let matchingAction = null;
    const pastActions = [];
    currentCustomer.actions.forEach((act) => {
      const millisecondsInTwelveHours = 43200000;
      if (
        new Date().getTime() >
        new Date(act.dueDate).getTime() - millisecondsInTwelveHours
      ) {
        pastActions.push(act);
      }
    });

    const relevantPastActions = [];

    if (pastActions[0]) {
      pastActions.forEach((pastAct) => {
        if (
          differenceInDays(new Date(), new Date(pastAct.dueDate)) === 0 &&
          pastAct.status === "incomplete"
        ) {
          relevantPastActions.push(pastAct);
        }
      });
    }

    if (relevantPastActions[0]) {
      relevantPastActions.forEach((relPastAct) => {
        if (relPastAct.actionType === "web-lead") {
          matchingAction = relPastAct;
        }
      });

      if (!matchingAction) {
        matchingAction = relevantPastActions[0];
      }
    }

    if (matchingAction) {
      return matchingAction;
    } else {
      return false;
    }
  };
  //
  const handleActionClick = (e) => {
    let actionElement = e.target;
    while (actionElement.parentElement.getAttribute("id") !== "parent") {
      actionElement = actionElement.parentElement;
    }

    localStorage.setItem("localCurrentAction", actionElement.id);

    history.push("/actions");
  };
  //
  const handleActionSelect = (e) => {
    if (e.target.checked) {
      safeStateSetter(() => {
        setSelectedFutureActionIds([...selectedFutureActionIds, e.target.id]);
      });
    } else {
      if (selectedFutureActionIds.includes(e.target.id)) {
        const localActionIds = [];
        selectedFutureActionIds.forEach((actId) => {
          if (actId !== e.target.id) {
            localActionIds.push(actId);
          }
        });
        safeStateSetter(() => {
          setSelectedFutureActionIds(localActionIds);
        });
      }
    }
  };
  //
  const handleCancel = (e) => {
    let elementToCancel = e.target;

    while (!elementToCancel.id) {
      elementToCancel = elementToCancel.parentElement;
    }

    switch (elementToCancel.id) {
      case "hide-edit-customer":
        disableAllView();
        break;

      case "hide-customer-notes":
        disableAllView();
        break;

      case "hide-text-conversation":
        localStorage.setItem("localTextCheckerCount", "1");
        clearInterval(textCheckerStatus);
        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(currentCustomer)
        );
        disableAllView();
        break;

      case "hide-compose-new-email":
        disableAllView();
        break;

      case "hide-view-email":
        setCurrentEmail("");
        setCurrentEmailSubject("");
        disableAllView();
        break;

      case "hide-phone-call":
        disableAllView();
        break;

      case "hide-edit-action-schedule":
        disableAllView();
        break;

      case "hide-compose-new-action":
        disableAllView();
        break;

      case "hide-appointment-info":
        disableAllView();
        break;

      case "hide-create-new-cycle":
        disableAllView();

        break;

      case "hide-store-visit":
        disableAllView();
        break;

      case "hide-cycle-notes":
        disableAllView();
        break;

      default:
        break;
    }
  };
  //
  const handleCycleClick = (e) => {
    // this is already stringified
    localStorage.setItem("localCurrentCycle", e.currentTarget.id);
    history.push("/cycle");
  };
  //
  const handleCycleTypeChange = (e) => {
    // console.log(e.target.value);
    safeStateSetter(() => {
      setNewCycleType(e.target.value);
    });
  };
  //
  const handleDealClick = (e) => {
    asyncSetFullStoreVisit(JSON.parse(e.currentTarget.id));
  };
  //
  const handleDeleteCycle = (e) => {
    const confirmed = window.confirm(
      "This will delete the cycle and all associated appointments and store visits - are you sure???"
    );

    if (confirmed) {
      asyncDeleteCycle(e.currentTarget.id);
    }
  };
  //
  const handleDialogueChange = (e) => {
    if (e.target.id === "contact-bool-true") {
      safeStateSetter(() => {
        setDialogueBool(true);
      });
    } else {
      safeStateSetter(() => {
        setDialogueBool(false);
      });
    }
  };
  //
  const handleDisplayChange = (e) => {
    const element = e.target;
    // safeStateSetter(() => {
    switch (element.name) {
      case "view-edit-customer":
        disableAllView();
        if (
          currentCustomer.phoneNumber &&
          typeof currentCustomer.phoneNumber === "string"
        ) {
          if (parsePhoneNumber(currentCustomer.phoneNumber)) {
            setEditedCustomer({
              ...currentCustomer,
              phoneNumber: parsePhoneNumber(
                currentCustomer.phoneNumber
              ).formatNational("US"),
            });
          } else {
            setEditedCustomer(currentCustomer);
          }
        } else {
          setEditedCustomer(currentCustomer);
        }
        setViewMainGrid(false);
        setViewEditCustomer(true);
        break;
      case "view-add-note":
        disableAllView();
        setViewMainGrid(false);
        setViewCustomerNotes(true);
        break;
      case "view-edit-action-schedule":
        disableAllView();

        setViewActionSchedule(true);
        setViewMainGrid(false);

        break;
      case "view-compose-new-action":
        disableAllView();
        setViewComposeNewAction(true);
        setViewMainGrid(false);
        break;

      case "view-compose-reply":
        setCurrentEmailSubject("");
        setCurrentEmail("");
        disableAllView();
        setViewComposeNewEmail(true);
        setViewMainGrid(false);
        break;

      case "view-create-new-cycle":
        disableAllView();
        setViewCreateNewCycle(true);
        setViewMainGrid(false);
        break;

      case "view-email-from-note":
        const { emailData } = JSON.parse(e.currentTarget.id);
        setCurrentEmailSubject(emailData.subject);
        setCurrentEmail(emailData.emailBody);
        disableAllView();
        setViewEmail(true);
        setViewMainGrid(false);

        break;

      default:
        break;
    }
    // });
  };
  //
  const handleEditCustomer = (e) => {
    switch (e.target.name) {
      case "first-name":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, firstName: e.target.value });
        });
        break;
      case "last-name":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, lastName: e.target.value });
        });
        break;
      case "phone-number":
        const initialPhoneNumber = e.target.value;
        const digits = initialPhoneNumber.match(/\d/g);
        let phoneNumber;
        digits
          ? (phoneNumber = digits.join(""))
          : (phoneNumber = initialPhoneNumber);
        let formattedNumber = "";
        if (
          // should be formatted
          (digits && digits.length === 10 && digits[0] !== "1") ||
          (digits && digits.length === 11 && digits[0] === "1")
        ) {
          formattedNumber = new AsYouType("US").input(phoneNumber);
          const phoneObject = parsePhoneNumber(formattedNumber, "US");
          if (phoneObject && phoneObject.isValid()) {
            safeStateSetter(() => {
              setTwilioPhone(phoneObject.number);
              setEditedCustomer({
                ...editedCustomer,
                phoneNumber: parsePhoneNumber(phoneObject.number)
                  ? parsePhoneNumber(phoneObject.number).formatNational("US")
                  : phoneNumber,
              });
            });
          } else {
            safeStateSetter(() => {
              setEditedCustomer({ ...editedCustomer, phoneNumber });
            });
          }
        } else {
          // should not be formatted
          safeStateSetter(() => {
            setEditedCustomer({ ...editedCustomer, phoneNumber });
          });
        }
        break;
      case "email":
        if (emailValidator.validate(e.target.value)) {
          safeStateSetter(() => {
            setTwilioEmail(e.target.value);
          });
        }
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            emailAddress: e.target.value,
          });
        });
        break;
      case "zip-code":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, zipCode: e.target.value });
        });
        if (e.target.value.length === 5) {
          callGetCityStateFromZip(e.target.value);
        }
        break;
      case "address":
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            streetAddress: e.target.value,
          });
        });
        break;
      case "city":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, city: e.target.value });
        });
        break;
      case "state":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, state: e.target.value });
        });
        break;
      case "alt-phone-number":
        const initialAltPhoneNumber = e.target.value;
        const altDigits = initialAltPhoneNumber.match(/\d/g);
        let altPhoneNumber;
        altDigits
          ? (altPhoneNumber = altDigits.join(""))
          : (altPhoneNumber = initialAltPhoneNumber);
        let formattedAltNumber = "";
        if (
          // should be formatted
          (altDigits && altDigits.length === 10 && altDigits[0] !== "1") ||
          (altDigits && altDigits.length === 11 && altDigits[0] === "1")
        ) {
          formattedAltNumber = new AsYouType("US").input(altPhoneNumber);
          const altPhoneObject = parsePhoneNumber(formattedAltNumber, "US");
          if (altPhoneObject && altPhoneObject.isValid()) {
            safeStateSetter(() => {
              setTwilioAltPhone(altPhoneObject.number);
              setEditedCustomer({
                ...editedCustomer,
                altPhoneNumber: parsePhoneNumber(altPhoneObject.number)
                  ? parsePhoneNumber(altPhoneObject.number).formatNational()
                  : altPhoneNumber,
              });
            });
          } else {
            safeStateSetter(() => {
              setEditedCustomer({ ...editedCustomer, altPhoneNumber });
            });
          }
        } else {
          // should not be formatted
          safeStateSetter(() => {
            setEditedCustomer({ ...editedCustomer, altPhoneNumber });
          });
        }
        break;
      case "alt-email":
        if (emailValidator.validate(e.target.value)) {
          safeStateSetter(() => {
            setTwilioAltEmail(e.target.value);
          });
        }
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            altEmailAddress: e.target.value,
          });
        });

        break;
      default:
        break;
    }
  };
  //
  const handleNewActionDueDateChange = (e) => {
    safeStateSetter(() => {
      setNewActionDueDate(e.target.value);
    });
  };
  //
  const handleNoteChange = (e) => {
    if (e.target.value !== "") {
      setSaveNoteButtonViewable(true);
    } else {
      setSaveNoteButtonViewable(false);
    }

    setCurrentNote(e.target.value);
  };
  //
  const handleSalesPersonChange = (e) => {
    if (e.target.value !== "" && e.target.value !== "BDC") {
      const clickedSalesPerson = JSON.parse(e.target.value);
      // console.log(clickedSalesPerson);
      safeStateSetter(() => {
        setNewCycleSalesPerson(clickedSalesPerson);
        setNewCycleSalesPersonId(clickedSalesPerson._id);
      });
    } else if (e.target.value === "BDC") {
      asyncGetBdcManager();
    }
  };
  //
  const handleVehicleChange = (e) => {
    // handleVehicleChange changes data in the database
    const element = e.target;

    switch (element.name) {
      case "model":
        safeStateSetter(() => {
          setCurrentModel(element.value);
        });

        break;

      case "type":
        safeStateSetter(() => {
          setCurrentVehicleType(element.value);
        });

        break;

      case "year":
        safeStateSetter(() => {
          setCurrentYear(element.value);
        });

        break;

      case "make":
        safeStateSetter(async () => {
          setCurrentMake(element.value);
          setCurrentModels([]);
          if (currentYear && currentYear !== "select-year") {
            const res = await callGetModels(currentYear, element.value);
            if (res) {
              setCurrentModel("");
              setCustomerPopulated(false);
            }
          }
        });

        break;

      case "stock":
        const stock = element.value;
        safeStateSetter(async () => {
          const vehicle = await dealershipContext.stockSearch(stock);
          setCurrentStock(stock);
          if (vehicle) {
            setCurrentVehicleType(vehicle.type);
            setCurrentYear(vehicle.year);
            setCurrentMake(vehicle.make);
            setCurrentVin(vehicle.vin);
            const models = await callGetModels(vehicle.year, vehicle.make);
            if (models) {
              setCurrentModel(vehicle.model);
              setCustomerPopulated(false);
            } else {
              setCustomerPopulated(false);
            }
          } else {
            setCurrentVin("");
          }
        });

        break;

      case "vin":
        const vin = element.value;
        safeStateSetter(async () => {
          const vehicle = await dealershipContext.vinSearch(vin);
          setCurrentVin(vin);
          if (vehicle) {
            setCurrentVehicleType(vehicle.type);
            setCurrentYear(vehicle.year);
            setCurrentMake(vehicle.make);
            setCurrentModels([]);
            setCurrentStock(vehicle.stock);
            const models = await callGetModels(vehicle.year, vehicle.make);
            if (models) {
              setCurrentModel(vehicle.model);
              setCustomerPopulated(false);
            } else {
              setCustomerPopulated(false);
            }
          } else {
            setCurrentStock("");
          }
        });

        break;

      default:
        console.log("element.name does not match any of the cases");
        break;
    }
  };
  //
  const handleViewContact = (e) => {
    switch (e.target.name) {
      case "text":
        disableAllView();
        safeStateSetter(() => {
          setViewMainGrid(false);
          setViewTextConversation(true);
          setCustomerPopulated(false);
          setTextCheckerStatus(
            setInterval(() => {
              const localTextCheckerCountStr = localStorage.getItem(
                "localTextCheckerCount"
              );

              let localTextCheckerCount;

              if (
                !localTextCheckerCountStr ||
                localTextCheckerCountStr === "200"
              ) {
                localStorage.setItem("localTextCheckerCount", "1");
                localTextCheckerCount = 1;
              } else {
                localTextCheckerCount = parseInt(localTextCheckerCountStr);
                localTextCheckerCount++;
                localStorage.setItem(
                  "localTextCheckerCount",
                  `${localTextCheckerCount}`
                );
              }

              if (localTextCheckerCount < 200) {
                if (history.location.pathname === "/customer") {
                  asyncCheckTexts();
                } else {
                  localStorage.setItem("localTextCheckerCount", "1");
                  clearInterval(textCheckerStatus);
                  window.location.reload();
                }
              } else {
                localStorage.setItem("localTextCheckerCount", "1");
                clearInterval(textCheckerStatus);
                window.location.reload();
              }
            }, 3500)
          );
        });

        // alert(
        //   "texting is currently down for maintenance, sorry for any inconvenience!"
        // );
        break;

      case "new-email":
        disableAllView();

        safeStateSetter(() => {
          setViewMainGrid(false);
          setViewComposeNewEmail(true);
        });

        break;

      // case "view-email":
      //   disableAllView();

      //   if (currentCustomer && currentCustomer.emailAddress) {
      //     let matchingAction = getCurrentAction();
      //     safeStateSetter(() => {
      //       if (matchingAction) {
      //         asyncSetResponseType(matchingAction);
      //       } else {
      //         disableAllView();
      //         setViewMainGrid(false);
      //         setViewComposeNewEmail(true);
      //       }
      //     });
      //   }

      //   break;

      case "phone":
        disableAllView();
        setViewMainGrid(false);
        setViewPhoneCall(true);
        break;

      default:
        break;
    }
  };
  //
  const loadAppointmentInfo = () => {
    if (customerAppointments && customerAppointments[0]) {
      customerAppointments.forEach((app) => {
        if (new Date().getTime() < new Date(app.date).getTime()) {
          safeStateSetter(() => {
            setApptId(app._id);
            setApptDate(
              `${format(new Date(app.date), "yyyy")}-${format(
                new Date(app.date),
                "MM"
              )}-${format(new Date(app.date), "dd")}`
            );
            setApptTime(
              `${format(new Date(app.date), "HH")}:${format(
                new Date(app.date),
                "mm"
              )}`
            );
            setApptStatus(app.status);
          });
        }
      });
    }
    safeStateSetter(() => {
      setViewAppointmentInfo(true);
      setViewMainGrid(false);
    });
  };
  //
  const loadCustomer = async () => {
    // this is the top level of data
    // we always get the cycle from context on initial navigation, and then we set the localStorage to persist on reloads
    // // we must ALWAYS delete the localStorage when we leave this page or edit any data
    // User rights management
    const loadedUser = await userContext.getUserOnly();

    safeStateSetter(() => {
      setDialogueBool(false);
    });

    if (loadedUser) {
      if (loadedUser.user.role === "receptionist") {
        safeStateSetter(() => {
          setContactButtonsViewable(false);
          setRemoveButtonViewable(false);
          setPrintReportButtonViewable(false);
          setAppointmentButtonViewable(false);
          setEditScheduleButtonViewable(false);
        });
      }

      const dealership = await userContext.getDealershipOfUser(
        loadedUser.user._id
      );
      if (dealership) {
        safeStateSetter(() => {
          setDealershipShortName(dealership.shortName);
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        console.log("could not get dealership of user, redirect to homepage");
        history.push("/");
      }
    }

    let salesPersonData;
    if (loadedUser) {
      salesPersonData = await dealershipContext.getSalesPeople(loadedUser.user);
    }

    if (salesPersonData) {
      const salesPersonIds = [];

      salesPersonData.forEach((salesPersonObj) => {
        salesPersonIds.push(salesPersonObj._id);
      });

      safeStateSetter(() => {
        setCurrentSalesPeople(salesPersonData);
      });
    }

    let localCurrentCustomer = localStorage.getItem("localCurrentCustomer");

    try {
      const parsed = JSON.parse(localCurrentCustomer);
      // console.log("parsed JSON");
      if (parsed._id) {
        localCurrentCustomer = parsed._id;
      }
    } catch (error) {
      // console.log("could not parse JSON: ", console.log(localCurrentCustomer));
    }

    // console.log("just got it", localCurrentCustomer);

    const fullCustomer = await getSubdocumentsOfCustomer(localCurrentCustomer);

    localStorage.setItem("localCurrentCustomer", JSON.stringify(fullCustomer));

    if (fullCustomer) {
      if (
        fullCustomer.dealership.toString() !==
        loadedUser.user.dealership.toString()
      ) {
        history.push("/");
      }

      if (!fullCustomer) {
        alert("could not load customer...");
        history.push("/");
      }
      userContext.addToLastTwoHundred(fullCustomer._id);

      const appointments = await getAppointmentsOfCustomer(fullCustomer._id);

      if (appointments) {
        setCustomerAppointments(appointments);
      }

      // console.log(fullCustomer.storeVisits, fullCustomer.cycles);
      fullCustomer.storeVisits.forEach((sv) => {
        let currentVehicle;
        let currentSalesPersonId;

        if (sv.status === "sold") {
          // console.log("sold deal");

          for (let t = 0; t < fullCustomer.cycles.length; t++) {
            // console.log("iteration:", sv.cycle, fullCustomer.cycles[t]);
            if (sv.cycle && sv.cycle === fullCustomer.cycles[t]._id) {
              // console.log("equal: ", fullCustomer.cycles[t]);
              currentVehicle = fullCustomer.cycles[t].vehicle;
              currentSalesPersonId = fullCustomer.cycles[t].primarySales;
            }
          }

          // console.log("currentSalesPersonId: ", currentSalesPersonId);

          const curDeal = {
            ...sv,
            cycleVehicle: currentVehicle || {},
            salesPerson: currentSalesPersonId,
          };
          const curDeals = currentDeals;
          if (curDeals.length > 0) {
            curDeals.forEach((existingDeal) => {
              if (existingDeal._id !== curDeal._id) {
                curDeals.push(curDeal);
              }
            });
          } else {
            curDeals.push(curDeal);
          }

          safeStateSetter(() => {
            setCurrentDeals(curDeals);
          });
        }
      });

      // logic

      let localTexts = [];

      if (fullCustomer.texts[0]) {
        // we assume these texts are full objects...
        localTexts = fullCustomer.texts;
        localTexts.sort((a, b) => {
          if (parseISO(a.createdAt) > parseISO(b.createdAt)) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      // EVERYTHING WE BUILD BELOW IN THE CONTEXT VERSION NEEDS TO BE COPY AND PASTED HERE

      safeStateSetter(() => {
        const pastActions = [];
        const futureActions = [];
        fullCustomer.actions.forEach((action) => {
          if (
            new Date().getTime() > new Date(action.dueDate).getTime() &&
            differenceInDays(new Date(), new Date(action.dueDate)) !== 0
          ) {
            pastActions.push(action);
          } else {
            futureActions.push(action);
          }
        });
        // past actions should be DESCENDING
        pastActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return -1;
          } else {
            return 1;
          }
        });
        // futureActions should be ASCENDING
        futureActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });

        fullCustomer.cycles.sort((a, b) => {
          if (a.active) {
            return -1;
          } else if (
            new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime()
          ) {
            return -1;
          } else {
            return 1;
          }
        });

        setCurrentActions([...futureActions, ...pastActions]);
        setCurrentCustomer(fullCustomer);

        fullCustomer.cycles.forEach((cy) => {
          if (cy.active) {
            setActiveCycle(cy);
            setActiveCycleFound(true);
          }
        });

        fullCustomer.notes.sort((a, b) => {
          if (
            new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
          ) {
            return -1;
          } else {
            return 1;
          }
        });
        setCurrentNotes(fullCustomer.notes);
        setCurrentTexts(localTexts);
        setCustomerPopulated(true);
        setLoading(false);
      });
    } else {
      safeStateSetter(() => {
        // console.log("fullCustomer: ", fullCustomer);

        setErrorState(true);
        console.log("do not have necessary data to load this page");
        history.push("/");
      });
    }

    if (fullCustomer && loadedUser && loadedUser.user.role === "salesperson") {
      const cycles = fullCustomer.cycles;
      let matchFound = false;

      cycles.forEach((cy) => {
        let cycleId;
        if (!cy.primarySales._id) {
          cycleId = cy.primarySales;
        } else {
          cycleId = cy.primarySales._id;
        }

        if (cycleId.toString() === loadedUser.user._id.toString()) {
          matchFound = true;
        }
      });

      safeStateSetter(() => {
        if (!matchFound) {
          // here there is no cycle belonging to the current salesperson
          setPrintReportButtonViewable(false);
          setContactButtonsViewable(false);
          setEditCustomerButtonViewable(false);
          setAppointmentButtonViewable(false);
          setEditScheduleButtonViewable(false);
        }
      });
    }
    if (fullCustomer && loadedUser && loadedUser.user.role !== "superuser") {
      setRemoveButtonViewable(false);
    }
    if (
      fullCustomer &&
      loadedUser &&
      (loadedUser.user.role === "salesperson" ||
        loadedUser.user.role === "receptionist")
    ) {
      setEditScheduleButtonViewable(false);
    }
  };
  //
  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };
  //
  const saveAppointment = async () => {
    if (apptDate || apptTime || apptStatus) {
      // console.log(apptDate, apptTime, apptStatus);

      const trueDate = new Date(`${apptDate}T${apptTime}`).toUTCString();
      const apptSaved = await saveAppt(
        currentCustomer,
        {
          date: trueDate,
          time: apptTime,
          status: apptStatus,
          setter: userContext.user._id,
        },
        activeCycle
      );

      if (apptSaved) {
        safeStateSetter(() => {
          setApptDate("");
          setApptStatus("");
          setApptTime("");
          disableAllView();
          setCustomerPopulated(false);
        });
      } else {
        alert("could not save appointment");
        window.location.reload();
      }
      //
      //
      //
    }
  };
  //
  const saveEditedCustomerHandler = (e) => {
    e.preventDefault();
    const finalEditedCustomer = { ...editedCustomer };
    if (twilioPhone) {
      finalEditedCustomer["phoneNumber"] = twilioPhone;
    }
    if (twilioAltPhone) {
      finalEditedCustomer["altPhoneNumber"] = twilioAltPhone;
    }
    if (twilioEmail) {
      finalEditedCustomer["emailAddress"] = twilioEmail;
    }
    if (twilioAltEmail) {
      finalEditedCustomer["altEmailAddress"] = twilioAltEmail;
    }

    // I think this code lets me get rid of the twilio fields
    if (
      finalEditedCustomer["phoneNumber"] &&
      typeof finalEditedCustomer["phoneNumber"] === "string" &&
      !parsePhoneNumber(finalEditedCustomer["phoneNumber"])
    ) {
      const phoneObj = parsePhoneNumber(
        finalEditedCustomer["phoneNumber"],
        "US"
      );
      finalEditedCustomer["phoneNumber"] = phoneObj.number;
    }

    if (
      finalEditedCustomer["altPhoneNumber"] &&
      typeof finalEditedCustomer["altPhoneNumber"] === "string" &&
      !parsePhoneNumber(finalEditedCustomer["altPhoneNumber"])
    ) {
      const altPhoneObj = parsePhoneNumber(
        finalEditedCustomer["altPhoneNumber"],
        "US"
      );
      finalEditedCustomer["altPhoneNumber"] = altPhoneObj.number;
    }

    asyncSaveEditedCustomer(finalEditedCustomer);
  };
  //
  const saveNewActionHandler = (e) => {
    // still have to deal with case where action is far into the future - I think userContext.getUser is the place
    // I say don't display the far future action here, but create it on the customer and check if there are any incomplete actions to do today that don't have an active cycle paired.  In this case, we create a new cycle with new actions and delete the action that was created in this function (also from the customer's array).
    if (newActionDueDate !== "") {
      asyncSaveNewAction();
    }
  };

  //
  const savePhoneCall = (e) => {
    // savePhoneCall changes data in the database
    if (phoneCallBody !== "") {
      const enteredNote = {
        customer: currentCustomer._id,
        noteType: "phonecall",
        dialogue: dialogueBool,
        body: phoneCallBody,
        writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
        user: userContext.user._id,
      };

      const asyncSaveNote = async () => {
        await addNote(enteredNote);

        const action = getCurrentAction();

        if (action) {
          const actionCompleted = await completeAction(action._id);
          if (!actionCompleted) {
            console.log("could not complete action");
          }
        }
      };
      asyncSaveNote();

      safeStateSetter(() => {
        setLoading(true);
        setCustomerPopulated(false);
        setPhoneCallBody("");
        disableAllView();
      });
    } else {
      console.log("ERROR: data missing, could not save note");
    }
  };
  //
  const sendEmail = (e) => {
    if (
      currentCustomer &&
      currentCustomer.emailAddress &&
      currentEmailSubject &&
      currentEmail
    ) {
      // here, I want to determine whether or not there is an incomplete action due today for this customer - if so pass the action, if not pass null (will need to modify API)
      // console.log(currentCustomer.actions, userContext);
      const matchingAction = getCurrentAction();

      asyncSendEmail(matchingAction, currentEmailSubject, currentEmail);
    } else {
      alert("data missing: could not send email...");
    }
  };
  //
  const sendTextHandler = (e) => {
    if (currentCustomer && currentCustomer.phoneNumber) {
      // here, I want to determine whether or not there is an incomplete action due today for this customer - if so pass the action, if not pass null (will need to modify API)
      // console.log(currentCustomer.actions, userContext);
      const matchingAction = getCurrentAction();

      asyncSendText(matchingAction, currentTextBody);
    } else {
      console.log("data missing: could not send text...");
    }
  };
  //
  const updateAppointment = (e) => {
    switch (e.target.name) {
      case "appt-date":
        setApptDate(e.target.value);

        break;
      case "appt-time":
        setApptTime(e.target.value);

        break;
      case "appt-status":
        // console.log(e.target.value);
        setApptStatus(e.target.value);

        break;

      default:
        break;
    }
  };
  //
  const updateCurrentEmail = (e) => {
    safeStateSetter(() => {
      setCurrentEmail(e.target.value);
    });
  };
  //
  const updateCurrentEmailSubject = (e) => {
    safeStateSetter(() => {
      setCurrentEmailSubject(e.target.value);
    });
  };
  //
  const updateCurrentTextBody = (e) => {
    safeStateSetter(() => {
      setCurrentTextBody(e.target.value);
    });
  };
  //
  const updatePhoneCall = (e) => {
    safeStateSetter(() => {
      setPhoneCallBody(e.target.value);
    });
    const savePhoneCallButton = document.getElementById("save-new-phone-call");
    if (e.target.value !== "") {
      savePhoneCallButton.classList.remove("button-normal-disabled");
    } else {
      savePhoneCallButton.classList.add("button-normal-disabled");
    }
  };
  //
  //
  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <div>
          {viewMainGrid && (
            <main
              className="flex-column-center-center page-column"
              ref={mainBodyRef}
            >
              <div className="grid">
                <div className="grid-row">
                  <div className="grid-item grid-item-row grid-item-short">
                    <div className="infobar-row infobar-row-top">
                      <div className="infobar-column infobar-column-first">
                        <h1 className="main-title main-title-smaller dark-blue-text margin-bottom-tiny center-text">
                          Customer
                          <span className="medium-text dark-blue-text margin-left-small">
                            {currentCustomer && currentCustomer.customerNumber}
                          </span>
                        </h1>
                        <div className="medium-text dark-blue-text center-text">
                          {`${currentCustomer && currentCustomer.firstName} ${
                            currentCustomer && currentCustomer.lastName
                          }`}
                        </div>
                      </div>

                      <div className="infobar-column">
                        <div className="form-row form-row-bottom">
                          {printReportButtonViewable ? (
                            <button className="button-normal small-medium-text margin-right-small">
                              Print Report
                            </button>
                          ) : (
                            <button className="button-normal button-normal-disabled small-medium-text margin-right-small">
                              Print Report
                            </button>
                          )}

                          <button className="button-normal small-medium-text">
                            Merge
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="infobar-row">
                      <div className="infobar-column">
                        {contactButtonsViewable ? (
                          <div className="form-row form-row-bottom">
                            <button
                              onClick={handleViewContact}
                              name="text"
                              className="button-elevated small-medium-text margin-right-small"
                            >
                              Text
                            </button>
                            <button
                              onClick={handleViewContact}
                              name="new-email"
                              className="button-elevated small-medium-text margin-right-small"
                            >
                              E-Mail
                            </button>
                            <button
                              onClick={handleViewContact}
                              name="phone"
                              className="button-elevated small-medium-text margin-right-small"
                            >
                              Phone
                            </button>
                          </div>
                        ) : (
                          <div className="form-row form-row-bottom fifty-opacity cursor-none">
                            <button
                              onClick={handleViewContact}
                              name="text"
                              className="button-elevated small-medium-text margin-right-small"
                            >
                              Text
                            </button>
                            <button
                              onClick={handleViewContact}
                              name="new-email"
                              className="button-elevated small-medium-text margin-right-small"
                            >
                              E-Mail
                            </button>
                            <button
                              onClick={handleViewContact}
                              name="phone"
                              className="button-elevated small-medium-text margin-right-small"
                            >
                              Phone
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="infobar-column">
                        <div className="form-row form-row-bottom">
                          {removeButtonViewable ? (
                            <button
                              className="button-negative small-medium-text"
                              onClick={deleteCustomerHandler}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              className="button-negative button-negative-disabled small-medium-text"
                              onClick={deleteCustomerHandler}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading medium-large-text white-text">
                      Information
                    </div>
                    <div className="grid-item-body">
                      <div className="grid-item-body-section">
                        <ul className="list small-text dark-blue-text">
                          <li>
                            Name:{" "}
                            {`${currentCustomer && currentCustomer.firstName} ${
                              currentCustomer && currentCustomer.lastName
                            }`}
                          </li>
                          <li>
                            Email:{" "}
                            {`${
                              currentCustomer && currentCustomer.emailAddress
                            }`}
                          </li>
                          <li>
                            Phone:{" "}
                            {currentCustomer &&
                              currentCustomer.phoneNumber &&
                              typeof currentCustomer.phoneNumber === "string" &&
                              parsePhoneNumber(
                                currentCustomer.phoneNumber
                              ).formatNational("US")}
                          </li>
                          <li>
                            Address:{" "}
                            {currentCustomer && currentCustomer.streetAddress}
                          </li>
                          <li>
                            City: {currentCustomer && currentCustomer.city}
                          </li>
                          <li>
                            State: {currentCustomer && currentCustomer.state}
                          </li>
                          <li>
                            Zip: {currentCustomer && currentCustomer.zipCode}
                          </li>
                        </ul>
                      </div>
                      <div className="form-row form-row-bottom">
                        {editCustomerButtonViewable ? (
                          <button
                            onClick={handleDisplayChange}
                            className="button-normal flex-grow small-medium-text margin-right-large"
                            name="view-edit-customer"
                          >
                            Edit
                          </button>
                        ) : (
                          <button
                            className="button-normal button-normal-disabled flex-grow small-medium-text margin-right-large"
                            name="view-edit-customer"
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading large-text white-text">
                      Notes
                    </div>
                    <div className="grid-item-body">
                      <div className="grid-item-body-section">
                        {currentNotes &&
                          currentNotes.map((note, index) => (
                            <div className="section-row" key={index}>
                              <div className="section-row-info">
                                <div className="section-row-info-row dark-blue-text tiny-text">
                                  {format(new Date(note.createdAt), "P")}
                                </div>
                                <div className="section-row-info-row dark-blue-text small-text">
                                  {format(new Date(note.createdAt), "p")}
                                </div>
                                <div className="section-row-info-row section-row-info-row-bottom dark-blue-text small-text">
                                  {note.writtenBy}
                                </div>
                              </div>
                              <div className="section-row-content note">
                                {note.noteType === "actiondeleted" ||
                                note.noteType === "actionincomplete" ||
                                note.noteType === "actionfailure" ||
                                note.noteType === "appointmentdeleted" ||
                                note.noteType === "cycleinactive" ||
                                note.noteType === "cycledeleted" ||
                                note.noteType === "cyclenothot" ? (
                                  <div className="note-heading note-heading-negative">
                                    {note.noteType}
                                  </div>
                                ) : (
                                  <div className="note-heading note-heading-elevated">
                                    {note.noteType}
                                  </div>
                                )}
                                <div className="note-body">{note.body}</div>
                                {note.emailData && (
                                  <button
                                    className="button-normal margin-small small-text"
                                    id={JSON.stringify(note)}
                                    onClick={handleDisplayChange}
                                    name="view-email-from-note"
                                  >
                                    View
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="form-row form-row-bottom">
                        <button
                          onClick={handleDisplayChange}
                          name="view-add-note"
                          className="button-normal full-width small-medium-text margin-right-large"
                        >
                          Add Note
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading large-text white-text">
                      Cycles
                    </div>
                    <div className="grid-item-body">
                      <div className="grid-item-body-section">
                        {currentCustomer &&
                          currentCustomer.cycles &&
                          currentCustomer.cycles.map((cy) => {
                            return (
                              <div
                                id={cy._id}
                                key={cy.cycleNumber}
                                className="flex-row-center-between"
                              >
                                <div
                                  id={JSON.stringify(cy)}
                                  key={cy._id}
                                  className="module module-bottom-margin module-with-header dark-blue-text"
                                  onClick={handleCycleClick}
                                >
                                  <div className="module-header">
                                    Cycle {cy.cycleNumber}
                                  </div>
                                  <div className="module-body">
                                    <div className="module-main">
                                      <div>
                                        {cy.vehicle &&
                                          `${cy.vehicle.type} ${cy.vehicle.make} ${cy.vehicle.model}`}
                                      </div>
                                      <div>
                                        {cy.vehicle && cy.vehicle.stock}
                                      </div>
                                      <div>
                                        created{" "}
                                        {format(new Date(cy.createdAt), "P")}
                                      </div>
                                    </div>
                                    <div className="module-main module-main-right">
                                      <div>
                                        {cy.active ? (
                                          <span className="dark-green-text">
                                            active
                                          </span>
                                        ) : (
                                          <span className="medium-red-text">
                                            not-active
                                          </span>
                                        )}
                                      </div>
                                      <div>
                                        {/* need to get name of cycle salesperson - currently only have _id */}
                                      </div>
                                      <div>
                                        updated{" "}
                                        {format(new Date(cy.updatedAt), "P")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {!cy.active &&
                                  currentCustomer.cycles.length > 1 && (
                                    <button
                                      className="button button-negative button-negative-smaller large-text margin-left-small"
                                      id={cy._id}
                                      onClick={handleDeleteCycle}
                                    >
                                      🗑
                                    </button>
                                  )}
                              </div>
                            );
                          })}
                      </div>
                      <div className="form-row">
                        {activeCycleFound ? (
                          <button className="button-normal button-normal-disabled medium-text flex-grow">
                            New Cycle
                          </button>
                        ) : (
                          <button
                            className="button-normal medium-text flex-grow"
                            name="view-create-new-cycle"
                            onClick={handleDisplayChange}
                          >
                            New Cycle
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading medium-large-text white-text">
                      Appointments
                    </div>
                    <div className="grid-item-body">
                      <div className="grid-item-body-section">
                        <div className="flex-column-center-center">
                          {customerAppointments &&
                            customerAppointments.map((appointment, index) => {
                              return (
                                <button
                                  onClick={loadAppointmentInfo}
                                  className="button-neutral full-width tiny-text margin-bottom-small"
                                  key={index}
                                >
                                  {" "}
                                  {appointment.date &&
                                    format(
                                      new Date(appointment.date),
                                      "Pp"
                                    )}{" "}
                                  -{" "}
                                  {/* appointment.setter is an objectId here in the database, need to make sure we get the subdocument */}
                                  {`${appointment.setter.firstName} ${appointment.setter.lastName}`}{" "}
                                </button>
                              );
                            })}
                        </div>
                      </div>
                      <div className="form-row form-row-bottom">
                        {activeCycleFound && appointmentButtonViewable ? (
                          <button
                            className="button-normal flex-grow small-medium-text"
                            name="set-edit-appointment"
                            id="add-appointment-button"
                            onClick={loadAppointmentInfo}
                          >
                            Set / Edit Appointment
                          </button>
                        ) : (
                          <button
                            className="button-normal button-normal-disabled flex-grow small-medium-text"
                            name="set-edit-appointment"
                            id="add-appointment-button"
                          >
                            Set / Edit Appointment
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading large-text white-text">
                      Deals
                    </div>
                    <div className="grid-item-body">
                      <div className="grid-item-body-section">
                        {currentDeals &&
                          currentDeals.map((deal) => {
                            console.log(deal);
                            return (
                              <div
                                className="module"
                                key={deal._id}
                                id={JSON.stringify(deal)}
                                onClick={handleDealClick}
                              >
                                <div className="module-section module-section-normal">
                                  <div className="white-text">
                                    {format(new Date(deal.date), "P")}
                                  </div>
                                </div>
                                <div className="module-main module-main-right">
                                  <div className="dark-amber-text">
                                    {`${deal.cycleVehicle.type} ${deal.cycleVehicle.year} ${deal.cycleVehicle.make} ${deal.cycleVehicle.model}`}
                                  </div>
                                  <div className="dark-amber-text">
                                    {(deal.salesPerson &&
                                      deal.salesPerson.firstName) ||
                                      ""}{" "}
                                    {(deal.salesPerson &&
                                      deal.salesPerson.lastName) ||
                                      ""}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading large-text white-text">
                      Actions
                    </div>
                    <div className="grid-item-body">
                      <div className="grid-item-body-section" id="parent">
                        {currentActions &&
                          currentActions.map((action) => {
                            return (
                              <div
                                className="module module-bottom-margin"
                                key={action._id}
                                id={JSON.stringify(action)}
                                onClick={handleActionClick}
                              >
                                {action.status === "complete" && (
                                  <div className="module-section module-section-elevated white-text center-text">
                                    {action.status}
                                  </div>
                                )}
                                {action.status === "incomplete" && (
                                  <div className="module-section module-section-normal white-text center-text">
                                    {action.status}
                                  </div>
                                )}
                                {action.status === "failure" && (
                                  <div className="module-section module-section-negative white-text center-text">
                                    {action.status}
                                  </div>
                                )}

                                <div className="module-main module-main-right">
                                  <div className="dark-blue-text">
                                    {action.actionType}
                                  </div>
                                  <div className="dark-blue-text">
                                    Due {format(new Date(action.dueDate), "P")}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="form-row form-row-bottom">
                        {editScheduleButtonViewable ? (
                          <button
                            onClick={handleDisplayChange}
                            className="button-neutral flex-grow small-medium-text"
                            name="view-edit-action-schedule"
                          >
                            Edit Schedule
                          </button>
                        ) : (
                          <button
                            onClick={handleDisplayChange}
                            className="button-neutral button-neutral-disabled flex-grow small-medium-text"
                            name="view-edit-action-schedule"
                          >
                            Edit Schedule
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          )}

          {viewAppointmentInfo && (
            <div className="popup">
              <div className="popup-content" ref={mainBodyRef}>
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Appointment Info
                  </p>
                  <div onClick={handleCancel} id="hide-appointment-info">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="module module-full-width">
                    <div className="module-main">
                      <div className="module-data dark-blue-green-text">
                        {currentCustomer && currentCustomer.firstName}{" "}
                        {currentCustomer && currentCustomer.lastName}
                      </div>
                      <div className="module-data dark-blue-green-text">
                        {currentCustomer &&
                        currentCustomer.phoneNumber &&
                        typeof currentCustomer.phoneNumber === "string" &&
                        parsePhoneNumber(currentCustomer.phoneNumber)
                          ? parsePhoneNumber(
                              currentCustomer.phoneNumber
                            ).formatNational("US")
                          : currentCustomer.phoneNumber}
                      </div>
                      <div className="module-data dark-blue-green-text">
                        {currentCustomer && currentCustomer.emailAddress}
                      </div>
                    </div>
                    <div className="module-section module-section-normal">
                      <div className="tiny-text white-text">Last Updated</div>

                      <div className="tiny-text white-text">
                        {currentCustomer &&
                          format(new Date(currentCustomer.updatedAt), "P")}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <p className="large-text medium-blue-text">Date:</p>
                    <input
                      type="date"
                      name="appt-date"
                      className="form-field large-text medium-blue-green-text"
                      id="appt-date"
                      onChange={updateAppointment}
                      value={apptDate && apptDate}
                    />
                  </div>
                  <div className="form-row">
                    <p className="large-text medium-blue-text">Time:</p>
                    <input
                      type="time"
                      min="09:00"
                      max="20:00"
                      step="900"
                      name="appt-time"
                      className="form-field large-text medium-blue-green-text"
                      id="appt-time"
                      onChange={updateAppointment}
                      value={apptTime && apptTime}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-radio-select flex-grow">
                      <div className="form-radio-select-field flex-grow">
                        <label
                          htmlFor="show-radio"
                          className="medium-large-text dark-green-text"
                        >
                          Show
                        </label>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            name="appt-status"
                            className="radio-button"
                            id="show-radio"
                            value="show"
                            onChange={updateAppointment}
                            checked={apptStatus === "show" ? true : false}
                          />
                          <div className="radio-button"></div>
                        </div>
                      </div>
                      <div className="form-radio-select-field flex-grow">
                        <label
                          htmlFor="pending-radio"
                          className="medium-large-text medium-amber-text"
                        >
                          Pending
                        </label>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            name="appt-status"
                            className="radio-button"
                            id="pending-radio"
                            value="pending"
                            onChange={updateAppointment}
                            checked={apptStatus === "pending" ? true : false}
                          />
                          <div className="radio-button"></div>
                        </div>
                      </div>
                      <div className="form-radio-select-field flex-grow">
                        <label
                          htmlFor="no-show-radio"
                          className="medium-large-text bright-red-text"
                        >
                          No Show
                        </label>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            name="appt-status"
                            className="radio-button"
                            id="no-show-radio"
                            value="no-show"
                            onChange={updateAppointment}
                            checked={apptStatus === "no-show" ? true : false}
                          />
                          <div className="radio-button"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    {!customerAppointments ||
                    customerAppointments.length === 0 ? (
                      <button className="button-negative button-negative-disabled medium-large-text flex-grow">
                        Delete Appointment
                      </button>
                    ) : (
                      <button
                        className="button-negative medium-large-text flex-grow"
                        onClick={deleteAppointmentHandler}
                        id={apptId && apptId}
                      >
                        Delete Appointment
                      </button>
                    )}
                  </div>
                  <div className="form-row">
                    <button
                      className="button-elevated flex-grow large-text"
                      id="appt-save-btn"
                      onClick={saveAppointment}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewTextConversation && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Text
                  </p>
                  <div onClick={handleCancel} id="hide-text-conversation">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="form-row margin-none">
                    <label
                      htmlFor="text-to"
                      className="small-text dark-blue-text margin-right-large"
                    >
                      Customer Phone:
                    </label>
                    <input
                      className="form-field small-text dark-blue-green-text flex-grow"
                      id="text-to"
                      value={
                        currentCustomer &&
                        currentCustomer.phoneNumber &&
                        typeof currentCustomer.phoneNumber === "string" &&
                        parsePhoneNumber(currentCustomer.phoneNumber)
                          ? parsePhoneNumber(
                              currentCustomer.phoneNumber
                            ).formatNational("US")
                          : currentCustomer.phoneNumber
                      }
                      disabled
                    />
                  </div>
                  <div className="popup-section small-text">
                    {currentTexts &&
                      currentTexts.map((cur, index) => {
                        return cur.direction === "incoming" ? (
                          <div className="form-row" key={index}>
                            <div className="incoming-text">{cur.body}</div>
                            <div></div>
                          </div>
                        ) : (
                          <div className="form-row" key={index}>
                            <div></div>
                            <div className="outgoing-text">{cur.body}</div>
                          </div>
                        );
                      })}
                    <div
                      ref={bottomOfTexts}
                      onClick={(e) => {
                        // it was a pain in the ass to figure out how to do this
                        const target = e.currentTarget;
                        target.scrollIntoView({ behavior: "smooth" });
                      }}
                    ></div>
                  </div>
                  <textarea
                    name="text-content"
                    id="text-content"
                    className="popup-section popup-section-short small-text medium-blue-text"
                    placeholder="Type Here..."
                    value={currentTextBody}
                    onChange={updateCurrentTextBody}
                  ></textarea>
                  <div className="form-row">
                    <button
                      className="button-elevated large-text flex-grow"
                      onClick={sendTextHandler}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewComposeNewEmail && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    E-Mail Customer
                  </p>
                  <div onClick={handleCancel} id="hide-compose-new-email">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="form-row">
                    <div className="dark-blue-text medium-text margin-right-small">
                      Subject:{" "}
                    </div>
                    <input
                      className="form-field medium-text dark-blue-green-text flex-grow"
                      value={currentEmailSubject && currentEmailSubject}
                      onChange={updateCurrentEmailSubject}
                      placeholder="- - - -"
                    />
                  </div>

                  <textarea
                    className="popup-body-container popup-body-container-taller popup-body-container-prewrap small-text dark-blue-green-text "
                    value={currentEmail}
                    onChange={updateCurrentEmail}
                    placeholder="Type E-Mail body here..."
                  ></textarea>
                  <button
                    className="button-elevated large-text"
                    onClick={sendEmail}
                  >
                    Send E-Mail
                  </button>
                </div>
              </div>
            </div>
          )}

          {viewPhoneCall && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Phone Call
                  </p>
                  <div onClick={handleCancel} id="hide-phone-call">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="form-row">
                    <div className="medium-text medium-blue-text">
                      Dialogue with customer?
                    </div>
                    <label
                      htmlFor="contact-bool-true"
                      className="medium-text dark-green-text margin-right-small"
                    >
                      True
                    </label>
                    <div className="custom-radio margin-right-small">
                      <input
                        type="radio"
                        name="contact-bool"
                        id="contact-bool-true"
                        onClick={handleDialogueChange}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="contact-bool-false"
                      className="medium-text dark-red-text margin-right-small"
                    >
                      False
                    </label>

                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="contact-bool"
                        id="contact-bool-false"
                        onClick={handleDialogueChange}
                      />
                      <div className="radio-button"></div>
                    </div>
                  </div>
                  <textarea
                    name="new-phone-call"
                    id="new-phone-call"
                    className="popup-body-container popup-body-container-taller medium-large-text dark-blue-green-text"
                    placeholder="Your Note Here..."
                    value={phoneCallBody}
                    onChange={updatePhoneCall}
                  ></textarea>

                  <div className="form-row">
                    <button
                      id="save-new-phone-call"
                      className="button-normal button-normal-disabled large-text flex-grow margin-top-small"
                      onClick={savePhoneCall}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewEditCustomer && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Edit Customer
                  </p>
                  <div onClick={handleCancel} id="hide-edit-customer">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <form className="popup-body popup-body-oversize popup-body-evenly">
                  <div className="form-row">
                    <label
                      htmlFor="first-name"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      First Name:
                    </label>
                    <input
                      type="text"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="first-name"
                      id="first-name"
                      spellCheck="false"
                      value={
                        editedCustomer &&
                        editedCustomer.firstName &&
                        editedCustomer.firstName
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="last-name"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Last Name:
                    </label>
                    <input
                      type="text"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="last-name"
                      id="last-name"
                      spellCheck="false"
                      value={
                        editedCustomer &&
                        editedCustomer.lastName &&
                        editedCustomer.lastName
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="phone-number"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Phone:
                    </label>
                    <input
                      type="tel"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="phone-number"
                      id="phone-number"
                      value={
                        editedCustomer &&
                        editedCustomer.phoneNumber &&
                        editedCustomer.phoneNumber
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="email"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      E-Mail:
                    </label>
                    <input
                      type="email"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="email"
                      id="email"
                      value={
                        editedCustomer &&
                        editedCustomer.emailAddress &&
                        editedCustomer.emailAddress
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="zip-code"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Zip:
                    </label>
                    <input
                      type="text"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="zip-code"
                      id="zip-code"
                      ref={enteredZip}
                      value={
                        editedCustomer &&
                        editedCustomer.zipCode &&
                        editedCustomer.zipCode
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>

                  <div className="form-row">
                    <label
                      htmlFor="address"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Address:
                    </label>
                    <input
                      type="text"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="address"
                      id="address"
                      value={
                        editedCustomer &&
                        editedCustomer.streetAddress &&
                        editedCustomer.streetAddress
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="city"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      City:
                    </label>
                    <input
                      type="text"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="city"
                      id="city"
                      value={
                        editedCustomer &&
                        editedCustomer.city &&
                        editedCustomer.city
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="state"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      State:
                    </label>
                    <input
                      type="text"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="state"
                      id="state"
                      value={
                        editedCustomer &&
                        editedCustomer.state &&
                        editedCustomer.state
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="alt-phone"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Alt. Phone:
                    </label>
                    <input
                      type="tel"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="alt-phone"
                      value={
                        editedCustomer &&
                        editedCustomer.altPhoneNumber &&
                        editedCustomer.altPhoneNumber
                      }
                      id="alt-phone"
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="alt-email"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Alt. Email:
                    </label>
                    <input
                      type="email"
                      className="form-field flex-grow small-text dark-blue-green-text"
                      name="alt-email"
                      id="alt-email"
                      value={
                        editedCustomer &&
                        editedCustomer.altEmailAddress &&
                        editedCustomer.altEmailAddress
                      }
                      onChange={handleEditCustomer}
                    />
                  </div>
                  <div className="form-row">
                    <button
                      className="button-elevated flex-grow large-text"
                      onClick={saveEditedCustomerHandler}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {viewCustomerNotes && (
            <div className="popup">
              <NoteComposer
                currentNote={currentNote}
                currentNotes={currentNotes}
                handleCancel={handleCancel}
                handleDialogueChange={handleDialogueChange}
                handleDisplayChange={handleDisplayChange}
                handleNoteChange={handleNoteChange}
                saveNote={saveNote}
                saveNoteButtonViewable={saveNoteButtonViewable}
              />
            </div>
          )}

          {viewActionSchedule && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Edit Action Schedule
                  </p>
                  <div onClick={handleCancel} id="hide-edit-action-schedule">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div
                    className="popup-section popup-section-taller"
                    id="action-schedule-list"
                  >
                    {currentActions &&
                      currentActions.map((action) => {
                        if (action.status === "incomplete") {
                          return (
                            <div
                              className="module-checkbox-container"
                              key={action._id}
                            >
                              <div className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  name="selected-future-action"
                                  id={action._id}
                                  key={action._id}
                                  onClick={handleActionSelect}
                                />
                                <div className="checkbox margin-right-small small-text center-text"></div>
                              </div>
                              <div className="module module-full-width module-bottom-margin">
                                <div className="module-section module-section-normal white-text center-text">
                                  {action.status}
                                </div>
                                <div className="module-main module-main-right">
                                  <div className="dark-blue-text">
                                    {action.actionType}
                                  </div>

                                  <div className="small-text dark-blue-text">
                                    Due: {format(new Date(action.dueDate), "P")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="module-checkbox-container module-checkbox-container-disabled"
                              key={action._id}
                            >
                              <div className="module-checkbox-container">
                                <div className="module module-full-width module-bottom-margin">
                                  {action.status === "complete" && (
                                    <div className="module-section module-section-elevated white-text center-text">
                                      {action.status}
                                    </div>
                                  )}

                                  {action.status === "failure" && (
                                    <div className="module-section module-section-negative white-text center-text">
                                      {action.status}
                                    </div>
                                  )}

                                  <div className="module-main module-main-right">
                                    <div className="dark-blue-text">
                                      {action.actionType}
                                    </div>

                                    <div className="small-text dark-blue-text">
                                      Due:{" "}
                                      {format(new Date(action.dueDate), "P")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                  {/* <div className="form-row">
                    <select
                      name="follow-up-schedule-select"
                      id="follow-up-schedule-select"
                      className="form-select small-medium-text dark-blue-green-text flex-grow"
                    >
                      {/* definitely going to need some logic here
                      <option value="default-unsold">Default Unsold</option>
                      <option value="default-sold">Default Sold</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div> */}
                  <div className="form-row">
                    {selectedFutureActionIds &&
                    selectedFutureActionIds.length ? (
                      <button
                        onClick={deleteSelectedActionsHandler}
                        className="button-negative large-text flex-grow"
                        id="delete-actions-button"
                      >
                        Delete Selected
                      </button>
                    ) : (
                      <button
                        className="button-negative button-negative-disabled large-text flex-grow"
                        id="delete-actions-button"
                      >
                        Delete Selected
                      </button>
                    )}
                  </div>
                  <div className="form-row">
                    <button
                      onClick={handleDisplayChange}
                      className="button-normal large-text flex-grow"
                      name="view-compose-new-action"
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewComposeNewAction && (
            <div className="popup" id="popup-new-action" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Compose New Action
                  </p>
                  <div onClick={handleCancel} id="hide-compose-new-action">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="form-row">
                    <p className="large-text medium-blue-text">Due Date:</p>
                    <input
                      type="date"
                      name="action-date"
                      className="form-field large-text medium-blue-green-text"
                      id="action-date"
                      onChange={handleNewActionDueDateChange}
                      value={newActionDueDate && newActionDueDate}
                    />
                  </div>
                  <div className="form-row">
                    <button
                      className="button-normal flex-grow margin-right-large large-text"
                      name="view-edit-action-schedule"
                      onClick={handleDisplayChange}
                    >
                      Back
                    </button>
                    <button
                      className="button-elevated flex-grow large-text"
                      onClick={saveNewActionHandler}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewCreateNewCycle && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Create New Cycle
                  </p>
                  <div onClick={handleCancel} id="hide-create-new-cycle">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="form-row">
                    <label
                      htmlFor="cycle-type"
                      className="small-text dark-blue-text margin-right-large"
                    >
                      Type:
                    </label>
                    <select
                      name="cycle-type"
                      id="cycle-type"
                      className="form-select small-text dark-blue-green-text flex-grow"
                      value={newCycleType && newCycleType}
                      onChange={handleCycleTypeChange}
                    >
                      <option value="phone">Phone</option>
                      <option value="walkin">Walk-In</option>
                    </select>
                  </div>

                  <div className="flex-column-center-center full-width">
                    <div className="medium-text dark-blue-text margin-bottom-medium">
                      Vehicle:
                    </div>

                    <div className="module module-full-width margin-none">
                      <div className="flex-column-center-evenly flex-grow hide-overflow">
                        <div className="form-row">
                          <input
                            className="form-field form-field-left dark-blue-green-text flex-grow"
                            type="text"
                            size="6"
                            name="stock"
                            placeholder="Stock"
                            value={currentStock && currentStock}
                            onChange={handleVehicleChange}
                          />
                          <select
                            className="form-field dark-blue-green-text flex-grow"
                            name="type"
                            value={currentVehicleType && currentVehicleType}
                            onChange={handleVehicleChange}
                          >
                            <option value="select-type">Select Type</option>
                            <option value="new">New</option>
                            <option value="used">Used</option>
                          </select>
                          <select
                            className="form-field dark-blue-green-text flex-grow"
                            name="make"
                            value={currentMake && currentMake}
                            onChange={handleVehicleChange}
                          >
                            <option value="select-make">Select Make</option>
                            {commonMakes &&
                              commonMakes.map((make) => {
                                return (
                                  <option key={make} value={make}>
                                    {make}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-row form-row-bottom">
                          <select
                            className="form-field form-field-left dark-blue-green-text flex-grow"
                            name="year"
                            value={currentYear && currentYear}
                            onChange={handleVehicleChange}
                          >
                            <option value="select-year">Year</option>
                            {years.map((year) => {
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                            <option value="antique">Antique</option>
                          </select>
                          <select
                            className="form-field form-field-left dark-blue-green-text flex-grow"
                            name="model"
                            value={currentModel && currentModel}
                            onChange={handleVehicleChange}
                          >
                            <option value="select-model">Model</option>

                            {currentModels &&
                              currentModels.map((cur) => {
                                return (
                                  <option key={cur} value={cur}>
                                    {cur}
                                  </option>
                                );
                              })}
                          </select>
                          <input
                            className="form-field dark-blue-green-text flex-grow"
                            type="text"
                            name="vin"
                            placeholder="VIN"
                            size="17"
                            value={currentVin && currentVin}
                            onChange={handleVehicleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label
                      htmlFor="salesperson"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Salesperson:
                    </label>
                    <select
                      name="salesperson"
                      id="salesperson"
                      className="form-select flex-grow small-text dark-blue-green-text"
                      value={JSON.stringify(newCycleSalesPerson)}
                      onChange={handleSalesPersonChange}
                    >
                      <option value="">Select...</option>
                      {newCycleType !== "walkin" && (
                        <option value="BDC">BDC</option>
                      )}
                      {currentSalesPeople.map((salesperson) => {
                        return (
                          <option
                            key={salesperson._id}
                            value={JSON.stringify(salesperson)}
                          >
                            {`${salesperson.firstName} ${salesperson.lastName}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-row">
                    <button
                      className="button-elevated flex-grow large-text"
                      onClick={createNewCycleHandler}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewStoreVisit && (
            <div className="popup" id="popup-store-visit" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Store Visit
                  </p>
                  <div onClick={handleCancel} id="hide-store-visit">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="module module-full-width fifty-opacity cursor-none">
                    <div className="module-main">
                      <div className="module-data dark-blue-green-text">
                        {currentStoreVisit &&
                          `${currentStoreVisit.customer.firstName} ${currentStoreVisit.customer.lastName}`}
                      </div>

                      <div className="module-data dark-blue-green-text">
                        {currentStoreVisit &&
                          currentStoreVisit.customer &&
                          currentStoreVisit.customer.phoneNumber &&
                          typeof currentStoreVisit.customer === "string" &&
                          parsePhoneNumber(
                            currentStoreVisit.customer.phoneNumber
                          ).formatNational("US")}
                      </div>

                      <div className="module-data dark-blue-green-text">
                        {currentStoreVisit &&
                          currentStoreVisit.customer.emailAddress}
                      </div>
                    </div>
                    <div
                      className="module-section module-section-normal"
                      id={JSON.stringify(currentStoreVisit.cycle)}
                    >
                      <div className="white-text">Cycle:</div>
                      <div className="white-text">
                        {currentStoreVisit &&
                          currentStoreVisit.cycle.cycleNumber}
                      </div>
                    </div>
                  </div>
                  <div className="form-row fifty-opacity cursor-none">
                    <label
                      htmlFor="cycle-type"
                      className="small-text medium-blue-text margin-right-large"
                    >
                      Date:
                    </label>

                    <div className="small-text dark-blue-green-text">
                      {currentStoreVisit &&
                        format(new Date(currentStoreVisit.date), "P")}
                    </div>

                    <label
                      htmlFor="cycle-type"
                      className="small-text medium-blue-text margin-right-large margin-left-medium"
                    >
                      Type:
                    </label>
                    <div className="form-field small-text medium-blue-green-text flex-grow">
                      {currentStoreVisit.cycle.cycleType !== "internet"
                        ? currentStoreVisit.cycle.cycleType
                        : currentStoreVisit.cycle.internetSource}
                    </div>
                  </div>

                  <div className="form-row fifty-opacity cursor-none">
                    <label
                      htmlFor="appointment-bool"
                      className="medium-blue-text small-text"
                    >
                      Appointment:
                    </label>
                    <div className="small-text margin-left-medium">
                      {currentStoreVisit && currentStoreVisit.hasAppointment ? (
                        <span className="dark-green-text">True</span>
                      ) : (
                        <span className="medium-red-text">False</span>
                      )}
                    </div>
                    <label
                      htmlFor="bdc-bool"
                      className="medium-blue-text small-text margin-left-medium"
                    >
                      BDC:
                    </label>
                    <div className="small-text margin-left-medium">
                      {currentStoreVisit && currentStoreVisit.cycle.isBdc ? (
                        <span className="dark-green-text">True</span>
                      ) : (
                        <span className="medium-red-text">False</span>
                      )}
                    </div>
                    <button
                      className="button-normal button-normal-smaller flex-grow small-text margin-left-medium"
                      name="view-store-visit-notes"
                      id={JSON.stringify(currentStoreVisit)}
                    >
                      View All Notes
                    </button>
                  </div>

                  <div className="form-row fifty-opacity cursor-none">
                    <label
                      htmlFor="salesperson-select"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Salesperson:
                    </label>
                    <select
                      name="salesperson"
                      id="salesperson"
                      className="form-select flex-grow small-text dark-blue-green-text"
                      value={currentSalesPerson}
                      onChange={() => {}}
                    >
                      <option value="">BDC</option>
                      {currentSalesPeople.map((salesperson) => {
                        return (
                          <option key={salesperson._id} value={salesperson._id}>
                            {`${salesperson.firstName} ${salesperson.lastName}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="module module-full-width margin-none fifty-opacity cursor-none">
                    <div className="flex-column-center-evenly flex-grow hide-overflow">
                      <div className="form-row">
                        <input
                          className="form-field form-field-left dark-blue-green-text flex-grow"
                          type="text"
                          size="6"
                          name="stock"
                          placeholder="Stock"
                          value={enteredStock && enteredStock}
                          onChange={() => {}}
                        />
                        <select
                          className="form-field dark-blue-green-text flex-grow"
                          name="type"
                          value={enteredVehicleType && enteredVehicleType}
                          onChange={() => {}}
                        >
                          <option value="select-type">Select Type</option>
                          <option value="new">New</option>
                          <option value="used">Used</option>
                        </select>
                        <select
                          className="form-field dark-blue-green-text flex-grow"
                          name="make"
                          value={enteredMake && enteredMake}
                          onChange={() => {}}
                        >
                          <option value="select-make">Select Make</option>
                          <option value={enteredMake}>{enteredMake}</option>
                        </select>
                      </div>
                      <div className="form-row form-row-bottom">
                        <select
                          className="form-field form-field-left dark-blue-green-text flex-grow"
                          name="year"
                          value={enteredYear && enteredYear}
                          onChange={() => {}}
                        >
                          <option value="select-year">Year</option>
                          <option value={enteredYear}>{enteredYear}</option>
                          <option value="antique">Antique</option>
                        </select>
                        <select
                          className="form-field form-field-left dark-blue-green-text flex-grow"
                          name="model"
                          value={enteredModel && enteredModel}
                          onChange={() => {}}
                        >
                          <option value="select-model">Model</option>

                          <option value={enteredModel}>{enteredModel}</option>
                        </select>
                        <input
                          className="form-field dark-blue-green-text flex-grow"
                          type="text"
                          name="vin"
                          placeholder="VIN"
                          size="17"
                          value={enteredVin && enteredVin}
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row fifty-opacity cursor-none">
                    <label
                      htmlFor="status-sold-spot-radio"
                      className="small-text medium-blue-text"
                    >
                      Sold Spot
                    </label>
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="showroom-status"
                        id="status-sold-spot-radio"
                        checked={true}
                        onChange={() => {
                          // do nothing
                        }}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="status-pending-radio"
                      className="small-text medium-blue-text"
                    >
                      Sold Deposit
                    </label>
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="showroom-status"
                        id="status-pending-radio"
                        checked={false}
                        onChange={() => {
                          // do nothing
                        }}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="status-not-sold-radio"
                      className="small-text medium-blue-text"
                    >
                      Not Sold
                    </label>
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="showroom-status"
                        id="status-not-sold-radio"
                        checked={false}
                        onChange={() => {
                          // do nothing
                        }}
                      />
                      <div className="radio-button"></div>
                    </div>
                  </div>

                  <div className="form-row fifty-opacity cursor-none">
                    <button className="button-elevated medium-large-text flex-grow">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {viewEmail && (
            <div className="popup">
              <EmailViewer
                currentCustomer={currentCustomer}
                currentEmail={{
                  subject: currentEmailSubject,
                  body: currentEmail,
                }}
                handleCancel={handleCancel}
                handleDisplayChange={handleDisplayChange}
                special={false}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );

  // end of component
};

export default Customer;
