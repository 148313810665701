import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

const NoteComposer = ({
  currentNote,
  currentNotes,
  handleCancel,
  handleDialogueChange,
  handleDisplayChange,
  handleNoteChange,
  saveNote,
  saveNoteButtonViewable,
}) => {
  return (
    <div className="popup-content">
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Note Composer
        </p>
        <div onClick={handleCancel} id="hide-cycle-notes">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="medium-text medium-blue-text">
            Dialogue with customer?
          </div>
          <label
            htmlFor="contact-bool-true"
            className="medium-text dark-green-text"
          >
            True
          </label>
          <div className="custom-radio">
            <input
              type="radio"
              name="contact-bool"
              id="contact-bool-true"
              onChange={handleDialogueChange}
            />
            <div className="radio-button"></div>
          </div>
          <label
            htmlFor="contact-bool-false"
            className="medium-text dark-red-text"
          >
            False
          </label>

          <div className="custom-radio">
            <input
              type="radio"
              name="contact-bool"
              id="contact-bool-false"
              onChange={handleDialogueChange}
              defaultChecked="true"
            />
            <div className="radio-button"></div>
          </div>
        </div>
        <textarea
          name="new-note"
          id="new-note"
          className="popup-body-container medium-large-text dark-blue-green-text"
          placeholder="Your Note Here..."
          value={currentNote}
          onChange={handleNoteChange}
        ></textarea>
        <div className="popup-body-container">
          {currentNotes &&
            currentNotes.map((note, index) => (
              <div className="section-row" key={index}>
                <div className="section-row-info">
                  <div className="section-row-info-row dark-blue-text tiny-text">
                    {format(new Date(note.createdAt), "P")}
                  </div>
                  <div className="section-row-info-row dark-blue-text small-text">
                    {format(new Date(note.createdAt), "p")}
                  </div>
                  <div className="section-row-info-row section-row-info-row-bottom dark-blue-text small-text">
                    {note.writtenBy}
                  </div>
                </div>
                <div className="section-row-content note">
                  {note.noteType === "actiondeleted" ||
                  note.noteType === "actionincomplete" ||
                  note.noteType === "actionfailure" ||
                  note.noteType === "appointmentdeleted" ||
                  note.noteType === "cycleinactive" ||
                  note.noteType === "cycledeleted" ||
                  note.noteType === "cyclenothot" ? (
                    <div className="note-heading note-heading-negative">
                      {note.noteType}
                    </div>
                  ) : (
                    <div className="note-heading note-heading-elevated">
                      {note.noteType}
                    </div>
                  )}
                  <div className="note-body">{note.body}</div>
                  {note.emailData && (
                    <button
                      className="button-normal margin-small small-text"
                      id={JSON.stringify(note)}
                      onClick={handleDisplayChange}
                      name="view-email-from-note"
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="form-row">
          {saveNoteButtonViewable ? (
            <button
              id="save-new-note"
              className="button-normal large-text flex-grow"
              onClick={(e) => {
                saveNote(e);
              }}
            >
              Save
            </button>
          ) : (
            <button
              id="save-new-note"
              className="button-normal button-normal-disabled large-text flex-grow"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

NoteComposer.propTypes = {
  currentNote: PropTypes.string,
  currentNotes: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDialogueChange: PropTypes.func.isRequired,
  handleDisplayChange: PropTypes.func.isRequired,
  handleNoteChange: PropTypes.func.isRequired,
  saveNote: PropTypes.func.isRequired,
  saveNoteButtonViewable: PropTypes.bool.isRequired,
};

export default NoteComposer;
