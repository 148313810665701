import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const EmailViewer = ({
  currentCustomer,
  currentEmail,
  handleCancel,
  handleDisplayChange,
  special,
}) => {
  const emailViewerRef = useRef();
  const emailViewerCancel = useRef();

  useEffect(() => {
    if (
      currentCustomer.exactDuplicates &&
      currentCustomer.exactDuplicates.email
    ) {
      alert(
        "Another customer has the same email address - email is not available until this conflict is resolved. Edit one of the customers or merge the two to resolve."
      );
      if (emailViewerRef.current) {
        emailViewerCancel.current.click();
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        special ? "popup-content popup-content-special" : "popup-content"
      }
      ref={emailViewerRef}
    >
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          View Email
        </p>
        <div
          onClick={handleCancel}
          id="hide-view-email"
          ref={emailViewerCancel}
        >
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="dark-blue-text medium-text margin-right-small">
            Subject:{" "}
          </div>
          <div className="form-field medium-text dark-blue-green-text flex-grow">
            {currentEmail && currentEmail.subject}
          </div>
        </div>

        <textarea
          className="popup-body-container popup-body-container-taller small-text dark-blue-green-text"
          value={currentEmail && currentEmail.body}
          onChange={() => {
            alert("This field is immutable!");
          }}
        ></textarea>
        <button
          className="button-normal medium-text"
          onClick={handleDisplayChange}
          name="view-compose-reply"
        >
          Compose E-Mail
        </button>
      </div>
    </div>
  );
};

EmailViewer.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  currentEmail: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDisplayChange: PropTypes.func.isRequired,
  special: PropTypes.bool.isRequired,
};

export default EmailViewer;
