import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const ActionOverview = ({
  SPResults,
  endDate,
  handleCancel,
  startDate,
  totalActions,
  totalCompleted,
}) => {
  const actionOverviewRef = useRef();
  //
  const [loading, setLoading] = useState(true);
  //
  useEffect(() => {
    loadActionOverview();
    //eslint-disable-next-line
  }, []);
  //
  const loadActionOverview = async () => {
    try {
      //sort SPResults

      let spActs = 0;
      let spComp = 0;

      SPResults.forEach((spr) => {
        spActs += parseInt(spr.actionsCompleted) + parseInt(spr.failedActions);
        spComp += parseInt(spr.actionsCompleted);
      });

      const spFail = spActs - spComp;

      let bdcFound = false;

      SPResults.forEach((spr) => {
        if (spr.firstName === "BDC") {
          bdcFound = true;
        }
      });

      if (!bdcFound) {
        SPResults.push({
          firstName: "BDC",
          lastName: "",
          actionsCompleted: totalCompleted - spComp,
          failedActions: totalActions - totalCompleted - spFail,
          actionsCompletedScore: Math.round(
            ((totalCompleted - spComp) /
              (totalCompleted -
                spComp +
                (totalActions - totalCompleted - spFail))) *
              100
          ),
        });
      }

      SPResults.sort((a, b) => {
        if (
          parseInt(a.actionsCompletedScore) > parseInt(b.actionsCompletedScore)
        ) {
          return -1;
        } else {
          return 1;
        }
      });

      //
      setLoading(false);
    } catch (error) {
      console.log("ActionOverview.loadActionOverview: ", error);
    }
  };
  //
  return (
    <div className="popup" ref={actionOverviewRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Actions Completed
          </p>
          <div
            className="cursor-pointer"
            id="hide-action-overview"
            onClick={handleCancel}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>

        <div className="popup-body popup-body-evenly">
          {loading ? (
            <div>
              <div className="lds-dual-ring"></div>
            </div>
          ) : (
            <div className="full-width flex-column-center-evenly">
              <div className="flex-row-center-center center-text medium-text dark-blue-green-text margin-bottom-large">
                This information is for the time period of&nbsp;
                {startDate} to {endDate}.
              </div>
              {SPResults &&
                SPResults.map((res, idx) => {
                  if (idx % 2 === 0) {
                    return (
                      <div
                        key={idx}
                        className="small-text flex-row-center-between ninety-width padding-small"
                      >
                        <p className="dark-blue-text">
                          {res.firstName} {res.lastName} :
                        </p>{" "}
                        <p className="dark-blue-green-text">
                          {res.actionsCompleted + res.failedActions} Total /{" "}
                          {res.actionsCompleted} Complete ={" "}
                          {!Number.isNaN(Math.round(res.actionsCompletedScore))
                            ? Math.round(res.actionsCompletedScore)
                            : "0"}
                          %
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={idx}
                        style={{ backgroundColor: "gainsboro" }}
                        className="small-text flex-row-center-between ninety-width padding-small"
                      >
                        <p className="dark-blue-text">
                          {res.firstName} {res.lastName} :
                        </p>{" "}
                        <p className="dark-blue-green-text">
                          {res.actionsCompleted + res.failedActions} Total /{" "}
                          {res.actionsCompleted} Complete ={" "}
                          {!Number.isNaN(Math.round(res.actionsCompletedScore))
                            ? Math.round(res.actionsCompletedScore)
                            : "0"}
                          %
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ActionOverview.propTypes = {
  SPResults: PropTypes.array,
  endDate: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  totalActions: PropTypes.number,
  totalCompleted: PropTypes.number,
};

export default ActionOverview;
