import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const SalesOverview = ({ SPResults, endDate, handleCancel, startDate }) => {
  const salesOverviewRef = useRef();
  //
  const [cleanSPResults, setCleanSPResults] = useState([]);
  const [loading, setLoading] = useState(true);
  //
  useEffect(() => {
    loadSalesOverview();
    //eslint-disable-next-line
  }, []);
  //
  const loadSalesOverview = async () => {
    try {
      //
      const localCleanSPResults = [];
      SPResults.forEach((spr) => {
        if (spr.firstName !== "BDC") {
          localCleanSPResults.push(spr);
        }
      });

      setCleanSPResults(localCleanSPResults);

      setLoading(false);
      //
    } catch (error) {
      console.log("SalesOverview.loadSalesOverview: ", error);
    }
  };
  //
  return (
    <div className="popup" ref={salesOverviewRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Sales Results Overview
          </p>
          <div
            className="cursor-pointer"
            id="hide-sales-overview"
            onClick={handleCancel}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>

        <div className="popup-body popup-body-evenly">
          {loading ? (
            <div>
              <div className="lds-dual-ring"></div>
            </div>
          ) : (
            <div className="full-width flex-column-center-evenly">
              <div className="flex-row-center-center center-text medium-text dark-blue-green-text margin-bottom-large">
                This information is for the time period of&nbsp;
                {startDate} to {endDate}.
              </div>
              {cleanSPResults &&
                cleanSPResults.map((res, idx) => {
                  if (idx % 2 === 0) {
                    return (
                      <div
                        key={idx}
                        className="medium-text flex-row-center-between ninety-width padding-small"
                      >
                        <p className="dark-blue-text">
                          {res.firstName} {res.lastName} :
                        </p>{" "}
                        <p className="dark-blue-green-text">
                          {res.sold} Sold / {res.deliveryRatio}% Closing
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={idx}
                        style={{ backgroundColor: "gainsboro" }}
                        className="medium-text flex-row-center-between ninety-width padding-small"
                      >
                        <p className="dark-blue-text">
                          {res.firstName} {res.lastName} :
                        </p>{" "}
                        <p className="dark-blue-green-text">
                          {res.sold} Sold / {res.deliveryRatio}% Closing
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SalesOverview.propTypes = {
  SPResults: PropTypes.array,
  endDate: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default SalesOverview;
