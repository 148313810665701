import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const CycleWebLeadResponder = ({
  currentCustomer,
  currentEmailSubject,
  currentEmail,
  emailTemplates,
  handleCancel,
  populateTemplate,
  sendEmail,
  updateCurrentEmail,
  updateCurrentEmailSubject,
}) => {
  const cycleWebLeadResponderRef = useRef();
  const cycleWebLeadResponderCancel = useRef();
  useEffect(() => {
    if (
      currentCustomer.exactDuplicates &&
      currentCustomer.exactDuplicates.email
    ) {
      alert(
        "Another customer has the same email address - email is not available until this conflict is resolved. Edit one of the customers or merge the two to resolve."
      );
      if (cycleWebLeadResponderRef.current) {
        cycleWebLeadResponderCancel.current.click();
      }
    }

    // eslint-disable-next-line
  }, []);
  return (
    <div className="popup" ref={cycleWebLeadResponderRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Web Lead Response
          </p>
          <div
            onClick={handleCancel}
            id="view-complete-weblead-cancel"
            ref={cycleWebLeadResponderCancel}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="form-row">
            <div className="dark-blue-text medium-text margin-right-small">
              Template:{" "}
            </div>
            <select
              name="email-template"
              id="email-template"
              className="form-select flex-grow small-text dark-blue-green-text"
              onChange={populateTemplate}
            >
              <option value="none">none</option>
              {emailTemplates.map((template) => {
                return (
                  <option key={template.uuid} value={JSON.stringify(template)}>
                    {`${template.title}`}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-row">
            <div className="dark-blue-text medium-text margin-right-small">
              Subject:{" "}
            </div>
            <input
              className="form-field medium-text dark-blue-green-text flex-grow"
              value={currentEmailSubject && currentEmailSubject}
              onChange={updateCurrentEmailSubject}
            />
          </div>

          <textarea
            className="popup-body-container popup-body-container-taller popup-body-container-prewrap small-text dark-blue-green-text "
            value={currentEmail}
            onChange={updateCurrentEmail}
          ></textarea>
          <button className="button-elevated large-text" onClick={sendEmail}>
            SEND RESPONSE
          </button>
        </div>
      </div>
    </div>
  );
};

CycleWebLeadResponder.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  currentEmailSubject: PropTypes.string,
  currentEmail: PropTypes.string,
  emailTemplates: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  populateTemplate: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  updateCurrentEmail: PropTypes.func.isRequired,
  updateCurrentEmailSubject: PropTypes.func.isRequired,
};

export default CycleWebLeadResponder;
