import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const DoreenReport = ({ doreenReport, handleCancel }) => {
  //
  const history = useHistory();
  //
  return (
    <div className="popup">
      <div className="popup-content padding-bottom-large popup-content-doreen">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Doreen Report
          </p>
          <div
            className="cursor-pointer"
            id="hide-doreen-report"
            onClick={handleCancel}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>

        <div className="popup-body popup-body-scrollable">
          {Object.keys(doreenReport).map((key, idx) => (
            <div className="large-text dark-blue-text margin-small" key={idx}>
              <span className="doreen-text-special">{key}</span>

              {doreenReport[key].map((c, index) => (
                <div
                  key={index}
                  className="small-text dark-blue-green-text cursor-pointer flex-row-center-between doreen-text-modules"
                  onClick={() => {
                    localStorage.setItem("localCurrentCustomer", c.customerId);
                    history.push("/customer");
                  }}
                >
                  <span>
                    {c.customer}: {c.result}
                  </span>
                  {/* {console.log(c)} */}
                  {c.result === "sold" && (
                    <span className="dark-green-text">
                      ({c.phoneNumber.charAt(2)}
                      {c.phoneNumber.charAt(3)}
                      {c.phoneNumber.charAt(4)}) {c.phoneNumber.charAt(5)}
                      {c.phoneNumber.charAt(6)}
                      {c.phoneNumber.charAt(7)}-{c.phoneNumber.charAt(8)}
                      {c.phoneNumber.charAt(9)}
                      {c.phoneNumber.charAt(10)}
                      {c.phoneNumber.charAt(11)}
                    </span>
                  )}
                  {c.result === "dead deal" && (
                    <span className="dark-red-text">
                      ({c.phoneNumber.charAt(2)}
                      {c.phoneNumber.charAt(3)}
                      {c.phoneNumber.charAt(4)}) {c.phoneNumber.charAt(5)}
                      {c.phoneNumber.charAt(6)}
                      {c.phoneNumber.charAt(7)}-{c.phoneNumber.charAt(8)}
                      {c.phoneNumber.charAt(9)}
                      {c.phoneNumber.charAt(10)}
                      {c.phoneNumber.charAt(11)}
                    </span>
                  )}
                  {c.result === "pending deal" ||
                    (c.result === "still working" && (
                      <span className="dark-blue-text">
                        ({c.phoneNumber.charAt(2)}
                        {c.phoneNumber.charAt(3)}
                        {c.phoneNumber.charAt(4)}) {c.phoneNumber.charAt(5)}
                        {c.phoneNumber.charAt(6)}
                        {c.phoneNumber.charAt(7)}-{c.phoneNumber.charAt(8)}
                        {c.phoneNumber.charAt(9)}
                        {c.phoneNumber.charAt(10)}
                        {c.phoneNumber.charAt(11)}
                      </span>
                    ))}
                </div>
              ))}
            </div>
          ))}
          <div className="margin-large">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

DoreenReport.propTypes = {
  doreenReport: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default DoreenReport;
