import React from "react";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";
import { format } from "date-fns";

const CycleAppointmentInfo = ({
  apptDate,
  apptId,
  apptStatus,
  apptTime,
  currentCustomer,
  currentCycle,
  deleteAppointmentHandler,
  handleCancel,
  saveAppointment,
  updateAppointment,
}) => {
  return (
    <div className="popup-content">
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Appointment Info
        </p>
        <div onClick={handleCancel} id="hide-appointment-info">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="module module-full-width">
          <div className="module-main">
            <div className="module-data dark-blue-green-text">
              {currentCustomer && currentCustomer.firstName}{" "}
              {currentCustomer && currentCustomer.lastName}
            </div>
            <div className="module-data dark-blue-green-text">
              {currentCustomer &&
              currentCustomer.phoneNumber &&
              typeof currentCustomer.phoneNumber === "string" &&
              parsePhoneNumber(currentCustomer.phoneNumber)
                ? parsePhoneNumber(currentCustomer.phoneNumber).formatNational(
                    "US"
                  )
                : currentCustomer.phoneNumber}
            </div>
            <div className="module-data dark-blue-green-text">
              {currentCustomer && currentCustomer.emailAddress}
            </div>
          </div>
          <div className="module-section module-section-normal">
            <div className="tiny-text white-text">Last Updated</div>

            <div className="tiny-text white-text">
              {currentCustomer &&
                format(new Date(currentCustomer.updatedAt), "P")}
            </div>
          </div>
        </div>

        <div className="form-row">
          <p className="large-text medium-blue-text">Date:</p>
          <input
            type="date"
            name="appt-date"
            className="form-field large-text medium-blue-green-text"
            id="appt-date"
            onChange={updateAppointment}
            value={apptDate && apptDate}
          />
        </div>
        <div className="form-row">
          <p className="large-text medium-blue-text">Time:</p>
          <input
            type="time"
            min="09:00"
            max="20:00"
            step="900"
            name="appt-time"
            className="form-field large-text medium-blue-green-text"
            id="appt-time"
            onChange={updateAppointment}
            value={apptTime && apptTime}
          />
        </div>
        <div className="form-row">
          <div className="form-radio-select flex-grow">
            <div className="form-radio-select-field flex-grow">
              <label
                htmlFor="show-radio"
                className="medium-large-text dark-green-text"
              >
                Show
              </label>
              <div className="custom-radio">
                <input
                  type="radio"
                  name="appt-status"
                  className="radio-button"
                  id="show-radio"
                  value="show"
                  onChange={updateAppointment}
                  checked={apptStatus === "show" ? true : false}
                />
                <div className="radio-button"></div>
              </div>
            </div>
            <div className="form-radio-select-field flex-grow">
              <label
                htmlFor="pending-radio"
                className="medium-large-text medium-amber-text"
              >
                Pending
              </label>
              <div className="custom-radio">
                <input
                  type="radio"
                  name="appt-status"
                  className="radio-button"
                  id="pending-radio"
                  value="pending"
                  onChange={updateAppointment}
                  checked={apptStatus === "pending" ? true : false}
                />
                <div className="radio-button"></div>
              </div>
            </div>
            <div className="form-radio-select-field flex-grow">
              <label
                htmlFor="no-show-radio"
                className="medium-large-text bright-red-text"
              >
                No Show
              </label>
              <div className="custom-radio">
                <input
                  type="radio"
                  name="appt-status"
                  className="radio-button"
                  id="no-show-radio"
                  value="no-show"
                  onChange={updateAppointment}
                  checked={apptStatus === "no-show" ? true : false}
                />
                <div className="radio-button"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-row">
          {!currentCycle.appointments ||
          currentCycle.appointments.length === 0 ? (
            <button className="button-negative button-negative-disabled medium-large-text flex-grow">
              Delete Appointment
            </button>
          ) : (
            <button
              className="button-negative medium-large-text flex-grow"
              onClick={deleteAppointmentHandler}
              id={apptId && apptId}
            >
              Delete Appointment
            </button>
          )}
        </div>
        <div className="form-row">
          <button
            className="button-elevated flex-grow large-text"
            id="appt-save-btn"
            onClick={saveAppointment}
          >
            Save
          </button>
          )
        </div>
      </div>
    </div>
  );
};

CycleAppointmentInfo.propTypes = {
  apptDate: PropTypes.string,
  apptId: PropTypes.string,
  apptStatus: PropTypes.string,
  apptTime: PropTypes.string,
  currentCustomer: PropTypes.object.isRequired,
  currentCycle: PropTypes.object.isRequired,
  deleteAppointmentHandler: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  updateAppointment: PropTypes.func.isRequired,
};

export default CycleAppointmentInfo;
