import axios from "axios";
import { axiosConfig } from "./axiosConfig";

export const dupDoubleCheck = async (type, customerId, value, dealershipId) => {
  try {
    if (!type || !customerId) {
      throw new Error("invalid data received");
    }

    if (type !== "phone" && type !== "email") {
      throw new Error("invalid type received");
    }

    let res = null;

    res = await axios.post(
      "/api/v1/customer/duplicateDoubleCheck",
      {
        customerId: customerId,
        type: type,
        value: value,
        dealershipId: dealershipId,
      },
      axiosConfig
    );

    if (res && res.data) {
      if (
        res.data.duplicateFound &&
        (res.data.duplicateFound === "true" || res.data.duplicateFound === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log("dupDoubleCheck Error: ", error);
  }
};
