import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import { format } from "date-fns";
import DealershipContext from "../../context/dealership/dealershipContext";

const TotalLeadsViewer = ({
  endDate,
  handleCancel,
  handleComposeManagerEmails,
  handleLeadCheck,
  leads,
  startDate,
  sold,
}) => {
  const dealershipContext = useContext(DealershipContext);

  const history = useHistory();

  const leadsViewerRef = useRef();

  const [completeLeads, setCompleteLeads] = useState([]);
  const [completeLeadsPopulated, setCompleteLeadsPopulated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!completeLeadsPopulated) {
      getCompleteLeads();
    }
    // eslint-disable-next-line
  }, [completeLeadsPopulated]);

  const asyncSearchWithinGroup = async (group) => {
    // this should get refactored into dealershipFunctions.js when I create it
    try {
      safeStateSetter(() => {
        setLoading(true);
      });
      const customerResults = await dealershipContext.search({
        allFields: searchText,
        searchType: "within-group",
        activeCycleSelected: false,
        withinGroup: group,
      });
      if (!customerResults) {
        throw new Error("could not get search results");
      }

      const resultLeads = [];

      // quadratic 👎
      customerResults.forEach((cusRes) => {
        for (let i = 0; i < completeLeads.length; i++) {
          if (completeLeads[i].customer._id === cusRes._id) {
            resultLeads.push(completeLeads[i]);
          }
        }
      });

      safeStateSetter(() => {
        setSearchText("");
        setCompleteLeads(resultLeads);
        setLoading(false);
      });
    } catch (error) {
      console.log("TotalLeadsViewer.asyncSearchWithinGroup: ", error.message);
    }
  };

  const getCompleteLeads = async () => {
    // here, leads are cycles
    try {
      safeStateSetter(() => {
        setLoading(true);
      });
      const customerIds = [];
      const hasDuplicate = [];

      leads.forEach((lead) => {
        if (customerIds.includes(lead.customer)) {
          hasDuplicate.push(lead.customer);
        }
        customerIds.push(lead.customer);
      });

      const res = await axios.post(
        "/api/v1/customer/get-customers-from-ids",
        { customerIds },
        axiosConfig
      );

      if (res.data.success) {
        const fullLeads = [];

        const active = [];
        const soldLeads = [];
        const old = [];
        const dead = [];

        leads.forEach((l) => {
          let sorted = false;
          // remember, a lead is a cycle
          if (l.active) {
            sorted = true;
            active.push(l);
          } else {
            sold.forEach((ssv) => {
              if (ssv.customer === l.customer) {
                sorted = true;
                soldLeads.push(l);
              }
            });

            if (!sorted) {
              if (hasDuplicate.includes(l.customer)) {
                sorted = true;
                old.push(l);
              } else {
                dead.push(l);
              }
            }
          }
        });

        res.data.customers.forEach((c) => {
          active.forEach((aL) => {
            if (c._id === aL.customer) {
              fullLeads.push({ ...aL, customer: c, viewStatus: "active" });
            }
          });
          sold.forEach((sL) => {
            if (c._id === sL.customer) {
              fullLeads.push({ ...sL, customer: c, viewStatus: "sold" });
            }
          });
          old.forEach((oL) => {
            if (c._id === oL.customer) {
              fullLeads.push({ ...oL, customer: c, viewStatus: "old" });
            }
          });
          dead.forEach((dL) => {
            if (c._id === dL.customer) {
              fullLeads.push({ ...dL, customer: c, viewStatus: "dead" });
            }
          });
        });

        fullLeads.sort((a, b) => {
          if (
            new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
          ) {
            return 1;
          } else {
            return -1;
          }
        });

        safeStateSetter(() => {
          setCompleteLeads(fullLeads);
          setCompleteLeadsPopulated(true);
          setLoading(false);
        });
      } else {
        throw new Error("could not get customers from database");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSearchWithinGroup = (e) => {
    const currentIds = [];

    completeLeads.forEach((compLead) => {
      currentIds.push(compLead.customer._id);
    });

    asyncSearchWithinGroup(currentIds);
  };

  const leadClickHandler = (e) => {
    const lead = JSON.parse(e.currentTarget.id);
    if (lead.active) {
      // go to cycle
      localStorage.setItem(
        "localCurrentCycle",
        JSON.stringify({ _id: lead._id, customer: lead.customer._id })
      );
      localStorage.setItem(
        "localCurrentCustomer",
        JSON.stringify({ _id: lead.customer._id })
      );

      history.push("/cycle");
    } else {
      // go to customer
      localStorage.setItem(
        "localCurrentCustomer",
        JSON.stringify({ _id: lead.customer._id })
      );
      history.push("/customer");
    }
  };

  const safeStateSetter = (fn) => {
    if (leadsViewerRef.current) {
      fn();
    }
  };

  const sortCompleteLeads = (e) => {
    if (e.currentTarget && e.currentTarget.id) {
      // do stuff
      switch (e.currentTarget.id) {
        case "sold-sort":
          const soldLeads = [];
          const nonSoldLeads = [];
          completeLeads.forEach((l) => {
            if (l.viewStatus === "sold") {
              soldLeads.push(l);
            } else {
              nonSoldLeads.push(l);
            }
          });

          soldLeads.sort((a, b) => {
            if (
              new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
            ) {
              return 1;
            } else {
              return -1;
            }
          });

          nonSoldLeads.sort((a, b) => {
            if (
              new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
            ) {
              return 1;
            } else {
              return -1;
            }
          });

          setCompleteLeads([...soldLeads, ...nonSoldLeads]);

          break;

        case "active-sort":
          const activeLeads = [];
          const nonActiveLeads = [];
          completeLeads.forEach((l) => {
            if (l.viewStatus === "active") {
              activeLeads.push(l);
            } else {
              nonActiveLeads.push(l);
            }
          });

          activeLeads.sort((a, b) => {
            if (
              new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
            ) {
              return 1;
            } else {
              return -1;
            }
          });

          nonActiveLeads.sort((a, b) => {
            if (
              new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
            ) {
              return 1;
            } else {
              return -1;
            }
          });

          setCompleteLeads([...activeLeads, ...nonActiveLeads]);

          break;

        case "dead-sort":
          const deadLeads = [];
          const nonDeadLeads = [];
          completeLeads.forEach((l) => {
            if (l.viewStatus === "dead") {
              deadLeads.push(l);
            } else {
              nonDeadLeads.push(l);
            }
          });

          deadLeads.sort((a, b) => {
            if (
              new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
            ) {
              return 1;
            } else {
              return -1;
            }
          });

          nonDeadLeads.sort((a, b) => {
            if (
              new Date(a.updatedAt).getTime() < new Date(b.updatedAt).getTime()
            ) {
              return 1;
            } else {
              return -1;
            }
          });

          setCompleteLeads([...deadLeads, ...nonDeadLeads]);

          break;

        default:
          break;
      }
    } else {
      console.log("invalid sort method click");
    }
  };

  return (
    <div className="popup" id="popup-total-leads" ref={leadsViewerRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Total Leads
          </p>
          <div
            onClick={handleCancel}
            id="hide-total-leads-viewer"
            className="cursor-pointer"
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="flex-row-center-center center-text medium-text dark-blue-green-text">
            These are all the leads received within the time period of&nbsp;
            {startDate} to {endDate}.
          </div>
          <div className="flex-row-center-center large-text dark-blue-text">
            <p> Results: {leads.length} </p>
          </div>
          <div className="form-row">
            <input
              type="text"
              className="form-field small-text dark-blue-green-text flex-grow margin-right-large"
              placeholder="Sort by search result..."
              onChange={(e) => {
                safeStateSetter(() => {
                  setSearchText(e.target.value);
                });
              }}
              value={searchText}
            />
            <button
              className="button-normal button-normal-smaller small-text"
              onClick={handleSearchWithinGroup}
            >
              Search
            </button>
          </div>
          <div className="legend full-width">
            <div
              className="flex-row-center-center button-normal button-normal-smaller"
              id="sold-sort"
              onClick={sortCompleteLeads}
            >
              <div className="legend-box legend-box-1 margin-right-medium">
                &nbsp;
              </div>
              <div className="small-medium-text">Sold</div>
            </div>

            <div
              className="flex-row-center-center button-normal button-normal-smaller"
              id="active-sort"
              onClick={sortCompleteLeads}
            >
              <div className="legend-box legend-box-2 margin-right-medium">
                &nbsp;
              </div>
              <div className="small-medium-text">Active</div>
            </div>

            <div className="flex-row-center-center button-normal button-normal-smaller cursor-none">
              <div className="legend-box legend-box-3 margin-right-medium">
                &nbsp;
              </div>
              <div className="small-medium-text">Replaced</div>
            </div>

            <div
              className="flex-row-center-center button-normal button-normal-smaller"
              id="dead-sort"
              onClick={sortCompleteLeads}
            >
              <div className="legend-box legend-box-4 margin-right-medium">
                &nbsp;
              </div>
              <div className="small-medium-text">Dead</div>
            </div>
          </div>
          <div className="popup-section popup-section-taller">
            {loading ? (
              <div className="flex-column-center-center">
                <div className="lds-dual-ring"></div>
              </div>
            ) : (
              <Fragment>
                {completeLeads.map((res, idx) => (
                  <div
                    className="module-checkbox-container"
                    key={idx}
                    id={`parent-${idx}`}
                  >
                    <div className="custom-checkbox">
                      {res.viewStatus === "old" ? (
                        <input
                          className="checkbox-input"
                          type="checkbox"
                          name="selected-actions"
                          onClick={(e) => {
                            alert(
                              "This lead has been replaced by a newer one for the same customer!"
                            );
                            e.currentTarget.checked = false;
                          }}
                        />
                      ) : (
                        <input
                          className="checkbox-input"
                          type="checkbox"
                          name="selected-actions"
                          id={JSON.stringify(res)}
                          onChange={handleLeadCheck}
                        />
                      )}

                      <div className="checkbox margin-right-small margin-bottom-small small-text center-text"></div>
                    </div>
                    <div
                      className="module module-full-width"
                      id={JSON.stringify(res)}
                      key={idx}
                      onClick={leadClickHandler}
                    >
                      <div className="module-main">
                        {res.active ? (
                          <div className="flex-row-center-center">
                            {res.customer.face && (
                              <img
                                className="avatar-smaller margin-right-small"
                                src={`data:image/jpeg;base64,${res.customer.face}`}
                                alt="customer_face"
                              />
                            )}
                            <div className="flex-column-center-evenly">
                              <div className="module-data dark-blue-green-text">
                                {res.customer && res.customer.firstName}{" "}
                                {res.customer && res.customer.lastName}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res.customer &&
                                  res.customer.phoneNumber &&
                                  typeof res.customer.phoneNumber ===
                                    "string" &&
                                  parsePhoneNumber(res.customer.phoneNumber) &&
                                  parsePhoneNumber(
                                    res.customer.phoneNumber
                                  ).formatNational()}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res.customer &&
                                  res.customer.emailAddress &&
                                  res.customer.emailAddress}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex-row-center-center">
                            {res.customer.face && (
                              <img
                                className="avatar-smaller margin-right-small"
                                src={`data:image/jpeg;base64,${res.customer.face}`}
                                alt="customer_face"
                              />
                            )}
                            <div className="flex-column-center-evenly">
                              <div className="module-data dark-blue-green-text">
                                {res.customer && res.customer.firstName}{" "}
                                {res.customer && res.customer.lastName}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res.customer &&
                                  res.customer.phoneNumber &&
                                  typeof res.customer.phoneNumber ===
                                    "string" &&
                                  parsePhoneNumber(res.customer.phoneNumber) &&
                                  parsePhoneNumber(
                                    res.customer.phoneNumber
                                  ).formatNational()}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res.customer && res.customer.emailAddress}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {res.viewStatus === "active" && (
                        <div className="module-section module-section-normal">
                          <div className="white-text">Last Updated</div>

                          <div className="white-text">
                            {res && format(new Date(res.updatedAt), "P")}
                          </div>
                        </div>
                      )}
                      {res.viewStatus === "sold" && (
                        <div className="module-section module-section-elevated">
                          <div className="white-text">Last Updated</div>

                          <div className="white-text">
                            {res && format(new Date(res.updatedAt), "P")}
                          </div>
                        </div>
                      )}
                      {res.viewStatus === "old" && (
                        <div className="module-section module-section-dark-amber">
                          <div className="white-text">Last Updated</div>

                          <div className="white-text">
                            {res && format(new Date(res.updatedAt), "P")}
                          </div>
                        </div>
                      )}
                      {res.viewStatus === "dead" && (
                        <div className="module-section module-section-negative">
                          <div className="white-text">Last Updated</div>

                          <div className="white-text">
                            {res && format(new Date(res.updatedAt), "P")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
          <div className="full-width">
            <button
              id="email-checked-button"
              className="full-width button-elevated button-elevated-disabled medium-large-text"
              onClick={handleComposeManagerEmails}
            >
              Email Checked
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TotalLeadsViewer.propTypes = {
  endDate: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleComposeManagerEmails: PropTypes.func.isRequired,
  handleLeadCheck: PropTypes.func.isRequired,
  leads: PropTypes.array.isRequired,
  sold: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default TotalLeadsViewer;
