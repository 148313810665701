import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DealershipContext from "../context/dealership/dealershipContext";

const ManualCustomerNumber = () => {
  const dealershipContext = useContext(DealershipContext);

  const [enteredCustomerNumber, setEnteredCustomerNumber] = useState("");

  const submitButtonRef = useRef();

  const history = useHistory();

  const manualCustomerNumberSubmit = async () => {
    if (dealershipContext && dealershipContext.searchResults) {
      const success = await dealershipContext.checkCustomerNumber(
        enteredCustomerNumber,
        dealershipContext.searchResults
      );

      if (success) {
        history.push("/data-maintenance");
      }
    }
  };

  const handleChange = (e) => {
    setEnteredCustomerNumber(e.target.value);

    if (submitButtonRef.current && e.target.value !== "") {
      submitButtonRef.current.classList.remove("button-normal-disabled");
    } else {
      submitButtonRef.current.classList.add("button-normal-disabled");
    }
  };

  return (
    <div className="popup" id="popup-manual-customer-number">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Manual Entry
          </p>
          <Link id="add-customer-exit" to={"/data-maintenance"}>
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </Link>
        </div>
        <div className="popup-body popup-body-center">
          <div className="medium-large-text margin-bottom-large dark-blue-text">
            Customer Number:
          </div>
          <input
            className="form-field large-text dark-blue-green-text margin-top-large margin-bottom-large"
            id="manual-customer-number"
            placeholder="- - - - - - - -"
            onChange={handleChange}
          />

          <button
            className="button-normal button-normal-disabled large-text margin-top-large"
            id="manual-customer-number-button"
            ref={submitButtonRef}
            onClick={manualCustomerNumberSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualCustomerNumber;
