import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import LoginPartOne from "../LoginPartOne";
import LoginPartTwo from "../LoginPartTwo";
import Dashboard from "../Dashboard";
import Account from "../Account";
import AddCustomer from "../AddCustomer";
import Customer from "../Customer";
import DataMaintenance from "../DataMaintenance";
import ManualCustomerNumber from "../ManualCustomerNumber";
import Navbar from "../Navbar";
import AppointmentConditional from "../AppointmentConditional";
import Performance from "../Performance";
import AppointmentInfo from "../AppointmentInfo";
import Cycle from "../Cycle";
import Actions from "../Actions";
import CustomerSearch from "../CustomerSearch";
import DeskLog from "../DeskLog";
import Inventory from "../Inventory";
import NotFound from "../NotFound";
import PrivateRoute from "../routing/PrivateRoute";
import PrivateEmailer from "../PrivateEmailer";
import VehicleFeed from "../VehicleFeed";
import SuperUser from "../SuperUser";
import Customs from "../Customs";

export const Routes = () => {
  return (
    <Fragment>
      <Route component={Navbar} />
      <Switch>
        <Route exact path="/login-part-one" component={LoginPartOne} />
        <Route exact path="/login-part-two" component={LoginPartTwo} />
        <Route exact path="/vehicle-uploader" component={VehicleFeed} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/add-customer" component={AddCustomer} />
        <PrivateRoute
          exact
          path="/data-maintenance"
          component={DataMaintenance}
        />
        <PrivateRoute
          exact
          path="/appointment-conditional"
          component={AppointmentConditional}
        />
        <PrivateRoute
          exact
          path="/appointment-info"
          component={AppointmentInfo}
        />
        <PrivateRoute exact path="/cycle" component={Cycle} />
        <PrivateRoute exact path="/actions" component={Actions} />
        <PrivateRoute
          exact
          path="/customer-search"
          component={CustomerSearch}
        />
        <PrivateRoute exact path="/desk-log" component={DeskLog} />
        <PrivateRoute
          exact
          path="/manual-customer-number"
          component={ManualCustomerNumber}
        />
        <PrivateRoute exact path="/customer" component={Customer} />
        <PrivateRoute exact path="/performance" component={Performance} />
        <PrivateRoute exact path="/inventory" component={Inventory} />
        <PrivateRoute exact path="/private-email" component={PrivateEmailer} />
        <PrivateRoute exact path="/superuser" component={SuperUser} />
        <PrivateRoute exact path="/customs" component={Customs} />
        <Route component={NotFound} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
