import React from "react";
import PropTypes from "prop-types";

const ComposeMultiEmail = ({
  currentMultiEmail,
  currentMultiEmailSubject,
  handleCancel,
  sendMultiEmailHandler,
  updateCurrentMultiEmail,
  updateCurrentMultiEmailSubject,
}) => {
  return (
    <div className="popup-content popup-content-special">
      <div className="popup-header popup-header-special">
        <p className="medium-large-text white-text center-text flex-grow">
          Compose Multi Email
        </p>
        <div onClick={handleCancel} id="hide-multi-email">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="dark-grey-text medium-text margin-right-small">
            Subject:{" "}
          </div>
          <input
            className="form-field form-field-red medium-text dark-red-text flex-grow"
            value={currentMultiEmailSubject && currentMultiEmailSubject}
            onChange={updateCurrentMultiEmailSubject}
            placeholder="- - - -"
          />
        </div>

        <textarea
          className="popup-body-container popup-body-container-taller popup-body-container-prewrap popup-body-container-red small-text dark-red-text "
          value={currentMultiEmail}
          onChange={updateCurrentMultiEmail}
          placeholder="Type E-Mail body here..."
        ></textarea>
        <button
          className="button-negative large-text"
          onClick={sendMultiEmailHandler}
        >
          Send E-Mails
        </button>
      </div>
    </div>
  );
};

ComposeMultiEmail.propTypes = {
  currentMultiEmail: PropTypes.string,
  currentMultiEmailSubject: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  sendMultiEmailHandler: PropTypes.func.isRequired,
  updateCurrentMultiEmail: PropTypes.func.isRequired,
  updateCurrentMultiEmailSubject: PropTypes.func.isRequired,
};

export default ComposeMultiEmail;
