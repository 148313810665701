import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

const LoginPartOne = () => {
  const authContext = useContext(AuthContext);

  const history = useHistory();

  const mainBodyRef = useRef();

  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      loadLoginPartOne();
    }

    // eslint-disable-next-line
  }, [loaded]);

  const loadLoginPartOne = async () => {
    const status = await authContext.checkAuthenticated();
    if (status) {
      console.log("successful auth check");
    } else {
      console.log("unsuccessful auth check");
    }

    if (status.authenticated) {
      history.push("/dashboard");
    }
    if (status.hasAuthCode) {
      history.push("/login-part-two");
    }
    if (mainBodyRef.current) {
      setLoaded(true);
    }
  };

  const userNameChange = (e) => {
    if (mainBodyRef.current) {
      setUserName(e.target.value);
    }
  };

  const passWordChange = (e) => {
    if (mainBodyRef.current) {
      setPassWord(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    if (userName !== "" && passWord !== "") {
      submitLoginPartOne();
    }
  };

  const submitLoginPartOne = async () => {
    const success = await authContext.loginPartOne(userName, passWord);
    if (success) {
      console.log("successfully acquired auth code");
      history.push("/login-part-two");
    } else {
      alert("could not acquire auth code");
      window.location.reload();
    }
  };

  return (
    <main
      className="page-column page-column-100-70"
      ref={mainBodyRef}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleSubmit(e);
        }
      }}
    >
      <h2 className="main-title dark-blue-text margin-large flex-row-center-center">
        Login
      </h2>
      <div className="margin-large flex-column-center-evenly">
        <input
          type="text"
          name="userName"
          id="user-name"
          placeholder="User Name"
          className="form-field large-text margin-large"
          onChange={(e) => {
            userNameChange(e);
          }}
        />
        <input
          type="password"
          name="passWord"
          id="pass-word"
          placeholder="Password"
          className="form-field large-text margin-large"
          onChange={(e) => {
            passWordChange(e);
          }}
        />
        <input
          type="submit"
          value="Get Auth Code"
          className="button-elevated large-text margin-large"
          onClick={handleSubmit}
        />
        <button
          className="button-normal large-text margin-medium"
          onClick={() => {
            history.push("/");
          }}
        >
          Home
        </button>
      </div>
    </main>
  );
};

export default LoginPartOne;
