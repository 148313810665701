import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";
//

export const addLicenseFrontToCustomer = async (
  license,
  face,
  cycle,
  customer
) => {
  try {
    // send to server
    if (!cycle || !customer) {
      throw new Error("did not receive cycle and/or customer objects");
    }

    const res = await axios.post(
      "/api/v1/customer/add-license-to-customer",
      { license, face, customerId: customer._id },
      axiosConfig
    );

    if (!res.data.success) {
      alert("could not add license to customer...");
    }

    // set cycle and customer to localStorage
    localStorage.setItem("localCurrentCycle", JSON.stringify(cycle));
    localStorage.setItem("localCurrentCustomer", JSON.stringify(customer));
    // reload
    window.location.reload();
  } catch (error) {
    console.log("customerFunctions.addLicenseFrontToCustomer: ", error.message);
  }
};

//
export const addNote = async (enteredNote) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/add-note",
      enteredNote,
      axiosConfig
    );

    if (res) {
      return res.data.newNote;
    } else {
      console.log("customerFunctions.addNote: no server response");
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.addNote: ", error.message);
  }
};
//
export const addStoreVisit = async (cycleId) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/add-store-visit",
      { cycleId },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.addStoreVisit: ", error.message);
  }
};
//
export const checkTexts = async (customer) => {
  try {
    const res = await axios.post(
      "/api/v1/text/check-texts",
      { customer },
      axiosConfig
    );

    if (res.data.success) {
      return res.data.currentTexts;
    } else {
      return [];
    }
  } catch (error) {
    console.log("customerFunctions.checkTexts", error.message);
  }
};
//
export const completeAction = async (actionId) => {
  try {
    actionId.toString();

    const res = await axios.post(
      "/api/v1/customer/complete-action",
      { actionId },
      axiosConfig
    );

    if (res.data.saved || res.data.customer) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.completeAction", error.message);
  }
};
//
export const completeMultiActions = async (actionIdArray) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/complete-multi-actions",
      { actionIdArray },
      axiosConfig
    );
    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error.message);
  }
};
//
export const createNewCycle = async (
  customerObj,
  typeData,
  vehicleDataObj,
  salesPersonId,
  appointmentIds,
  storeVisitIds,
  isBdc,
  internetSource
) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/create-cycle",
      {
        customerObj,
        typeData,
        vehicleDataObj,
        salesPersonId,
        appointmentIds,
        storeVisitIds,
        isBdc,
        internetSource,
      },
      axiosConfig
    );

    if (res.data) {
      return {
        cycle: res.data.cycle,
        customer: res.data.customer,
        vehicle: res.data.vehicleData,
      };
    }
  } catch (error) {
    console.log("customerFunctions.createNewCycle: ", error.message);
  }
};
//
export const createSearchedCustomer = async (searchedInfo) => {
  try {
    const { data } = await axios.post(
      "/api/v1/customer/create-customer-object",
      searchedInfo,
      axiosConfig
    );

    return data.customerObj;
  } catch (error) {
    console.log(error.message);
  }
};
//
export const cycleTypeUpdate = async (type, currentCycle, setSelectedType) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/update-cycle-type",
      {
        id: currentCycle._id,
        type,
      },
      axiosConfig
    );

    if (res.data.success) {
      setSelectedType(type);
    } else {
      throw new Error(
        "Data Error: could not update Cycle type on the server..."
      );
    }
  } catch (error) {
    alert(error.message);
  }
};
//
export const deleteAppointment = async (appId) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/delete-appointment",
      { appId },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.deleteAppointment", error.message);
  }
};
//
export const deleteCustomer = async (customerId) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/delete-customer",
      { customerId },
      axiosConfig
    );

    if (!res.data.success) {
      throw new Error("could not delete customer");
    } else {
      return true;
    }
  } catch (error) {
    console.log(error.message);
  }
};
//
export const deleteSelectedActions = async (actionIdArray) => {
  try {
    // console.log(actionIdArray);
    const res = await axios.post(
      "/api/v1/customer/delete-selected-actions",
      { actionIdArray },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.deleteSelectedActions: ", error.message);
  }
};
//
export const editSalesPersonOfCycle = async (salesPersonId, currentCycleId) => {
  // console.log(salesPersonId, currentCycleId);
  try {
    const res = await axios.post(
      "/api/v1/customer/edit-salesperson-of-cycle",
      { currentCycleId, salesPersonId },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.editSalesPersonOfCycle: ", error.message);
  }
};
//
export const getAppointmentsOfCustomer = async (customerId) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/get-appointments-of-customer",
      { customerId },
      axiosConfig
    );

    if (res.data.success) {
      return res.data.appointments;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.getAppointmentsOfCustomer", error.message);
  }
};
//
export const getCurrentVehicle = async (cycleIdArray) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/get-current-vehicle",
      { cycleIdArray },
      axiosConfig
    );
    return res.data.vehicle;
  } catch (error) {
    console.log("customerFunctions.getCurrentVehicle", error.message);
  }
};
//
export const getCustomersFromIds = async (customerIds) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/get-customers-from-ids",
      { customerIds },
      axiosConfig
    );

    if (res.data.success) {
      return res.data.customers;
    } else {
      throw new Error("could not get customers from ids");
    }
  } catch (error) {
    console.log("customerFunctions.getCustomersFromIds: ", error);
  }
};
//
export const getCyclesFromIds = async (cycleIds) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/get-cycles-from-ids",
      { cycleIds },
      axiosConfig
    );

    if (res.data.success) {
      return res.data.cycles;
    }
  } catch (error) {
    console.log("customerFunctions.getCyclesFromIds: ", error);
  }
};
//
export const getCyclesOfCustomer = async (customerId) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/get-cycles-of-customer",
      { customerId },
      axiosConfig
    );
    if (res.data.success) {
      return res.data;
    }
  } catch (error) {
    console.log("customerFunctions.getCyclesOfCustomer: ", error.message);
    return { cycles: [] };
  }
};
//
export const getSubdocumentsOfCustomer = async (customerId) => {
  try {
    customerId.toString();

    const res = await axios.post(
      "/api/v1/customer/get-subdocuments-of-customer",
      { customerId },
      axiosConfig
    );

    return res.data.customerWithSubdocuments;
  } catch (error) {
    console.log("customerFunctions.getSubdocumentsOfCustomer: ", error.message);
  }
};
//
export const getSubdocumentsOfCycle = async (cycleId) => {
  // remember, the data that is in this response is in an INVALID format for storing in the database
  // this data structure makes the front end easier to write
  try {
    cycleId.toString();

    const res = await axios.post(
      "/api/v1/customer/get-subdocuments-of-cycle",
      { cycleId },
      axiosConfig
    );

    if (res) {
      return res.data.cycleWithSubdocuments;
    }
  } catch (error) {
    console.log("customerFunctions.getSubdocumentsOfCycle: ", error.message);
  }
};
//
export const internetSourceUpdate = async (
  source,
  currentCycle,
  setSelectedInternetSource
) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/update-internet-source",
      {
        id: currentCycle._id,
        source,
      },
      axiosConfig
    );

    if (res.data.success) {
      setSelectedInternetSource(source);
    } else {
      throw new Error(
        "Data Error: could not update Cycle internet source on the server..."
      );
    }
  } catch (error) {
    alert(error.message);
  }
};
//
export const markCycleHot = async (hotStatus, cycleId) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/mark-cycle-hot",
      { hotStatus, cycleId },
      axiosConfig
    );

    if (res.data) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.markCycleHot: ", error.message);
  }
};
//
export const mergeExistingCustomers = async (customersToMerge) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/merge-existing-customers",
      {
        customersToMerge,
      },
      axiosConfig
    );

    if (res.data) {
      return res.data.finalCustomer;
    }
  } catch (error) {
    console.log(error.message);
  }
};
//
export const mergeNewlyCreatedCustomer = async (
  customersToMerge,
  searchedCustomer,
  propertiesOfFutureCycle
) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/merge-newly-created-customer",
      { customersToMerge, searchedCustomer, propertiesOfFutureCycle },
      axiosConfig
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
//
export const replaceActionSchedule = async (
  customerId,
  cycleId,
  followUpTemplateId
) => {
  try {
    await axios.post(
      "/api/v1/customer/replace-action-schedule",
      { customerId, cycleId, followUpTemplateId },
      axiosConfig
    );
    window.location.reload();
  } catch (error) {
    alert("error replacing action schedule");
    window.location.reload();
  }
};
//
export const saveAppt = async (customer, appointment, currentCycle) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/save-appointment",
      { customer, appointment, currentCycle },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.saveAppt: ", error.message);
  }
};
//
export const saveEditedCustomer = async (editedCustomerObj) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/save-edited-customer",
      { editedCustomerObj },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.saveEditedCustomer: ", error.message);
  }
};
//
export const saveNewAction = async (
  dueDateObj,
  customerId,
  cycleId,
  actionType
) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/save-new-action",
      { dueDateObj, customerId, cycleId, actionType },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.saveNewAction: ", error.message);
  }
};
//
export const sendManagerEmails = async (
  currentUser,
  cycleJsonArr,
  emailSubject,
  emailBody
) => {
  try {
    const res = await axios.post(
      "/api/v1/email/send-manager-emails",
      { currentUser, cycleJsonArr, emailSubject, emailBody },
      axiosConfig
    );

    if (res.data.success) {
      alert("emails sent!");
    } else {
      throw new Error("could not send emails");
    }
  } catch (error) {
    alert(error.message);
    console.log("customerFunctions.sendManagerEmails: ", error);
  }
};
//
export const sendMultiEmail = async (customerIds, emailSubject, emailBody) => {
  try {
    const res = await axios.post(
      "/api/v1/email/send-multi-email",
      {
        customerIds,
        emailSubject,
        emailBody,
      },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.sendMultiEmail: ", error.message);
  }
};
//
export const sendMultiText = async (customerIds, textBody) => {
  try {
    const res = await axios.post(
      "/api/v1/text/send-multi-text",
      {
        customerIds,
        textBody,
      },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.sendMultiText: ", error.message);
  }
};
//
export const sendReply = async (recipientObj, subjectString, bodyString) => {
  try {
    let isKbb = false;
    if (subjectString.includes("Instant Cash Offer")) {
      console.log("INCLUDED");
      isKbb = true;
    } else {
      console.log("NOT INCLUDED");
    }
    const res = await axios.post(
      "/api/v1/email/send-reply",
      { recipientObj, subjectString, bodyString, isKbb },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.sendReply: ", error.message);
  }
};
//
export const sendText = async (customer, textBody) => {
  try {
    const res = await axios.post(
      "/api/v1/text/send-text",
      { customer, textBody },
      axiosConfig
    );

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.sendText: ", error.message);
  }
};
//
export const setCycleActive = async (cycle) => {
  try {
    // there is a time when we receive an empty appointments array...
    const res = await axios.post(
      "/api/v1/customer/set-cycle-active",
      { cycleId: cycle._id },
      axiosConfig
    );
    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("customerFunctions.setCycleActive: ", error.message);
  }
};
//
export const setCycleInactive = async (cycle) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/set-cycle-inactive",
      { cycleId: cycle._id },
      axiosConfig
    );
    if (res.data.success) {
      return true;
    }
  } catch (error) {
    console.log("customerFunctions.setCycleInactive: ", error.message);
  }
};
//
export const setSearchedCustomer = async (
  searchedInfo,
  type,
  data,
  salesperson
) => {
  try {
    localStorage.setItem(
      "searchedCustomer",
      JSON.stringify({
        customerObj: searchedInfo ? JSON.parse(searchedInfo) : null,
        searchedCustomerType: type,
        searchedCustomerVehicle: data,
        searchedCustomerSalesPerson: salesperson,
      })
    );

    return true;
  } catch (error) {
    console.log("customerFunctions.setSearchedCustomer: ", error.message);
    return false;
  }
};
//
export const updateEmailNote = async (noteObj) => {
  try {
    await axios.post(
      "/api/v1/customer/update-email-note",
      { noteObj },
      axiosConfig
    );
  } catch (error) {
    console.log("customerFunctions.updateEmailNote: ", error.message);
  }
};
//
export const updateVehicle = async (
  cycleId,
  year,
  make,
  model,
  type,
  stock,
  vin
) => {
  try {
    const res = await axios.post(
      "/api/v1/customer/update-cycle-vehicle",
      { cycleId, year, make, model, type, stock, vin },
      axiosConfig
    );

    if (res.data) {
      return res.data.success;
    }
  } catch (error) {
    console.log(error.message);
  }
};
