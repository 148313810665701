import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js";
import { format } from "date-fns";
import {
  getCustomersFromIds,
  getCyclesFromIds,
} from "../../functions/customerFunctions";
import DealershipContext from "../../context/dealership/dealershipContext";
import UserContext from "../../context/users/userContext";

const SoldViewer = ({
  endDate,
  handleCancel,
  customerClickHandler,
  passedStoreVisits,
  startDate,
}) => {
  //
  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);
  //
  const soldViewerRef = useRef();
  //
  const [currentSalesPerson, setCurrentSalesPerson] = useState("");
  const [currentSalesPeople, setCurrentSalesPeople] = useState([]);
  const [soldCustomersPopulated, setSoldCustomersPopulated] = useState(false);
  const [soldCustomers, setSoldCustomers] = useState([]);
  const [soldStoreVisits, setSoldStoreVisits] = useState([]);
  const [loading, setLoading] = useState(false);
  //
  useEffect(() => {
    if (!soldCustomersPopulated) {
      loadSoldViewer();
    }
    // eslint-disable-next-line
  }, []);

  const handleSalesPersonChange = (e) => {
    safeStateSetter(() => {
      setLoading(true);
    });

    const localSales = e.currentTarget.value;

    setCurrentSalesPerson(localSales);

    const cycleIds = [];

    passedStoreVisits.forEach((sv) => {
      cycleIds.push(sv.cycle);
    });

    (async function () {
      const cyclesWithSalesPeople = await getCyclesFromIds(cycleIds);

      const localStoreVisits = [];

      cyclesWithSalesPeople.forEach((cwsp) => {
        passedStoreVisits.forEach((psv) => {
          if (cwsp._id === psv.cycle) {
            if (localSales === "") {
              localStoreVisits.push(psv);
            } else if (
              `${cwsp.primarySales.firstName} ${cwsp.primarySales.lastName}` ===
              localSales
            ) {
              localStoreVisits.push(psv);
            }
          }
        });
      });

      // console.log(localStoreVisits);

      setSoldStoreVisits(localStoreVisits);

      let customerIds = [];

      localStoreVisits.forEach((lsv) => {
        customerIds.push(lsv.customer);
      });

      const dbCustomers = await getCustomersFromIds(customerIds);

      if (dbCustomers) {
        safeStateSetter(() => {
          setLoading(false);
          setSoldCustomers(dbCustomers);
        });
      } else {
        console.log("could not get sold customers");
      }
    })();
  };

  const loadSoldViewer = async () => {
    try {
      setLoading(true);

      const soldCustomerIds = [];
      if (!soldStoreVisits[0]) {
        if (passedStoreVisits[0]) {
          setSoldStoreVisits(passedStoreVisits);
          passedStoreVisits.forEach((psv) => {
            soldCustomerIds.push(psv.customer);
          });
        }
      } else {
        soldStoreVisits.forEach((ssv) => {
          soldCustomerIds.push(ssv.customer);
        });
      }

      const dbCustomers = await getCustomersFromIds(soldCustomerIds);

      // console.log(dbCustomers);

      const { user } = await userContext.getUserOnly();

      // we start off knowing who the salespeople are and who the user is

      // all we need to do is make sure the STRING in currentSalesPersonString matches the current salesperson?

      let salesPersonData;
      if (user) {
        salesPersonData = await dealershipContext.getSalesPeople(user);

        safeStateSetter(() => {
          setCurrentSalesPeople(salesPersonData);
        });
      }

      // console.log(dbCustomers);

      if (dbCustomers) {
        safeStateSetter(() => {
          setLoading(false);
          setSoldCustomers(dbCustomers);
          setSoldCustomersPopulated(true);
        });
      } else {
        console.log("could not get sold customers");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const safeStateSetter = (fn) => {
    if (soldViewerRef.current) {
      fn();
    }
  };

  return (
    <div className="popup" id="popup-sold-store-visits" ref={soldViewerRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Total Sold
          </p>
          <div
            onClick={handleCancel}
            id="hide-sold-store-visits"
            className="cursor-pointer"
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="flex-row-center-center center-text medium-text dark-blue-green-text">
            These are all the sold customers entered within the time period
            of&nbsp;
            {startDate} to {endDate}.
          </div>
          <select
            name="salesperson"
            id="salesperson"
            className="form-select three-quarter-width small-text dark-blue-green-text"
            value={currentSalesPerson}
            onChange={handleSalesPersonChange}
          >
            <option value="">Select...</option>

            {currentSalesPeople.map((salesperson) => {
              return (
                <option
                  key={salesperson._id}
                  value={`${salesperson.firstName} ${salesperson.lastName}`}
                >
                  {`${salesperson.firstName} ${salesperson.lastName}`}
                </option>
              );
            })}
          </select>
          <div className="flex-row-center-center large-text dark-blue-text">
            <p> Results: {soldStoreVisits.length} </p>
          </div>
          <div className="popup-section popup-section-taller">
            {loading ? (
              <div className="flex-column-center-center">
                <div className="lds-dual-ring"></div>
              </div>
            ) : (
              <Fragment>
                {soldCustomers.map((res, idx) => (
                  <div
                    className="module-checkbox-container"
                    key={idx}
                    id={`parent-${idx}`}
                  >
                    <div
                      className="module module-full-width"
                      id={JSON.stringify(res)}
                      key={idx}
                      onClick={customerClickHandler}
                    >
                      <div className="module-main">
                        {res.active ? (
                          <div className="flex-row-center-center">
                            {res.face && (
                              <img
                                className="avatar-smaller margin-right-small"
                                src={`data:image/jpeg;base64,${res.face}`}
                                alt="customer_face"
                              />
                            )}

                            <div className="flex-column-center-evenly">
                              <div className="module-data dark-blue-green-text">
                                {res && res.firstName} {res && res.lastName}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res &&
                                  res.phoneNumber &&
                                  typeof res.phoneNumber === "string" &&
                                  parsePhoneNumber(res.phoneNumber) &&
                                  parsePhoneNumber(
                                    res.phoneNumber
                                  ).formatNational()}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res && res.emailAddress && res.emailAddress}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex-row-center-center">
                            {res.face && (
                              <img
                                className="avatar-smaller margin-right-small"
                                src={`data:image/jpeg;base64,${res.face}`}
                                alt="customer_face"
                              />
                            )}
                            <div className="flex-column-center-evenly">
                              <div className="module-data dark-blue-green-text">
                                {res && res.firstName} {res && res.lastName}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res &&
                                  res.phoneNumber &&
                                  typeof res.phoneNumber === "string" &&
                                  parsePhoneNumber(res.phoneNumber) &&
                                  parsePhoneNumber(
                                    res.phoneNumber
                                  ).formatNational()}
                              </div>

                              <div className="module-data dark-blue-green-text">
                                {res && res.emailAddress}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="module-section module-section-elevated">
                        <div className="white-text">Last Updated</div>
                        <div className="white-text">
                          {res && format(new Date(res.updatedAt), "P")}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SoldViewer.propTypes = {
  endDate: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  customerClickHandler: PropTypes.func.isRequired,
  passedStoreVisits: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default SoldViewer;
