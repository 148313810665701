import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js";
import { format } from "date-fns";
import { getCustomersFromIds } from "../../functions/customerFunctions";

const TotalStoreVisitViewer = ({
  endDate,
  handleCancel,
  totalStoreVisits,
  startDate,
}) => {
  const history = useHistory();

  const totalStoreVisitViewerRef = useRef();

  const [storeVisitsPopulated, setStoreVisitsPopulated] = useState(false);
  const [storeVisits, setStoreVisits] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!storeVisitsPopulated) {
      loadTotalStoreVisitViewer();
    }
    // eslint-disable-next-line
  }, []);

  const loadTotalStoreVisitViewer = async () => {
    try {
      setLoading(true);
      const customerIds = [];

      const localStoreVisits = totalStoreVisits;

      localStoreVisits.forEach((sv) => {
        customerIds.push(sv.customer);
      });

      const dbCustomers = await getCustomersFromIds(customerIds);

      // console.log(dbCustomers);

      if (dbCustomers) {
        localStoreVisits.forEach((sv) => {
          // console.log(sv.customer);
          // let matchFound = false;
          dbCustomers.forEach((c) => {
            if (sv.customer._id) {
              if (sv.customer._id.toString() === c._id.toString()) {
                // matchFound = true;
                sv.customer = c;
              }
            } else {
              if (sv.customer.toString() === c._id.toString()) {
                // matchFound = true;
                sv.customer = c;
              }
            }
          });
          // if (!matchFound) {
          //   console.log(
          //     "failure",
          //     sv.customer,
          //     customerIds.includes(sv.customer)
          //   );
          // } else {
          //   console.log(
          //     "success",
          //     sv.customer,
          //     customerIds.includes(sv.customer._id)
          //   );
          // }
          // console.log(sv);
        });

        localStoreVisits.sort((a, b) => {
          if (
            new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()
          ) {
            return 1;
          } else {
            return -1;
          }
        });

        // console.log(localStoreVisits);

        safeStateSetter(() => {
          setLoading(false);
          setStoreVisits(localStoreVisits);
          setStoreVisitsPopulated(true);
        });
      } else {
        console.log("could not get customers");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const safeStateSetter = (fn) => {
    if (totalStoreVisitViewerRef.current) {
      fn();
    }
  };

  const storeVisitClickHandler = (e) => {
    const fullObj = JSON.parse(e.currentTarget.id);

    history.push({
      pathname: "/desk-log",
      state: {
        svDate: fullObj.createdAt,
      },
    });
  };

  return (
    <div
      className="popup"
      id="popup-sold-store-visits"
      ref={totalStoreVisitViewerRef}
    >
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Total Store Visits
          </p>
          <div
            onClick={handleCancel}
            id="hide-total-store-visits"
            className="cursor-pointer"
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="flex-row-center-center center-text medium-text dark-blue-green-text">
            These are all the store visits within the time period of&nbsp;
            {startDate} to {endDate}.
          </div>
          <div className="flex-row-center-center large-text dark-blue-text">
            <p> Results: {totalStoreVisits.length} </p>
          </div>
          <div className="popup-section popup-section-taller">
            {loading ? (
              <div className="flex-column-center-center">
                <div className="lds-dual-ring"></div>
              </div>
            ) : (
              <Fragment>
                {storeVisits.map((res, idx) => (
                  <div
                    className="module-checkbox-container"
                    key={idx}
                    id={`parent-${idx}`}
                  >
                    <div
                      className="module module-full-width"
                      id={JSON.stringify(res)}
                      key={idx}
                      onClick={storeVisitClickHandler}
                    >
                      <div className="module-main">
                        <div className="flex-row-center-center">
                          {res.customer.face && (
                            <img
                              className="avatar-smaller margin-right-small"
                              src={`data:image/jpeg;base64,${res.customer.face}`}
                              alt="customer_face"
                            />
                          )}

                          <div className="flex-column-center-evenly">
                            <div className="module-data dark-blue-green-text">
                              {res && res.customer && res.customer.firstName}{" "}
                              {res && res.customer && res.customer.lastName}
                            </div>

                            <div className="module-data dark-blue-green-text">
                              {res &&
                                res.customer &&
                                res.customer.phoneNumber &&
                                typeof res.customer.phoneNumber === "string" &&
                                parsePhoneNumber(res.customer.phoneNumber) &&
                                parsePhoneNumber(
                                  res.customer.phoneNumber
                                ).formatNational()}
                            </div>

                            <div className="module-data dark-blue-green-text">
                              {res &&
                                res.customer &&
                                res.customer.emailAddress &&
                                res.customer.emailAddress}
                            </div>
                          </div>
                        </div>
                      </div>
                      {res.status && res.status === "sold" && (
                        <div className="module-section module-section-elevated">
                          <div className="white-text">Date:</div>
                          <div className="white-text">
                            {res &&
                              res.createdAt &&
                              format(new Date(res.createdAt), "P")}
                          </div>
                        </div>
                      )}

                      {res.status && res.status === "pending" && (
                        <div className="module-section module-section-dark-amber">
                          <div className="white-text">Date:</div>
                          <div className="white-text">
                            {res &&
                              res.createdAt &&
                              format(new Date(res.createdAt), "P")}
                          </div>
                        </div>
                      )}

                      {res.status && res.status === "not-sold" && (
                        <div className="module-section module-section-negative">
                          <div className="white-text">Date:</div>
                          <div className="white-text">
                            {res &&
                              res.createdAt &&
                              format(new Date(res.createdAt), "P")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TotalStoreVisitViewer.propTypes = {
  endDate: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  totalStoreVisits: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default TotalStoreVisitViewer;
