import React from "react";
import PropTypes from "prop-types";

const ComposeMultiText = ({
  currentMultiTextBody,
  handleCancel,
  sendMultiTextHandler,
  updateCurrentMultiTextBody,
}) => {
  return (
    <div className="popup-content popup-content-special">
      <div className="popup-header popup-header-special">
        <p className="large-text white-text center-text flex-grow">
          Compose Multi Text
        </p>
        <div onClick={handleCancel} id="hide-multi-text">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <textarea
          className="popup-section popup-section-red small-medium-text dark-red-text"
          placeholder="Type Here..."
          value={currentMultiTextBody}
          onChange={updateCurrentMultiTextBody}
        ></textarea>
        <button
          className="button-negative large-text"
          onClick={sendMultiTextHandler}
        >
          Send Texts
        </button>
      </div>
    </div>
  );
};

ComposeMultiText.propTypes = {
  currentMultiTextBody: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  sendMultiTextHandler: PropTypes.func.isRequired,
  updateCurrentMultiTextBody: PropTypes.func.isRequired,
};

export default ComposeMultiText;
