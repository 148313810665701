import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const Sources = ({ endDate, handleCancel, fullCycles, startDate }) => {
  const sourcesRef = useRef();
  //
  const [loading, setLoading] = useState(true);
  const [sourceTable, setSourceTable] = useState(null);
  const [soldTable, setSoldTable] = useState(null);
  const [percentages, setPercentages] = useState(null);
  //
  useEffect(() => {
    loadSources();
    //eslint-disable-next-line
  }, []);
  //
  const loadSources = async () => {
    try {
      let localTable = null;

      fullCycles.forEach((cy) => {
        if (!localTable) {
          localTable = {};
        }
        if (cy.cycleType === "internet") {
          if (cy.internetSource) {
            if (localTable[cy.internetSource]) {
              localTable[cy.internetSource].push(cy);
            } else {
              localTable[cy.internetSource] = [];
              localTable[cy.internetSource].push(cy);
            }
          } else {
            if (localTable["internet-unattributed"]) {
              localTable["internet-unattributed"].push(cy);
            } else {
              localTable["internet-unattributed"] = [];
              localTable["internet-unattributed"].push(cy);
            }
          }
        } else if (cy.cycleType === "phone") {
          if (!localTable["phone"]) {
            localTable["phone"] = [];
            localTable["phone"].push(cy);
          } else {
            localTable["phone"].push(cy);
          }
        } else {
          if (!localTable["walkin"]) {
            localTable["walkin"] = [];
            localTable["walkin"].push(cy);
          } else {
            localTable["walkin"].push(cy);
          }
        }
      });

      if (Object.keys(localTable).length === 0) {
        localTable = null;
      }

      if (localTable) {
        const sortable = [];
        for (let sourceName in localTable) {
          sortable.push([sourceName, localTable[sourceName]]);
        }

        sortable.sort((a, b) => {
          return a[1].length - b[1].length;
        });

        sortable.reverse();

        const sortedTable = {};

        sortable.forEach((item) => {
          sortedTable[item[0]] = item[1];
        });

        // console.log(sortedTable);

        const soldTable = {};

        const relevantStoreVisitIds = [];
        for (const src in sortedTable) {
          sortedTable[src].forEach((cy) => {
            if (!cy.active && cy.storeVisits && cy.storeVisits.length > 0) {
              cy.storeVisits.forEach((svid) => {
                relevantStoreVisitIds.push(svid);
              });
            }
          });
        }

        // server time

        const res = await axios.post(
          "/api/v1/dealership/get-store-visits-by-ids",
          { relevantStoreVisitIds },
          axiosConfig
        );

        // console.log(res);

        for (const src in sortedTable) {
          sortedTable[src].forEach((cy) => {
            if (
              !cy.active &&
              cy.storeVisits &&
              cy.storeVisits.length > 0 &&
              res.data.storeVisits &&
              res.data.storeVisits.length > 0
            ) {
              res.data.storeVisits.forEach((sv) => {
                if (cy.storeVisits.includes(sv._id) && sv.status === "sold") {
                  if (!soldTable[src]) {
                    soldTable[src] = [];
                  }
                  soldTable[src].push(cy);
                }
              });
            }
          });
        }

        // here we can also calculate total phone, internet, and walkin closing!
        const phoneInternetWalkin = {};

        const internetSources = {};

        for (const src in sortedTable) {
          if (!soldTable[src]) {
            // do stuff
            soldTable[src] = [];
          }

          switch (src) {
            case "walkin":
              phoneInternetWalkin["walkin"] =
                (soldTable[src].length / sortedTable[src].length) * 100;

              break;

            case "phone":
              phoneInternetWalkin["phone"] =
                (soldTable[src].length / sortedTable[src].length) * 100;

              break;

            default:
              internetSources[src] = {
                leads: sortedTable[src].length,
                sold: soldTable[src].length,
              };
              break;
          }
        }

        let totalInternetLeads = 0;
        let totalInternetSold = 0;

        for (const iSource in internetSources) {
          totalInternetLeads += parseInt(internetSources[iSource].leads);
          totalInternetSold += parseInt(internetSources[iSource].sold);
        }

        phoneInternetWalkin["internet"] =
          (totalInternetSold / totalInternetLeads) * 100;
        // console.log(soldTable);

        // console.log(phoneInternetWalkin);

        setPercentages(phoneInternetWalkin);
        setSourceTable(sortedTable);
        setSoldTable(soldTable);
        setLoading(false);
      } else {
        alert("no sourced leads found");
        window.location.reload();
      }
    } catch (error) {
      console.log("Sources.loadSources: ", error);
    }
  };
  //
  return (
    <div className="popup" ref={sourcesRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">Sources</p>
          <div
            className="cursor-pointer"
            id="hide-sources"
            onClick={handleCancel}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>

        <div className="popup-body popup-body-evenly">
          {loading ? (
            <div>
              <div className="lds-dual-ring"></div>
            </div>
          ) : (
            <div className="full-width flex-column-center-evenly full-height">
              <div className="flex-row-center-center center-text medium-text dark-blue-green-text">
                <span>
                  These are all the&nbsp;
                  <span className="dark-red-text">leads&nbsp;received</span>
                  &nbsp;within the time period of&nbsp;
                  {startDate} to {endDate}.
                </span>
              </div>
              {Object.keys(sourceTable).map((src, idx) => (
                <div
                  className="medium-text dark-blue-text three-quarter-width flex-row-center-evenly "
                  key={idx}
                >
                  <p>{src}:</p>
                  <p>
                    {sourceTable[src].length} Leads /{" "}
                    {soldTable[src] ? soldTable[src].length : "0"} Sold
                  </p>
                </div>
              ))}

              <div className="flex-row-center-center center-text medium-text dark-blue-green-text">
                This store's closing % by lead type:
              </div>
              <div className="medium-text dark-blue-text three-quarter-width flex-row-center-evenly">
                <p>Phone:&nbsp;</p>
                {percentages.phone >= 25 && (
                  <p className="dark-green-text">
                    {percentages && percentages.phone.toFixed(2)}%
                  </p>
                )}
                {percentages.phone >= 20 && percentages.phone < 25 && (
                  <p className="dark-amber-text">
                    {percentages && percentages.phone.toFixed(2)}%
                  </p>
                )}
                {percentages.phone < 20 && (
                  <p className="dark-red-text">
                    {percentages && percentages.phone.toFixed(2)}%
                  </p>
                )}
                <p className="dark-amber-text small-text">
                  (National Avg.: 25%)
                </p>
              </div>
              <div className="medium-text dark-blue-text three-quarter-width flex-row-center-evenly ">
                <p>Internet:&nbsp;</p>
                {percentages.internet >= 15 && (
                  <p className="dark-green-text">
                    {percentages && percentages.internet.toFixed(2)}%
                  </p>
                )}
                {percentages.internet >= 10 && percentages.internet < 15 && (
                  <p className="dark-amber-text">
                    {percentages && percentages.internet.toFixed(2)}%
                  </p>
                )}
                {percentages.internet < 10 && (
                  <p className="dark-red-text">
                    {percentages && percentages.internet.toFixed(2)}%
                  </p>
                )}
                <p className="dark-amber-text small-text">
                  (National Avg.: 15%)
                </p>
              </div>
              <div className="medium-text dark-blue-text three-quarter-width flex-row-center-evenly ">
                <p>Walkin:&nbsp;</p>
                {percentages.walkin >= 33 && (
                  <p className="dark-green-text">
                    {percentages && percentages.walkin.toFixed(2)}%
                  </p>
                )}
                {percentages.walkin >= 28 && percentages.walkin < 33 && (
                  <p className="dark-amber-text">
                    {percentages && percentages.walkin.toFixed(2)}%
                  </p>
                )}
                {percentages.walkin < 28 && (
                  <p className="dark-red-text">
                    {percentages && percentages.walkin.toFixed(2)}%
                  </p>
                )}
                <p className="dark-amber-text small-text">
                  (National Avg.: 33%)
                </p>
              </div>
              <button
                className="button-normal button-normal-smaller small-medium-text"
                onClick={() => {
                  window.alert("feature coming soon!");
                }}
              >
                View Detail
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Sources.propTypes = {
  endDate: PropTypes.string.isRequired,
  fullCycles: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default Sources;
