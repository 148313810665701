import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";
import format from "date-fns/format";

import UserContext from "../context/users/userContext.js";
import {
  getSubdocumentsOfCustomer,
  saveAppt,
} from "../functions/customerFunctions";

const AppointmentInfo = () => {
  const userContext = useContext(UserContext);

  const checkEnteredCustomerRef = useRef();
  const mainBodyRef = useRef();

  const history = useHistory();

  const [appointment, setAppointment] = useState(null);
  const [apptDate, setApptDate] = useState("");
  const [apptTime, setApptTime] = useState("");
  // temporary fix here for apptStatus
  const [apptStatus, setApptStatus] = useState("pending");
  const [apptSetter, setApptSetter] = useState("");
  const [customer, setCustomer] = useState(null);
  const [cycle, setCycle] = useState(null);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    if (!dataReady) {
      loadAppointmentInfo();
    }

    // eslint-disable-next-line
  }, [dataReady]);

  const loadAppointmentInfo = async () => {
    const userLoaded = await userContext.getUserOnly();
    console.log("got user only");
    const currentCustomer = JSON.parse(
      localStorage.getItem("localCurrentCustomer")
    );

    if (currentCustomer && userLoaded && userLoaded.user) {
      setApptSetter(userLoaded.user._id);
      const customerWithSubdocuments = await getSubdocumentsOfCustomer(
        currentCustomer._id
      );
      if (customerWithSubdocuments) {
        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(customerWithSubdocuments)
        );
      } else {
      }
    } else {
    }

    const localCurrentCustomer = JSON.parse(
      localStorage.getItem("localCurrentCustomer")
    );
    if (localCurrentCustomer) {
      // console.log(localCurrentCustomer);
      setDataReady(true);

      if (mainBodyRef.current) {
        setCustomer(localCurrentCustomer);
        const localCurrentCycle = JSON.parse(
          localStorage.getItem("localCurrentCycle")
        );
        if (localCurrentCycle) {
          setCycle(localCurrentCycle);
        }
      }
    }
  };

  const clearResults = () => {
    // double check how this works
    if (mainBodyRef.current) {
      setCustomer(null);
    }
  };

  const checkEnteredCustomer = (e) => {
    if (!e.target.value || e.target.value === " ") {
      history.push("/add-customer");
    }
  };

  const updateAppointment = async (e) => {
    // this needs to be done by checking Appointment objects on the currentCustomer fix this...4/19/21

    let updatedCustomer = null;
    switch (e.target.name) {
      case "appt-date":
        setApptDate(e.target.value);
        updatedCustomer = {
          ...customer,
          nextAppointment: {
            date: e.target.value,
            time: apptTime,
            status: apptStatus,
            setter: apptSetter,
          },
        };
        break;
      case "appt-time":
        setApptTime(e.target.value);
        updatedCustomer = {
          ...customer,
          nextAppointment: {
            date: apptDate,
            time: e.target.value,
            status: apptStatus,
            setter: apptSetter,
          },
        };
        break;
      case "appt-status":
        // console.log(e.target.value);
        setApptStatus(e.target.value);
        updatedCustomer = {
          ...customer,
          nextAppointment: {
            date: apptDate,
            time: apptTime,
            status: e.target.value,
            setter: apptSetter,
          },
        };
        break;

      default:
        break;
    }

    if (customer) {
      setAppointment(updatedCustomer.nextAppointment);
    }
  };

  const saveAppointment = async () => {
    if (
      appointment &&
      (appointment.date ||
        appointment.time ||
        appointment.status ||
        appointment.setter)
    ) {
      const trueDate = new Date(
        `${appointment.date}T${appointment.time}`
      ).toUTCString();

      appointment.date = trueDate;

      const apptSaved = await saveAppt(customer, appointment, cycle);

      localStorage.setItem("localCurrentCustomer", JSON.stringify(customer));
      localStorage.setItem("localCurrentCycle", JSON.stringify(cycle));

      if (apptSaved) {
        history.push("/cycle");
      }
    }
  };

  //
  // B. what information needs to be output from this page?
  //

  // 1 - updated appointment information

  // 2 - command to go back where we were before

  // 3 - command to delete the appointment and go back to where
  // we were before

  return (
    <div className="popup" id="popup-appointment-info" ref={mainBodyRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Appointment Info
          </p>
          <Link to="/cycle" onClick={clearResults}>
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </Link>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="module module-full-width">
            <div className="module-main">
              <div className="module-data dark-blue-green-text">
                {customer && customer.firstName} {customer && customer.lastName}
                <input
                  className="hidden"
                  ref={checkEnteredCustomerRef}
                  onClick={checkEnteredCustomer}
                  onChange={checkEnteredCustomer}
                  value={customer ? customer.firstName : " "}
                />
              </div>
              <div className="module-data dark-blue-green-text">
                {customer &&
                  customer.phoneNumber &&
                  typeof customer.phoneNumber === "string" &&
                  parsePhoneNumber(customer.phoneNumber) &&
                  parsePhoneNumber(customer.phoneNumber).formatNational()}
              </div>
              <div className="module-data dark-blue-green-text">
                {customer && customer.emailAddress}
              </div>
            </div>
            <div className="module-section module-section-normal">
              <div className="tiny-text white-text">Last Updated</div>

              <div className="tiny-text white-text">
                {customer && format(new Date(customer.updatedAt), "P")}
              </div>
            </div>
          </div>

          <div className="form-row">
            <p className="large-text medium-blue-text">Date:</p>
            <input
              type="date"
              name="appt-date"
              className="form-field large-text medium-blue-green-text"
              id="appt-date"
              onChange={updateAppointment}
              value={apptDate && apptDate}
            />
          </div>
          <div className="form-row">
            <p className="large-text medium-blue-text">Time:</p>
            <input
              type="time"
              min="09:00"
              max="20:00"
              step="900"
              name="appt-time"
              className="form-field large-text medium-blue-green-text"
              id="appt-time"
              onChange={updateAppointment}
              value={apptTime && apptTime}
            />
          </div>
          <div className="form-row">
            <div className="form-radio-select flex-grow">
              <div className="form-radio-select-field flex-grow">
                <label
                  htmlFor="show-radio"
                  className="medium-large-text dark-green-text"
                >
                  Show
                </label>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name="appt-status"
                    className="radio-button"
                    id="show-radio"
                    value="show"
                    onChange={updateAppointment}
                    checked={apptStatus === "show" ? true : false}
                  />
                  <div className="radio-button"></div>
                </div>
              </div>
              <div className="form-radio-select-field flex-grow">
                <label
                  htmlFor="pending-radio"
                  className="medium-large-text medium-amber-text"
                >
                  Pending
                </label>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name="appt-status"
                    className="radio-button"
                    id="pending-radio"
                    value="pending"
                    onChange={updateAppointment}
                    checked={apptStatus === "pending" ? true : false}
                  />
                  <div className="radio-button"></div>
                </div>
              </div>
              <div className="form-radio-select-field flex-grow">
                <label
                  htmlFor="no-show-radio"
                  className="medium-large-text bright-red-text"
                >
                  No Show
                </label>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name="appt-status"
                    className="radio-button"
                    id="no-show-radio"
                    value="no-show"
                    onChange={updateAppointment}
                    checked={apptStatus === "no-show" ? true : false}
                  />
                  <div className="radio-button"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row">
            <button
              className={
                customer && customer.nextAppointment
                  ? "button-negative medium-large-text flex-grow"
                  : "button-negative button-negative-disabled medium-large-text flex-grow "
              }
            >
              Delete Appointment
            </button>
          </div>
          <div className="form-row">
            <button
              className="button-normal flex-grow margin-right-large large-text"
              onClick={history.goBack}
            >
              Back
            </button>
            <button
              className="button-elevated flex-grow large-text"
              id="appt-save-btn"
              onClick={saveAppointment}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentInfo;
