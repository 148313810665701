import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

export const Landing = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const mainBodyRef = useRef();
  const backgroundImage = useRef();
  const imgElement = useRef();
  const deskLogImg = useRef();
  const mobileFirstButton = useRef();
  const performanceScreenButton = useRef();
  const performanceStatsButton = useRef();
  const duplicateDetectionButton = useRef();
  const colorCodedButton = useRef();
  const deskLogTrendsButton = useRef();
  const notesButton = useRef();
  const inventoryButton = useRef();

  const [authenticated, setAuthenticated] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [performanceSourceState, setPerformanceSourceState] = useState("");
  const [deskLogSourceState, setDeskLogSourceState] = useState("");

  const loadLanding = async () => {
    const status = await authContext.checkAuthenticated();
    if (status.authenticated && mainBodyRef.current) {
      setLoaded(true);
      setAuthenticated(true);
    } else {
      backgroundImage.current.style["opacity"] = "25%";
      performanceScreenButton.current.click();
      mobileFirstButton.current.click();
    }
  };

  useEffect(() => {
    if (!loaded && mainBodyRef.current) {
      loadLanding();
    }
    // eslint-disable-next-line
  }, [loaded]);

  if (authenticated) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <div className="flex-column-center-between full-width" ref={mainBodyRef}>
        <div className="main-header-background" ref={backgroundImage}></div>
        <div className="flex-column-center-center background-white padding-large">
          <h1 className="main-title main-title-huge dark-blue-text">
            Lead&nbsp;Zen
          </h1>
          <p className="large-text dark-blue-green-text spaced-letters">
            The Uncluttered CRM
          </p>
          <div className="flex-row-center-center margin-top-medium">
            <button
              className="button-normal margin-right-large small-medium-text"
              onClick={() => {
                history.push("/login-part-one");
              }}
            >
              Log&nbsp;In
            </button>
            <a
              href="https://www.youtube.com/watch?v=7wpgkLg6vzM"
              className="button-normal margin-right-large small-medium-text"
              target="_blank"
              rel="noreferrer"
            >
              Learn&nbsp;More
            </a>
            <button
              className="button-elevated small-medium-text"
              onClick={(e) => {
                history.push("/public-contact");
              }}
            >
              Request&nbsp;Demo
            </button>
          </div>
        </div>

        <div className="border-section"></div>

        <div className="homepage-section full-width" id="first-section">
          <div className="flex-column-center-center homepage-section-half padding-large">
            <h2 className="main-title dark-blue-text margin-bottom-large">
              One&nbsp;Experience
            </h2>
            <p className="medium-text dark-blue-green-text section-subheading center-text spaced-letters margin-bottom-large">
              Lead Zen is the mobile-first Automotive CRM system designed with
              you in mind
            </p>

            <div className="grid">
              <div className="ten-grid-item padding-medium">
                <div className="fifty-height ten-grid-item-image ten-grid-item-image-uncluttered full-width"></div>
                <div className="small-medium-text dark-blue-text padding-large fifty-height flex-column-center-center">
                  Lead Zen is designed from the ground up to reduce complexity.
                </div>
              </div>

              <div className="ten-grid-item padding-medium">
                <div className="fifty-height ten-grid-item-image ten-grid-item-image-one-interface full-width"></div>
                <div className="small-medium-text dark-blue-text padding-large fifty-height flex-column-center-center">
                  Looks and works the same on mobile and on desktop pc.
                </div>
              </div>

              <div className="ten-grid-item padding-medium">
                <div className="fifty-height ten-grid-item-image ten-grid-item-image-salesperson-on-phone full-width"></div>
                <div className="small-medium-text dark-blue-text padding-large fifty-height flex-column-center-center">
                  Our clear, readable user interface makes follow-up simple.
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow iphone-graphic full-page-height"></div>
        </div>

        <div className="border-section">&nbsp;</div>

        <div className="homepage-section homepage-section-offset full-width padding-bottom-large">
          <div className="flex-column-center-center margin-top-large">
            <h2 className="main-title dark-blue-text margin-bottom-large margin-top-large">
              Discover Efficiency
            </h2>

            <p className="dark-blue-green-text medium-large-text section-subheading center-text spaced-letters">
              Lead Zen is the system that lets you reach more customers in less
              time
            </p>

            <div className="grid margin-top-large margin-bottom-large">
              <div className="grid-row">
                <div className="grid-item">
                  <div className="grid-item-image grid-item-image-customer-search full-width flex-grow margin-bottom-medium"></div>
                  <div>
                    <p className="dark-blue-text small-medium-text center-text padding-small">
                      Our high-quality search algorithm helps you find your
                      customers easily and accurately.
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="grid-item-image grid-item-image-call-center full-width flex-grow margin-bottom-small"></div>
                  <div>
                    <p className="dark-blue-text small-medium-text center-text ">
                      Lead Zen makes managing follow-up actions easier than
                      ever.
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="grid-item-image grid-item-image-exponential full-width flex-grow margin-bottom-small"></div>
                  <div>
                    <p className="dark-blue-text small-medium-text center-text ">
                      Our <span className="dark-red-text">Multi Select</span>{" "}
                      system is a game-changer,{" "}
                      <span className="italic-text">exponentially</span>{" "}
                      increasing the number of customers within your reach.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-section">&nbsp;</div>
        </div>

        <div className="homepage-section full-width padding-bottom-large">
          <div className="flex-column-center-center margin-top-large full-width">
            <h2 className="main-title dark-blue-text margin-bottom-large margin-top-large">
              Know More
            </h2>

            <p className="dark-blue-green-text medium-large-text section-subheading center-text spaced-letters margin-bottom-large">
              Lead Zen is built to help managers cut through the noise
            </p>

            <div className="center-text performance-section">
              <div className="margin-bottom-medium ">
                <svg className="icon icon-performance">
                  <use href="/icons/symbol-defs.svg#icon-graph"></use>
                </svg>
              </div>
              <p className="medium-amber-text large-text">Performance</p>
              <div className="medium-text medium-amber-text margin-top-large">
                <div
                  className={performanceSourceState && performanceSourceState}
                  ref={imgElement}
                ></div>

                <div
                  className="margin-top-large button-neutral"
                  ref={performanceScreenButton}
                  onMouseOver={() => {
                    performanceScreenButton.current.style.color = "white";
                    performanceScreenButton.current.style.backgroundColor =
                      "#bf7513";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(imgElement.current.classList);
                    if (!classes.includes("performance-image-screen")) {
                      performanceScreenButton.current.style.color = "#bf7513";
                      performanceScreenButton.current.style.backgroundColor =
                        "white";
                    }
                  }}
                  onClick={() => {
                    performanceScreenButton.current.style.color = "white";
                    performanceScreenButton.current.style.backgroundColor =
                      "#bf7513";
                    colorCodedButton.current.style.color = "#bf7513";
                    colorCodedButton.current.style.backgroundColor = "white";
                    duplicateDetectionButton.current.style.color = "#bf7513";
                    duplicateDetectionButton.current.style.backgroundColor =
                      "white";
                    performanceStatsButton.current.style.color = "#bf7513";
                    performanceStatsButton.current.style.backgroundColor =
                      "white";
                    if (mainBodyRef.current) {
                      setPerformanceSourceState(
                        "performance-image performance-image-screen"
                      );
                    }
                  }}
                >
                  Manage all relevant Sales and BDC data on one screen
                </div>
                <div
                  className="margin-top-large button-neutral"
                  ref={performanceStatsButton}
                  onMouseOver={() => {
                    performanceStatsButton.current.style.color = "white";
                    performanceStatsButton.current.style.backgroundColor =
                      "#bf7513";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(imgElement.current.classList);
                    if (!classes.includes("performance-image-stats")) {
                      performanceStatsButton.current.style.color = "#bf7513";
                      performanceStatsButton.current.style.backgroundColor =
                        "white";
                    }
                  }}
                  onClick={() => {
                    performanceStatsButton.current.style.color = "white";
                    performanceStatsButton.current.style.backgroundColor =
                      "#bf7513";
                    colorCodedButton.current.style.color = "#bf7513";
                    colorCodedButton.current.style.backgroundColor = "white";
                    duplicateDetectionButton.current.style.color = "#bf7513";
                    duplicateDetectionButton.current.style.backgroundColor =
                      "white";
                    performanceScreenButton.current.style.color = "#bf7513";
                    performanceScreenButton.current.style.backgroundColor =
                      "white";

                    if (mainBodyRef.current) {
                      setPerformanceSourceState(
                        "performance-image performance-image-stats"
                      );
                    }
                  }}
                >
                  Easily drill down to receive statistics on each salesperson
                </div>
                <div
                  className="margin-top-large button-neutral"
                  ref={duplicateDetectionButton}
                  onMouseOver={() => {
                    duplicateDetectionButton.current.style.color = "white";
                    duplicateDetectionButton.current.style.backgroundColor =
                      "#bf7513";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(imgElement.current.classList);
                    if (!classes.includes("performance-image-duplicate")) {
                      duplicateDetectionButton.current.style.color = "#bf7513";
                      duplicateDetectionButton.current.style.backgroundColor =
                        "white";
                    }
                  }}
                  onClick={() => {
                    duplicateDetectionButton.current.style.color = "white";
                    duplicateDetectionButton.current.style.backgroundColor =
                      "#bf7513";
                    colorCodedButton.current.style.color = "#bf7513";
                    colorCodedButton.current.style.backgroundColor = "white";
                    performanceScreenButton.current.style.color = "#bf7513";
                    performanceScreenButton.current.style.backgroundColor =
                      "white";
                    performanceStatsButton.current.style.color = "#bf7513";
                    performanceStatsButton.current.style.backgroundColor =
                      "white";
                    if (mainBodyRef.current) {
                      setPerformanceSourceState(
                        "performance-image performance-image-duplicate"
                      );
                    }
                  }}
                >
                  Highly advanced duplicate detection system ensures that the
                  reports you are looking at are accurate
                </div>
                <div
                  className="margin-top-large button-neutral"
                  ref={colorCodedButton}
                  onMouseOver={() => {
                    colorCodedButton.current.style.color = "white";
                    colorCodedButton.current.style.backgroundColor = "#bf7513";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(imgElement.current.classList);
                    if (!classes.includes("performance-image-color")) {
                      colorCodedButton.current.style.color = "#bf7513";
                      colorCodedButton.current.style.backgroundColor = "white";
                    }
                  }}
                  onClick={() => {
                    colorCodedButton.current.style.color = "white";
                    colorCodedButton.current.style.backgroundColor = "#bf7513";
                    duplicateDetectionButton.current.style.color = "#bf7513";
                    duplicateDetectionButton.current.style.backgroundColor =
                      "white";
                    performanceScreenButton.current.style.color = "#bf7513";
                    performanceScreenButton.current.style.backgroundColor =
                      "white";
                    performanceStatsButton.current.style.color = "#bf7513";
                    performanceStatsButton.current.style.backgroundColor =
                      "white";
                    if (mainBodyRef.current) {
                      setPerformanceSourceState(
                        "performance-image performance-image-color"
                      );
                    }
                  }}
                >
                  Color-coded system makes management a breeze
                </div>
              </div>
            </div>

            <div className="center-text desk-log-section">
              <div className="margin-bottom-medium ">
                <svg className="icon icon-sales-log">
                  <use href="/icons/symbol-defs.svg#icon-pencil"></use>
                </svg>
              </div>
              <p className="dark-green-text large-text">Desk Log</p>
              <div className="medium-text dark-green-text margin-top-large">
                <div
                  className={deskLogSourceState && deskLogSourceState}
                  ref={deskLogImg}
                ></div>

                <div
                  className="margin-top-large button-elevated"
                  ref={mobileFirstButton}
                  onMouseOver={() => {
                    mobileFirstButton.current.style.color = "white";
                    mobileFirstButton.current.style.backgroundColor = "#08992b";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(deskLogImg.current.classList);
                    if (!classes.includes("desk-log-image-mobile")) {
                      mobileFirstButton.current.style.color = "#08992b";
                      mobileFirstButton.current.style.backgroundColor = "white";
                    }
                  }}
                  onClick={() => {
                    mobileFirstButton.current.style.color = "white";
                    mobileFirstButton.current.style.backgroundColor = "#08992b";
                    inventoryButton.current.style.color = "#08992b";
                    inventoryButton.current.style.backgroundColor = "white";
                    notesButton.current.style.color = "#08992b";
                    notesButton.current.style.backgroundColor = "white";
                    deskLogTrendsButton.current.style.color = "#08992b";
                    deskLogTrendsButton.current.style.backgroundColor = "white";
                    if (mainBodyRef.current) {
                      setDeskLogSourceState(
                        "desk-log-image desk-log-image-mobile"
                      );
                    }
                  }}
                >
                  Mobile-first design increases your ability to know what's
                  going on at all times
                </div>
                <div
                  className="margin-top-large button-elevated"
                  ref={deskLogTrendsButton}
                  onMouseOver={() => {
                    deskLogTrendsButton.current.style.color = "white";
                    deskLogTrendsButton.current.style.backgroundColor =
                      "#08992b";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(deskLogImg.current.classList);
                    if (!classes.includes("desk-log-image-trends")) {
                      deskLogTrendsButton.current.style.color = "#08992b";
                      deskLogTrendsButton.current.style.backgroundColor =
                        "white";
                    }
                  }}
                  onClick={() => {
                    deskLogTrendsButton.current.style.color = "white";
                    deskLogTrendsButton.current.style.backgroundColor =
                      "#08992b";
                    inventoryButton.current.style.color = "#08992b";
                    inventoryButton.current.style.backgroundColor = "white";
                    notesButton.current.style.color = "#08992b";
                    notesButton.current.style.backgroundColor = "white";
                    mobileFirstButton.current.style.color = "#08992b";
                    mobileFirstButton.current.style.backgroundColor = "white";
                    if (mainBodyRef.current) {
                      setDeskLogSourceState(
                        "desk-log-image desk-log-image-trends"
                      );
                    }
                  }}
                >
                  Each date features a sold tally to help you keep track of
                  daily trends
                </div>
                <div
                  className="margin-top-large button-elevated"
                  ref={notesButton}
                  onMouseOver={() => {
                    notesButton.current.style.color = "white";
                    notesButton.current.style.backgroundColor = "#08992b";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(deskLogImg.current.classList);
                    if (!classes.includes("desk-log-image-notes")) {
                      notesButton.current.style.color = "#08992b";
                      notesButton.current.style.backgroundColor = "white";
                    }
                  }}
                  onClick={() => {
                    notesButton.current.style.color = "white";
                    notesButton.current.style.backgroundColor = "#08992b";
                    inventoryButton.current.style.color = "#08992b";
                    inventoryButton.current.style.backgroundColor = "white";
                    mobileFirstButton.current.style.color = "#08992b";
                    mobileFirstButton.current.style.backgroundColor = "white";
                    deskLogTrendsButton.current.style.color = "#08992b";
                    deskLogTrendsButton.current.style.backgroundColor = "white";
                    if (mainBodyRef.current) {
                      setDeskLogSourceState(
                        "desk-log-image desk-log-image-notes"
                      );
                    }
                  }}
                >
                  Quickly view all notes associated with each customer in your
                  showroom
                </div>
                <div
                  className="margin-top-large button-elevated"
                  ref={inventoryButton}
                  onMouseOver={() => {
                    inventoryButton.current.style.color = "white";
                    inventoryButton.current.style.backgroundColor = "#08992b";
                  }}
                  onMouseLeave={() => {
                    const classes = Array.from(deskLogImg.current.classList);
                    if (!classes.includes("desk-log-image-inventory")) {
                      inventoryButton.current.style.color = "#08992b";
                      inventoryButton.current.style.backgroundColor = "white";
                    }
                  }}
                  onClick={() => {
                    inventoryButton.current.style.color = "white";
                    inventoryButton.current.style.backgroundColor = "#08992b";
                    notesButton.current.style.color = "#08992b";
                    notesButton.current.style.backgroundColor = "white";
                    mobileFirstButton.current.style.color = "#08992b";
                    mobileFirstButton.current.style.backgroundColor = "white";
                    deskLogTrendsButton.current.style.color = "#08992b";
                    deskLogTrendsButton.current.style.backgroundColor = "white";
                    if (mainBodyRef.current) {
                      setDeskLogSourceState(
                        "desk-log-image desk-log-image-inventory"
                      );
                    }
                  }}
                >
                  Inventory Viewer helps you to ensure that the right vehicle is
                  shown to the right customer
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-section">&nbsp;</div>
        <div className="homepage-section homepage-section-offset full-width padding-large">
          <div className="flex-column-center-center margin-top-large margin-left-large margin-right-large">
            <div className="grid margin-top-large margin-bottom-large margin-left-large margin-right-large">
              <p className="center-text large-text dark-blue-text full-width">
                Lead Zen has the power to get more customers through your door
              </p>
              <div className="full-width flex-row-center-center margin-top-large margin-bottom-large">
                <p className="medium-large-text dark-blue-green-text margin-top-large center-text early-adopter-subheading">
                  With highly competitive pricing, this is the perfect time to
                  make the switch! Click the button to contact us:
                </p>
              </div>

              <button
                className="button-elevated large-text margin-top-large"
                onClick={(e) => {
                  history.push("/public-contact");
                }}
              >
                I'm Interested
              </button>
            </div>
            <div className="margin-top-large">&nbsp;</div>
          </div>
        </div>

        <div className="border-section">&nbsp;</div>
        <div className="border-section">&nbsp;</div>
        <div className="border-section">&nbsp;</div>
        <div className="border-section">&nbsp;</div>
        <footer className="margin-bottom-small">
          <a href="https://www.bayhawk.cc" target="_blank" rel="noreferrer">
            &copy;2021&nbsp;Bayhawk&nbsp;Software,&nbsp;LLC.
          </a>
        </footer>
      </div>
    );
  }
};

export default Landing;
