import React from "react";
import PropTypes from "prop-types";

const CustomerEditor = ({
  editedCustomer,
  handleCancel,
  handleEditCustomer,
  saveEditedCustomerHandler,
  special,
}) => {
  return (
    <div
      className={
        special ? "popup-content popup-content-special" : "popup-content"
      }
    >
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Edit Customer
        </p>
        <div onClick={handleCancel} id="hide-edit-customer">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <form className="popup-body popup-body-oversize popup-body-evenly">
        <div className="form-row">
          <label
            htmlFor="first-name"
            className="medium-text medium-blue-text margin-right-small"
          >
            First Name:
          </label>
          <input
            type="text"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="first-name"
            id="first-name"
            spellCheck="false"
            value={
              editedCustomer &&
              editedCustomer.firstName &&
              editedCustomer.firstName
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="last-name"
            className="medium-text medium-blue-text margin-right-small"
          >
            Last Name:
          </label>
          <input
            type="text"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="last-name"
            id="last-name"
            spellCheck="false"
            value={
              editedCustomer &&
              editedCustomer.lastName &&
              editedCustomer.lastName
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="phone-number"
            className="medium-text medium-blue-text margin-right-small"
          >
            Phone:
          </label>
          <input
            type="tel"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="phone-number"
            id="phone-number"
            value={
              editedCustomer &&
              editedCustomer.phoneNumber &&
              editedCustomer.phoneNumber
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="email"
            className="medium-text medium-blue-text margin-right-small"
          >
            E-Mail:
          </label>
          <input
            type="email"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="email"
            id="email"
            value={
              editedCustomer &&
              editedCustomer.emailAddress &&
              editedCustomer.emailAddress
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="zip-code"
            className="medium-text medium-blue-text margin-right-small"
          >
            Zip:
          </label>
          <input
            type="text"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="zip-code"
            id="zip-code"
            value={
              editedCustomer && editedCustomer.zipCode && editedCustomer.zipCode
            }
            onChange={handleEditCustomer}
          />
        </div>

        {/** */}
        <div className="form-row">
          <label
            htmlFor="address"
            className="medium-text medium-blue-text margin-right-small"
          >
            Address:
          </label>
          <input
            type="text"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="address"
            id="address"
            value={
              editedCustomer &&
              editedCustomer.streetAddress &&
              editedCustomer.streetAddress
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="city"
            className="medium-text medium-blue-text margin-right-small"
          >
            City:
          </label>
          <input
            type="text"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="city"
            id="city"
            value={editedCustomer && editedCustomer.city && editedCustomer.city}
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="state"
            className="medium-text medium-blue-text margin-right-small"
          >
            State:
          </label>
          <input
            type="text"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="state"
            id="state"
            value={
              editedCustomer && editedCustomer.state && editedCustomer.state
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="alt-phone"
            className="medium-text medium-blue-text margin-right-small"
          >
            Alt. Phone:
          </label>
          <input
            type="tel"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="alt-phone"
            value={
              editedCustomer &&
              editedCustomer.altPhoneNumber &&
              editedCustomer.altPhoneNumber
            }
            id="alt-phone"
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <label
            htmlFor="alt-email"
            className="medium-text medium-blue-text margin-right-small"
          >
            Alt. Email:
          </label>
          <input
            type="email"
            className="form-field flex-grow small-text dark-blue-green-text"
            name="alt-email"
            id="alt-email"
            value={
              editedCustomer &&
              editedCustomer.altEmailAddress &&
              editedCustomer.altEmailAddress
            }
            onChange={handleEditCustomer}
          />
        </div>
        <div className="form-row">
          <button
            className="button-elevated flex-grow medium-text"
            onClick={saveEditedCustomerHandler}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

CustomerEditor.propTypes = {
  editedCustomer: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleEditCustomer: PropTypes.func.isRequired,
  saveEditedCustomerHandler: PropTypes.func.isRequired,
  special: PropTypes.bool.isRequired,
};

export default CustomerEditor;
