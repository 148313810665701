import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO, getYear, subDays, addDays } from "date-fns";
import DealershipContext from "../context/dealership/dealershipContext";
import UserContext from "../context/users/userContext";
import TotalLeadsViewer from "./popups/TotalLeadsViewer";
import ApptPerformanceViewer from "./popups/ApptPerformanceViewer";
import ManagerEmailComposer from "./popups/ManagerEmailComposer";
import Customs from "./Customs";
import SoldViewer from "./popups/SoldViewer";
import TotalStoreVisitViewer from "./popups/TotalStoreVisitViewer";
import ActionOverview from "./popups/ActionOverview";
import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";

const Performance = () => {
  //
  //
  //

  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);

  //
  const history = useHistory();
  //
  const mainBodyRef = useRef();
  //
  const [averageSales, setAverageSales] = useState([]);
  const [completionTime, setCompletionTime] = useState(0);
  const [currentSalesPerson, setCurrentSalesPerson] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [goodSales, setGoodSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needsImprovementSales, setNeedsImprovementSales] = useState([]);
  const [performancePopulated, setPerformancePopulated] = useState(false);
  const [salesPerfDetailDoreen, setSalesPerfDetailDoreen] = useState(null);
  const [selectedCycles, setSelectedCycles] = useState([]);
  const [SPResults, setSPResults] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [totalActions, setTotalActions] = useState("");
  const [totalAppointments, setTotalAppointments] = useState("");
  const [totalBdcCycles, setTotalBdcCycles] = useState("");
  // const [totalBdcSold, setTotalBdcSold] = useState();
  const [totalCompleted, setTotalCompleted] = useState("");
  const [totalLeads, setTotalLeads] = useState("");
  const [totalFullLeads, setTotalFullLeads] = useState([]);
  const [totalSold, setTotalSold] = useState("");
  const [totalStoreVisits, setTotalStoreVisits] = useState("");
  const [viewActionOverview, setViewActionOverview] = useState(false);
  const [viewApptPerformance, setViewApptPerformance] = useState(false);
  const [viewComposeManagerEmails, setViewComposeManagerEmail] =
    useState(false);
  const [viewCustoms, setViewCustoms] = useState(false);
  const [viewMainGrid, setViewMainGrid] = useState(false);
  const [viewSold, setViewSold] = useState(false);
  const [viewTotalLeads, setViewTotalLeads] = useState(false);
  const [viewTotalStoreVisits, setViewTotalStoreVisits] = useState(false);
  const [viewUserPerformance, setViewUserPerformance] = useState(false);
  //
  useEffect(() => {
    if (!performancePopulated) {
      loadPerformance();
    }

    //eslint-disable-next-line
  }, [performancePopulated]);
  //
  //
  //
  const years = [];
  const thisYear = getYear(parseISO(new Date().toISOString()));
  years.push(thisYear + 1);
  for (let i = 0; i < 25; i++) {
    years.push(thisYear - i);
  }
  //
  //
  //
  const appointmentsHandler = (e) => {
    //  here we'll want to display a view that lists all of the bdc users of this dealership and the number of appointments set by each
    safeStateSetter(() => {
      setViewApptPerformance(true);
    });
  };
  //
  const customerClickHandler = (e) => {
    localStorage.setItem("localCurrentCustomer", e.currentTarget.id);
    history.push("/customer");
  };
  //
  const disableAllView = () => {
    setViewApptPerformance(false);
    setViewComposeManagerEmail(false);
    setViewCustoms(false);
    setViewUserPerformance(false);
    setViewSold(false);
    setViewTotalLeads(false);
    setViewTotalStoreVisits(false);
    setViewActionOverview(false);
    setViewMainGrid(true);
  };
  //
  const handleCancel = (e) => {
    switch (e.currentTarget.id) {
      case "hide-user-performance":
        disableAllView();
        break;

      case "hide-appt-performance":
        disableAllView();
        break;

      case "hide-manager-email-composer":
        setSelectedCycles([]);
        disableAllView();
        break;

      case "hide-total-leads-viewer":
        disableAllView();
        break;

      case "hide-customs":
        disableAllView();
        break;

      case "hide-sold-store-visits":
        disableAllView();
        break;

      case "hide-total-store-visits":
        disableAllView();
        break;

      case "hide-action-overview":
        disableAllView();
        break;

      default:
        break;
    }
  };
  //
  const handleComposeManagerEmails = () => {
    if (
      currentUser.role === "administrator" ||
      currentUser.role === "superuser"
    ) {
      setViewComposeManagerEmail(true);
    } else {
      alert(
        "You do not have permission to access this feature - contact your administrator or superuser."
      );
    }
  };
  //
  const handleDisplayChange = (e) => {
    switch (e.currentTarget.name) {
      case "view-user-performance":
        const clickedSalesPerson = JSON.parse(e.currentTarget.id);
        if (userContext.user.role !== "salesperson") {
          disableAllView();
          setCurrentSalesPerson(clickedSalesPerson);

          // ***Correct Types***
          //
          // setCurrentSalesPerson({
          //   firstName: "John",
          //   lastName: "Smith",
          //   sold: "18",
          //   soldRank: 4,
          //   deliveryRatio: "72%",
          //   deliveryRank: 3,
          //   actionsCompleted: 85,
          //   actionsRank: 3,
          //   appointments: "20",
          //   apptRank: 4,
          //   totalRank: 3,
          // });
          setViewMainGrid(false);
          setViewUserPerformance(true);
        } else if (
          userContext.user.role === "salesperson" &&
          userContext.user._id.toString() === clickedSalesPerson._id.toString()
        ) {
          disableAllView();
          setCurrentSalesPerson(clickedSalesPerson);
          setViewMainGrid(false);
          setViewUserPerformance(true);
        } else {
          alert("you do not have access to this");
        }

        break;

      default:
        break;
    }
  };
  //
  const handleLeadCheck = (e) => {
    const priorSelectedCycles = selectedCycles;
    const currentCycle = JSON.parse(e.currentTarget.id);
    if (e.currentTarget.checked) {
      if (
        currentCycle.viewStatus !== "old" &&
        currentCycle.viewStatus !== "dead"
      ) {
        priorSelectedCycles.push(JSON.stringify(currentCycle));
        setSelectedCycles(priorSelectedCycles);
        if (priorSelectedCycles.length > 0) {
          const emailCheckedButton = document.getElementById(
            "email-checked-button"
          );
          emailCheckedButton.classList.remove("button-elevated-disabled");
        }
      }
    } else {
      const indexToDelete = priorSelectedCycles.indexOf(
        JSON.stringify(currentCycle)
      );
      priorSelectedCycles.splice(indexToDelete, 1);
      setSelectedCycles(priorSelectedCycles);
      if (priorSelectedCycles.length === 0) {
        const emailCheckedButton = document.getElementById(
          "email-checked-button"
        );
        emailCheckedButton.classList.add("button-elevated-disabled");
      }
    }
  };
  //
  const handleTotalLeadsClick = (e) => {
    if (currentUser && currentUser.role !== "superuser") {
      alert(
        "This is the number of new buying cycles created within the selected time period."
      );
    } else {
      safeStateSetter(() => {
        setViewTotalLeads(true);
      });
    }
  };
  //
  const handleTotalSoldClick = () => {
    safeStateSetter(() => {
      setViewSold(true);
    });
  };
  //
  const handleTotalStoreVisitsClick = () => {
    safeStateSetter(() => {
      setViewTotalStoreVisits(true);
    });
  };
  //
  const handleActionsCompletedClick = () => {
    safeStateSetter(() => {
      setViewActionOverview(true);
    });
  };
  //
  const loadPerformance = async () => {
    try {
      // PREPARE FOR BATTLE!
      const loadedUser = await userContext.getFullUser();
      // console.log(loadedUser);
      if (loadedUser) {
        if (!loadedUser.user.active || loadedUser.user.active === "false") {
          window.location.reload();
        }
        safeStateSetter(() => {
          setCurrentUser(loadedUser.user);
        });
      } else {
        history.push("/");
      }
      // Specified Date Range
      //
      let startDateObj = null;
      let endDateObj = null;
      if (!startDate) {
        if (
          localStorage.getItem("localStartDate") &&
          new Date().getTime() -
            parseInt(
              localStorage.getItem("performanceDateRangeUpdatedMilliseconds")
            ) <
            86400000
        ) {
          startDateObj = new Date(
            new Date(localStorage.getItem("localStartDate")).getTime() +
              43200000
          );

          const formattedStartDate = format(startDateObj, "P");
          const formattedStartDateElements = formattedStartDate.split("/");
          const localStartDate = `${formattedStartDateElements[2]}-${formattedStartDateElements[0]}-${formattedStartDateElements[1]}`;

          safeStateSetter(() => {
            setStartDate(localStartDate);
          });
        } else {
          startDateObj = new Date(subDays(new Date(), 30));
          const formattedStartDate = format(startDateObj, "P");
          const formattedStartDateElements = formattedStartDate.split("/");
          const usableStartDate = `${formattedStartDateElements[2]}-${formattedStartDateElements[0]}-${formattedStartDateElements[1]}`;

          safeStateSetter(() => {
            setStartDate(usableStartDate);
          });
        }
      } else {
        const startDateElements = startDate.split("-");
        startDateObj = new Date(
          startDateElements[0],
          (parseInt(startDateElements[1]) - 1).toString(),
          startDateElements[2]
        );
      }

      if (!endDate) {
        if (
          localStorage.getItem("localEndDate") &&
          new Date().getTime() -
            parseInt(
              localStorage.getItem("performanceDateRangeUpdatedMilliseconds")
            ) <
            86400000
        ) {
          endDateObj = new Date(
            new Date(localStorage.getItem("localEndDate")).getTime() + 43200000
          );
          const formattedEndDate = format(endDateObj, "P");
          const formattedEndDateElements = formattedEndDate.split("/");
          const localEndDate = `${formattedEndDateElements[2]}-${formattedEndDateElements[0]}-${formattedEndDateElements[1]}`;
          safeStateSetter(() => {
            setEndDate(localEndDate);
          });
        } else {
          endDateObj = new Date();
          const formattedEndDate = format(endDateObj, "P");
          const formattedEndDateElements = formattedEndDate.split("/");
          const usableEndDate = `${formattedEndDateElements[2]}-${formattedEndDateElements[0]}-${formattedEndDateElements[1]}`;
          safeStateSetter(() => {
            setEndDate(usableEndDate);
          });
        }
      } else {
        const endDateElements = endDate.split("-");
        endDateObj = new Date(
          endDateElements[0],
          (parseInt(endDateElements[1]) - 1).toString(),
          endDateElements[2]
        );
      }

      // DONE!

      // Total Leads:
      // Total new buying cycles created within the specified date range

      const performanceData = await dealershipContext.getPerformanceData(
        startDateObj,
        endDateObj
      );

      // console.log(performanceData);

      const localActions = performanceData.totalActions.length;
      const localAppointments = performanceData.totalAppointments.length;
      const localBdcCycles = performanceData.totalBdcCycles.length;
      // const localBdcSold = performanceData.totalBdcSold.length;
      const localCompleted = performanceData.totalCompleted.length;
      const localCycles = performanceData.totalCycles.length;
      const localSold = performanceData.totalSold;
      const localDealershipHours = performanceData.dealershipHours;

      const webLeadCompletionTimes = [];

      performanceData.totalCompleted.forEach((cmpAct) => {
        if (cmpAct.actionType === "web-lead") {
          if (localDealershipHours) {
            let dayCreated = new Date(cmpAct.createdAt).getDay();
            let hourCreated = new Date(cmpAct.createdAt).getHours();
            let usableStartTime = null;

            const days = [
              "sunday",
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
            ];

            const startTimeOfCreatedDay = localDealershipHours[days[dayCreated]]
              .startTime
              ? localDealershipHours[days[dayCreated]].startTime
              : null;

            const closingTimeOfCreatedDay = localDealershipHours[
              days[dayCreated]
            ].closingTime
              ? localDealershipHours[days[dayCreated]].closingTime
              : null;

            if (startTimeOfCreatedDay && closingTimeOfCreatedDay) {
              if (hourCreated < parseInt(startTimeOfCreatedDay)) {
                usableStartTime = new Date(cmpAct.createdAt).setHours(
                  parseInt(startTimeOfCreatedDay)
                );
              } else if (hourCreated > parseInt(closingTimeOfCreatedDay)) {
                // need to figure out next business hour
                let count = 0;
                let startTimeString = null;
                for (let i = dayCreated + 1; i <= days.length; i++) {
                  if (count > 7) {
                    break;
                  }
                  if (i === days.length) {
                    i = 0;
                  }
                  if (localDealershipHours[days[i]].startTime) {
                    startTimeString = localDealershipHours[days[i]].startTime;
                    break;
                  }
                  count++;
                }

                usableStartTime = new Date(
                  addDays(new Date(cmpAct.createdAt), count)
                ).setHours(parseInt(startTimeString));
              } else {
                usableStartTime = new Date(cmpAct.createdAt);
              }
            } else {
              let count = 0;
              let startTimeString = null;

              for (let i = dayCreated + 1; i <= days.length; i++) {
                if (count > 7) {
                  break;
                }
                if (i === days.length) {
                  i = 0;
                }
                count++;
                if (localDealershipHours[days[i]].startTime) {
                  startTimeString = localDealershipHours[days[i]].startTime;
                  break;
                }
              }

              usableStartTime = new Date(
                addDays(new Date(cmpAct.createdAt), count)
              ).setHours(parseInt(startTimeString));
            }

            usableStartTime = new Date(usableStartTime);

            webLeadCompletionTimes.push(
              (new Date(cmpAct.updatedAt).getTime() -
                usableStartTime.getTime()) /
                60000 /
                60
            );
          }

          // console.log(
          //   new Date(cmpAct.updatedAt).getTime() / 60000 / 60 -
          //     new Date(cmpAct.createdAt).getTime() / 60000 / 60
          // );

          // IF THE ABOVE ALGORITHM FAILS, JUST USE THIS:
          // webLeadCompletionTimes.push(
          //   new Date(cmpAct.updatedAt).getTime() / 60000 / 60 -
          //     new Date(cmpAct.createdAt).getTime() / 60000 / 60
          // );
        }
      });

      let webLeadCompletionTotal = 0;

      // console.log(webLeadCompletionTimes);

      webLeadCompletionTimes.forEach((t) => {
        webLeadCompletionTotal += t;
      });

      let averageWebLeadResponseTime = null;

      if (webLeadCompletionTotal / webLeadCompletionTimes.length < 0) {
        averageWebLeadResponseTime = (0.1).toFixed(1);
      } else {
        averageWebLeadResponseTime = (
          webLeadCompletionTotal / webLeadCompletionTimes.length
        ).toFixed(1);
      }

      console.log(performanceData);

      // console.log(averageWebLeadResponseTime);

      //
      //SALESPERSON CALCULATIONS:

      performanceData.salesPersonResults.forEach((sp) => {
        const soldScore = sp["sold"] * 5;

        // console.log(sp["deliveryRatio"]);

        const deliveryScore = sp["deliveryRatio"] * 0.2;

        const actionsCompletedScore =
          (sp["actionsCompleted"] /
            (sp["failedActions"] + sp["actionsCompleted"])) *
          100;

        const apptScore = sp["appointments"];

        sp["soldScore"] = soldScore;

        sp["deliveryScore"] = deliveryScore;

        sp["actionsCompletedScore"] = !Number.isNaN(actionsCompletedScore)
          ? actionsCompletedScore
          : 0;

        sp["apptScore"] = apptScore;

        // console.log(
        //   `soldScore: ${soldScore} deliveryScore: ${deliveryScore} actionsCompletedScore: ${sp["actionsCompletedScore"]} apptScore: ${apptScore}`
        // );

        sp["totalScore"] =
          parseInt(sp["soldScore"]) +
          parseInt(sp["deliveryScore"]) +
          parseInt(sp["actionsCompletedScore"]) +
          parseInt(sp["apptScore"]);

        // console.log(sp["totalScore"]);
      });
      performanceData.salesPersonResults.sort((a, b) => {
        if (a.soldScore > b.soldScore) {
          return -1;
        } else {
          return 1;
        }
      });
      performanceData.salesPersonResults.forEach((sp, index) => {
        sp["soldRank"] = index + 1;
      });
      performanceData.salesPersonResults.sort((a, b) => {
        if (a.deliveryScore > b.deliveryScore) {
          return -1;
        } else {
          return 1;
        }
      });
      performanceData.salesPersonResults.forEach((sp, index) => {
        sp["deliveryRank"] = index + 1;
      });
      performanceData.salesPersonResults.sort((a, b) => {
        if (a.actionsCompletedScore > b.actionsCompletedScore) {
          return -1;
        } else {
          return 1;
        }
      });
      performanceData.salesPersonResults.forEach((sp, index) => {
        sp["actionsRank"] = index + 1;
      });
      performanceData.salesPersonResults.sort((a, b) => {
        if (a.apptScore > b.apptScore) {
          return -1;
        } else {
          return 1;
        }
      });
      performanceData.salesPersonResults.forEach((sp, index) => {
        sp["apptRank"] = index + 1;
      });
      performanceData.salesPersonResults.sort((a, b) => {
        if (a.totalScore > b.totalScore) {
          return -1;
        } else {
          return 1;
        }
      });
      performanceData.salesPersonResults.forEach((sp, index) => {
        sp["totalRank"] = index + 1;
      });

      const numberEach = Math.ceil(
        performanceData.salesPersonResults.length / 3
      );

      const localGoodSales = [];
      const localAverageSales = [];
      const localNeedsImprovementSales = [];

      performanceData.salesPersonResults.forEach((spResult, idx) => {
        // console.log(spResult);

        // console.log(idx, numberEach, spResult);

        if (idx <= numberEach) {
          localGoodSales.push(spResult);
        } else if (idx <= numberEach + numberEach) {
          localAverageSales.push(spResult);
        } else {
          localNeedsImprovementSales.push(spResult);
        }
      });

      // END OF SALESPERSON CALCULATIONS

      const customerIds = [];

      performanceData.totalCycles.forEach((cy) => {
        if (!customerIds.includes(cy.customer)) {
          customerIds.push(cy.customer);
        }
      });

      const localPerfDetail = {};

      console.log("about to perform loop");
      performanceData.salesPersonResults.forEach((sp, idx) => {
        // console.log(sp);
        localPerfDetail[`${sp.firstName} ${sp.lastName}`] = [];

        (async function () {
          const res = await axios.post(
            "/api/v1/customer/get-customers-from-ids",
            { customerIds },
            axiosConfig
          );

          const customers = res.data.customers;

          customers.forEach((c) => {
            // console.log("customer: ", c.firstName, c.lastName);

            performanceData.totalCycles.forEach((cy) => {
              if (cy.customer === c._id && cy.primarySales === sp._id) {
                let sold = false;

                let pendingDeal = false;

                let stillWorking = false;

                let dead = false;

                let svFound = false;

                let unresolvedSvFound = false;

                performanceData.totalStoreVisits.forEach((sv) => {
                  if (sv.customer === c._id) {
                    svFound = true;
                    if (sv.status === "sold") {
                      sold = true;
                    }

                    if (!sold && sv.status === "pending") {
                      pendingDeal = true;
                    }

                    if (sv.status === "not-sold") {
                      unresolvedSvFound = true;
                    }
                  }
                });

                if (!svFound || unresolvedSvFound) {
                  // performanceData.totalCycles.forEach((cy) => {
                  if (!sold && !pendingDeal && !stillWorking && !dead) {
                    if (!cy.active) {
                      dead = true;
                    } else {
                      stillWorking = true;
                    }
                  }
                  // });
                }

                let result = "";

                if (sold) {
                  result = "sold";
                } else if (pendingDeal) {
                  result = "pending deal";
                } else if (stillWorking) {
                  result = "still working";
                } else if (dead) {
                  result = "dead deal";
                } else {
                  console.log("error, no category set to true...");
                }

                localPerfDetail[`${sp.firstName} ${sp.lastName}`].push({
                  customerId: c._id,
                  customer: `${c.firstName} ${c.lastName}`,
                  phoneNumber: c.phoneNumber ? `${c.phoneNumber}` : ` `,
                  result,
                });
              }
            });
          });

          return localPerfDetail;
        })().then((res) => {
          if (idx === performanceData.salesPersonResults.length - 1) {
            // console.log(res);
            setSalesPerfDetailDoreen(res);
          }
        });
      });

      //

      safeStateSetter(() => {
        setAverageSales(localAverageSales);
        setSPResults(performanceData.salesPersonResults);
        setGoodSales(localGoodSales);
        setLoading(false);
        setNeedsImprovementSales(localNeedsImprovementSales);
        setTotalActions(localActions);
        setTotalAppointments(localAppointments);
        setTotalBdcCycles(localBdcCycles);
        // setTotalBdcSold(localBdcSold);
        setTotalCompleted(localCompleted);
        setTotalSold(localSold);
        setTotalStoreVisits(performanceData.totalStoreVisits);
        setTotalLeads(localCycles);
        setTotalFullLeads(performanceData.totalCycles);
        setViewMainGrid(true);
        setCompletionTime(averageWebLeadResponseTime);
        //
        setPerformancePopulated(true);
      });
    } catch (error) {
      console.log(error);
      alert("could not load performance");
      history.push("/");
    }
  };
  //
  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };
  //
  const updateRange = (e) => {
    // do stuff
    localStorage.setItem(
      "performanceDateRangeUpdatedMilliseconds",
      `${new Date().getTime()}`
    );
    safeStateSetter(() => {
      if (
        e.currentTarget.name === "start-date" &&
        new Date(endDate).getTime() > new Date(e.currentTarget.value).getTime()
      ) {
        setStartDate(e.currentTarget.value);
        localStorage.setItem("localStartDate", e.currentTarget.value);
      } else if (
        e.currentTarget.name === "end-date" &&
        new Date(startDate).getTime() <
          new Date(e.currentTarget.value).getTime()
      ) {
        setEndDate(e.currentTarget.value);
        localStorage.setItem("localEndDate", e.currentTarget.value);
      } else {
        alert("could not update the specified date range...");
      }
      setPerformancePopulated(false);
    });
  };
  //
  const viewCustomsHandler = (e) => {
    setViewMainGrid(false);
    setViewCustoms(true);
  };
  //
  //
  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <main>
          {/* // */}
          {viewMainGrid && (
            <div className="flex-column-center-center" ref={mainBodyRef}>
              <div className="grid-item grid-item-short large-text dark-blue-text">
                <p className="margin-bottom-medium main-title main-title-smaller">
                  Performance
                </p>
                <div className="become-column">
                  <input
                    type="date"
                    name="start-date"
                    className="form-field medium-text dark-blue-text margin-right-small"
                    id="start-date"
                    onChange={updateRange}
                    value={startDate && startDate}
                  />
                  <p className="medium-text">to</p>
                  <input
                    type="date"
                    name="end-date"
                    className="form-field medium-text dark-blue-text margin-left-small"
                    id="end-date"
                    onChange={updateRange}
                    value={endDate && endDate}
                  />
                </div>
              </div>

              <div className="grid">
                <div className="grid-row">
                  <div
                    className="ten-grid-item ten-grid-item-short"
                    onClick={handleTotalLeadsClick}
                  >
                    <div className="large-text dark-blue-text margin-bottom-small">
                      Total Leads
                    </div>
                    <div className="large-text dark-blue-green-text margin-top-small">
                      {/* // */}
                      {totalLeads && totalLeads}
                      {/* // */}
                    </div>
                  </div>

                  <div
                    className="ten-grid-item ten-grid-item-short"
                    onClick={handleTotalStoreVisitsClick}
                  >
                    <div className="medium-large-text dark-blue-text margin-bottom-small">
                      Total Store Visits
                    </div>
                    <div className="large-text dark-blue-green-text margin-top-small">
                      {/* // */}
                      {totalStoreVisits && totalStoreVisits.length}
                      {/* // */}
                    </div>
                  </div>

                  <div
                    className="ten-grid-item ten-grid-item-short"
                    onClick={handleTotalSoldClick}
                  >
                    <div className="large-text dark-blue-text margin-bottom-small">
                      Total Delivered
                    </div>
                    <div className="large-text dark-blue-green-text margin-top-small">
                      {/* // */}
                      {totalSold && totalSold.length}
                      {/* // */}
                    </div>
                  </div>

                  <div
                    className="ten-grid-item ten-grid-item-short"
                    onClick={handleActionsCompletedClick}
                  >
                    <div className="medium-large-text dark-blue-text margin-bottom-small">
                      Actions Completed
                    </div>
                    <div className="large-text dark-blue-green-text margin-top-small">
                      {/* // */}
                      {totalActions &&
                        totalCompleted &&
                        `${Math.round((totalCompleted / totalActions) * 100)}%`}
                      {/* // */}
                    </div>
                  </div>
                </div>

                <div className="grid-row">
                  <div
                    className="ten-grid-item ten-grid-item-short ten-grid-item-green"
                    onClick={() => {
                      alert(
                        "This is the total number of new phone and internet cycles created within the specified date range."
                      );
                    }}
                  >
                    <div className="large-text dark-blue-green-text margin-bottom-small">
                      BDC Leads
                    </div>
                    <div className=" large-text dark-blue-text margin-top-small">
                      {/* // */}
                      {totalBdcCycles && totalBdcCycles}
                      {/* // */}
                    </div>
                  </div>

                  <div
                    className="ten-grid-item ten-grid-item-short ten-grid-item-green"
                    onClick={appointmentsHandler}
                  >
                    <div className="medium-large-text dark-blue-green-text margin-bottom-small">
                      Appointments
                    </div>
                    <div className=" large-text dark-blue-text margin-top-small">
                      {/* // */}
                      {totalAppointments && totalAppointments}
                      {/* // */}
                    </div>
                  </div>

                  <div
                    className="ten-grid-item ten-grid-item-short ten-grid-item-green"
                    onClick={() => {
                      alert(
                        "This is the average time between the creation and completion of a new web lead action."
                      );
                    }}
                  >
                    <div className="medium-text dark-blue-green-text margin-bottom-small">
                      Initial Response Avg.
                    </div>

                    <div className=" medium-large-text dark-blue-text margin-top-small">
                      {/* // */}
                      {completionTime && completionTime} Minutes
                      {/* // */}
                    </div>
                  </div>

                  <div
                    className="ten-grid-item ten-grid-item-short ten-grid-item-red"
                    onClick={viewCustomsHandler}
                  >
                    <div className="large-text dark-red-text margin-bottom-small">
                      Customs
                    </div>
                  </div>
                </div>

                <div className="grid-row">
                  <div className="grid-item grid-item-titled">
                    <div className="grid-item-heading grid-item-heading-elevated large-text white-text">
                      Good
                    </div>
                    <div className="grid-item-body grid">
                      {goodSales.map((gs, index) => {
                        return (
                          <button
                            className="button button-elevated button-elevated-smaller small-medium-text"
                            name="view-user-performance"
                            id={JSON.stringify(gs)}
                            onClick={handleDisplayChange}
                            key={index}
                          >
                            {gs.firstName.charAt(0)}. {gs.lastName}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="grid-item grid-item-titled">
                    {" "}
                    <div className="grid-item-heading grid-item-heading-neutral large-text white-text">
                      Average
                    </div>
                    <div className="grid-item-body grid">
                      {averageSales.map((as, index) => {
                        return (
                          <button
                            className="button button-neutral button-neutral-smaller small-medium-text"
                            name="view-user-performance"
                            id={JSON.stringify(as)}
                            onClick={handleDisplayChange}
                            key={index}
                          >
                            {as.firstName.charAt(0)}. {as.lastName}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="grid-item grid-item-titled">
                    {" "}
                    <div className="grid-item-heading grid-item-heading-negative large-text white-text">
                      Needs Improvement
                    </div>
                    <div className="grid-item-body grid">
                      {needsImprovementSales.map((nis, index) => {
                        return (
                          <button
                            className="button button-negative button-negative-smaller small-medium-text"
                            name="view-user-performance"
                            id={JSON.stringify(nis)}
                            onClick={handleDisplayChange}
                            key={index}
                          >
                            {nis.firstName.charAt(0)}. {nis.lastName}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* // */}
          {viewApptPerformance && (
            <ApptPerformanceViewer
              startDate={startDate}
              endDate={endDate}
              handleCancel={handleCancel}
            />
          )}
          {/* // */}
          {viewTotalLeads && (
            <TotalLeadsViewer
              startDate={startDate}
              endDate={endDate}
              handleLeadCheck={handleLeadCheck}
              leads={totalFullLeads}
              handleCancel={handleCancel}
              handleComposeManagerEmails={handleComposeManagerEmails}
              sold={totalSold}
            />
          )}
          {/* // */}
          {viewSold && (
            <SoldViewer
              startDate={startDate}
              endDate={endDate}
              customerClickHandler={customerClickHandler}
              passedStoreVisits={totalSold}
              handleCancel={handleCancel}
            />
          )}
          {/* // */}
          {viewUserPerformance && (
            <div className="popup">
              <div className="popup-content" ref={mainBodyRef}>
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    User Performance
                  </p>
                  <div onClick={handleCancel} id="hide-user-performance">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="flex-row-center-between full-width large-text dark-blue-green-text form-field">
                    <div className="flex-row-center-center full-width">
                      {currentSalesPerson && currentSalesPerson.firstName}{" "}
                      {currentSalesPerson && currentSalesPerson.lastName}
                    </div>
                  </div>

                  <div className="flex-row-center-between full-width medium-large-text dark-blue-text blue-green-underline">
                    <div className="flex-row-center-center full-width">
                      Sold:
                    </div>
                    <div className="flex-row-center-center">
                      {currentSalesPerson && currentSalesPerson.sold}
                    </div>
                    <div className="flex-row-center-center full-width">
                      {currentSalesPerson &&
                        currentSalesPerson.soldRank === 1 &&
                        "1st Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.soldRank === 2 &&
                        "2nd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.soldRank === 3 &&
                        "3rd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.soldRank >= 4 &&
                        `${currentSalesPerson.soldRank}th Place`}
                    </div>
                  </div>
                  <div className="flex-row-center-between full-width medium-large-text dark-blue-text blue-green-underline">
                    <div className="flex-row-center-center full-width">
                      Delivery Ratio:
                    </div>
                    <div className="flex-row-center-center">
                      {currentSalesPerson && currentSalesPerson.deliveryRatio
                        ? `${currentSalesPerson.deliveryRatio}%`
                        : "0"}
                    </div>
                    <div className="flex-row-center-center full-width">
                      {currentSalesPerson &&
                        currentSalesPerson.deliveryRank === 1 &&
                        "1st Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.deliveryRank === 2 &&
                        "2nd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.deliveryRank === 3 &&
                        "3rd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.deliveryRank >= 4 &&
                        `${currentSalesPerson.deliveryRank}th Place`}
                    </div>
                  </div>

                  <div className="flex-row-center-between full-width medium-large-text dark-blue-text blue-green-underline">
                    <div className="flex-row-center-center full-width small-medium-text">
                      Actions Completed:
                    </div>
                    <div className="flex-row-center-center">
                      {currentSalesPerson &&
                        `${Math.round(
                          currentSalesPerson.actionsCompletedScore
                        )}%`}
                    </div>
                    <div className="flex-row-center-center full-width">
                      {currentSalesPerson &&
                        currentSalesPerson.actionsRank === 1 &&
                        "1st Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.actionsRank === 2 &&
                        "2nd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.actionsRank === 3 &&
                        "3rd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.actionsRank >= 4 &&
                        `${currentSalesPerson.actionsRank}th Place`}
                    </div>
                  </div>

                  <div className="flex-row-center-between full-width medium-large-text dark-blue-text blue-green-underline">
                    <div className="flex-row-center-center full-width">
                      Appointments:
                    </div>
                    <div className="flex-row-center-center">
                      {currentSalesPerson && currentSalesPerson.appointments}
                    </div>
                    <div className="flex-row-center-center full-width">
                      {currentSalesPerson &&
                        currentSalesPerson.apptRank === 1 &&
                        "1st Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.apptRank === 2 &&
                        "2nd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.apptRank === 3 &&
                        "3rd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.apptRank >= 4 &&
                        `${currentSalesPerson.apptRank}th Place`}
                    </div>
                  </div>

                  <div className="flex-row-center-between full-width large-text dark-green-text form-field">
                    <div className="flex-row-center-center full-width">
                      OVERALL:
                    </div>
                    <div className="flex-row-center-center full-width">
                      {currentSalesPerson &&
                        currentSalesPerson.totalRank === 1 &&
                        "1st Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.totalRank === 2 &&
                        "2nd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.totalRank === 3 &&
                        "3rd Place"}
                      {currentSalesPerson &&
                        currentSalesPerson.totalRank >= 4 &&
                        `${currentSalesPerson.totalRank}th Place`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* // */}
          {viewComposeManagerEmails && (
            <ManagerEmailComposer
              currentUser={currentUser}
              selectedCycles={selectedCycles}
              handleCancel={handleCancel}
            />
          )}
          {/* // */}
          {viewCustoms && (
            <Customs
              SPResults={SPResults}
              endDate={endDate}
              handleCancel={handleCancel}
              doreenReport={salesPerfDetailDoreen}
              fullCycles={totalFullLeads}
              startDate={startDate}
            />
          )}

          {viewTotalStoreVisits && (
            <TotalStoreVisitViewer
              endDate={endDate}
              handleCancel={handleCancel}
              totalStoreVisits={totalStoreVisits}
              startDate={startDate}
            />
          )}

          {viewActionOverview && (
            <ActionOverview
              SPResults={SPResults}
              totalCompleted={totalCompleted}
              totalActions={totalActions}
              endDate={endDate}
              handleCancel={handleCancel}
              startDate={startDate}
            />
          )}
        </main>
      )}
    </Fragment>
  );

  // end of component
};

export default Performance;
