import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { sendManagerEmails } from "../../functions/customerFunctions";

const ManagerEmailComposer = ({
  currentUser,
  handleCancel,
  selectedCycles,
}) => {
  const [managerEmailSubject, setManagerEmailSubject] = useState("");
  const [managerEmailBody, setManagerEmailBody] = useState("");

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleSendManagerEmails = (e) => {
    sendManagerEmails(
      currentUser,
      selectedCycles,
      managerEmailSubject,
      managerEmailBody
    );
    window.location.reload();
  };

  return (
    <div className="popup" id="popup-total-leads">
      <div className="popup-content">
        <div className="popup-header popup-header-special">
          <p className="large-text white-text center-text flex-grow">
            Manager Email Composer
          </p>
          <div
            id="hide-manager-email-composer"
            className="cursor-pointer"
            onClick={handleCancel}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly large-text dark-blue-text">
          <div className="form-row">
            <div className="dark-grey-text medium-text margin-right-small">
              Subject:{" "}
            </div>
            <input
              className="form-field form-field-red medium-text dark-red-text flex-grow"
              value={managerEmailSubject}
              onChange={(e) => {
                setManagerEmailSubject(e.currentTarget.value);
              }}
              placeholder="- - - -"
            />
          </div>

          <textarea
            className="popup-body-container popup-body-container-taller popup-body-container-prewrap popup-body-container-red small-text dark-red-text "
            value={managerEmailBody}
            onChange={(e) => {
              setManagerEmailBody(e.currentTarget.value);
            }}
            placeholder="Type E-Mail body here..."
          ></textarea>
          <button
            className="button-negative large-text"
            onClick={handleSendManagerEmails}
          >
            Send E-Mails
          </button>
        </div>
      </div>
    </div>
  );
};

ManagerEmailComposer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedCycles: PropTypes.array.isRequired,
};

export default ManagerEmailComposer;
