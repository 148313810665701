import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Sources from "./customs/Sources";
import SalesOverview from "./customs/SalesOverview";
import DoreenReport from "./customs/DoreenReport";

const Customs = ({
  SPResults,
  endDate,
  doreenReport,
  fullCycles,
  handleCancel,
  startDate,
}) => {
  //
  const history = useHistory();
  //
  const customsRef = useRef();
  //
  const [loading, setLoading] = useState(true);

  const [viewDoreenReport, setViewDoreenReport] = useState(false);
  const [viewMainColumn, setViewMainColumn] = useState(true);
  const [viewSalesOverview, setViewSalesOverview] = useState(false);
  const [viewSources, setViewSources] = useState(false);
  //
  useEffect(() => {
    if (loading) {
      loadCustoms();
    }

    // eslint-disable-next-line
  }, [loading]);
  //
  const disableAllView = () => {
    setViewDoreenReport(false);
    setViewSalesOverview(false);
    setViewSources(false);
    setViewMainColumn(true);
  };
  //
  const handleCustomsCancel = (e) => {
    switch (e.currentTarget.id) {
      case "hide-sources":
        disableAllView();
        break;

      case "hide-sales-overview":
        disableAllView();
        break;

      case "hide-doreen-report":
        disableAllView();
        break;
      default:
        // console.log("wtf");
        break;
    }
  };
  //

  const handleDisplayChange = (e) => {
    switch (e.currentTarget.id) {
      case "view-sources":
        setViewMainColumn(false);
        setViewSources(true);
        break;
      case "view-sales-overview":
        setViewMainColumn(false);
        setViewSalesOverview(true);
        break;

      case "view-doreen-report":
        setViewMainColumn(false);
        setViewDoreenReport(true);
        break;
      default:
        // console.log("wtf");
        break;
    }
  };

  //
  const loadCustoms = async () => {
    try {
      setLoading(true);
      // debugging
      // console.log(fullCycles);
      //
      setViewMainColumn(true);

      //
      setLoading(false);
    } catch (error) {
      alert("could not load custom reports");
      history.push("/performance");
    }
  };
  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={customsRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <div className="page-column page-column-100-70" ref={customsRef}>
          {viewMainColumn && (
            <div className="flex-column-center-center blue-green-underline">
              <div className="margin-top-large"></div>
              <p className="main-title dark-blue-text">
                Dealership Custom Reports
              </p>
              <div className="margin-top-large"></div>
              <button
                className="button-normal medium-text margin-small"
                id="view-sources"
                onClick={handleDisplayChange}
              >
                Sources
              </button>
              <div className="margin-top-large"></div>
              <button
                className="button-normal medium-text margin-small"
                id="view-sales-overview"
                onClick={handleDisplayChange}
              >
                Sales Results Overview
              </button>

              <div className="margin-top-large"></div>
              <button
                className="button-normal medium-text margin-small"
                id="view-doreen-report"
                onClick={handleDisplayChange}
              >
                Doreen Report
              </button>

              <div className="margin-top-large"></div>
            </div>
          )}
          <div className="medium-text dark-blue-text margin-top-large flex-row-center-center full-width center-text blue-green-underline">
            <p className="margin-bottom-large">
              To create a custom report, please send us a message by clicking
              "Support" from the navigation menu.
            </p>
          </div>
          <div className="flex-row-center-center">
            <button
              className="button-normal medium-large-text margin-top-large"
              id="hide-customs"
              onClick={handleCancel}
            >
              Back to Performance
            </button>
          </div>
        </div>
      )}

      {viewSources && (
        <Sources
          endDate={endDate}
          handleCancel={handleCustomsCancel}
          fullCycles={fullCycles}
          startDate={startDate}
        />
      )}

      {viewSalesOverview && (
        <SalesOverview
          SPResults={SPResults}
          endDate={endDate}
          handleCancel={handleCustomsCancel}
          startDate={startDate}
        />
      )}

      {doreenReport && viewDoreenReport && (
        <DoreenReport
          doreenReport={doreenReport}
          handleCancel={handleCustomsCancel}
        />
      )}
    </Fragment>
  );
};

Customs.propTypes = {
  SPResults: PropTypes.array,
  endDate: PropTypes.string.isRequired,
  fullCycles: PropTypes.array.isRequired,
  doreenReport: PropTypes.object,
  startDate: PropTypes.string.isRequired,
};

export default Customs;
