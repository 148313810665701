import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../context/users/userContext";

export const PublicContact = () => {
  const userContext = useContext(UserContext);

  const history = useHistory();

  const mainBodyRef = useRef();
  const emailBodyRef = useRef();
  const contactInfoRef = useRef();
  const sendButton = useRef();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const asyncSendPublicEmail = async (emailBody, contactInfo) => {
    // this is where the action happens
    try {
      const result = await userContext.sendPublicEmail(emailBody, contactInfo);

      if (result) {
        // back home
        alert("SUCCESS!");
        history.push("/");
      } else {
        throw new Error("could not send e-mail");
      }
    } catch (error) {
      alert(error.message);
      window.location.reload();
    }
  };

  const checkValid = (e) => {
    if (
      emailBodyRef.current.value !== "" &&
      contactInfoRef.current.value !== ""
    ) {
      // send button available
      sendButton.current.classList.remove("button-elevated-disabled");
    } else {
      // send button not available
      sendButton.current.classList.add("button-elevated-disabled");
    }
  };

  const sendPublicEmail = (e) => {
    asyncSendPublicEmail(
      emailBodyRef.current.value,
      contactInfoRef.current.value
    );
  };

  return (
    //
    <div className="flex-column-center-between full-width" ref={mainBodyRef}>
      <div className="border-section"></div>
      <p className="large-text dark-blue-text">Contact Us!</p>

      <p className="medium-large-text dark-blue-green-text margin-top-large center-text">
        If you have any questions or would like to receive a complete
        demonstration, please call us at
      </p>
      <p className="medium-large-text dark-red-text margin-top-large">
        774-930-5640
      </p>
      <p className="medium-large-text dark-blue-green-text margin-top-large">
        or
      </p>
      <textarea
        className="public-contact-container popup-body-container-prewrap small-medium-text dark-blue-green-text margin-top-large"
        placeholder="Send us an e-mail!"
        ref={emailBodyRef}
        onChange={checkValid}
      ></textarea>
      <textarea
        className="public-contact-container public-contact-container-smaller popup-body-container-prewrap small-medium-text dark-blue-green-text margin-top-large"
        placeholder="email address and/or phone #"
        ref={contactInfoRef}
        onChange={checkValid}
      ></textarea>
      <div className="flex-row-center-center">
        <button
          className="button-normal large-text margin-top-large margin-right-large"
          onClick={() => {
            history.push("/");
          }}
        >
          Home
        </button>
        <button
          className="button-elevated large-text margin-top-large button-elevated-disabled"
          onClick={sendPublicEmail}
          ref={sendButton}
        >
          Send
        </button>
      </div>

      <div className="border-section"></div>
      <div className="border-section"></div>
    </div>
  );
};

export default PublicContact;
