import React from "react";
import PropTypes from "prop-types";

const MultiSelectConfig = ({
  handleCancel,
  handleDisplayChange,
  handleMultiSelectMethod,
}) => {
  return (
    <div className="popup-content popup-content-special">
      <div className="popup-header popup-header-special">
        <p className="large-text white-text center-text flex-grow">
          Multi Select
        </p>
        <div onClick={handleCancel} id="hide-multi-select-config">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="fifty-height flex-column-center-evenly">
          <p className="large-text dark-grey-text margin-bottom-large">
            Select Method:
          </p>
          {/* This is temporarily disabled until we can figure out how to reliably deliver text messages */}
          <button
            className="button-neutral large-text full-width"
            id="text-method-selector"
            onClick={handleMultiSelectMethod}
          >
            Text
          </button>

          <button
            className="button-neutral large-text full-width"
            id="email-method-selector"
            onClick={handleMultiSelectMethod}
          >
            E-Mail
          </button>
          <div className="hidden flex-column-center-evenly" id="method-stats">
            <p className="medium-text">Statistics:</p>
            <p className="medium-text" id="current-stats"></p>
          </div>
          <button
            className="hidden button-negative large-text full-width"
            id="compose-emails-button"
            name="view-compose-multi-email"
            onClick={handleDisplayChange}
          >
            Compose Email
          </button>
          <button
            className="hidden button-negative large-text full-width"
            id="compose-texts-button"
            name="view-compose-multi-text"
            onClick={handleDisplayChange}
          >
            Compose Text
          </button>
        </div>
      </div>
    </div>
  );
};

MultiSelectConfig.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleDisplayChange: PropTypes.func.isRequired,
  handleMultiSelectMethod: PropTypes.func.isRequired,
};

export default MultiSelectConfig;
