import { SEARCHRESULTS, CLEARRESULTS } from "../types";

const dealershipReducer = (state, action) => {
  switch (action.type) {
    case SEARCHRESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case CLEARRESULTS:
      return {
        ...state,
        searchResults: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default dealershipReducer;
