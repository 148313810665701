import React from "react";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";
import { format } from "date-fns";

const ActionDataMaintenance = ({
  handleCancel,
  handleDisplayChange,
  handleNoDuplicateFound,
  mergeCustomerHandler,
  searchedCustomerState,
  searchResultsState,
}) => {
  return (
    <div className="popup-content popup-content-special">
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Data Maintenance
        </p>
        <div onClick={handleCancel} id="view-data-maintenance-cancel">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="medium-text medium-blue-text">Current Information:</div>

        <div className="module module-full-width">
          <div className="module-main">
            <div className="module-data dark-blue-green-text">
              {searchedCustomerState && searchedCustomerState.firstName}{" "}
              {searchedCustomerState && searchedCustomerState.lastName}
            </div>
            <div className="module-data dark-blue-green-text">
              {searchedCustomerState &&
                searchedCustomerState.phoneNumber &&
                typeof searchedCustomerState.phoneNumber === "string" &&
                parsePhoneNumber(searchedCustomerState.phoneNumber) &&
                parsePhoneNumber(
                  searchedCustomerState.phoneNumber
                ).formatNational("US")}
            </div>
            <div className="module-data dark-blue-green-text">
              {searchedCustomerState && searchedCustomerState.emailAddress}
            </div>
            <div className="module-data dark-blue-text">
              {searchedCustomerState && searchedCustomerState.customerId}
            </div>
          </div>
          <div className="module-section module-section-normal">
            <div className="tiny-text white-text">Last Updated</div>

            <div className="tiny-text white-text">
              {searchedCustomerState &&
                searchedCustomerState.updatedAt &&
                format(new Date(searchedCustomerState.updatedAt), "P")}
            </div>
          </div>
        </div>

        <div className="medium-text medium-blue-text">Possible Duplicates:</div>

        <div
          className="popup-section popup-section-taller"
          onClick={handleDisplayChange}
        >
          {searchResultsState &&
            searchResultsState.map((result, index) => {
              if (result._id !== searchedCustomerState._id) {
                return (
                  <div className="module-checkbox-container" key={index}>
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="customer-search-results"
                        id={result._id}
                        className="checkbox-clickable"
                        onClick={handleDisplayChange}
                      />
                      <div className="checkbox margin-right-small small-text center-text"></div>
                    </div>
                    <div className="module module-full-width">
                      <div className="module-main">
                        <div className="module-data dark-blue-green-text">
                          {result && result.firstName}{" "}
                          {result && result.lastName}
                        </div>

                        <div className="module-data dark-blue-green-text">
                          {result &&
                            result.phoneNumber &&
                            typeof result.phoneNumber === "string" &&
                            parsePhoneNumber(result.phoneNumber) &&
                            parsePhoneNumber(result.phoneNumber).formatNational(
                              "US"
                            )}
                        </div>

                        <div className="module-data dark-blue-green-text">
                          {result && result.emailAddress && result.emailAddress}
                        </div>
                      </div>
                      <div className="module-section module-section-normal">
                        <div className="white-text">Last Updated</div>

                        <div className="white-text">
                          {result && format(new Date(result.updatedAt), "P")}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
        </div>

        <div className="form-row">
          <button
            name="view-manual-customer-number"
            className="button-normal small-medium-text flex-grow margin-right-small"
            onClick={handleDisplayChange}
          >
            Manual Entry
          </button>
          <button
            className="button-normal button-normal-disabled button-disabled small-medium-text flex-grow"
            id="action-data-maintenance-merge-button"
            onClick={mergeCustomerHandler}
          >
            Merge With Current
          </button>
        </div>

        <div className="form-row">
          <button
            onClick={handleNoDuplicateFound}
            className="button-elevated medium-large-text flex-grow"
          >
            No Duplicate Found
          </button>
        </div>
      </div>
    </div>
  );
};

ActionDataMaintenance.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleDisplayChange: PropTypes.func.isRequired,
  handleNoDuplicateFound: PropTypes.func.isRequired,
  mergeCustomerHandler: PropTypes.func.isRequired,
  searchedCustomerState: PropTypes.object.isRequired,
  searchResultsState: PropTypes.array.isRequired,
};

export default ActionDataMaintenance;
