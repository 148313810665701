//

// Open Source Modules

//
import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { format, parseISO, differenceInDays, getYear } from "date-fns";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import emailValidator from "email-validator";

//
// testing
// import axios from "axios";
// import { axiosConfig } from "../util/axiosConfig";
//

//

// Local Modules

//
import UserContext from "../context/users/userContext";
import DealershipContext from "../context/dealership/dealershipContext";
import { getModels, getCityStateFromZip } from "../util/addCustomerApiCalls";
import {
  addNote,
  checkTexts,
  completeAction,
  completeMultiActions,
  getCurrentVehicle,
  getCyclesFromIds,
  mergeExistingCustomers,
  saveAppt,
  saveEditedCustomer,
  sendMultiEmail,
  sendMultiText,
  sendReply,
  sendText,
  setSearchedCustomer,
  updateEmailNote,
  updateVehicle,
  cycleTypeUpdate,
  internetSourceUpdate,
} from "../functions/customerFunctions";
import commonMakes from "../util/commonMakes";
import CurrentActionViewer from "./popups/CurrentActionViewer";
import NoteWriter from "./popups/NoteWriter";
import EmailViewer from "./popups/EmailViewer";
import EmailReplyComposer from "./popups/EmailReplyComposer";
import NewEmailComposer from "./popups/NewEmailComposer";
import WebLeadResponder from "./popups/WebLeadResponder";
import ActionDataMaintenance from "./popups/ActionDataMaintenance";
import ActionText from "./popups/ActionText";
import MultiSelectConfig from "./popups/MultiSelectConfig";
import ComposeMultiText from "./popups/ComposeMultiText";
import ComposeMultiEmail from "./popups/ComposeMultiEmail";
import CustomerEditor from "./popups/CustomerEditor";
import LogPhoneCall from "./popups/LogPhoneCall";
import ActionAppointmentInfo from "./popups/ActionAppointmentInfo";
//

// COMPONENT

//
const Actions = () => {
  //

  // HOOKS

  //
  const userContext = useContext(UserContext);
  const dealershipContext = useContext(DealershipContext);
  //
  const history = useHistory();
  //
  const mainBodyRef = useRef();
  const completeSelectedButton = useRef();
  //
  const [actionGroups, setActionGroups] = useState(null);
  const [actionSearchTextButtonViewable, setActionSearchTextButtonViewable] =
    useState(false);
  const [actionsPopulated, setActionsPopulated] = useState(false);
  const [apptDate, setApptDate] = useState("");
  const [apptStatus, setApptStatus] = useState("");
  const [apptTime, setApptTime] = useState("");
  const [currentAction, setCurrentAction] = useState(null);
  const [currentActions, setCurrentActions] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [currentCycle, setCurrentCycle] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentEmailReplySubject, setCurrentEmailReplySubject] = useState("");
  const [currentEmailReply, setCurrentEmailReply] = useState("");
  const [currentMake, setCurrentMake] = useState("select-make");
  const [currentModel, setCurrentModel] = useState("");
  const [currentModels, setCurrentModels] = useState([]);
  const [currentMultiEmail, setCurrentMultiEmail] = useState("");
  const [currentMultiEmailSubject, setCurrentMultiEmailSubject] = useState("");
  const [currentMultiTextBody, setCurrentMultiTextBody] = useState("");
  const [currentNewEmail, setCurrentNewEmail] = useState("");
  const [currentNewEmailSubject, setCurrentNewEmailSubject] = useState("");
  const [currentNotes, setCurrentNotes] = useState([]);
  const [currentStock, setCurrentStock] = useState("");
  const [currentTextBody, setCurrentTextBody] = useState("");
  const [currentTexts, setCurrentTexts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentVehicleType, setCurrentVehicleType] = useState("select-type");
  const [currentVin, setCurrentVin] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [customersToMerge, setCustomersToMerge] = useState([]);
  const [dialogueCallBool, setDialogueCallBool] = useState(false);
  const [dialogueNoteBool, setDialogueNoteBool] = useState(false);
  const [dealershipInternetSources, setDealershipInternetSources] = useState(
    []
  );
  const [dealershipShortName, setDealershipShortName] = useState("");
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [hiddenAction, setHiddenAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [newNote, setNewNote] = useState(false);
  const [noteBody, setNoteBody] = useState("");
  const [phoneCallBody, setPhoneCallBody] = useState("");
  const [searchedCustomerState, setSearchedCustomerState] = useState(null);
  const [searchResultsState, setSearchResultsState] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedType, setSelectedType] = useState("type");
  const [selectedInternetSource, setSelectedInternetSource] =
    useState("source");
  const [sortOrder, setSortOrder] = useState("");
  const [textCheckerStatus, setTextCheckerStatus] = useState(null);
  const [twilioEmail, setTwilioEmail] = useState("");
  const [twilioAltEmail, setTwilioAltEmail] = useState("");
  const [twilioPhone, setTwilioPhone] = useState("");
  const [twilioAltPhone, setTwilioAltPhone] = useState("");
  const [viewAppointmentInfo, setViewAppointmentInfo] = useState(false);
  const [viewAsUser, setViewAsUser] = useState("");
  const [viewCompleteWebLead, setViewCompleteWebLead] = useState(false);
  const [viewComposeMultiEmail, setViewComposeMultiEmail] = useState(false);
  const [viewComposeMultiText, setViewComposeMultiText] = useState(false);
  const [viewComposeNewEmail, setViewComposeNewEmail] = useState(false);
  const [viewComposeReply, setViewComposeReply] = useState(false);
  const [viewDataMaintenance, setViewDataMaintenance] = useState(false);
  const [viewEditCustomer, setViewEditCustomer] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);
  const [viewEmailButton, setViewEmailButton] = useState(false);
  const [viewMultiSelectConfig, setViewMultiSelectConfig] = useState(false);
  const [viewPhoneButton, setViewPhoneButton] = useState(false);
  const [viewPhoneCall, setViewPhoneCall] = useState(false);
  const [viewTextButton, setViewTextButton] = useState(false);
  const [viewTextConversation, setViewTextConversation] = useState(false);
  //
  useEffect(() => {
    if (!actionsPopulated && !errorState) {
      loadActions();
    }

    //
    if (document.getElementById("bottom-of-texts")) {
      const bottomOfTexts = document.getElementById("bottom-of-texts");
      bottomOfTexts.click();
    }

    // eslint-disable-next-line
  }, [actionsPopulated, viewTextConversation]);
  //

  // EXPRESSIONS

  //
  const localCurrentAction = JSON.parse(
    localStorage.getItem("localCurrentAction")
  );
  //
  const years = [];
  //
  const thisYear = getYear(parseISO(new Date().toISOString()));
  //
  years.push(thisYear + 1);
  //
  for (let i = 0; i < 25; i++) {
    years.push(thisYear - i);
  }
  //

  // FUNCTIONS

  //
  const asyncCheckTexts = async () => {
    const newTextFound = await checkTexts(currentCustomer);

    safeStateSetter(() => {
      if (newTextFound) {
        newTextFound.sort((a, b) => {
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
          } else {
            return -1;
          }
        });
        setCurrentTexts(newTextFound);
      }
    });
  };
  //
  const asyncLoadDataMaintenance = async (action) => {
    try {
      //this is a weird dealershipContext.search call
      // console.log(action);
      const results = await dealershipContext.search(action.customer);

      if (results) {
        safeStateSetter(() => {
          setSearchResultsState(results);
          setSearchedCustomerState(action.customer);
          disableAllView();
          setHiddenAction(action);
          setViewDataMaintenance(true);
        });
      } else {
        console.log("could not get search results!");
      }
    } catch (error) {
      console.log("Actions.asyncLoadDataMaintenance: ", error.message);
    }
  };
  //
  const asyncSearchWithinGroup = async (group, fullActions) => {
    try {
      const customerResults = await dealershipContext.search({
        allFields: searchText,
        searchType: "within-group",
        activeCycleSelected: true,
        withinGroup: group,
      });
      if (!customerResults) {
        console.log("could not get search results");
      }

      const resultActions = [];

      // quadratic 👎
      customerResults.forEach((cusRes) => {
        for (let i = 0; i < fullActions.length; i++) {
          if (fullActions[i].customer._id === cusRes._id) {
            resultActions.push(fullActions[i]);
          }
        }
      });

      safeStateSetter(() => {
        setSearchText("");
        setCurrentActions(resultActions);
      });
    } catch (error) {
      console.log("Actions.asyncSearchWithinGroup: ", error.message);
    }
  };
  //
  const asyncSendMultiEmail = async (
    actionObjects,
    emailSubject,
    emailBody
  ) => {
    // NOT SURE WHY THIS ISN'T WORKING MAKE MY WAY BACK HERE
    // here we have an array of full objects and two strings
    try {
      // the first thing we have to do is get the unique customers that will receive an email
      // we don't want one customer receiving multiple emails here.
      const customerIds = [];
      const actionIds = [];
      actionObjects.forEach((act, idx) => {
        actionIds.push(act._id);
        if (act.customer.emailAddress) {
          if (
            act.customer.exactDuplicates &&
            act.customer.exactDuplicates.email
          ) {
            actionIds.splice(idx, 1);
            alert(
              `${act.customer.firstName} ${act.customer.lastName} shares an email address with another customer. Emailing this customer is not possible until this conflict is resolved. Edit one of the customers or merge the two to resolve. The rest will receive Multi Email.`
            );
          } else {
            // console.log(act.customer.exactDuplicates);
            customerIds.push(act.customer._id);
          }
        } else {
          // could not add customer to array
        }
      });
      // now we should have a proper array of customer ids
      // this will send the email, create the Email object in the database, and save it to the Customer in the database;
      const multiEmailSent = await sendMultiEmail(
        customerIds,
        emailSubject,
        emailBody
      );

      if (multiEmailSent) {
        // console.log("emails were sent correctly");

        const completedAllActions = await completeMultiActions(actionIds);

        if (completedAllActions) {
          // console.log("actions were completed correctly");
          safeStateSetter(() => {
            disableAllView();
            setCurrentMultiEmail("");
            setCurrentMultiEmailSubject("");
            setCurrentAction(null);
            localStorage.setItem("localCurrentAction", null);
            setActionsPopulated(false);
          });
        } else {
          throw new Error("actions were NOT completed correctly");
        }
      } else {
        throw new Error("emails were NOT sent correctly");
      }
    } catch (error) {
      console.log("Actions.asyncSendMultiEmail: ", error.message);
    }
  };
  //
  const asyncSendNewEmail = async (action, subject, body) => {
    console.log("hello from asyncSendNewEmail");
    const sent = await sendReply(action.customer, subject, body);
    if (sent) {
      let enteredNote;
      if (
        action.actionType === "unsold-follow-up" ||
        action.actionType === "text-received"
      ) {
        enteredNote = {
          customer: action.customer._id,
          noteType: "newemail",
          dialogue: false,
          body: "New E-Mail Sent!",
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          emailData: {
            from: `${dealershipShortName}-assistance@leadzen.cc`,
            subject: currentNewEmailSubject,
            emailBody: currentNewEmail,
          },
          user: userContext.user._id,
        };
        // console.log("Actions enteredNote: ", enteredNote);
      } else if (action.actionType === "web-lead") {
        enteredNote = {
          customer: action.customer._id,
          noteType: "webleadresponse",
          dialogue: false,
          body: "Web lead response sent!",
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          emailData: {
            from: `${dealershipShortName}-assistance@leadzen.cc`,
            subject: currentEmailReplySubject || currentNewEmailSubject,
            emailBody: currentEmailReply || currentNewEmail,
          },
          user: userContext.user._id,
        };
      } else {
        // might need to add more cases here
        console.log("invalid actionType");
      }
      const addedNote = await addNote(enteredNote);

      const completedAction = await completeAction(action._id);

      safeStateSetter(() => {
        if (addedNote && completedAction) {
          window.location.reload();
        } else {
          alert("could not add note and/or complete action");
          window.location.reload();
        }
      });
    } else {
      alert("could not send new email");
      window.location.reload();
    }
  };
  //
  const asyncSendReply = async (action, subject, body) => {
    const replySent = await sendReply(action.customer, subject, body);

    if (replySent) {
      // console.log("reply sent");
      const previousNewestNote = currentNotes[0];

      if (previousNewestNote.noteType === "emailreceived") {
        await updateEmailNote(previousNewestNote);
      }

      let enteredNote;

      if (
        action.actionType === "unsold-follow-up" ||
        action.actionType === "email-received"
      ) {
        enteredNote = {
          customer: action.customer._id,
          noteType: "emailreply",
          dialogue: true,
          body: "E-mail reply sent!",
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          emailData: {
            from: `${dealershipShortName}-assistance@leadzen.cc`,
            subject: currentEmailReplySubject,
            emailBody: currentEmailReply,
          },
          user: userContext.user._id,
        };
      } else if (action.actionType === "web-lead") {
        enteredNote = {
          customer: action.customer._id,
          noteType: "webleadresponse",
          dialogue: false,
          body: "Web lead response sent!",
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          emailData: {
            from: `${dealershipShortName}-assistance@leadzen.cc`,
            subject: currentEmailReplySubject,
            emailBody: currentEmailReply,
          },
          user: userContext.user._id,
        };
      } else {
        console.log("invalid actionType");
      }

      const noteAdded = await addNote(enteredNote);
      if (noteAdded) {
        // console.log("note added");
      }

      const actionCompleted = await completeAction(action._id);
      if (actionCompleted) {
        // console.log("action completed");
      }
      if (noteAdded && actionCompleted) {
        window.location.reload();
      } else if (!noteAdded && !actionCompleted) {
        console.log("could neither add note nor complete action...");
      } else if (!noteAdded) {
        console.log("could not add note");
      } else {
        console.log("could not complete action");
      }
    } else {
      console.log("email not sent...");
    }
  };
  //
  const asyncSendText = async (action, body) => {
    try {
      const textSent = await sendText(action.customer, body);

      if (textSent) {
        console.log("text was sent correctly");

        const completedAction = await completeAction(action._id);

        if (completedAction) {
          // console.log("action was completed correctly");
          safeStateSetter(() => {
            disableAllView();
            localStorage.setItem("localTextCheckerCount", "1");
            clearInterval(textCheckerStatus);
            setCurrentTextBody("");
            setCurrentAction(null);
            // const curActs = currentActions.filter(
            //   (ac) => ac._id !== action._id
            // );
            // if (curActs.length) {
            //   setCurrentActions(curActs);
            // } else {
            window.location.reload();
            // }
          });
        } else {
          console.log("action was NOT completed correctly");
          window.location.reload();
        }
      } else {
        console.log("text was NOT sent correctly");
        window.location.reload();
      }
    } catch (error) {
      console.log("Actions.asyncSendText: ", error.message);
    }
  };
  //
  const asyncSendMultiText = async (actions, body) => {
    // here we have an array of full objects and a string
    try {
      // the first thing we have to do is get the unique customers that will receive a text
      // we don't want one customer receiving multiple texts here.
      const customerIds = [];
      const actionIds = [];
      // console.log(actions);
      actions.forEach((act, idx) => {
        actionIds.push(act._id);
        if (
          !customerIds.includes(act.customer._id) &&
          act.customer.phoneNumber
        ) {
          if (
            act.customer.exactDuplicates &&
            act.customer.exactDuplicates.phone
          ) {
            actionIds.splice(idx, 1);
            alert(
              `${act.customer.firstName} ${act.customer.lastName} shares a phone number with another customer. Texting this customer is not possible until this conflict is resolved. Edit one of the customers or merge the two to resolve. The rest will receive Multi Text.`
            );
          } else {
            customerIds.push(act.customer._id);
          }
        } else {
          // could not add customer to array
        }
      });
      // now we should have a proper array of customers
      // this will send the text, create the Text object, and save it to the customer;
      const multiTextSent = await sendMultiText(customerIds, body);

      if (multiTextSent) {
        // console.log("texts were sent correctly");

        const completedAllActions = await completeMultiActions(actionIds);

        if (completedAllActions) {
          // console.log("actions were completed correctly");
          safeStateSetter(() => {
            disableAllView();
            setCurrentTextBody("");
            setCurrentAction(null);
            localStorage.setItem("localCurrentAction", null);
            setActionsPopulated(false);
          });
        } else {
          throw new Error("actions were NOT completed correctly");
        }
      } else {
        throw new Error("texts were NOT sent correctly");
      }
    } catch (error) {
      console.log("Actions.asyncSendMultiText: ", error.message);
    }
  };
  //
  const buttonViewSelection = (matchingAction) => {
    safeStateSetter(() => {
      if (
        !matchingAction.customer.phoneNumber &&
        !matchingAction.customer.altPhoneNumber
      ) {
        setViewTextButton(false);
        setViewPhoneButton(false);
      } else {
        setViewTextButton(true);
        setViewPhoneButton(true);
      }

      if (
        !matchingAction.customer.emailAddress &&
        !matchingAction.customer.altEmailAddress
      ) {
        setViewEmailButton(false);
      } else {
        setViewEmailButton(true);
      }
    });
  };
  //
  const callGetCityStateFromZip = async (zip) => {
    try {
      const result = await getCityStateFromZip(zip);
      safeStateSetter(() => {
        if (result) {
          setEditedCustomer({
            ...editedCustomer,
            city: result.city,
            state: result.state,
            zipCode: zip,
          });
        } else {
          setEditedCustomer({
            ...editedCustomer,
            city: "",
            state: "",
            zipCode: zip,
          });
        }
      });
    } catch (error) {
      console.log("Actions.callGetCityStateFromZip: ", error.message);
      safeStateSetter(() => {
        setEditedCustomer({ ...editedCustomer, city: "", state: "" });
      });
    }
  };
  //
  const callGetModels = async (year, make) => {
    try {
      const result = await getModels(year, make);

      const lowerCaseResult = [];

      if (result) {
        result.forEach((el) => {
          lowerCaseResult.push(el.toLowerCase());
        });
        safeStateSetter(() => {
          setCurrentModels(lowerCaseResult);
        });
        return true;
      }
    } catch (error) {
      //
    }
  };
  //
  const disableAllView = () => {
    safeStateSetter(() => {
      setNewNote(false);
      setViewPhoneCall(false);
      setViewAppointmentInfo(false);
      setViewEmail(false);
      setViewComposeReply(false);
      setViewComposeNewEmail(false);
      setViewCompleteWebLead(false);
      setViewDataMaintenance(false);
      setViewEditCustomer(false);
      setViewTextConversation(false);
      setViewMultiSelectConfig(false);
      setViewComposeMultiText(false);
      setViewComposeMultiEmail(false);
    });
  };
  //
  const loadActions = async () => {
    // const startTime = Date.now();
    setLoading(true);
    const userLoaded = await userContext.getUser();

    if (!userLoaded.user.active || userLoaded.user.active === "false") {
      window.location.reload();
    }

    // console.log("userLoaded: ", Date.now() - startTime);

    // PHASE 1: actions
    if (
      userLoaded &&
      userLoaded.incompleteActions &&
      userLoaded.incompleteActions[0]
    ) {
      // console.log(userLoaded.incompleteActions);
      // User rights management
      if (userLoaded.user.role === "receptionist") {
        history.push("/dashboard");
        return;
      }
      //
      const dealership = await userContext.getDealershipOfUser(
        userLoaded.user._id
      );
      if (dealership) {
        safeStateSetter(() => {
          // console.log("have dealership: ", Date.now() - startTime);
          setDealershipShortName(dealership.shortName);
          setEmailTemplates(dealership.emailTemplates);
          setDealershipInternetSources(dealership.possibleInternetSources);
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        console.log("could not get dealership of user, redirect to homepage");
        history.push("/");
      }

      // console.log(
      //   "about to call getSubdocumentsOfActions with: ",
      //   userLoaded.incompleteActions
      // );
      const loadedActions = await userContext.getSubdocumentsOfActions(
        userLoaded.incompleteActions
      );
      // console.log(loadedActions);
      if (loadedActions) {
        // console.log("have loadedActions: ", Date.now() - startTime);
        // here is where we deal with the sort order the user has selected
        const usedVehicleActions = [];
        const newVehicleActions = [];
        const unknownVehicleActions = [];

        loadedActions.forEach((loadedAct) => {
          if (
            loadedAct.cycle &&
            loadedAct.cycle.vehicle &&
            loadedAct.cycle.vehicle.type &&
            loadedAct.cycle.vehicle.type === "used"
          ) {
            usedVehicleActions.push(loadedAct);
          } else if (
            loadedAct.cycle &&
            loadedAct.cycle.vehicle &&
            loadedAct.cycle.vehicle.type &&
            loadedAct.cycle.vehicle.type === "new"
          ) {
            newVehicleActions.push(loadedAct);
          } else {
            unknownVehicleActions.push(loadedAct);
          }
        });
        // create actionGroups
        const localActionGroups = {};

        let cycleIdsFound = [];

        loadedActions.forEach((loadedAct) => {
          if (
            Object.keys(loadedAct.cycle).length > 0 &&
            !cycleIdsFound.includes(loadedAct.cycle._id)
          ) {
            cycleIdsFound = [...cycleIdsFound, loadedAct.cycle._id];
          } else {
            cycleIdsFound = [...cycleIdsFound, ...loadedAct.customer.cycles];
          }
        });

        (async function () {
          const cycles = await getCyclesFromIds(cycleIdsFound);

          return cycles;
        })()
          .then((cycles) => {
            for (let i = 0; i < loadedActions.length; i++) {
              const loadedAct = loadedActions[i];

              // console.log(cycles);
              // console.log(loadedAct);

              if (Object.keys(loadedAct.cycle).length < 1) {
                // sold actions

                // async time

                let mostRecentlyUpdatedCycle;

                const cyclesOfThisCustomer = [];

                cycles.forEach((cy) => {
                  // console.log(cy.customer, loadedAct.customer._id);
                  if (cy.customer === loadedAct.customer._id) {
                    cyclesOfThisCustomer.push(cy);
                  } else {
                  }
                });

                if (!cyclesOfThisCustomer.length) {
                  console.log(loadedAct.customer._id);
                  continue;
                }

                cyclesOfThisCustomer.forEach((cy) => {
                  if (!mostRecentlyUpdatedCycle) {
                    mostRecentlyUpdatedCycle = cy;
                  }
                  if (cy.storeVisits) {
                    if (
                      cy.storeVisits.length > 0 &&
                      new Date(mostRecentlyUpdatedCycle.updatedAt).getTime() <
                        new Date(cy.updatedAt).getTime()
                    ) {
                      mostRecentlyUpdatedCycle = cy;
                    }
                  } else {
                    cy.storeVisits = [];
                  }
                });

                if (
                  !localActionGroups[mostRecentlyUpdatedCycle.primarySales._id]
                ) {
                  localActionGroups[mostRecentlyUpdatedCycle.primarySales._id] =
                    [];
                  localActionGroups[
                    mostRecentlyUpdatedCycle.primarySales._id
                  ].push(loadedAct);
                } else {
                  localActionGroups[
                    mostRecentlyUpdatedCycle.primarySales._id
                  ].push(loadedAct);
                }
              } else {
                // unsold actions
                // console.log("not sold");
                if (!localActionGroups[loadedAct.cycle.primarySales]) {
                  localActionGroups[loadedAct.cycle.primarySales] = [];
                  localActionGroups[loadedAct.cycle.primarySales].push(
                    loadedAct
                  );
                } else {
                  localActionGroups[loadedAct.cycle.primarySales].push(
                    loadedAct
                  );
                }
              }
            }
            return { cycles };
          })
          .then(({ cycles }) => {
            const namedActionGroups = {};

            for (let id in localActionGroups) {
              cycles.forEach((cy) => {
                if (cy.primarySales._id === id) {
                  if (cy.primarySales.role === "bdcmanager") {
                    namedActionGroups[`BDC`] = localActionGroups[id];
                  } else {
                    namedActionGroups[
                      `${cy.primarySales.firstName} ${cy.primarySales.lastName}`
                    ] = localActionGroups[id];
                  }
                }
              });
            }

            safeStateSetter(() => {
              setActionGroups({ All: loadedActions, ...namedActionGroups });
            });

            let localViewAsUser = localStorage.getItem("localViewAsUser");

            if (localViewAsUser) {
              const localActionGroups = {
                All: loadedActions,
                ...namedActionGroups,
              };
              safeStateSetter(() => {
                setViewAsUser(localViewAsUser);
                setCurrentActions(localActionGroups[localViewAsUser]);
              });
            } else {
              safeStateSetter(() => {
                setViewAsUser("All");
                setCurrentActions(loadedActions);
              });
            }
          });

        usedVehicleActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });

        newVehicleActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });

        unknownVehicleActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });
        if (
          !localStorage.getItem("sortOrder") ||
          localStorage.getItem("sortOrder") === "alphabetical"
        ) {
          safeStateSetter(() => {
            setSortOrder("alphabetical-a-z");
          });

          loadedActions.sort((a, b) => {
            if (a.customer.lastName > b.customer.lastName) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (localStorage.getItem("sortOrder") === "oldest-first") {
          safeStateSetter(() => {
            setSortOrder("due-date-oldest");
          });
          // due date oldest first
          loadedActions.sort((a, b) => {
            if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (localStorage.getItem("sortOrder") === "newest-first") {
          safeStateSetter(() => {
            setSortOrder("due-date-newest");
          });
          // due date newest first
          loadedActions.sort((a, b) => {
            if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
              return -1;
            } else {
              return 1;
            }
          });
        } else if (localStorage.getItem("sortOrder") === "used-first") {
          safeStateSetter(() => {
            setSortOrder("type-used-first");
          });
          // vehicle type used
          for (let i = 0; i < loadedActions.length; i++) {
            loadedActions.splice(i, 1);
            if (loadedActions.length > 0) {
              i--;
            }
          }

          usedVehicleActions.forEach((usedAct) => {
            loadedActions.push(usedAct);
          });

          newVehicleActions.forEach((newAct) => {
            loadedActions.push(newAct);
          });

          unknownVehicleActions.forEach((unknownAct) => {
            loadedActions.push(unknownAct);
          });
        } else if (localStorage.getItem("sortOrder") === "new-first") {
          safeStateSetter(() => {
            setSortOrder("type-new-first");
          });
          // vehicle type new
          for (let i = 0; i < loadedActions.length; i++) {
            loadedActions.splice(i, 1);
            if (loadedActions.length > 0) {
              i--;
            }
          }

          newVehicleActions.forEach((newAct) => {
            loadedActions.push(newAct);
          });

          usedVehicleActions.forEach((usedAct) => {
            loadedActions.push(usedAct);
          });

          unknownVehicleActions.forEach((unknownAct) => {
            loadedActions.push(unknownAct);
          });
        } else if (localStorage.getItem("sortOrder") === "cycle-oldest") {
          //
          safeStateSetter(() => {
            setSortOrder("cycle-oldest");
          });
          // oldest cycle first
          const noCycle = [];
          const hasCycle = [];
          loadedActions.forEach((ac) => {
            if (!ac.cycle._id) {
              noCycle.push(ac);
            } else {
              hasCycle.push(ac);
            }
          });

          // this means that anything without an active cycle (sold-follow-up) will be last
          hasCycle.sort((a, b) => {
            if (
              new Date(a.cycle.createdAt).getTime() <
              new Date(b.cycle.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          loadedActions.splice(0, loadedActions.length);

          hasCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          noCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          // console.log(loadedActions);
        } else if (localStorage.getItem("sortOrder") === "cycle-newest") {
          //
          safeStateSetter(() => {
            setSortOrder("cycle-newest");
          });
          // oldest cycle first
          const noCycle = [];
          const hasCycle = [];
          loadedActions.forEach((ac) => {
            if (!ac.cycle._id) {
              noCycle.push(ac);
            } else {
              hasCycle.push(ac);
            }
          });

          // this means that anything without an active cycle (sold-follow-up) will be last
          hasCycle.sort((a, b) => {
            if (
              new Date(a.cycle.createdAt).getTime() >
              new Date(b.cycle.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          loadedActions.splice(0, loadedActions.length);

          hasCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          noCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          // console.log(loadedActions);
        }

        // sort by search result will be dealt with elsewhere
        safeStateSetter(() => {
          setCurrentUser(userLoaded);
          setActionGroups(localActionGroups);
          localStorage.setItem("localCurrentActions", loadedActions);
          setActionsPopulated(true);
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        console.log("could not load actions of user, redirect to homepage");
        history.push("/");
      }
      //
      //
      // PHASE 2: action
      //
      //
      if (localCurrentAction) {
        // this localCurrentAction should be an action with subdocuments
        let matchFound = false;
        let matchingAction = null;
        loadedActions.forEach((incAct) => {
          if (incAct._id === localCurrentAction._id) {
            matchFound = true;
            matchingAction = incAct;
          }
        });

        if (matchFound) {
          // we have the action
          localStorage.setItem(
            "localCurrentAction",
            JSON.stringify(matchingAction)
          );

          matchingAction.customer.emails.sort((a, b) => {
            if (
              new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          matchingAction.customer.notes.sort((a, b) => {
            if (
              new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          let localTexts = [];
          if (matchingAction.customer.texts[0]) {
            // we assume these texts are full objects...
            localTexts = matchingAction.customer.texts;
            localTexts.sort((a, b) => {
              if (parseISO(a.createdAt) > parseISO(b.createdAt)) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          //
          // should we set customer context at this point?  why would we?
          //

          if (
            matchingAction &&
            matchingAction.cycle &&
            matchingAction.cycle.vehicle &&
            matchingAction.cycle.vehicle.year !== "" &&
            matchingAction.cycle.vehicle.make !== ""
          ) {
            const vehicleModelResults = await getModels(
              matchingAction.cycle.vehicle.year,
              matchingAction.cycle.vehicle.make
            );

            if (vehicleModelResults) {
              safeStateSetter(() => {
                setCurrentModels(vehicleModelResults);
                setCurrentMake(matchingAction.cycle.vehicle.make.toLowerCase());
                setCurrentModel(
                  matchingAction.cycle.vehicle.model.toLowerCase()
                );
              });
            }
          } else {
            console.log("missing necessary information to get models");
          }

          safeStateSetter(() => {
            if (matchingAction && matchingAction.cycle) {
              setCurrentCycle(matchingAction && matchingAction.cycle);

              setCurrentMake(
                matchingAction &&
                  matchingAction.cycle &&
                  matchingAction.cycle.vehicle &&
                  matchingAction.cycle.vehicle.make
              );

              if (
                matchingAction &&
                matchingAction.cycle &&
                matchingAction.cycle.vehicle &&
                matchingAction.cycle.vehicle.model &&
                typeof matchingAction.cycle.vehicle.model === "string"
              ) {
                setCurrentModel(
                  matchingAction.cycle.vehicle.model.toLowerCase()
                );
              }

              setCurrentStock(
                matchingAction &&
                  matchingAction.cycle &&
                  matchingAction.cycle.vehicle &&
                  matchingAction.cycle.vehicle.stock
              );

              setCurrentVehicleType(
                matchingAction &&
                  matchingAction.cycle &&
                  matchingAction.cycle.vehicle &&
                  matchingAction.cycle.vehicle.type
              );

              setCurrentVin(
                matchingAction &&
                  matchingAction.cycle &&
                  matchingAction.cycle.vehicle &&
                  matchingAction.cycle.vehicle.vin
              );

              setCurrentYear(
                matchingAction &&
                  matchingAction.cycle &&
                  matchingAction.cycle.vehicle &&
                  matchingAction.cycle.vehicle.year
              );
            }
            setCurrentAction(matchingAction);
            setCurrentCustomer(matchingAction && matchingAction.customer);
            setCurrentEmail(
              matchingAction &&
                matchingAction.customer &&
                matchingAction.customer.emails[0]
            );
            setCurrentNotes(matchingAction.customer.notes);
            setCurrentTexts(localTexts);
            setSelectedType(
              matchingAction.cycle ? matchingAction.cycle.cycleType : "type"
            );
            setSelectedInternetSource(
              (matchingAction.cycle && matchingAction.cycle.internetSource) ||
                "source"
            );
          });

          buttonViewSelection(matchingAction);
        } else {
          console.log("localCurrentAction not found in action list...");
          safeStateSetter(() => {
            setActionsPopulated(true);
          });
        }
      }
    } else {
      safeStateSetter(() => {
        setErrorState(true);
      });
      console.log(
        "user having incomplete actions could not be loaded, redirect to homepage"
      );
      history.push("/dashboard");
    }
    safeStateSetter(() => {
      // console.log("end of loadActions: ", Date.now() - startTime);
      setLoading(false);
    });
    //
    loadActionsBackground();
  };
  //
  const loadActionsBackground = async () => {
    // THIS IS ALMOST THE SAME AS LOADACTIONS, BUT WORKS WITH THE FULL DATABASE OF DEALERSHIP CUSTOMERS

    // const startTime = Date.now();

    const userLoaded = await userContext.getFullUser();

    // console.log("userLoaded: ", Date.now() - startTime);

    // PHASE 1: actions
    if (
      userLoaded &&
      userLoaded.incompleteActions &&
      userLoaded.incompleteActions[0]
    ) {
      // User rights management
      if (userLoaded.user.role === "receptionist") {
        history.push("/dashboard");
        return;
      }
      //
      const dealership = await userContext.getDealershipOfUser(
        userLoaded.user._id
      );
      if (dealership) {
        safeStateSetter(() => {
          // console.log("have dealership: ", Date.now() - startTime);
          setDealershipShortName(dealership.shortName);
          setEmailTemplates(dealership.emailTemplates);
          setDealershipInternetSources(dealership.possibleInternetSources);
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        console.log("could not get dealership of user, redirect to homepage");
        history.push("/");
      }

      // console.log(
      //   "about to call getSubdocumentsOfActions with: ",
      //   userLoaded.incompleteActions
      // );
      const loadedActions = await userContext.getSubdocumentsOfActions(
        userLoaded.incompleteActions
      );
      if (loadedActions) {
        // console.log("have loadedActions: ", Date.now() - startTime);
        // here is where we deal with the sort order the user has selected
        const usedVehicleActions = [];
        const newVehicleActions = [];
        const unknownVehicleActions = [];

        loadedActions.forEach((loadedAct) => {
          if (
            loadedAct.cycle &&
            loadedAct.cycle.vehicle &&
            loadedAct.cycle.vehicle.type &&
            loadedAct.cycle.vehicle.type === "used"
          ) {
            usedVehicleActions.push(loadedAct);
          } else if (
            loadedAct.cycle &&
            loadedAct.cycle.vehicle &&
            loadedAct.cycle.vehicle.type &&
            loadedAct.cycle.vehicle.type === "new"
          ) {
            newVehicleActions.push(loadedAct);
          } else {
            unknownVehicleActions.push(loadedAct);
          }
        });
        // create actionGroups
        const localActionGroups = {};

        let cycleIdsFound = [];

        loadedActions.forEach((loadedAct) => {
          if (
            Object.keys(loadedAct.cycle).length > 0 &&
            !cycleIdsFound.includes(loadedAct.cycle._id)
          ) {
            cycleIdsFound = [...cycleIdsFound, loadedAct.cycle._id];
          } else {
            cycleIdsFound = [...cycleIdsFound, ...loadedAct.customer.cycles];
          }
        });

        (async function () {
          const cycles = await getCyclesFromIds(cycleIdsFound);

          return cycles;
        })()
          .then((cycles) => {
            for (let i = 0; i < loadedActions.length; i++) {
              const loadedAct = loadedActions[i];

              // console.log(cycles);
              // console.log(loadedAct);

              if (Object.keys(loadedAct.cycle).length < 1) {
                // sold actions

                // async time

                let mostRecentlyUpdatedCycle;

                const cyclesOfThisCustomer = [];

                cycles.forEach((cy) => {
                  // console.log(cy.customer, loadedAct.customer._id);
                  if (cy.customer === loadedAct.customer._id) {
                    cyclesOfThisCustomer.push(cy);
                  } else {
                  }
                });

                cyclesOfThisCustomer.forEach((cy) => {
                  if (!mostRecentlyUpdatedCycle) {
                    mostRecentlyUpdatedCycle = cy;
                  }
                  if (cy.storeVisits) {
                    if (
                      cy.storeVisits.length > 0 &&
                      new Date(mostRecentlyUpdatedCycle.updatedAt).getTime() <
                        new Date(cy.updatedAt).getTime()
                    ) {
                      mostRecentlyUpdatedCycle = cy;
                    }
                  } else {
                    cy.storeVisits = [];
                  }
                });

                if (!cyclesOfThisCustomer.length) {
                  console.log(loadedAct.customer._id);
                  continue;
                }

                if (
                  !localActionGroups[mostRecentlyUpdatedCycle.primarySales._id]
                ) {
                  localActionGroups[mostRecentlyUpdatedCycle.primarySales._id] =
                    [];
                  localActionGroups[
                    mostRecentlyUpdatedCycle.primarySales._id
                  ].push(loadedAct);
                } else {
                  localActionGroups[
                    mostRecentlyUpdatedCycle.primarySales._id
                  ].push(loadedAct);
                }
              } else {
                // unsold actions
                // console.log("not sold");
                if (!localActionGroups[loadedAct.cycle.primarySales]) {
                  localActionGroups[loadedAct.cycle.primarySales] = [];
                  localActionGroups[loadedAct.cycle.primarySales].push(
                    loadedAct
                  );
                } else {
                  localActionGroups[loadedAct.cycle.primarySales].push(
                    loadedAct
                  );
                }
              }
            }
            return { cycles };
          })
          .then(({ cycles }) => {
            const namedActionGroups = {};

            for (let id in localActionGroups) {
              cycles.forEach((cy) => {
                if (cy.primarySales._id === id) {
                  if (cy.primarySales.role === "bdcmanager") {
                    namedActionGroups[`BDC`] = localActionGroups[id];
                  } else {
                    namedActionGroups[
                      `${cy.primarySales.firstName} ${cy.primarySales.lastName}`
                    ] = localActionGroups[id];
                  }
                }
              });
            }

            safeStateSetter(() => {
              setActionGroups({ All: loadedActions, ...namedActionGroups });
            });

            let localViewAsUser = localStorage.getItem("localViewAsUser");

            if (localViewAsUser) {
              const localActionGroups = {
                All: loadedActions,
                ...namedActionGroups,
              };
              safeStateSetter(() => {
                setViewAsUser(localViewAsUser);
                setCurrentActions(localActionGroups[localViewAsUser]);
              });
            } else {
              safeStateSetter(() => {
                setViewAsUser("All");
                setCurrentActions(loadedActions);
              });
            }
          });

        usedVehicleActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });

        newVehicleActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });

        unknownVehicleActions.sort((a, b) => {
          if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
            return 1;
          } else {
            return -1;
          }
        });
        if (
          !localStorage.getItem("sortOrder") ||
          localStorage.getItem("sortOrder") === "alphabetical"
        ) {
          safeStateSetter(() => {
            setSortOrder("alphabetical-a-z");
          });

          loadedActions.sort((a, b) => {
            if (a.customer.lastName > b.customer.lastName) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (localStorage.getItem("sortOrder") === "oldest-first") {
          safeStateSetter(() => {
            setSortOrder("due-date-oldest");
          });
          // due date oldest first
          loadedActions.sort((a, b) => {
            if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
              return 1;
            } else {
              return -1;
            }
          });
        } else if (localStorage.getItem("sortOrder") === "newest-first") {
          safeStateSetter(() => {
            setSortOrder("due-date-newest");
          });
          // due date newest first
          loadedActions.sort((a, b) => {
            if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
              return -1;
            } else {
              return 1;
            }
          });
        } else if (localStorage.getItem("sortOrder") === "used-first") {
          safeStateSetter(() => {
            setSortOrder("type-used-first");
          });
          // vehicle type used
          for (let i = 0; i < loadedActions.length; i++) {
            loadedActions.splice(i, 1);
            if (loadedActions.length > 0) {
              i--;
            }
          }

          usedVehicleActions.forEach((usedAct) => {
            loadedActions.push(usedAct);
          });

          newVehicleActions.forEach((newAct) => {
            loadedActions.push(newAct);
          });

          unknownVehicleActions.forEach((unknownAct) => {
            loadedActions.push(unknownAct);
          });
        } else if (localStorage.getItem("sortOrder") === "new-first") {
          safeStateSetter(() => {
            setSortOrder("type-new-first");
          });
          // vehicle type new
          for (let i = 0; i < loadedActions.length; i++) {
            loadedActions.splice(i, 1);
            if (loadedActions.length > 0) {
              i--;
            }
          }

          newVehicleActions.forEach((newAct) => {
            loadedActions.push(newAct);
          });

          usedVehicleActions.forEach((usedAct) => {
            loadedActions.push(usedAct);
          });

          unknownVehicleActions.forEach((unknownAct) => {
            loadedActions.push(unknownAct);
          });
        } else if (localStorage.getItem("sortOrder") === "cycle-oldest") {
          //
          safeStateSetter(() => {
            setSortOrder("cycle-oldest");
          });
          // oldest cycle first
          const noCycle = [];
          const hasCycle = [];
          loadedActions.forEach((ac) => {
            if (!ac.cycle._id) {
              noCycle.push(ac);
            } else {
              hasCycle.push(ac);
            }
          });

          // this means that anything without an active cycle (sold-follow-up) will be last
          hasCycle.sort((a, b) => {
            if (
              new Date(a.cycle.createdAt).getTime() <
              new Date(b.cycle.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          loadedActions.splice(0, loadedActions.length);

          hasCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          noCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          // console.log(loadedActions);
        } else if (localStorage.getItem("sortOrder") === "cycle-newest") {
          //
          safeStateSetter(() => {
            setSortOrder("cycle-newest");
          });
          // oldest cycle first
          const noCycle = [];
          const hasCycle = [];
          loadedActions.forEach((ac) => {
            if (!ac.cycle._id) {
              noCycle.push(ac);
            } else {
              hasCycle.push(ac);
            }
          });

          // this means that anything without an active cycle (sold-follow-up) will be last
          hasCycle.sort((a, b) => {
            if (
              new Date(a.cycle.createdAt).getTime() >
              new Date(b.cycle.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });

          loadedActions.splice(0, loadedActions.length);

          hasCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          noCycle.forEach((ac) => {
            loadedActions.push(ac);
          });

          // console.log(loadedActions);
        }

        // sort by search result will be dealt with elsewhere
        safeStateSetter(() => {
          setCurrentUser(userLoaded);
          setActionGroups(localActionGroups);
          localStorage.setItem("localCurrentActions", loadedActions);
          setActionsPopulated(true);
        });
      } else {
        safeStateSetter(() => {
          setErrorState(true);
        });
        console.log("could not load actions of user, redirect to homepage");
        history.push("/");
      }
      //
      //
      // PHASE 2: action
      //
      //
      // if (localCurrentAction) {
      //   // this localCurrentAction should be an action with subdocuments
      //   let matchFound = false;
      //   let matchingAction = null;
      //   loadedActions.forEach((incAct) => {
      //     if (incAct._id === localCurrentAction._id) {
      //       matchFound = true;
      //       matchingAction = incAct;
      //     }
      //   });

      //   if (matchFound) {
      //     // we have the action
      //     localStorage.setItem(
      //       "localCurrentAction",
      //       JSON.stringify(matchingAction)
      //     );

      //     matchingAction.customer.emails.sort((a, b) => {
      //       if (
      //         new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
      //       ) {
      //         return -1;
      //       } else {
      //         return 1;
      //       }
      //     });

      //     matchingAction.customer.notes.sort((a, b) => {
      //       if (
      //         new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
      //       ) {
      //         return -1;
      //       } else {
      //         return 1;
      //       }
      //     });

      //     let localTexts = [];
      //     if (matchingAction.customer.texts[0]) {
      //       // we assume these texts are full objects...
      //       localTexts = matchingAction.customer.texts;
      //       localTexts.sort((a, b) => {
      //         if (parseISO(a.createdAt) > parseISO(b.createdAt)) {
      //           return 1;
      //         } else {
      //           return -1;
      //         }
      //       });
      //     }

      //     //
      //     // should we set customer context at this point?  why would we?
      //     //

      //     if (
      //       matchingAction &&
      //       matchingAction.cycle &&
      //       matchingAction.cycle.vehicle &&
      //       matchingAction.cycle.vehicle.year !== "" &&
      //       matchingAction.cycle.vehicle.make !== ""
      //     ) {
      //       const vehicleModelResults = await getModels(
      //         matchingAction.cycle.vehicle.year,
      //         matchingAction.cycle.vehicle.make
      //       );

      //       if (vehicleModelResults) {
      //         safeStateSetter(() => {
      //           setCurrentModels(vehicleModelResults);
      //           setCurrentMake(matchingAction.cycle.vehicle.make.toLowerCase());
      //           setCurrentModel(
      //             matchingAction.cycle.vehicle.model.toLowerCase()
      //           );
      //         });
      //       }
      //     } else {
      //       console.log("missing necessary information to get models");
      //     }

      //     safeStateSetter(() => {
      //       if (matchingAction && matchingAction.cycle) {
      //         setCurrentCycle(matchingAction && matchingAction.cycle);

      //         setCurrentMake(
      //           matchingAction &&
      //             matchingAction.cycle &&
      //             matchingAction.cycle.vehicle &&
      //             matchingAction.cycle.vehicle.make
      //         );

      //         if (
      //           matchingAction &&
      //           matchingAction.cycle &&
      //           matchingAction.cycle.vehicle &&
      //           matchingAction.cycle.vehicle.model &&
      //           typeof matchingAction.cycle.vehicle.model === "string"
      //         ) {
      //           setCurrentModel(
      //             matchingAction.cycle.vehicle.model.toLowerCase()
      //           );
      //         }

      //         setCurrentStock(
      //           matchingAction &&
      //             matchingAction.cycle &&
      //             matchingAction.cycle.vehicle &&
      //             matchingAction.cycle.vehicle.stock
      //         );

      //         setCurrentVehicleType(
      //           matchingAction &&
      //             matchingAction.cycle &&
      //             matchingAction.cycle.vehicle &&
      //             matchingAction.cycle.vehicle.type
      //         );

      //         setCurrentVin(
      //           matchingAction &&
      //             matchingAction.cycle &&
      //             matchingAction.cycle.vehicle &&
      //             matchingAction.cycle.vehicle.vin
      //         );

      //         setCurrentYear(
      //           matchingAction &&
      //             matchingAction.cycle &&
      //             matchingAction.cycle.vehicle &&
      //             matchingAction.cycle.vehicle.year
      //         );
      //       }
      //       setCurrentAction(matchingAction);
      //       setCurrentCustomer(matchingAction && matchingAction.customer);
      //       setCurrentEmail(
      //         matchingAction &&
      //           matchingAction.customer &&
      //           matchingAction.customer.emails[0]
      //       );
      //       setCurrentNotes(matchingAction.customer.notes);
      //       setCurrentTexts(localTexts);

      //       setSelectedType(
      //         matchingAction.cycle ? matchingAction.cycle.cycleType : "type"
      //       );
      //       setSelectedInternetSource(
      //         (matchingAction.cycle && matchingAction.cycle.internetSource) ||
      //           "source"
      //       );
      //     });

      //     buttonViewSelection(matchingAction);
      //   } else {
      //     console.log("localCurrentAction not found in action list...");
      //   }
      // }
    } else {
      safeStateSetter(() => {
        setErrorState(true);
      });
      console.log(
        "user having incomplete actions could not be loaded, redirect to homepage"
      );
      history.push("/dashboard");
    }

    //
  };
  //
  const loadAppointmentInfo = () => {
    // console.log("loadAppointmentInfo");
    // console.log("currentAction: ", currentAction);
    if (
      currentAction &&
      currentAction.cycle &&
      currentAction.cycle["appointments"][0]
    ) {
      // console.log("have appointments");
      currentAction.cycle.appointments.forEach((app) => {
        if (
          new Date().getTime() < new Date(app.date).getTime() &&
          app.status === "pending"
        ) {
          safeStateSetter(() => {
            setApptDate(
              `${format(new Date(app.date), "yyyy")}-${format(
                new Date(app.date),
                "MM"
              )}-${format(new Date(app.date), "dd")}`
            );
            setApptTime(
              `${format(new Date(app.date), "HH")}:${format(
                new Date(app.date),
                "mm"
              )}`
            );
            setApptStatus(app.status);
          });
        }
      });
    }
    safeStateSetter(() => {
      setViewAppointmentInfo(true);
    });
  };
  //

  // Here, we are only merging the new web lead customer (which already exists) and a previous customer from the database (which also already exists).  We are merging two existing customers.

  const mergeCustomerHandler = async () => {
    // now we should have the correct array of _ids in customersToMerge
    // let's start by unchecking all currently checked boxes...

    const checkBoxes = Array.from(
      document.getElementsByClassName("checkbox-clickable")
    );
    checkBoxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    });

    // first we need to send our customersToMerge to the server for actual merging of data
    // console.log("ABOUT TO MERGE CUSTOMERS");
    const mergedCustomerData = await mergeExistingCustomers([
      ...customersToMerge,
      searchedCustomerState,
    ]);

    const vehicleData = await getCurrentVehicle(mergedCustomerData.cycles);

    // dealershipContext.addDealershipCustomer(mergedCustomerData);
    //
    // next we need to set the matches without anything in the mergeArray
    // console.log("ABOUT TO SET POSSIBLE DUPLICATES");
    const correctArray = dealershipContext.purgeMerged(customersToMerge);

    if (mergedCustomerData) {
      setSearchedCustomer(
        JSON.stringify(mergedCustomerData),
        mergedCustomerData.searchedCustomerType,
        vehicleData
      );

      // console.log(mergedCustomerData);

      // dealershipContext.findMatches(mergedCustomerData);

      safeStateSetter(() => {
        setSearchedCustomerState(mergedCustomerData);
        setSearchResultsState(correctArray);
      });
    }
    // done!
  };

  //
  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };

  // EVENT HANDLERS
  //

  //
  const checkSelected = (e) => {
    const checkableActions = Array.from(
      document.querySelectorAll(".checkbox-input")
    );
    let checkedFound = false;

    const checkedActions = [];
    checkableActions.forEach((action) => {
      if (action.checked) {
        checkedFound = true;
        checkedActions.push(JSON.parse(action.id));
      }
    });
    safeStateSetter(() => {
      setSelectedActions(checkedActions);
    });
    if (checkedFound) {
      completeSelectedButton.current.classList.remove("button-normal-disabled");
    } else {
      if (
        !Array.from(completeSelectedButton.current.classList).includes(
          "button-normal-disabled"
        )
      ) {
        completeSelectedButton.current.classList.add("button-normal-disabled");
      }
    }
  };
  //
  const handleCancel = (e) => {
    let elementToCancel = e.target;

    while (!elementToCancel.id) {
      elementToCancel = elementToCancel.parentElement;
    }

    safeStateSetter(() => {
      switch (elementToCancel.id) {
        case "current-action-cancel":
          disableAllView();
          setCurrentAction(null);

          localStorage.setItem("localCurrentAction", null);
          break;
        case "actions-cancel":
          history.push("/");
          break;
        case "note-cancel":
          disableAllView();
          break;
        case "hide-view-email":
          disableAllView();
          break;
        case "view-complete-weblead-cancel":
          disableAllView();
          break;
        case "hide-compose-email-reply":
          disableAllView();
          setViewEmail(true);
          break;
        case "hide-compose-new-email":
          disableAllView();
          break;
        case "hide-text-conversation":
          localStorage.setItem("localTextCheckerCount", "1");
          clearInterval(textCheckerStatus);
          disableAllView();
          break;
        case "view-data-maintenance-cancel":
          disableAllView();
          break;
        case "hide-multi-select-config":
          disableAllView();
          break;
        case "hide-multi-text":
          disableAllView();
          break;
        case "hide-multi-email":
          disableAllView();
          break;
        case "hide-edit-customer":
          disableAllView();
          break;
        case "hide-phone-call":
          disableAllView();
          break;
        case "appointment-info-cancel":
          disableAllView();
          break;
        default:
          break;
      }
    });
  };
  //
  const handleDialogueNote = (e) => {
    safeStateSetter(() => {
      if (e.target.getAttribute("id") === "contact-bool-true") {
        setDialogueNoteBool(true);
      } else {
        setDialogueNoteBool(false);
      }
    });
  };
  //
  const handleDialogueCall = (e) => {
    safeStateSetter(() => {
      if (e.target.getAttribute("id") === "contact-bool-true") {
        setDialogueCallBool(true);
      } else {
        setDialogueCallBool(false);
      }
    });
  };
  //
  const handleDisplayChange = (e) => {
    const element = e.target;
    safeStateSetter(() => {
      switch (element.name) {
        // display major component
        case "view-email-from-note":
          const currentNote = JSON.parse(e.target.id);
          if (currentNote.emailData) {
            disableAllView();
            setCurrentEmailReplySubject(`RE: ${currentNote.emailData.subject}`);
            setCurrentEmailReply(`
  
  __________
  
  ${currentNote.emailData.emailBody}
  `);
            setCurrentEmail({
              subject: currentNote.emailData.subject,
              body: currentNote.emailData.emailBody,
            });
            setViewEmail(true);
          } else {
            console.log("display change failure: could not read emailData...");
          }

          break;
        // display major componentds
        case "view-text-conversation":
          disableAllView();
          setViewTextConversation(true);

          setTextCheckerStatus(
            setInterval(() => {
              const localTextCheckerCountStr = localStorage.getItem(
                "localTextCheckerCount"
              );

              let localTextCheckerCount;

              if (
                !localTextCheckerCountStr ||
                localTextCheckerCountStr === "200"
              ) {
                localStorage.setItem("localTextCheckerCount", "1");
                localTextCheckerCount = 1;
              } else {
                localTextCheckerCount = parseInt(localTextCheckerCountStr);
                localTextCheckerCount++;
                localStorage.setItem(
                  "localTextCheckerCount",
                  `${localTextCheckerCount}`
                );
              }
              console.log("localTextCheckerCount: ", localTextCheckerCount);

              if (localTextCheckerCount < 200) {
                if (history.location.pathname === "/actions") {
                  asyncCheckTexts();
                } else {
                  localStorage.setItem("localTextCheckerCount", "1");
                  clearInterval(textCheckerStatus);
                  window.location.reload();
                }
              } else {
                localStorage.setItem("localTextCheckerCount", "1");
                clearInterval(textCheckerStatus);
                window.location.reload();
              }
            }, 3500)
          );

          // alert(
          //   "texting is currently down for maintenance, sorry for any inconvenience!"
          // );
          break;
        // display major component
        case "view-email":
          if (currentCustomer.emailAddress) {
            switch (currentAction.actionType) {
              case "unsold-follow-up":
                disableAllView();
                setViewComposeNewEmail(true);

                break;
              case "sold-follow-up":
                disableAllView();
                setViewComposeNewEmail(true);

                break;
              case "web-lead":
                disableAllView();
                setViewCompleteWebLead(true);

                break;
              case "email-received":
                disableAllView();
                setCurrentEmailReplySubject(
                  `RE: ${currentAction.customer.emails[0].subject}`
                );
                setCurrentEmailReply(`
  
  __________
  
  ${currentAction.customer.emails[0].body}
  `);
                setViewEmail(true);
                break;
              case "text-received":
                disableAllView();
                setViewComposeNewEmail(true);
                break;
              default:
                console.log("invalid actionType...");
                break;
            }
          } else {
            console.log("customer has no email address!");
          }

          break;
        // display major component
        case "new-note":
          disableAllView();
          setNewNote(true);

          break;
        // display major component
        case "view-phone":
          disableAllView();
          setViewPhoneCall(true);
          break;
        // display major component
        case "view-compose-reply":
          disableAllView();
          setViewComposeReply(true);

          break;
        // display minor button
        case "customer-search-results":
          if (element.classList.contains("checkbox-clickable")) {
            const checkBoxes = Array.from(
              document.getElementsByClassName("checkbox-clickable")
            );
            const newArray = customersToMerge;
            let checkedBox = false;
            for (let i = 0; i < checkBoxes.length; i++) {
              const customerId = checkBoxes[i].getAttribute("id");
              if (checkBoxes[i].checked) {
                if (!newArray.includes(customerId)) {
                  newArray.push(customerId);
                }

                if (!newArray.includes(searchedCustomerState._id)) {
                  newArray.push(searchedCustomerState._id);
                }

                checkedBox = true;
              } else {
                if (newArray.includes(customerId)) {
                  newArray.splice(newArray.indexOf(customerId), 1);
                }
              }
            }
            const mergeButton = document.getElementById(
              "action-data-maintenance-merge-button"
            );
            if (checkedBox) {
              setCustomersToMerge(newArray);
              mergeButton.classList.remove("button-normal-disabled");
            } else {
              if (searchedCustomerState) {
                mergeButton.classList.add("button-normal-disabled");
              }
            }
          }

          break;
        // display major component
        case "complete-selected-button":
          disableAllView();
          setCurrentAction(null);
          localStorage.setItem("localCurrentAction", null);
          setViewMultiSelectConfig(true);

          break;
        // display major component
        case "view-compose-multi-text":
          disableAllView();
          setCurrentAction(null);
          localStorage.setItem("localCurrentAction", null);
          setViewComposeMultiText(true);
          break;
        // display major component
        case "view-compose-multi-email":
          disableAllView();
          setCurrentAction(null);
          localStorage.setItem("localCurrentAction", null);
          setViewComposeMultiEmail(true);
          break;
        // display major component
        case "view-edit-customer":
          disableAllView();
          if (
            currentCustomer &&
            currentCustomer.phoneNumber &&
            typeof currentCustomer.phoneNumber === "string"
          ) {
            if (parsePhoneNumber(currentCustomer.phoneNumber)) {
              setEditedCustomer({
                ...currentCustomer,
                phoneNumber: parsePhoneNumber(
                  currentCustomer.phoneNumber
                ).formatNational("US"),
              });
            } else {
              setEditedCustomer(currentCustomer);
            }
          } else {
            setEditedCustomer(currentCustomer);
          }

          setViewEditCustomer(true);
          break;
        default:
          break;
      }
    });
  };
  //
  const handleEditCustomer = (e) => {
    switch (e.target.name) {
      case "first-name":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, firstName: e.target.value });
        });
        break;
      case "last-name":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, lastName: e.target.value });
        });
        break;
      case "phone-number":
        const initialPhoneNumber = e.target.value;
        const digits = initialPhoneNumber.match(/\d/g);
        let phoneNumber;
        digits
          ? (phoneNumber = digits.join(""))
          : (phoneNumber = initialPhoneNumber);
        let formattedNumber = "";
        if (
          // should be formatted
          (digits && digits.length === 10 && digits[0] !== "1") ||
          (digits && digits.length === 11 && digits[0] === "1")
        ) {
          formattedNumber = new AsYouType("US").input(phoneNumber);
          const phoneObject = parsePhoneNumber(formattedNumber, "US");
          if (phoneObject && phoneObject.isValid()) {
            safeStateSetter(() => {
              setTwilioPhone(phoneObject.number);
              setEditedCustomer({
                ...editedCustomer,
                phoneNumber: parsePhoneNumber(phoneObject.number)
                  ? parsePhoneNumber(phoneObject.number).formatNational()
                  : phoneNumber,
              });
            });
          } else {
            safeStateSetter(() => {
              setEditedCustomer({ ...editedCustomer, phoneNumber });
            });
          }
        } else {
          // should not be formatted
          safeStateSetter(() => {
            setEditedCustomer({ ...editedCustomer, phoneNumber });
          });
        }
        break;
      case "email":
        if (emailValidator.validate(e.target.value)) {
          safeStateSetter(() => {
            setTwilioEmail(e.target.value);
          });
        }
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            emailAddress: e.target.value,
          });
        });
        break;
      case "zip-code":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, zipCode: e.target.value });
        });
        if (e.target.value.length === 5) {
          callGetCityStateFromZip(e.target.value);
        }
        break;
      case "address":
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            streetAddress: e.target.value,
          });
        });
        break;
      case "city":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, city: e.target.value });
        });
        break;
      case "state":
        safeStateSetter(() => {
          setEditedCustomer({ ...editedCustomer, state: e.target.value });
        });
        break;
      case "alt-phone-number":
        const initialAltPhoneNumber = e.target.value;
        const altDigits = initialAltPhoneNumber.match(/\d/g);
        let altPhoneNumber;
        altDigits
          ? (altPhoneNumber = altDigits.join(""))
          : (altPhoneNumber = initialAltPhoneNumber);
        let formattedAltNumber = "";
        if (
          // should be formatted
          (altDigits && altDigits.length === 10 && altDigits[0] !== "1") ||
          (altDigits && altDigits.length === 11 && altDigits[0] === "1")
        ) {
          formattedAltNumber = new AsYouType("US").input(altPhoneNumber);
          const altPhoneObject = parsePhoneNumber(formattedAltNumber, "US");
          if (altPhoneObject && altPhoneObject.isValid()) {
            safeStateSetter(() => {
              setTwilioAltPhone(altPhoneObject.number);
              setEditedCustomer({
                ...editedCustomer,
                altPhoneNumber: parsePhoneNumber(altPhoneObject.number)
                  ? parsePhoneNumber(altPhoneObject.number).formatNational()
                  : altPhoneNumber,
              });
            });
          } else {
            safeStateSetter(() => {
              setEditedCustomer({ ...editedCustomer, altPhoneNumber });
            });
          }
        } else {
          // should not be formatted
          safeStateSetter(() => {
            setEditedCustomer({ ...editedCustomer, altPhoneNumber });
          });
        }
        break;
      case "alt-email":
        if (emailValidator.validate(e.target.value)) {
          safeStateSetter(() => {
            setTwilioAltEmail(e.target.value);
          });
        }
        safeStateSetter(() => {
          setEditedCustomer({
            ...editedCustomer,
            altEmailAddress: e.target.value,
          });
        });

        break;
      default:
        break;
    }
  };
  //
  const handleMultiSelectMethod = (e) => {
    const localSelectedActions = selectedActions;
    const numberCurrentSelected = localSelectedActions.length;
    // there was a bug where this wasn't working properly, but it disappeared as soon as I wrote this console log...
    // console.log(
    //   "handleMultiSelectMethod: ",
    //   localSelectedActions,
    //   numberCurrentSelected
    // );
    const customers = [];
    localSelectedActions.forEach((lsa) => {
      customers.push(lsa.customer);
    });

    const currentStats = document.getElementById("current-stats");
    const emailMethodSelector = document.getElementById(
      "email-method-selector"
    );
    const methodStats = document.getElementById("method-stats");
    const textMethodSelector = document.getElementById("text-method-selector");
    const composeEmailsButton = document.getElementById(
      "compose-emails-button"
    );
    const composeTextsButton = document.getElementById("compose-texts-button");

    if (e.target.id === "text-method-selector") {
      // alert(
      //   "texting is currently down for maintenance, sorry for any inconvenience!"
      // );

      let textable = 0;
      customers.forEach((cus) => {
        if (cus.phoneNumber) {
          textable++;
        }
      });

      if (textable === 0) {
        composeTextsButton.classList.add("button-negative-disabled");
      }

      if (!Array.from(emailMethodSelector.classList).includes("hidden")) {
        emailMethodSelector.classList.add("hidden");
        methodStats.classList.remove("hidden");
        currentStats.innerHTML = `<span class="large-text">${textable}</span> of ${numberCurrentSelected} possible`;
        composeTextsButton.classList.remove("hidden");
        composeEmailsButton.classList.add("hidden");
      } else {
        emailMethodSelector.classList.remove("hidden");
        methodStats.classList.add("hidden");
        composeTextsButton.classList.add("hidden");
        composeEmailsButton.classList.add("hidden");
      }
    } else if (e.target.id === "email-method-selector") {
      let emailable = 0;
      customers.forEach((cus) => {
        if (cus.emailAddress) {
          emailable++;
        }
      });

      if (emailable === 0) {
        composeEmailsButton.classList.add("button-negative-disabled");
      }

      methodStats.classList.remove("hidden");
      currentStats.innerHTML = `<span class="large-text">${emailable}</span> of ${numberCurrentSelected} possible`;
      composeEmailsButton.classList.remove("hidden");

      if (!Array.from(textMethodSelector.classList).includes("hidden")) {
        textMethodSelector.classList.add("hidden");
        methodStats.classList.remove("hidden");
        currentStats.innerHTML = `<span class="large-text">${emailable}</span> of ${numberCurrentSelected} possible`;
        composeEmailsButton.classList.remove("hidden");
        composeTextsButton.classList.add("hidden");
      } else {
        textMethodSelector.classList.remove("hidden");
        methodStats.classList.add("hidden");
        composeTextsButton.classList.add("hidden");
        composeEmailsButton.classList.add("hidden");
      }
    } else {
      console.log("handleMultiSelectMethod: invalid element id");
    }
  };
  //
  const handleNoDuplicateFound = (e) => {
    safeStateSetter(() => {
      setViewDataMaintenance(false);
      setCurrentAction(hiddenAction);
      localStorage.setItem("localCurrentAction", JSON.stringify(hiddenAction));
      setCurrentCustomer(hiddenAction.customer);
      setCurrentNotes(hiddenAction.customer.notes);
      window.location.reload();
    });
  };
  //
  const handleSearchWithinGroup = (e) => {
    const currentIds = [];

    currentActions.forEach((curAct) => {
      currentIds.push(curAct.customer._id);
    });

    asyncSearchWithinGroup(currentIds, currentActions);
  };
  //
  const handleSortOrderChange = (e) => {
    const loadedActions = currentActions;

    switch (e.target.value) {
      case "alphabetical-a-z":
        localStorage.setItem("sortOrder", "alphabetical");
        break;
      case "due-date-oldest":
        localStorage.setItem("sortOrder", "oldest-first");
        break;
      case "due-date-newest":
        localStorage.setItem("sortOrder", "newest-first");
        break;
      case "type-used-first":
        localStorage.setItem("sortOrder", "used-first");
        break;
      case "type-new-first":
        localStorage.setItem("sortOrder", "new-first");
        break;
      case "cycle-oldest":
        localStorage.setItem("sortOrder", "cycle-oldest");
        break;
      case "cycle-newest":
        localStorage.setItem("sortOrder", "cycle-newest");
        break;
      default:
        break;
    }

    const usedVehicleActions = [];
    const newVehicleActions = [];
    const unknownVehicleActions = [];

    if (
      !localStorage.getItem("sortOrder") ||
      localStorage.getItem("sortOrder") === "alphabetical"
    ) {
      safeStateSetter(() => {
        setSortOrder("alphabetical-a-z");
      });

      loadedActions.sort((a, b) => {
        if (a.customer.lastName > b.customer.lastName) {
          return 1;
        } else {
          return -1;
        }
      });
    } else if (localStorage.getItem("sortOrder") === "oldest-first") {
      safeStateSetter(() => {
        setSortOrder("due-date-oldest");
      });
      // due date oldest first
      loadedActions.sort((a, b) => {
        if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
          return 1;
        } else {
          return -1;
        }
      });
    } else if (localStorage.getItem("sortOrder") === "newest-first") {
      safeStateSetter(() => {
        setSortOrder("due-date-newest");
      });
      // due date newest first
      loadedActions.sort((a, b) => {
        if (new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime()) {
          return -1;
        } else {
          return 1;
        }
      });
    } else if (localStorage.getItem("sortOrder") === "used-first") {
      safeStateSetter(() => {
        setSortOrder("type-used-first");
      });
      // vehicle type used
      for (let i = 0; i < loadedActions.length; i++) {
        loadedActions.splice(i, 1);
        if (loadedActions.length > 0) {
          i--;
        }
      }

      usedVehicleActions.forEach((usedAct) => {
        loadedActions.push(usedAct);
      });

      newVehicleActions.forEach((newAct) => {
        loadedActions.push(newAct);
      });

      unknownVehicleActions.forEach((unknownAct) => {
        loadedActions.push(unknownAct);
      });
    } else if (localStorage.getItem("sortOrder") === "new-first") {
      safeStateSetter(() => {
        setSortOrder("type-new-first");
      });
      // vehicle type new
      for (let i = 0; i < loadedActions.length; i++) {
        loadedActions.splice(i, 1);
        if (loadedActions.length > 0) {
          i--;
        }
      }

      newVehicleActions.forEach((newAct) => {
        loadedActions.push(newAct);
      });

      usedVehicleActions.forEach((usedAct) => {
        loadedActions.push(usedAct);
      });

      unknownVehicleActions.forEach((unknownAct) => {
        loadedActions.push(unknownAct);
      });
    } else if (localStorage.getItem("sortOrder") === "cycle-oldest") {
      //
      safeStateSetter(() => {
        setSortOrder("cycle-oldest");
      });
      // oldest cycle first
      const noCycle = [];
      const hasCycle = [];
      loadedActions.forEach((ac) => {
        if (!ac.cycle._id) {
          noCycle.push(ac);
        } else {
          hasCycle.push(ac);
        }
      });

      // this means that anything without an active cycle (sold-follow-up) will be last
      hasCycle.sort((a, b) => {
        if (
          new Date(a.cycle.createdAt).getTime() <
          new Date(b.cycle.createdAt).getTime()
        ) {
          return -1;
        } else {
          return 1;
        }
      });

      loadedActions.splice(0, loadedActions.length);

      hasCycle.forEach((ac) => {
        loadedActions.push(ac);
      });

      noCycle.forEach((ac) => {
        loadedActions.push(ac);
      });

      // console.log(loadedActions);
    } else if (localStorage.getItem("sortOrder") === "cycle-newest") {
      //
      safeStateSetter(() => {
        setSortOrder("cycle-newest");
      });
      // oldest cycle first
      const noCycle = [];
      const hasCycle = [];
      loadedActions.forEach((ac) => {
        if (!ac.cycle._id) {
          noCycle.push(ac);
        } else {
          hasCycle.push(ac);
        }
      });

      // this means that anything without an active cycle (sold-follow-up) will be last
      hasCycle.sort((a, b) => {
        if (
          new Date(a.cycle.createdAt).getTime() >
          new Date(b.cycle.createdAt).getTime()
        ) {
          return -1;
        } else {
          return 1;
        }
      });

      loadedActions.splice(0, loadedActions.length);

      hasCycle.forEach((ac) => {
        loadedActions.push(ac);
      });

      noCycle.forEach((ac) => {
        loadedActions.push(ac);
      });

      setCurrentActions(loadedActions);
    }
  };
  //
  const handleVehicleChange = (e) => {
    // handleVehicleChange changes data in the database
    const element = e.target;

    switch (element.name) {
      case "model":
        safeStateSetter(() => {
          setCurrentModel(element.value.toLowerCase());
        });

        const asyncUpdateModel = async (element, cycle) => {
          const returned = await updateVehicle(
            cycle._id,
            currentYear,
            currentMake,
            element.value,
            currentVehicleType,
            "",
            ""
          );
          if (returned) {
            safeStateSetter(() => {
              setCurrentStock("");
              setCurrentVin("");
              setActionsPopulated(false);
            });
          } else {
            console.log("updateVehicle failure - could not update model");
          }
        };

        asyncUpdateModel(element, currentAction.cycle);

        break;

      case "type":
        safeStateSetter(() => {
          setCurrentVehicleType(element.value);
        });

        const asyncUpdateType = async (element, cycle) => {
          const returned = await updateVehicle(
            cycle._id,
            currentYear,
            currentMake,
            currentModel,
            element.value,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(() => {
              setCurrentStock("");
              setCurrentVin("");
              setActionsPopulated(false);
            });
          } else {
            console.log("updateVehicle failure - could not update type");
          }
        };

        asyncUpdateType(element, currentAction.cycle);

        break;

      case "year":
        safeStateSetter(() => {
          setCurrentYear(element.value);
        });

        const asyncUpdateYear = async (element, cycle) => {
          const returned = updateVehicle(
            cycle._id,
            element.value,
            currentMake,
            "",
            currentVehicleType,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(async () => {
              setCurrentModels([]);

              if (currentMake && currentMake !== "select-make") {
                setModelsLoading(true);
                const res = await callGetModels(element.value, currentMake);
                if (res) {
                  setModelsLoading(false);
                  setCurrentModel("");
                  setActionsPopulated(false);
                }
              }
            });
          } else {
            console.log("updateVehicle failure - could not update year");
          }
        };

        asyncUpdateYear(element, currentAction.cycle);

        break;

      case "make":
        safeStateSetter(() => {
          setCurrentMake(element.value);
        });

        const asyncUpdateMake = async (element, cycle) => {
          const returned = updateVehicle(
            cycle._id,
            currentYear,
            element.value,
            "",
            currentVehicleType,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(async () => {
              setCurrentModels([]);
              if (currentYear && currentYear !== "select-year") {
                setModelsLoading(true);
                const res = await callGetModels(currentYear, element.value);
                if (res) {
                  setModelsLoading(false);
                  setCurrentModel("");
                  setActionsPopulated(false);
                }
              }
            });
          } else {
            console.log("updateVehicle failure - could not update make");
          }
        };

        asyncUpdateMake(element, currentAction.cycle);

        break;

      case "stock":
        const stock = element.value;
        safeStateSetter(() => {
          setCurrentStock(stock);
        });

        const asyncStockSearch = async (stock) => {
          const vehicle = await dealershipContext.stockSearch(stock);
          if (vehicle) {
            const returned = await updateVehicle(
              currentAction.cycle._id,
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.type,
              vehicle.stock,
              vehicle.vin
            );

            if (returned) {
              safeStateSetter(async () => {
                setCurrentVehicleType(vehicle.type);
                setCurrentYear(vehicle.year);
                setCurrentMake(vehicle.make);
                setCurrentVin(vehicle.vin);
                await callGetModels(vehicle.year, vehicle.make);
                setCurrentModel(vehicle.model.toLowerCase());
                setActionsPopulated(false);
              });
            } else {
              console.log(
                "updateVehicle failure - could not update based on stock number provided"
              );
            }
          } else {
            console.log("stock not found");
            safeStateSetter(() => {
              setCurrentVin("");
            });
          }
        };
        asyncStockSearch(stock);

        break;

      case "vin":
        const vin = element.value;
        safeStateSetter(() => {
          setCurrentVin(vin);
        });

        const asyncVinSearch = async (vin) => {
          const vehicle = await dealershipContext.vinSearch(vin);
          if (vehicle) {
            // console.log("cycle.js: ", vehicle);
            const returned = await updateVehicle(
              currentAction.cycle._id,
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.type,
              vehicle.stock,
              vehicle.vin
            );

            if (returned) {
              safeStateSetter(async () => {
                setCurrentVehicleType(vehicle.type);
                setCurrentYear(vehicle.year);
                setCurrentMake(vehicle.make);
                setCurrentModels([]);
                const models = await callGetModels(vehicle.year, vehicle.make);
                if (models) {
                  setCurrentModel(vehicle.model.toLowerCase());
                  setActionsPopulated(false);
                } else {
                  setActionsPopulated(false);
                }
              });
            } else {
              console.log(
                "updateVehicle failure - could not update based on vin provided"
              );
            }
          } else {
            console.log("vin not found");
            safeStateSetter(() => {
              setCurrentStock("");
            });
          }
        };
        asyncVinSearch(vin);

        break;

      default:
        console.log("element.name does not match any of the cases");
        break;
    }
  };
  //
  const handleViewAsChange = (e) => {
    setViewAsUser(e.currentTarget.value);
    localStorage.setItem("localViewAsUser", e.currentTarget.value);
    setCurrentActions(actionGroups[e.currentTarget.value]);
  };
  //
  const handleViewCustomer = (e) => {
    localStorage.setItem(
      "localCurrentCustomer",
      JSON.stringify(currentAction.customer)
    );

    history.push("/customer");
  };
  //
  const handleViewCycle = (e) => {
    localStorage.setItem(
      "localCurrentCustomer",
      JSON.stringify(currentAction.customer)
    );

    if (currentAction.cycle) {
      localStorage.setItem(
        "localCurrentCycle",
        JSON.stringify(currentAction.cycle)
      );

      history.push("/cycle");
    }
  };
  //
  const loadActionInfo = (e) => {
    // when we recreate loadActionInfo, our experimental implementation is `parent-${index}`
    // safeStateSetter(() => {
    //   setLoading(true);
    // });
    let actionElement = e.target;

    while (
      !actionElement.parentElement.getAttribute("id").startsWith("parent")
    ) {
      actionElement = actionElement.parentElement;
    }

    const action = JSON.parse(actionElement.id);

    safeStateSetter(() => {
      setCurrentEmailReply("");
      setCurrentEmailReplySubject("");
      setCurrentNewEmail("");
      setCurrentNewEmailSubject("");
      setCurrentTextBody("");
      setCurrentMultiEmail("");
      setCurrentMultiEmailSubject("");
      setCurrentMultiTextBody("");
      setNoteBody("");
      if (action.cycle) {
        setSelectedType(action.cycle.cycleType);
        setSelectedInternetSource(action.cycle.internetSource);
      } else {
        setSelectedType("type");
        setSelectedInternetSource("source");
      }
    });

    let localTexts = [];
    if (action.customer.texts[0]) {
      localTexts = action.customer.texts;
      localTexts.sort((a, b) => {
        if (parseISO(a.createdAt) > parseISO(b.createdAt)) {
          return 1;
        } else {
          return -1;
        }
      });
    }

    // console.log("loadActionInfo", action);

    userContext.addToLastTwoHundred(action.customer._id);

    disableAllView();
    if (action.actionType === "web-lead") {
      localStorage.setItem("localCurrentAction", null);
      safeStateSetter(() => {
        setCurrentAction(null);
      });
      // view data maintenance
      asyncLoadDataMaintenance(action);
    } else {
      localStorage.setItem("localCurrentAction", JSON.stringify(action));
      safeStateSetter(() => {
        if (action.cycle) {
          setCurrentCycle(action.cycle);
          if (action.cycle.vehicle) {
            setCurrentMake(action.cycle.vehicle.make);
            setCurrentYear(action.cycle.vehicle.year);
            (async function () {
              const actionModels = await getModels(
                action.cycle.vehicle.year,
                action.cycle.vehicle.make
              );
              if (actionModels) {
                setCurrentModels(actionModels);
              } else {
                setCurrentModels([]);
              }
            })();

            setCurrentModel(
              action.cycle.vehicle.model &&
                action.cycle.vehicle.model.toLowerCase()
            );
            setCurrentStock(action.cycle.vehicle.stock);
            setCurrentVehicleType(action.cycle.vehicle.type);
            setCurrentVin(action.cycle.vehicle.vin);
          }
          // console.log(action);
        }
        setCurrentAction(action);
        setCurrentCustomer(action.customer);
        setCurrentEmail(action.customer.emails[0]);
        setCurrentNotes(
          action.customer.notes.sort((a, b) => {
            if (
              new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ) {
              // don't switch
              return -1;
            } else {
              // switch
              return 1;
            }
          })
        );
        setCurrentTexts(localTexts);
      });

      buttonViewSelection(action);
    }
  };
  //
  const populateTemplate = (e) => {
    if (!e.currentTarget.value || e.currentTarget.value === "none") {
      console.log('!e.currentTarget.value || e.currentTarget.value === "none"');
      console.log("e.target.value: ", e.target.value);
      safeStateSetter(() => {
        setCurrentNewEmailSubject("");
        setCurrentNewEmail("");
      });
    } else {
      // console.log(e.currentTarget.value);

      const templateObj = JSON.parse(e.currentTarget.value);

      // console.log(templateObj);
      safeStateSetter(() => {
        setCurrentNewEmailSubject(templateObj.subject);
        setCurrentNewEmail(templateObj.text);
      });
    }
  };
  //
  const saveAppointment = async () => {
    if (apptDate || apptTime || apptStatus) {
      // console.log(apptDate, apptTime, apptStatus);

      const trueDate = new Date(`${apptDate}T${apptTime}`).toUTCString();

      const apptSaved = await saveAppt(
        currentCustomer,
        {
          date: trueDate,
          time: apptTime,
          status: apptStatus,
          setter: userContext.user._id,
        },
        currentCycle
      );

      if (apptSaved) {
        safeStateSetter(() => {
          setApptDate("");
          setApptStatus("");
          setApptTime("");
          disableAllView();
          setActionsPopulated(false);
        });
      }
      //
      //
      //
    }
  };
  //
  const saveEditedCustomerHandler = (e) => {
    e.preventDefault();
    const finalEditedCustomer = { ...editedCustomer };
    if (twilioPhone) {
      finalEditedCustomer["phoneNumber"] = twilioPhone;
    }
    if (twilioAltPhone) {
      finalEditedCustomer["altPhoneNumber"] = twilioAltPhone;
    }
    if (twilioEmail) {
      finalEditedCustomer["emailAddress"] = twilioEmail;
    }
    if (twilioAltEmail) {
      finalEditedCustomer["altEmailAddress"] = twilioAltEmail;
    }

    // I think this code lets me get rid of the twilio fields
    if (
      finalEditedCustomer["phoneNumber"] &&
      typeof finalEditedCustomer["phoneNumber"] === "string" &&
      !parsePhoneNumber(finalEditedCustomer["phoneNumber"])
    ) {
      const phoneObj = parsePhoneNumber(
        finalEditedCustomer["phoneNumber"],
        "US"
      );
      finalEditedCustomer["phoneNumber"] = phoneObj.number;
    }

    if (
      finalEditedCustomer["altPhoneNumber"] &&
      typeof finalEditedCustomer["altPhoneNumber"] === "string" &&
      !parsePhoneNumber(finalEditedCustomer["altPhoneNumber"])
    ) {
      const altPhoneObj = parsePhoneNumber(
        finalEditedCustomer["altPhoneNumber"],
        "US"
      );
      finalEditedCustomer["altPhoneNumber"] = altPhoneObj.number;
    }

    (async () => {
      try {
        // edit database here

        const customerSaved = await saveEditedCustomer(finalEditedCustomer);

        if (customerSaved) {
          disableAllView();
          safeStateSetter(() => {
            setActionsPopulated(false);
          });
        } else {
          throw new Error("could not save edited customer...");
        }
      } catch (error) {
        console.log("Actions.asyncSaveEditedCustomer: ", error.message);
      }
    })();
  };
  //
  const saveNote = (e) => {
    // saveNote changes data in the database
    if (noteBody !== "") {
      const enteredNote = {
        customer: currentCustomer._id,
        noteType: "actioncomplete",
        dialogue: dialogueNoteBool,
        body: noteBody,
        writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
        user: userContext.user._id,
      };

      const asyncSaveNote = async () => {
        const noteAdded = await addNote(enteredNote);

        const actionCompleted = await completeAction(currentAction._id);

        console.log(actionCompleted);

        safeStateSetter(() => {
          if (noteAdded && actionCompleted) {
            console.log("noteAdded and actionCompleted are TRUE");
            disableAllView();
            setCurrentAction(null);
            const curActs = currentActions.filter(
              (ac) => ac._id !== currentAction._id
            );
            console.log("curActs = ", curActs.length);
            if (curActs.length) {
              setCurrentActions(curActs);
            } else {
              window.location.reload();
            }
            setNoteBody("");
          }
        });
      };
      asyncSaveNote();
    } else {
      console.log("ERROR: data missing, could not save note");
    }
  };
  //
  const sendMultiEmailHandler = (e) => {
    if (selectedActions[0]) {
      asyncSendMultiEmail(
        selectedActions,
        currentMultiEmailSubject,
        currentMultiEmail
      );
    } else {
      console.log("data missing: could not send texts...");
    }
  };
  //
  const sendNewEmail = (e) => {
    console.log("hello from sendNewEmail");
    if (
      currentAction.customer.emailAddress &&
      currentNewEmailSubject &&
      currentNewEmail
    ) {
      asyncSendNewEmail(currentAction, currentNewEmailSubject, currentNewEmail);
    }
  };
  //
  const savePhoneCall = (e) => {
    // savePhoneCall changes data in the database
    if (phoneCallBody !== "") {
      const enteredNote = {
        customer: currentCustomer._id,
        noteType: "actioncomplete",
        dialogue: dialogueCallBool,
        body: phoneCallBody,
        writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
        user: userContext.user._id,
      };

      const asyncSaveNote = async () => {
        const noteAdded = await addNote(enteredNote);

        const actionCompleted = await completeAction(currentAction._id);

        safeStateSetter(() => {
          if (noteAdded && actionCompleted) {
            disableAllView();
            setCurrentAction(null);
            const curActs = currentActions.filter(
              (ac) => ac._id !== currentAction._id
            );
            if (curActs.length) {
              setCurrentActions(curActs);
            } else {
              window.location.reload();
            }
            setPhoneCallBody("");
          }
        });
      };
      asyncSaveNote();
    } else {
      console.log("ERROR: data missing, could not save note");
    }
  };
  //
  const sendReplyHandler = (e) => {
    // console.log("sendReply front");
    if (
      currentAction &&
      currentAction.customer &&
      currentAction.customer.emailAddress &&
      currentEmailReplySubject &&
      currentEmailReply
    ) {
      // console.log("about to call asyncSendReply");
      asyncSendReply(
        currentAction,
        currentEmailReplySubject,
        currentEmailReply
      );
    } else {
      console.log("data missing: could not send reply...");
    }
  };
  //
  const sendTextHandler = (e) => {
    if (
      currentAction &&
      currentAction.customer &&
      currentAction.customer.phoneNumber
    ) {
      e.currentTarget.style.visibility = "hidden";
      asyncSendText(currentAction, currentTextBody);
    } else {
      console.log("data missing: could not send text...");
    }
  };
  //
  const sendMultiTextHandler = (e) => {
    if (selectedActions[0]) {
      asyncSendMultiText(selectedActions, currentMultiTextBody);
    } else {
      console.log("data missing: could not send texts...");
    }
  };
  //
  const updateAppointment = (e) => {
    safeStateSetter(() => {
      switch (e.target.name) {
        case "appt-date":
          setApptDate(e.target.value);

          break;
        case "appt-time":
          setApptTime(e.target.value);

          break;
        case "appt-status":
          // console.log(e.target.value);
          setApptStatus(e.target.value);

          break;

        default:
          break;
      }
    });
  };
  //
  const updateCurrentEmailReply = (e) => {
    safeStateSetter(() => {
      setCurrentEmailReply(e.target.value);
    });
  };
  //
  const updateCurrentEmailReplySubject = (e) => {
    safeStateSetter(() => {
      setCurrentEmailReplySubject(e.target.value);
    });
  };
  //
  const updateCurrentNewEmail = (e) => {
    safeStateSetter(() => {
      setCurrentNewEmail(e.target.value);
    });
  };
  //
  const updateCurrentNewEmailSubject = (e) => {
    safeStateSetter(() => {
      setCurrentNewEmailSubject(e.target.value);
    });
  };
  //
  const updateCurrentMultiEmail = (e) => {
    safeStateSetter(() => {
      setCurrentMultiEmail(e.target.value);
    });
  };
  //
  const updateCurrentMultiEmailSubject = (e) => {
    safeStateSetter(() => {
      setCurrentMultiEmailSubject(e.target.value);
    });
  };
  //
  const updateCurrentMultiTextBody = (e) => {
    safeStateSetter(() => {
      setCurrentMultiTextBody(e.target.value);
    });
  };
  //
  const updateCurrentTextBody = (e) => {
    safeStateSetter(() => {
      setCurrentTextBody(e.target.value);
    });
  };
  //
  const updateCycleType = (e) => {
    const type = e.currentTarget.value;

    cycleTypeUpdate(type, currentCycle, setSelectedType);
  };
  //
  const updateInternetSource = (e) => {
    const source = e.currentTarget.value;

    internetSourceUpdate(source, currentCycle, setSelectedInternetSource);
  };
  //
  const updateNote = (e) => {
    safeStateSetter(() => {
      setNoteBody(e.target.value);
    });
    const saveNoteButton = document.getElementById("save-new-note");
    if (e.target.value !== "") {
      saveNoteButton.classList.remove("button-normal-disabled");
    } else {
      saveNoteButton.classList.add("button-normal-disabled");
    }
  };
  //
  const updatePhoneCall = (e) => {
    safeStateSetter(() => {
      setPhoneCallBody(e.target.value);
    });
    const savePhoneCallButton = document.getElementById("save-new-phone-call");
    if (e.target.value !== "") {
      savePhoneCallButton.classList.remove("button-normal-disabled");
    } else {
      savePhoneCallButton.classList.add("button-normal-disabled");
    }
  };
  //
  const updateSearchText = (e) => {
    safeStateSetter(() => {
      if (e.target.value && e.target.value !== "") {
        setActionSearchTextButtonViewable(true);
      } else {
        setActionSearchTextButtonViewable(false);
      }
      setSearchText(e.target.value);
    });
  };
  //

  // JSX

  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <main className="page-column flex-row-center-center" ref={mainBodyRef}>
          <div className="page-row-1050 flex-grow">
            <div
              className="popup-content popup-content-special"
              id="popup-special-action-list"
            >
              <div className="popup-header">
                <p className="large-text white-text center-text flex-grow">
                  Action List
                </p>
                <div onClick={handleCancel} id="actions-cancel">
                  <svg className="icon-small icon-small-back">
                    <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                  </svg>
                </div>
              </div>
              <div className="popup-body popup-body-between">
                {currentUser && currentUser.role !== "salesperson" && (
                  <div className="form-row">
                    <label
                      htmlFor="View As"
                      className="small-text dark-blue-text margin-right-large"
                    >
                      View As:
                    </label>
                    <select
                      name="View As"
                      id="View As"
                      className="form-select small-text dark-blue-green-text flex-grow"
                      value={viewAsUser}
                      onChange={handleViewAsChange}
                    >
                      {Object.keys(actionGroups).map((userName, idx) => (
                        <option key={idx} value={userName}>
                          {userName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="form-row">
                  <label
                    htmlFor="primary-sort"
                    className="small-text dark-blue-text margin-right-large"
                  >
                    Primary Sort:
                  </label>
                  <select
                    name="primary-sort"
                    id="primary-sort"
                    className="form-select small-text dark-blue-green-text flex-grow"
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                  >
                    <option value="alphabetical-a-z">Alphabetical</option>
                    <option value="due-date-oldest">
                      Due Date (Oldest First)
                    </option>
                    <option value="due-date-newest">
                      Due Date (Newest First)
                    </option>
                    <option value="type-used-first">
                      Vehicle Type (Used First)
                    </option>
                    <option value="type-new-first">
                      Vehicle Type (New First)
                    </option>
                    <option value="cycle-oldest">
                      Cycle Age (Oldest First)
                    </option>
                    <option value="cycle-newest">
                      Cycle Age (Newest First)
                    </option>
                  </select>
                </div>
                <div className="form-row">
                  <input
                    type="text"
                    className="form-field small-text dark-blue-green-text flex-grow margin-right-large"
                    placeholder="Sort by search result..."
                    onChange={updateSearchText}
                    value={searchText}
                  />
                  {actionSearchTextButtonViewable ? (
                    <button
                      className="button-normal button-normal-smaller small-text"
                      onClick={handleSearchWithinGroup}
                    >
                      Search
                    </button>
                  ) : (
                    <button
                      className="button-normal button-normal-smaller small-text button-normal-disabled"
                      onClick={handleSearchWithinGroup}
                    >
                      Search
                    </button>
                  )}
                </div>
                <div className="popup-body-container flex-grow margin-bottom-small">
                  {currentActions &&
                    Array.isArray(currentActions) &&
                    currentActions.map((incAction, index) => {
                      return (
                        <div
                          className="module-checkbox-container"
                          key={index}
                          id={`parent-${index}`}
                        >
                          <div className="custom-checkbox">
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              name="selected-actions"
                              id={JSON.stringify(incAction)}
                              onChange={checkSelected}
                            />
                            <div className="checkbox margin-right-small margin-bottom-small small-text center-text"></div>
                          </div>
                          {incAction.cycle.hot ? (
                            <div
                              className="module module-full-width module-bottom-margin module-special module-special-hot"
                              id={JSON.stringify(incAction)}
                              onClick={loadActionInfo}
                            >
                              {incAction.actionType === "sold-follow-up" && (
                                <div className="module-section module-section-elevated white-text center-text">
                                  {`${incAction.actionType.split("-")[0]} ${
                                    incAction.actionType.split("-")[1]
                                  } ${
                                    incAction.actionType.split("-")[2]
                                      ? incAction.actionType.split("-")[2]
                                      : ""
                                  }`}
                                </div>
                              )}
                              {(incAction.actionType === "text-received" ||
                                incAction.actionType === "email-received") && (
                                <div className="module-section module-section-urgent white-text center-text">
                                  {`${incAction.actionType.split("-")[0]} ${
                                    incAction.actionType.split("-")[1]
                                  } ${
                                    incAction.actionType.split("-")[2]
                                      ? incAction.actionType.split("-")[2]
                                      : ""
                                  }`}
                                </div>
                              )}
                              {(incAction.actionType === "web-lead" ||
                                incAction.actionType === "vehicle-match") && (
                                <div className="module-section module-section-negative white-text center-text">
                                  {`${incAction.actionType.split("-")[0]} ${
                                    incAction.actionType.split("-")[1]
                                  } ${
                                    incAction.actionType.split("-")[2]
                                      ? incAction.actionType.split("-")[2]
                                      : ""
                                  }`}
                                </div>
                              )}
                              {incAction.actionType !== "sold-follow-up" &&
                                incAction.actionType !== "web-lead" &&
                                incAction.actionType !== "vehicle-match" &&
                                incAction.actionType !== "text-received" &&
                                incAction.actionType !== "email-received" && (
                                  <div className="module-section module-section-normal white-text center-text">
                                    {`${incAction.actionType.split("-")[0]} ${
                                      incAction.actionType.split("-")[1]
                                    } ${
                                      incAction.actionType.split("-")[2]
                                        ? incAction.actionType.split("-")[2]
                                        : ""
                                    }`}
                                  </div>
                                )}

                              {incAction.customer.face && (
                                <img
                                  className="avatar-medium-small margin-right-tiny margin-left-small"
                                  src={`data:image/jpeg;base64,${incAction.customer.face}`}
                                  alt="customer_face"
                                />
                              )}

                              <div
                                className="module-main module-main-right"
                                id="not-here"
                              >
                                <div className="dark-blue-text">
                                  {incAction.customer.firstName &&
                                    `${incAction.customer.firstName} ${incAction.customer.lastName}`}
                                </div>
                                {incAction.cycle._id && (
                                  <div className="dark-blue-text">
                                    Source:{" "}
                                    {incAction.cycle.cycleType !== "internet"
                                      ? incAction.cycle.cycleType
                                      : incAction.cycle.internetSource}
                                  </div>
                                )}
                                {incAction.cycle._id && (
                                  <div className="dark-blue-text">
                                    Cycle Age:{" "}
                                    {`${differenceInDays(
                                      new Date(),
                                      new Date(
                                        incAction.cycle &&
                                          incAction.cycle.createdAt
                                      )
                                    )} days`}
                                  </div>
                                )}

                                <div className="dark-blue-text">
                                  Due:{" "}
                                  {format(new Date(incAction.dueDate), "P")}
                                </div>
                                {incAction.cycle.active && (
                                  <div className="dark-blue-text">
                                    Sales: {incAction.cycle.salesPersonName}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="module module-full-width module-bottom-margin module-special"
                              id={JSON.stringify(incAction)}
                              onClick={loadActionInfo}
                            >
                              {incAction.actionType === "sold-follow-up" && (
                                <div className="module-section module-section-elevated white-text center-text">
                                  {`${incAction.actionType.split("-")[0]} ${
                                    incAction.actionType.split("-")[1]
                                  } ${
                                    incAction.actionType.split("-")[2]
                                      ? incAction.actionType.split("-")[2]
                                      : ""
                                  }`}
                                </div>
                              )}

                              {(incAction.actionType === "text-received" ||
                                incAction.actionType === "email-received") && (
                                <div className="module-section module-section-urgent white-text center-text">
                                  {`${incAction.actionType.split("-")[0]} ${
                                    incAction.actionType.split("-")[1]
                                  } ${
                                    incAction.actionType.split("-")[2]
                                      ? incAction.actionType.split("-")[2]
                                      : ""
                                  }`}
                                </div>
                              )}

                              {(incAction.actionType === "web-lead" ||
                                incAction.actionType === "vehicle-match") && (
                                <div className="module-section module-section-negative white-text center-text">
                                  {`${incAction.actionType.split("-")[0]} ${
                                    incAction.actionType.split("-")[1]
                                  } ${
                                    incAction.actionType.split("-")[2]
                                      ? incAction.actionType.split("-")[2]
                                      : ""
                                  }`}
                                </div>
                              )}

                              {incAction.actionType !== "sold-follow-up" &&
                                incAction.actionType !== "web-lead" &&
                                incAction.actionType !== "vehicle-match" &&
                                incAction.actionType !== "text-received" &&
                                incAction.actionType !== "email-received" && (
                                  <div className="module-section module-section-normal white-text center-text">
                                    {`${incAction.actionType.split("-")[0]} ${
                                      incAction.actionType.split("-")[1]
                                    } ${
                                      incAction.actionType.split("-")[2]
                                        ? incAction.actionType.split("-")[2]
                                        : ""
                                    }`}
                                  </div>
                                )}
                              {incAction.customer.face && (
                                <img
                                  className="avatar-medium-small margin-right-tiny margin-left-small"
                                  src={`data:image/jpeg;base64,${incAction.customer.face}`}
                                  alt="customer_face"
                                />
                              )}
                              <div
                                className="module-main module-main-right"
                                id="not-here"
                              >
                                <div className="dark-blue-text">
                                  {incAction.customer.firstName &&
                                    `${incAction.customer.firstName} ${incAction.customer.lastName}`}
                                </div>
                                {incAction.cycle._id && (
                                  <div className="dark-blue-text">
                                    Source:{" "}
                                    {incAction.cycle.cycleType !== "internet"
                                      ? incAction.cycle.cycleType
                                      : incAction.cycle.internetSource}
                                  </div>
                                )}
                                {incAction.cycle._id && (
                                  <div className="dark-blue-text">
                                    Cycle Age:{" "}
                                    {`${differenceInDays(
                                      new Date(),
                                      new Date(
                                        incAction.cycle &&
                                          incAction.cycle.createdAt
                                      )
                                    )} days`}
                                  </div>
                                )}

                                <div className="dark-blue-text">
                                  Due:{" "}
                                  {format(new Date(incAction.dueDate), "P")}
                                </div>

                                {incAction.cycle.active && (
                                  <div className="dark-blue-text">
                                    Sales: {incAction.cycle.salesPersonName}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className="form-row">
                  <button
                    className="button-normal button-normal-disabled medium-large-text flex-grow"
                    name="complete-selected-button"
                    ref={completeSelectedButton}
                    onClick={(e) => {
                      if (
                        !Array.from(e.target.classList).includes(
                          "button-normal-disabled"
                        )
                      ) {
                        handleDisplayChange(e);
                      }
                    }}
                  >
                    Complete Group
                  </button>
                </div>
              </div>
            </div>

            {currentAction && (
              <CurrentActionViewer
                commonMakes={commonMakes}
                currentAction={currentAction}
                currentCustomer={currentCustomer}
                currentMake={currentMake}
                currentModel={currentModel}
                currentModels={currentModels}
                currentNotes={currentNotes}
                currentStock={currentStock}
                currentVehicleType={currentVehicleType}
                currentVin={currentVin}
                currentYear={currentYear}
                dealershipInternetSources={dealershipInternetSources}
                handleCancel={handleCancel}
                handleDisplayChange={handleDisplayChange}
                handleVehicleChange={handleVehicleChange}
                handleViewCustomer={handleViewCustomer}
                handleViewCycle={handleViewCycle}
                loadAppointmentInfo={loadAppointmentInfo}
                modelsLoading={modelsLoading}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                selectedInternetSource={selectedInternetSource}
                setSelectedInternetSource={setSelectedInternetSource}
                updateCycleType={updateCycleType}
                updateInternetSource={updateInternetSource}
                userLoaded={currentUser}
                viewEmailButton={viewEmailButton}
                viewPhoneButton={viewPhoneButton}
                viewTextButton={viewTextButton}
                years={years}
              />
            )}

            {newNote && (
              <NoteWriter
                dialogueBool={dialogueNoteBool}
                handleCancel={handleCancel}
                handleDialogueNote={handleDialogueNote}
                heading="Action Note"
                noteBody={noteBody}
                saveNote={saveNote}
                updateNote={updateNote}
              />
            )}

            {viewEmail && (
              <EmailViewer
                currentCustomer={currentCustomer}
                currentEmail={currentEmail}
                handleCancel={handleCancel}
                handleDisplayChange={handleDisplayChange}
                special={true}
              />
            )}

            {viewComposeReply && (
              <EmailReplyComposer
                currentCustomer={currentCustomer}
                currentEmailReply={currentEmailReply}
                currentEmailReplySubject={currentEmailReplySubject}
                handleCancel={handleCancel}
                sendReplyHandler={sendReplyHandler}
                special={true}
                updateCurrentEmailReply={updateCurrentEmailReply}
                updateCurrentEmailReplySubject={updateCurrentEmailReplySubject}
              />
            )}

            {viewComposeNewEmail && (
              <NewEmailComposer
                currentAction={currentAction}
                currentCustomer={currentCustomer}
                currentNewEmail={currentNewEmail}
                currentNewEmailSubject={currentNewEmailSubject}
                emailTemplates={emailTemplates}
                handleCancel={handleCancel}
                populateTemplate={populateTemplate}
                sendNewEmail={sendNewEmail}
                special={true}
                updateCurrentNewEmail={updateCurrentNewEmail}
                updateCurrentNewEmailSubject={updateCurrentNewEmailSubject}
              />
            )}

            {viewCompleteWebLead && (
              <WebLeadResponder
                currentCustomer={currentAction.customer}
                currentNewEmail={currentNewEmail}
                currentNewEmailSubject={currentNewEmailSubject}
                emailTemplates={emailTemplates}
                handleCancel={handleCancel}
                populateTemplate={populateTemplate}
                sendNewEmail={sendNewEmail}
                updateCurrentNewEmail={updateCurrentNewEmail}
                updateCurrentNewEmailSubject={updateCurrentNewEmailSubject}
              />
            )}

            {viewDataMaintenance && (
              <ActionDataMaintenance
                handleCancel={handleCancel}
                handleDisplayChange={handleDisplayChange}
                handleNoDuplicateFound={handleNoDuplicateFound}
                mergeCustomerHandler={mergeCustomerHandler}
                searchedCustomerState={searchedCustomerState}
                searchResultsState={searchResultsState}
              />
            )}

            {viewTextConversation && (
              <ActionText
                currentCustomer={currentCustomer}
                currentAction={currentAction}
                currentTextBody={currentTextBody}
                currentTexts={currentTexts}
                handleCancel={handleCancel}
                sendTextHandler={sendTextHandler}
                updateCurrentTextBody={updateCurrentTextBody}
              />
            )}

            {viewMultiSelectConfig && (
              <MultiSelectConfig
                handleCancel={handleCancel}
                handleDisplayChange={handleDisplayChange}
                handleMultiSelectMethod={handleMultiSelectMethod}
              />
            )}

            {viewComposeMultiText && (
              <ComposeMultiText
                currentMultiTextBody={currentMultiTextBody}
                handleCancel={handleCancel}
                sendMultiTextHandler={sendMultiTextHandler}
                updateCurrentMultiTextBody={updateCurrentMultiTextBody}
              />
            )}

            {viewComposeMultiEmail && (
              <ComposeMultiEmail
                currentMultiEmail={currentMultiEmail}
                currentMultiEmailSubject={currentMultiEmailSubject}
                handleCancel={handleCancel}
                sendMultiEmailHandler={sendMultiEmailHandler}
                updateCurrentMultiEmail={updateCurrentMultiEmail}
                updateCurrentMultiEmailSubject={updateCurrentMultiEmailSubject}
              />
            )}

            {viewEditCustomer && (
              <CustomerEditor
                editedCustomer={editedCustomer}
                handleCancel={handleCancel}
                handleEditCustomer={handleEditCustomer}
                saveEditedCustomerHandler={saveEditedCustomerHandler}
                special={true}
              />
            )}

            {viewPhoneCall && (
              <LogPhoneCall
                handleCancel={handleCancel}
                handleDialogueCall={handleDialogueCall}
                phoneCallBody={phoneCallBody}
                savePhoneCall={savePhoneCall}
                special={true}
                updatePhoneCall={updatePhoneCall}
              />
            )}

            {viewAppointmentInfo && (
              <ActionAppointmentInfo
                apptDate={apptDate}
                apptStatus={apptStatus}
                apptTime={apptTime}
                currentCustomer={currentCustomer}
                handleCancel={handleCancel}
                saveAppointment={saveAppointment}
                updateAppointment={updateAppointment}
              />
            )}
          </div>
        </main>
      )}
    </Fragment>
  );
};

export default Actions;
