export const simpleEncrypt = (str) => {
  // THIS IS ***NOT*** SECURE WHATSOEVER - IT'S ONLY DESIGNED TO MAKE THE HTML HARDER TO READ
  // keep working on this

  const reversed = str.split("").reverse();

  const revisedReversed = [];
  reversed.forEach((char, idx) => {
    const honeyPotArr = [];
    for (let i = 0; i < 10; i++) {
      let cur = Math.round(Math.random() * 10);
      honeyPotArr.push(cur);
    }
    honeyPotArr.splice(0, 0, `%@@%@@%`);
    honeyPotArr.push(`@%%@%%@`);
    const honeyPotStr = honeyPotArr.join("");
    let random = Math.round(Math.random() * 7);
    random = random += 27;
    if (idx % random === 0) {
      revisedReversed.push(honeyPotStr);
      revisedReversed.push(char);
    } else {
      revisedReversed.push(char);
    }
  });
  return revisedReversed.join("");
};

export const simpleDecrypt = (str) => {
  let localStr = str;
  const myRegEx = /%@@%@@%\d+@%%@%%@/g;
  while (localStr.match(myRegEx) && localStr.match(myRegEx).length > 0) {
    const curStr = localStr.match(myRegEx)[0];
    localStr = localStr.replace(curStr, "");
  }
  let localArr = localStr.split("");
  return localArr.reverse().join("");
};
