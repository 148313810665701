import React, { useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";

const VehicleFeed = () => {
  const passcode = useRef();
  const username = useRef();
  const csv = useRef();
  const dealership = useRef();

  const sendInventory = async (passcode, username, dealership, csv) => {
    try {
      if (!passcode || !username || !csv) {
        throw new Error("missing required field!");
      }

      const res = await axios.post(
        `/api/v1/vehicle/vehicle-uploader`,
        { passcode, username, dealership, csv },
        axiosConfig
      );

      if (res.data.success) {
        alert("Vehicles Successfully Uploaded");
        window.location.reload();
      } else {
        throw new Error(`Could not upload`);
      }
    } catch (error) {
      alert(error.message);
      window.location.reload();
    }
  };

  const handleSend = (e) => {
    if (
      !csv.current.files[0] ||
      !passcode.current.value ||
      !username.current.value ||
      !dealership.current.value
    ) {
      alert("missing required fields!");
    } else {
      let reader = new FileReader();

      reader.readAsDataURL(csv.current.files[0]);

      reader.onloadend = async () => {
        const result = reader.result.split(",")[1];
        sendInventory(
          passcode.current.value,
          username.current.value,
          dealership.current.value,
          result
        );
      };
    }
  };

  return (
    <div className="popup" id="popup-appointment-conditional">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Vehicle Uploader
          </p>
          <Link to="/">
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </Link>
        </div>
        <div className="popup-body">
          <div className="flex-column-center-evenly full-height">
            <input
              className="form-field medium-text"
              type="text"
              placeholder="username"
              ref={username}
            />
            <input
              className="form-field medium-text"
              type="password"
              placeholder="passcode"
              ref={passcode}
            />
            <input
              className="form-field medium-text"
              type="text"
              placeholder="dealership"
              ref={dealership}
            />
            <p className="small-medium-text dark-blue-text">
              Please choose a .csv file to upload:
            </p>
            <input className="form-field medium-text" type="file" ref={csv} />

            <button className="large-text button-normal" onClick={handleSend}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleFeed;
