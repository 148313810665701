import React from "react";
import PropTypes from "prop-types";

const NoteViewer = ({
  handleCancel,
  handleDialogueNote,
  noteBody,
  saveNote,
  updateNote,
}) => {
  return (
    <div className="popup-content popup-content-special">
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Action Note
        </p>
        <div onClick={handleCancel} id="note-cancel">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="medium-text medium-blue-text">
            Dialogue with customer?
          </div>
          <label
            htmlFor="contact-bool-true"
            className="medium-text dark-green-text margin-right-small"
          >
            True
          </label>
          <div className="custom-radio margin-right-small">
            <input
              type="radio"
              name="contact-bool"
              id="contact-bool-true"
              onClick={handleDialogueNote}
            />
            <div className="radio-button"></div>
          </div>
          <label
            htmlFor="contact-bool-false"
            className="medium-text dark-red-text margin-right-small"
          >
            False
          </label>

          <div className="custom-radio">
            <input
              type="radio"
              name="contact-bool"
              id="contact-bool-false"
              onClick={handleDialogueNote}
              defaultChecked="true"
            />
            <div className="radio-button"></div>
          </div>
        </div>
        <textarea
          name="new-note"
          id="new-note"
          className="popup-body-container popup-body-container-taller medium-large-text dark-blue-green-text"
          placeholder="Your Note Here..."
          value={noteBody}
          onChange={updateNote}
        ></textarea>

        <div className="form-row">
          <button
            id="save-new-note"
            className="button-normal button-normal-disabled large-text flex-grow margin-top-small"
            onClick={saveNote}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

NoteViewer.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleDialogueNote: PropTypes.func.isRequired,
  noteBody: PropTypes.string,
  saveNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
};

export default NoteViewer;
