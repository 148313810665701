import React from "react";
import PropTypes from "prop-types";

const LogPhoneCall = ({
  handleCancel,
  handleDialogueCall,
  phoneCallBody,
  savePhoneCall,
  special,
  updatePhoneCall,
}) => {
  return (
    <div
      className={
        special ? "popup-content popup-content-special" : "popup-content"
      }
    >
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Phone Call
        </p>
        <div onClick={handleCancel} id="hide-phone-call">
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="medium-text medium-blue-text">
            Dialogue with customer?
          </div>
          <label
            htmlFor="contact-bool-true"
            className="medium-text dark-green-text margin-right-small"
          >
            True
          </label>
          <div className="custom-radio margin-right-small">
            <input
              type="radio"
              name="contact-bool"
              id="contact-bool-true"
              onClick={handleDialogueCall}
            />
            <div className="radio-button"></div>
          </div>
          <label
            htmlFor="contact-bool-false"
            className="medium-text dark-red-text margin-right-small"
          >
            False
          </label>

          <div className="custom-radio">
            <input
              type="radio"
              name="contact-bool"
              id="contact-bool-false"
              onClick={handleDialogueCall}
              defaultChecked="true"
            />
            <div className="radio-button"></div>
          </div>
        </div>
        <textarea
          name="new-phone-call"
          id="new-phone-call"
          className="popup-body-container popup-body-container-taller medium-large-text dark-blue-green-text"
          placeholder="Your Note Here..."
          value={phoneCallBody}
          onChange={updatePhoneCall}
        ></textarea>

        <div className="form-row">
          <button
            id="save-new-phone-call"
            className="button-normal button-normal-disabled large-text flex-grow margin-top-small"
            onClick={savePhoneCall}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

LogPhoneCall.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleDialogueCall: PropTypes.func.isRequired,
  phoneCallBody: PropTypes.string,
  savePhoneCall: PropTypes.func.isRequired,
  special: PropTypes.bool.isRequired,
  updatePhoneCall: PropTypes.func.isRequired,
};

export default LogPhoneCall;
