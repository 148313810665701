import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { getYear, parseISO } from "date-fns";
import emailValidator from "email-validator";
import {
  postLicenseData,
  getModels,
  getCityStateFromZip,
} from "../util/addCustomerApiCalls";
import commonMakes from "../util/commonMakes";
import Resizer from "react-image-file-resizer";
import DealershipContext from "../context/dealership/dealershipContext";
import UserContext from "../context/users/userContext";
// import * as faceapi from "face-api.js";
import { createSearchedCustomer } from "../functions/customerFunctions";

const AddCustomer = () => {
  //
  // HOOKS

  // useContext()
  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);

  const history = useHistory();

  // useState()

  const [enteredAddress, setEnteredAddress] = useState("");
  const [enteredFirst, setEnteredFirst] = useState("");
  const [enteredLast, setEnteredLast] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [twilioPhone, setTwilioPhone] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [twilioEmail, setTwilioEmail] = useState("");
  const [enteredZipCode, setEnteredZipCode] = useState("");
  const [enteredStock, setEnteredStock] = useState("");
  const [enteredVehicleType, setEnteredVehicleType] = useState("select-type");
  const [enteredYear, setEnteredYear] = useState("select-year");
  const [enteredMake, setEnteredMake] = useState("select-make");
  const [enteredModel, setEnteredModel] = useState("select-model");
  const [enteredCustomerType, setEnteredCustomerType] = useState("walkin");
  const [enteredCity, setEnteredCity] = useState("");
  const [enteredState, setEnteredState] = useState("");
  const [currentModels, setCurrentModels] = useState([]);
  const [currentSalesPeople, setCurrentSalesPeople] = useState([]);
  // try this as a stringified object so that we can pass data
  const [currentSalesPerson, setCurrentSalesPerson] = useState("");
  const [currentSalesPersonId, setCurrentSalesPersonId] = useState("");
  const [enteredNotes, setEnteredNotes] = useState("");
  const [enteredAltPhone, setEnteredAltPhone] = useState("");
  const [enteredAltEmail, setEnteredAltEmail] = useState("");
  const [licenseFrontLoading, setLicenseFrontLoading] = useState(false);
  const [twilioAltPhone, setTwilioAltPhone] = useState("");
  const [twilioAltEmail, setTwilioAltEmail] = useState("");
  const [modelsLoading, setModelsLoading] = useState(false);
  const [validStock, setValidStock] = useState(false);
  // on this page, enteredVin can only be set by the async result of dealershipContext.stockSearch()
  const [enteredVin, setEnteredVin] = useState("");
  // may need enteredTrim, if I end up dealing with trims at all.  It's in the model right now - 6/12/21
  const [walkInSelected, setWalkInSelected] = useState(true);
  const [loading, setLoading] = useState(false);

  //
  // useEffect()
  useEffect(() => {
    // console.log("STEP 1 ADDCUSTOMER");
    loadAddCustomer();
    // eslint-disable-next-line
  }, []);
  //
  //

  // useRef()

  const popupBody = useRef();
  const addressField = useRef();
  const cityField = useRef();
  const stateField = useRef();
  const altPhoneField = useRef();
  const altEmailField = useRef();
  const saveButton = useRef();
  const secondSaveButton = useRef();
  const addCustomerButtonsOne = useRef();
  const scanLicenseButton = useRef();
  const scanLicenseFrontModule = useRef();
  const scanLicenseFrontButton = useRef();
  const walkInRadio = useRef();
  const incomingCallRadio = useRef();
  const enteredZip = useRef();
  const salesPersonData = useRef();

  //
  // API calls
  //
  const asyncGetBdcManager = async () => {
    try {
      const bdcManager = await dealershipContext.getBdcManager();
      setCurrentSalesPersonId(bdcManager._id);
      setCurrentSalesPerson("BDC");
      return bdcManager;
    } catch (error) {
      console.log("AddCustomer.asyncGetBdcManager: ", error.message);
    }
  };
  //
  const asyncSaveCustomer = async (customerObject) => {
    try {
      // console.log("hello from asyncSaveCustomer");
      // console.log(localStorage.getItem("currentFace"));
      const bdcManager = await asyncGetBdcManager();

      if (currentSalesPerson && currentSalesPersonId) {
        customerObject.currentSalesPerson = currentSalesPerson;
        customerObject.currentSalesPersonId = currentSalesPersonId;
      } else if (bdcManager) {
        customerObject.currentSalesPerson = bdcManager;
        customerObject.currentSalesPersonId = bdcManager._id;
      } else {
        alert("Error: no salesperson or bdc manager assigned");
        return;
      }

      const correctObject = await createSearchedCustomer(customerObject);

      // console.log(JSON.parse(localStorage.getItem("localSearchedCustomer")));

      dealershipContext.clearMatches();

      if (correctObject) {
        localStorage.setItem(
          "localSearchedCustomer",
          JSON.stringify({
            finalCustomer: correctObject,
            searchedCustomerType: customerObject.enteredCustomerType,
            searchedCustomerVehicle: {
              type: customerObject.enteredVehicleType,
              stock: customerObject.enteredStock,
              year: customerObject.enteredYear,
              make: customerObject.enteredMake,
              model: customerObject.enteredModel,
              vin: customerObject.enteredVin,
            },
            searchedCustomerSalesPerson: {
              name: customerObject.currentSalesPerson,
              _id: customerObject.currentSalesPersonId,
            },
          })
        );

        // localStorage.setItem("currentLicense", "");
        // localStorage.setItem("currentFace", "");

        console.log(
          "correct object made, localStorage set, now searching for duplicates"
        );
        const success = await dealershipContext.findMatches(correctObject);
        if (success) {
          history.push("/data-maintenance");
        } else {
          alert("could not search for duplicates...");
        }
      }
    } catch (error) {
      alert("could not create customer object");
    }
  };
  //

  const loadAddCustomer = async () => {
    try {
      // if we're here we dont want the previously open cycle
      // still not exactly sure about this step and how it should work...
      localStorage.removeItem("localCurrentCycle");
      localStorage.removeItem("localCurrentCustomer");
      localStorage.removeItem("localSearchedCustomer");
      localStorage.removeItem("localSearchResults");

      //
      const { user } = await userContext.getUserOnly();

      if (!user.active || user.active === "false") {
        window.location.reload();
      }

      // we start off knowing who the salespeople are and who the user is

      // all we need to do is make sure the STRING in currentSalesPersonString matches the current salesperson?

      let salesPersonData;
      if (user) {
        salesPersonData = await dealershipContext.getSalesPeople(user);
        // console.log("salesPersonData = ", salesPersonData);
      } else {
      }

      if (salesPersonData) {
        const salesPersonIds = [];

        salesPersonData.forEach((salesPersonObj) => {
          salesPersonIds.push(salesPersonObj._id);
        });

        // I actually don't want the user here unless the user is a salesperson
        // if (!salesPersonIds.includes(user._id)) {
        //   salesPersonData.push({
        //     firstName: user.firstName,
        //     lastName: user.lastName,
        //     _id: user._id,
        //   });
        // }

        if (popupBody.current) {
          setCurrentSalesPeople(salesPersonData);
          // if (user.role !== "receptionist") {
          //   // setCurrentSalesPerson(`${user.firstName} ${user.lastName}`);
          //   setCurrentSalesPerson(
          //     JSON.stringify({
          //       firstName: user.firstName,
          //       lastName: user.lastName,
          //       _id: user._id,
          //     })
          //   );
          //   setCurrentSalesPersonId(user._id);
          // }
          walkInRadio.current.click();
        }
      } else {
        console.log("Error: No salesperson data");
      }
    } catch (error) {
      console.log("AddCustomer.loadAddCustomer: ", error.message);
    }
  };

  const callGetModels = async (year, make) => {
    try {
      const result = await getModels(year, make);
      // console.log(result);
      if (popupBody.current && result) {
        setCurrentModels(result);
      }
      return true;
    } catch (error) {
      // console.log("addCustomer.callGetModels: ", error);
    }
  };

  const callGetCityStateFromZip = async (zip) => {
    try {
      const result = await getCityStateFromZip(zip);
      if (popupBody.current) {
        if (result) {
          setEnteredCity(result.city);
          setEnteredState(result.state);
        } else {
          setEnteredCity("");
          setEnteredState("");
        }
      }
    } catch (error) {
      // console.log("addCustomer.callGetCityStateFromZip: ", error);
      if (popupBody.current) {
        setEnteredCity("");
        setEnteredState("");
      }
    }
  };

  const checkEnter = (e) => {
    if (e.key === "Enter") {
      saveCustomer(e);
    }
  };

  //
  // EVENT HANDLERS
  //
  // Display
  //
  const sectionTwoFormRows = [
    addressField,
    cityField,
    stateField,
    altPhoneField,
    altEmailField,
    secondSaveButton,
  ];
  const showSectionTwo = (e) => {
    e.preventDefault();
    addCustomerButtonsOne.current.style.display = "none";
    sectionTwoFormRows.forEach((formRow) => {
      formRow.current.classList.remove("form-row-hidden");
    });
  };

  const years = [];
  const currentYear = getYear(parseISO(new Date().toISOString()));
  years.push(currentYear + 1);
  for (let i = 0; i < 25; i++) {
    years.push(currentYear - i);
  }
  //
  // Form input
  //
  const handleInput = (e) => {
    e.preventDefault();
    const element = e.target;
    switch (element.name) {
      case "zip-code":
        const zip = element.value;
        setEnteredZipCode(zip);
        if (zip.length === 5) {
          callGetCityStateFromZip(zip);
        }
        break;
      case "first-name":
        const firstName = element.value;
        setEnteredFirst(firstName);
        break;
      case "last-name":
        const lastName = element.value;
        setEnteredLast(lastName);
        break;
      case "phone-number":
        const initialPhoneNumber = element.value;
        const digits = initialPhoneNumber.match(/\d/g);
        let phoneNumber;
        digits
          ? (phoneNumber = digits.join(""))
          : (phoneNumber = initialPhoneNumber);
        let formattedNumber = "";
        if (
          // should be formatted
          (digits && digits.length === 10 && digits[0] !== "1") ||
          (digits && digits.length === 11 && digits[0] === "1")
        ) {
          formattedNumber = new AsYouType("US").input(phoneNumber);
          const phoneObject = parsePhoneNumber(formattedNumber, "US");
          if (phoneObject && phoneObject.isValid()) {
            setTwilioPhone(phoneObject.number);
          }
          setEnteredPhone(formattedNumber);
        } else {
          // should not be formatted
          setEnteredPhone(phoneNumber);
        }
        break;
      case "email":
        const email = element.value;

        if (emailValidator.validate(email)) {
          setTwilioEmail(email);
        }

        setEnteredEmail(email);
        break;
      case "stock-number":
        const stock = element.value;
        // check dealership database for vin matching this stock'
        const asyncStockSearch = async () => {
          const vehicle = await dealershipContext.stockSearch(stock);
          if (vehicle && popupBody.current) {
            setEnteredVehicleType(vehicle.type);
            setEnteredYear(vehicle.year);
            setEnteredMake(vehicle.make);
            setEnteredVin(vehicle.vin);
            setCurrentModels([]);
            setValidStock(true);
            const models = await callGetModels(vehicle.year, vehicle.make);
            if (models) {
              setEnteredModel(vehicle.model);
            }
          }
        };

        asyncStockSearch();

        setEnteredStock(stock);

        break;
      case "vehicle-of-interest-vehicle-type":
        const vehicleType = element.value;
        setEnteredVehicleType(vehicleType);
        // not exactly sure why validStock matters...if we change this, it will never the the same as the stock number
        if (validStock) {
          setEnteredStock("");
          setEnteredVin("");
          setValidStock(false);
        }
        break;
      case "vehicle-of-interest-model":
        const vehicleModel = element.value;
        setEnteredModel(vehicleModel);
        break;
      case "notes":
        const notes = element.value;
        setEnteredNotes(notes);
        break;
      case "address":
        const address = element.value;
        setEnteredAddress(address);
        break;
      case "city":
        const city = element.value;
        setEnteredCity(city);
        break;
      case "state":
        const state = element.value;
        setEnteredState(state);
        break;
      case "alt-phone":
        const initialAltPhone = element.value;
        const altDigits = initialAltPhone.match(/\d/g);
        let altPhoneNumber;
        altDigits
          ? (altPhoneNumber = altDigits.join(""))
          : (altPhoneNumber = initialAltPhone);
        let formattedAltNumber = "";
        if (
          // should be formatted
          (altDigits && altDigits.length === 10 && altDigits[0] !== "1") ||
          (altDigits && altDigits.length === 11 && altDigits[0] === "1")
        ) {
          formattedAltNumber = new AsYouType("US").input(altPhoneNumber);
          const phoneObject = parsePhoneNumber(formattedAltNumber, "US");
          if (phoneObject && phoneObject.isValid()) {
            setTwilioAltPhone(phoneObject.number);
          }
          setEnteredAltPhone(formattedAltNumber);
        } else {
          // should not be formatted
          setEnteredAltPhone(altPhoneNumber);
        }
        break;
      case "alt-email":
        const altEmail = element.value;

        if (emailValidator.validate(altEmail)) {
          setTwilioAltEmail(altEmail);
        }

        setEnteredAltEmail(altEmail);
        break;
      default:
        break;
    }
  };

  const makeYearChange = async (e) => {
    if (e.target.name === "vehicle-of-interest-year") {
      setCurrentModels([]);
      setEnteredYear(e.target.value);
      if (enteredMake && enteredMake !== "select-make") {
        setModelsLoading(true);
        const res = await callGetModels(e.target.value, enteredMake);
        if (res) {
          setModelsLoading(false);
        }
      }
    } else if (e.target.name === "vehicle-of-interest-make") {
      setCurrentModels([]);
      setEnteredMake(e.target.value);
      if (enteredYear && enteredYear !== "entered-year") {
        setModelsLoading(true);
        const res = await callGetModels(enteredYear, e.target.value);
        if (res) {
          setModelsLoading(false);
        }
      }
    }
  };

  const handleSalesPersonChange = (e) => {
    if (e.target.value !== "" && e.target.value !== "BDC") {
      const clickedSalesPerson = JSON.parse(e.target.value);

      if (popupBody.current) {
        setCurrentSalesPersonId(clickedSalesPerson._id);
        setCurrentSalesPerson(e.target.value);
      }
    } else if (e.target.value === "BDC") {
      asyncGetBdcManager();
    }
  };

  const handleRadio = (e) => {
    // had to make this separate because we don't want e.preventDefault()
    const element = e.target;
    if (element === walkInRadio.current) {
      setEnteredCustomerType("walkin");
      setWalkInSelected(true);
    } else if (element === incomingCallRadio.current) {
      setEnteredCustomerType("phone");
      setWalkInSelected(false);
    }
  };

  const saveCustomer = (e) => {
    e.preventDefault();

    setLoading(true);
    const customerObject = {
      enteredFirst,
      enteredLast,
      twilioPhone,
      twilioEmail,
      enteredZipCode,
      enteredStock,
      enteredVehicleType,
      enteredYear,
      enteredMake,
      enteredModel,
      enteredVin,
      enteredCustomerType,
      currentSalesPerson,
      currentSalesPersonId,
      enteredNotes,
      enteredAddress,
      enteredCity,
      enteredState,
      twilioAltPhone,
      twilioAltEmail,
    };

    if (customerObject.enteredFirst && customerObject.enteredLast) {
      // console.log("BEFORE: ", customerObject);
      if (customerObject.twilioEmail) {
        customerObject.twilioEmail = customerObject.twilioEmail.toLowerCase();
      }

      if (customerObject.twilioAltEmail) {
        customerObject.twilioAltEmail =
          customerObject.twilioAltEmail.toLowerCase();
      }

      if (!currentSalesPerson || !currentSalesPersonId) {
        if (walkInSelected) {
          e.preventDefault();
          setLoading(false);
          alert("a walkin must have a salesperson");
          return;
        } else if (enteredCustomerType === "phone" && !twilioPhone) {
          e.preventDefault();
          setLoading(false);
          alert("a phone up must have a valid phone number");
          return;
        }
        // console.log(
        //   "about to enter asyncSaveCustomer with no existing salesperson"
        // );
        asyncSaveCustomer(customerObject);
      } else {
        asyncSaveCustomer(customerObject);
      }
    } else {
      alert("required fields missing!");
      setLoading(false);
    }
  };

  //
  // Media
  //
  const sendId = (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    let reader = new FileReader();

    Resizer.imageFileResizer(
      file,
      1250,
      1250,
      "JPEG",
      100,
      0,
      (uri) => {
        reader.readAsDataURL(uri);
      },
      "file"
    );

    if (popupBody && popupBody.current) {
      popupBody.current.style.display = "none";
      scanLicenseFrontModule.current.classList.remove("hidden");
      scanLicenseFrontModule.current.style.display = "flex";
    }
    reader.onloadend = async () => {
      const result = reader.result.split(",")[1];
      const licenseInfo = await postLicenseData(result);
      if (
        licenseInfo &&
        licenseInfo.success &&
        popupBody &&
        popupBody.current
      ) {
        setEnteredAddress(licenseInfo.enteredAddress);
        setEnteredFirst(licenseInfo.enteredFirst);
        setEnteredLast(licenseInfo.enteredLast);
        setEnteredZipCode(licenseInfo.enteredZipCode);
        setEnteredCity(licenseInfo.enteredCity);
        setEnteredState(licenseInfo.enteredState);
      }
    };
  };

  const scanLicenseFront = async (e) => {
    e.preventDefault();
    if (popupBody.current) {
      setLicenseFrontLoading(true);
    }
    const file = e.target.files[0];
    let reader = new FileReader();

    // let resizedFile;

    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        reader.readAsDataURL(uri);
      },
      "file"
    );

    reader.onloadend = async () => {
      const result = reader.result.split(",")[1];

      const hiddenLicenseFrontImg = document.getElementById(
        "hidden-license-front-img"
      );
      hiddenLicenseFrontImg.setAttribute("src", reader.result);

      // console.log(faceapi);

      // await faceapi.loadTinyFaceDetectorModel("/models");

      // const detection = await faceapi.detectSingleFace(
      //   hiddenLicenseFrontImg,
      //   new faceapi.TinyFaceDetectorOptions({ inputSize: 320 })
      // );

      // console.log("DETECTION", detection);

      // setTimeout(() => {
      //   if (!detection) {
      //     alert("could not detect face...");
      //     if (popupBody.current) {
      //       setLicenseFrontLoading(false);
      //     }
      //   }
      // }, 3000);

      if (popupBody.current) {
        setLicenseFrontLoading(false);
      }

      // const regionsToExtract = [
      //   new faceapi.Rect(
      //     detection._box._x,
      //     detection._box._y,
      //     detection._box._width,
      //     detection._box._height
      //   ),
      // ];

      // console.log("REGIONS TO EXTRACT", regionsToExtract);

      // const canvases = await faceapi.extractFaces(
      //   hiddenLicenseFrontImg,
      //   regionsToExtract
      // );

      // console.log(canvases);

      // these values actually need to go to localStorage, then get deleted when no duplicate is found

      // console.log(result);
      // console.log(canvases[0].toDataURL().split(",")[1]);
      // console.log("currentLicense: ", result);
      localStorage.setItem("currentLicense", result);
      // console.log(localStorage.getItem("currentLicense"));
      // localStorage.setItem(
      //   "currentFace",
      //   canvases[0].toDataURL().split(",")[1]
      // );

      if (popupBody.current) {
        setLicenseFrontLoading(false);
        scanLicenseFrontModule.current.style.display = "none";
        popupBody.current.style.display = "flex";
      }
    };
  };

  return (
    <Fragment>
      {loading ? (
        <main className="full-page-center full-page-center-with-navbar">
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <div className="popup" id="popup-add-customer" onKeyUp={checkEnter}>
          <div className="popup-content">
            <div className="popup-header">
              <p className="large-text white-text center-text flex-grow">
                Add Customer
              </p>
              <div
                id="add-customer-exit"
                onClick={(e) => {
                  history.goBack();
                }}
              >
                <svg className="icon-small icon-small-back">
                  <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                </svg>
              </div>
            </div>
            <form
              className="popup-body popup-body-oversize popup-body-evenly"
              ref={popupBody}
            >
              <div className="form-row">
                <label
                  htmlFor="first-name"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  First Name:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="first-name"
                  id="first-name"
                  spellCheck="false"
                  value={enteredFirst}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row">
                <label
                  htmlFor="last-name"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Last Name:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="last-name"
                  id="last-name"
                  spellCheck="false"
                  value={enteredLast}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row">
                <label
                  htmlFor="phone-number"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Phone:
                </label>
                <input
                  type="tel"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="phone-number"
                  id="phone-number"
                  value={enteredPhone}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row">
                <label
                  htmlFor="email"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  E-Mail:
                </label>
                <input
                  type="email"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="email"
                  id="email"
                  value={enteredEmail}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row">
                <label
                  htmlFor="zip-code"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Zip:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="zip-code"
                  id="zip-code"
                  ref={enteredZip}
                  value={enteredZipCode}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row">
                <label
                  htmlFor="stock-number"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Stock Number:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="stock-number"
                  id="stock-number"
                  value={enteredStock}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row">
                <label
                  htmlFor="vehicle-of-interest-vehicle-type"
                  className="medium-text medium-blue-text"
                >
                  Vehicle Type
                </label>
                <div className="medium-text medium-blue-text">
                  &nbsp;|&nbsp;
                </div>
                <label
                  htmlFor="vehicle-of-interest-year"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Year:
                </label>
                <select
                  name="vehicle-of-interest-vehicle-type"
                  id="vehicle-of-interest-vehicle-type"
                  className="form-select flex-grow small-text dark-blue-green-text margin-right-small"
                  value={enteredVehicleType}
                  onChange={handleInput}
                >
                  <option value="select-type">Type</option>
                  <option value="new">New</option>
                  <option value="used">Used</option>
                </select>
                <select
                  name="vehicle-of-interest-year"
                  id="vehicle-of-interest-year"
                  className="form-select flex-grow small-text dark-blue-green-text"
                  value={enteredYear}
                  onChange={makeYearChange}
                >
                  <option value="select-year">Year</option>
                  {years.map((year) => {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                  <option value="antique">Antique</option>
                </select>
              </div>
              <div className="form-row">
                <label
                  htmlFor="vehicle-of-interest-make"
                  className="small-medium-text medium-blue-text"
                >
                  Make
                </label>
                <div className="medium-text medium-blue-text">
                  &nbsp;|&nbsp;
                </div>
                <label
                  htmlFor="vehicle-of-interest-model"
                  className="small-medium-text medium-blue-text margin-right-small"
                >
                  Model:
                </label>

                <select
                  name="vehicle-of-interest-make"
                  id="vehicle-of-interest-make"
                  className="form-select form-select-oversize flex-grow small-text dark-blue-green-text margin-right-small"
                  value={enteredMake}
                  onChange={makeYearChange}
                >
                  <option value="select-make">Make</option>
                  {commonMakes.map((make) => {
                    return (
                      <option key={make} value={make}>
                        {make}
                      </option>
                    );
                  })}
                </select>
                <select
                  name="vehicle-of-interest-model"
                  id="vehicle-of-interest-model"
                  className="form-select form-select-oversize flex-grow small-text dark-blue-green-text"
                  value={enteredModel}
                  onChange={handleInput}
                >
                  {modelsLoading ? (
                    <option value="null">Loading...</option>
                  ) : (
                    <option value="select-model">Model</option>
                  )}

                  {currentModels &&
                    currentModels.map((currentModel) => {
                      return (
                        <option key={currentModel} value={currentModel}>
                          {currentModel}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-row">
                <label
                  htmlFor="form-radio-select"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Type:
                </label>
                <div
                  className="form-radio-select flex-grow"
                  id="form-radio-select"
                >
                  <div className="form-radio-select-field flex-grow">
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="customer-type"
                        id="walk-in-radio"
                        ref={walkInRadio}
                        onClick={handleRadio}
                        value={enteredCustomerType && enteredCustomerType}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="walk-in-radio"
                      className="small-text dark-blue-green-text"
                    >
                      Walk-In
                    </label>
                  </div>
                  <div className="form-radio-select-field flex-grow">
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="customer-type"
                        id="incoming-call-radio"
                        ref={incomingCallRadio}
                        onClick={handleRadio}
                        value={enteredCustomerType && enteredCustomerType}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="incoming-call-radio"
                      className="small-text dark-blue-green-text"
                    >
                      Incoming Call
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <label
                  htmlFor="salesperson"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Salesperson:
                </label>
                <select
                  name="salesperson"
                  id="salesperson"
                  className="form-select flex-grow small-text dark-blue-green-text"
                  value={currentSalesPerson}
                  onChange={handleSalesPersonChange}
                >
                  <option value="">Select...</option>
                  {!walkInSelected && <option value="BDC">BDC</option>}
                  {currentSalesPeople.map((salesperson) => {
                    return (
                      <option
                        key={salesperson._id}
                        value={JSON.stringify(salesperson)}
                      >
                        {`${salesperson.firstName} ${salesperson.lastName}`}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-row">
                <label
                  htmlFor="notes"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Notes:
                </label>
                <textarea
                  type="text"
                  name="notes"
                  id="notes"
                  className="small-text dark-blue-green-text form-notes flex-grow"
                  placeholder="Your notes here..."
                  value={enteredNotes}
                  onChange={handleInput}
                ></textarea>
              </div>
              <div
                className="form-row"
                id="add-customer-buttons-1"
                ref={addCustomerButtonsOne}
              >
                <button
                  className="button-normal flex-grow medium-text margin-right-small"
                  onClick={showSectionTwo}
                >
                  More
                </button>
                <button
                  className="button-normal flex-grow medium-text margin-right-small"
                  onClick={async (e) => {
                    e.preventDefault();
                    scanLicenseButton.current.click();
                  }}
                >
                  Scan License Back
                </button>

                <input
                  className="hidden"
                  type="file"
                  ref={scanLicenseButton}
                  onChange={sendId}
                />
                <input
                  className="hidden"
                  type="file"
                  name="license-front"
                  ref={scanLicenseFrontButton}
                  onChange={scanLicenseFront}
                />

                <img
                  className="hidden"
                  alt="hidden"
                  src=""
                  id="hidden-license-front-img"
                />

                <button
                  ref={saveButton}
                  className="button-elevated flex-grow medium-text"
                  onClick={saveCustomer}
                >
                  Save
                </button>
              </div>
              <div className="form-row form-row-hidden" ref={addressField}>
                <label
                  htmlFor="address"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Address:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="address"
                  id="address"
                  value={enteredAddress}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row form-row-hidden" ref={cityField}>
                <label
                  htmlFor="city"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  City:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="city"
                  id="city"
                  value={enteredCity}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row form-row-hidden" ref={stateField}>
                <label
                  htmlFor="state"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  State:
                </label>
                <input
                  type="text"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="state"
                  id="state"
                  value={enteredState}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row form-row-hidden" ref={altPhoneField}>
                <label
                  htmlFor="alt-phone"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Alt. Phone:
                </label>
                <input
                  type="tel"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="alt-phone"
                  value={enteredAltPhone}
                  id="alt-phone"
                  onChange={handleInput}
                />
              </div>
              <div className="form-row form-row-hidden" ref={altEmailField}>
                <label
                  htmlFor="alt-email"
                  className="medium-text medium-blue-text margin-right-small"
                >
                  Alt. Email:
                </label>
                <input
                  type="email"
                  className="form-field flex-grow small-text dark-blue-green-text"
                  name="alt-email"
                  id="alt-email"
                  value={enteredAltEmail}
                  onChange={handleInput}
                />
              </div>
              <div className="form-row form-row-hidden" ref={secondSaveButton}>
                <button
                  to="/data-maintenance"
                  className="button-elevated flex-grow medium-text"
                  onClick={saveCustomer}
                >
                  Save
                </button>
              </div>
            </form>
            {licenseFrontLoading && (
              <div className="flex-column-center-center three-quarter-height">
                <div className="lds-dual-ring"></div>
              </div>
            )}
            <div
              className="flex-column-center-center three-quarter-height hidden"
              ref={scanLicenseFrontModule}
            >
              <button
                className="button-normal margin-bottom-large medium-text margin-right-small"
                onClick={(e) => {
                  e.preventDefault();
                  scanLicenseFrontButton.current.click();
                }}
              >
                Scan Front of License
              </button>
              <button
                className="button-normal medium-text"
                onClick={(e) => {
                  e.preventDefault();
                  scanLicenseFrontModule.current.style.display = "none";
                  popupBody.current.style.display = "flex";
                }}
              >
                Go Back
              </button>
              <div className="hidden" ref={salesPersonData}></div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddCustomer;
