import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { format, parseISO, getYear } from "date-fns";
import parsePhoneNumber from "libphonenumber-js";
import { getModels } from "../util/addCustomerApiCalls";
import commonMakes from "../util/commonMakes";
import UserContext from "../context/users/userContext";
import DealershipContext from "../context/dealership/dealershipContext";
import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";
import {
  addNote,
  editSalesPersonOfCycle,
  updateVehicle,
} from "../functions/customerFunctions";

const DeskLog = (passedViewDate) => {
  const location = useLocation();
  //
  const userContext = useContext(UserContext);

  const dealershipContext = useContext(DealershipContext);
  //
  const history = useHistory();
  //
  const mainBodyRef = useRef();
  const dmsButtonHandle = useRef();
  //
  const [apptSorting, setApptSorting] = useState("time");
  const [currentModels, setCurrentModels] = useState([]);
  const [currentNote, setCurrentNote] = useState("");
  const [currentSalesPeople, setCurrentSalesPeople] = useState([]);
  const [currentSalesPerson, setCurrentSalesPerson] = useState("");
  const [currentStoreVisit, setCurrentStoreVisit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enteredMake, setEnteredMake] = useState("");
  const [enteredModel, setEnteredModel] = useState("");
  const [enteredStock, setEnteredStock] = useState("");
  const [enteredVehicleType, setEnteredVehicleType] = useState("");
  const [enteredVin, setEnteredVin] = useState("");
  const [enteredYear, setEnteredYear] = useState("");
  const [numberSold, setNumberSold] = useState(0);
  const [numberPending, setNumberPending] = useState(0);
  const [numberNotSold, setNumberNotSold] = useState(0);
  const [saveNoteButtonViewable, setSaveNoteButtonViewable] = useState(false);
  const [sorting, setSorting] = useState("time");
  const [status, setStatus] = useState("");
  const [storeVisits, setStoreVisits] = useState([]);
  const [storeVisitInteractive, setStoreVisitInteractive] = useState(true);
  const [storeVisitsPopulated, setStoreVisitsPopulated] = useState(false);
  const [todayAppointments, setTodayAppointments] = useState([]);
  const [viewDate, setViewDate] = useState("");
  const [viewStoreVisitNotes, setViewStoreVisitNotes] = useState(false);
  const [viewMainGrid, setViewMainGrid] = useState(true);
  const [viewStoreVisit, setViewStoreVisit] = useState(false);
  const [viewTodayAppointments, setViewTodayAppointments] = useState(false);
  //
  useEffect(() => {
    if (!storeVisitsPopulated) {
      loadStoreVisits();
    }
    // eslint-disable-next-line
  }, [storeVisitsPopulated]);
  //
  const years = [];
  const thisYear = getYear(parseISO(new Date().toISOString()));
  years.push(thisYear + 1);
  for (let i = 0; i < 25; i++) {
    years.push(thisYear - i);
  }
  //
  const asyncHandleSalesPersonChange = async (salesPersonId) => {
    try {
      let salesId = salesPersonId;
      if (salesId === "") {
        const salesObj = await dealershipContext.getBdcManager();
        salesId = salesObj._id;
      } else {
        console.log('salesId does not equal ""');
      }

      // change salesperson of cycle on both database and context
      const newCycle = await editSalesPersonOfCycle(
        salesId,
        currentStoreVisit.cycle._id
      );

      if (newCycle) {
        safeStateSetter(() => {
          setCurrentSalesPerson(salesId);
          setStoreVisitsPopulated(false);
        });
      } else {
        console.log("cycle not edited...");
      }
    } catch (error) {
      console.log("asyncHandleSalesPersonChange", error.message);
    }
  };
  //
  const asyncSaveNote = async () => {
    try {
      const savedNote = await addNote({
        customer: currentStoreVisit.customer._id,
        noteType: "standard",
        body: currentNote,
        writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
        user: userContext.user._id,
        dialogue: true,
      });

      if (savedNote) {
        const newStoreVisit = currentStoreVisit;
        newStoreVisit.customer.notes.unshift(savedNote);
        setCurrentNote("");
        setCurrentStoreVisit(newStoreVisit);
        setStoreVisitsPopulated(false);
        setViewStoreVisitNotes(false);
        setViewStoreVisit(true);
      } else {
        throw new Error("could not save note");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //
  const asyncSaveStoreVisit = async () => {
    try {
      if (currentNote !== "") {
        const savedNote = await addNote({
          customer: currentStoreVisit.customer._id,
          noteType: "standard",
          body: currentNote,
          writtenBy: `${userContext.user.firstName} ${userContext.user.lastName}`,
          user: userContext.user._id,
          dialogue: true,
        });

        if (!savedNote) {
          console.log("could not save note");
        } else {
          setCurrentNote("");
        }
      }

      const storeVisitSaved = await dealershipContext.saveStoreVisit(
        currentStoreVisit
      );

      if (storeVisitSaved) {
        safeStateSetter(() => {
          setViewStoreVisit(false);
          setViewMainGrid(true);
          setStoreVisitsPopulated(false);
        });
      } else {
        alert("could not save store visit...");
      }
    } catch (error) {
      console.log(error.message);
      window.location.reload();
    }
  };
  //
  const callGetModels = async (year, make, model) => {
    const result = await getModels(year, make);

    if (result) {
      safeStateSetter(() => {
        setCurrentModels(result);
        if (model && model !== " ") {
          setEnteredModel(model);
        }
      });
      return true;
    } else {
      return false;
    }
  };
  //
  const dmsPush = async (customerId) => {
    try {
      const res = await axios.post(
        "/api/v1/customer/dms-push",
        {
          customerId,
        },
        axiosConfig
      );

      if (res.data.success) {
        if (res.data.returnedData) {
          if (res.data.returnedData.cplDealNumber) {
            alert(
              `Deal  ${res.data.returnedData.cplDealNumber} successfully sent to Car Pro!`
            );
          } else {
            alert(`Success response from Car Pro, but no deal number...`);
          }
        } else {
          alert(`Deal successfully sent to DMS!`);
        }
        window.location.reload();
      } else {
        throw new Error("could not send to DMS...");
      }
    } catch (error) {
      console.log("DeskLog.dmsPush: ", error.message);
    }
  };
  //
  const handleDisplayChange = (e) => {
    let sv = null;
    if (e.target.id) {
      sv = JSON.parse(e.target.id);
    }

    safeStateSetter(() => {
      switch (e.target.name) {
        case "view-store-visit":
          sv.customer.notes.sort((a, b) => {
            if (
              new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ) {
              return -1;
            } else {
              return 1;
            }
          });
          setCurrentSalesPerson(sv.cycle.primarySales._id);
          setCurrentStoreVisit(sv);
          if (sv.cycle.vehicle.year && sv.cycle.vehicle.make) {
            (async function () {
              const haveModels = await callGetModels(
                sv.cycle.vehicle.year,
                sv.cycle.vehicle.make,
                sv.cycle.vehicle.model
              );
              if (haveModels) {
                setEnteredMake(sv.cycle.vehicle.make);
                setEnteredStock(sv.cycle.vehicle.stock);
                setEnteredVehicleType(sv.cycle.vehicle.type);
                setEnteredYear(sv.cycle.vehicle.year);
                setEnteredVin(sv.cycle.vehicle.vin);
              }
            })();
          }

          if (sv.status === "sold") {
            setStatus("sold");
          } else if (sv.status === "pending") {
            setStatus("pending");
          } else {
            setStatus("not-sold");
          }

          setViewStoreVisit(true);
          setViewMainGrid(false);
          break;

        case "view-store-visit-notes":
          setViewStoreVisit(false);
          setViewStoreVisitNotes(true);
          setViewMainGrid(false);
          break;

        case "view-today-appointments":
          (async function () {
            try {
              const fetchedAppts = await axios.post(
                "/api/v1/dealership/get-today-appointments",
                { viewDate },
                axiosConfig
              );

              if (!fetchedAppts) {
                throw new Error("could not get appointments from server");
              } else {
                setTodayAppointments(fetchedAppts.data.todayAppointments);
                // the order actually matters here.  if setViewMainGrid is after setViewTodayAppointments, mainBodyRef.current will be null
                setViewMainGrid(false);
                setViewTodayAppointments(true);
              }
            } catch (error) {
              console.log(error.message);
            }
          })();
          break;
        default:
          break;
      }
    });
  };
  //
  const handleCancel = (e) => {
    safeStateSetter(() => {
      switch (e.currentTarget.id) {
        case "hide-store-visit":
          setViewStoreVisit(false);
          setViewMainGrid(true);
          setStoreVisitsPopulated(false);
          break;

        case "hide-store-visit-notes":
          setViewStoreVisit(true);
          setViewStoreVisitNotes(false);
          setViewMainGrid(false);
          break;

        case "hide-today-appointments":
          setViewTodayAppointments(false);
          setViewMainGrid(true);
          break;
        default:
          break;
      }
    });
  };
  //
  const handleNoteChange = (e) => {
    if (e.target.value !== "") {
      safeStateSetter(() => {
        setSaveNoteButtonViewable(true);
      });
    } else {
      safeStateSetter(() => {
        setSaveNoteButtonViewable(false);
      });
    }
    safeStateSetter(() => {
      setCurrentNote(e.target.value);
    });
  };
  //
  const handleSalesPersonChange = (e) => {
    // change the database
    asyncHandleSalesPersonChange(e.target.value);
  };
  //
  const handleSaveStoreVisit = (e) => {
    asyncSaveStoreVisit();
  };
  //
  const handleSortingChange = (e) => {
    const sortType = e.target.id;

    const currentStoreVisits = storeVisits;

    if (sortType === "sorting-time-radio") {
      currentStoreVisits.sort((a, b) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1;
        } else {
          return 1;
        }
      });

      safeStateSetter(() => {
        setSorting("time");
        setStoreVisits(currentStoreVisits);
      });
    } else if (sortType === "sorting-salesperson-radio") {
      currentStoreVisits.sort((a, b) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1;
        } else {
          return 1;
        }
      });

      const salesPersonNames = [];

      const salesMap = {};

      currentStoreVisits.forEach((curSV) => {
        if (
          !salesPersonNames.includes(
            `${curSV.cycle.primarySales.firstName} ${curSV.cycle.primarySales.lastName}`
          )
        ) {
          salesPersonNames.push(
            `${curSV.cycle.primarySales.firstName} ${curSV.cycle.primarySales.lastName}`
          );
        }

        if (
          salesMap[
            `${curSV.cycle.primarySales.firstName} ${curSV.cycle.primarySales.lastName}`
          ]
        ) {
          salesMap[
            `${curSV.cycle.primarySales.firstName} ${curSV.cycle.primarySales.lastName}`
          ].push(curSV);
        } else {
          salesMap[
            `${curSV.cycle.primarySales.firstName} ${curSV.cycle.primarySales.lastName}`
          ] = [curSV];
        }
      });

      salesPersonNames.sort((a, b) => {
        if (a < b) {
          return -1;
        } else {
          return 1;
        }
      });

      const finalArray = [];

      salesPersonNames.forEach((spName) => {
        // this is quadratic, but that's ok because this will never have big data
        salesMap[spName].forEach((sv) => {
          finalArray.push(sv);
        });
      });

      safeStateSetter(() => {
        setSorting("salesperson");
        setStoreVisits(finalArray);
      });
    } else if (sortType === "sorting-status-radio") {
      const solds = [];
      const pendings = [];
      const notSolds = [];

      currentStoreVisits.forEach((sv) => {
        if (sv.status === "sold") {
          solds.push(sv);
        } else if (sv.status === "pending") {
          pendings.push(sv);
        } else if (sv.status === "not-sold") {
          notSolds.push(sv);
        } else {
          alert("error sorting...");
        }
      });

      const finalSVArray = [];

      solds.forEach((ssv) => finalSVArray.push(ssv));
      pendings.forEach((psv) => finalSVArray.push(psv));
      notSolds.forEach((nssv) => finalSVArray.push(nssv));

      safeStateSetter(() => {
        setSorting("status");
        setStoreVisits(finalSVArray);
      });
    } else {
      alert("invalid sorting type...");
    }
  };
  //
  const handleApptSortingChange = (e) => {
    const apptSortType = e.target.id;

    if (apptSortType === "sorting-appointment-setter-radio") {
      setApptSorting("setter");
      todayAppointments.sort((a, b) => {
        if (a.setter.charAt(0) > b.setter.charAt) {
          return -1;
        } else {
          return 1;
        }
      });
    } else {
      setApptSorting("time");
      todayAppointments.sort((a, b) => {
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  };
  //
  const handleStatusChange = (e) => {
    e.target.checked = true;
    const localStoreVisit = currentStoreVisit;
    safeStateSetter(() => {
      switch (e.target.id) {
        case "status-sold-spot-radio":
          localStoreVisit.status = "sold";
          setCurrentStoreVisit(localStoreVisit);
          setStatus("sold");
          break;

        case "status-pending-radio":
          localStoreVisit.status = "pending";
          setCurrentStoreVisit(localStoreVisit);
          setStatus("pending");
          break;

        case "status-not-sold-radio":
          localStoreVisit.status = "not-sold";
          setCurrentStoreVisit(localStoreVisit);
          setStatus("not-sold");
          break;

        default:
          break;
      }
    });
  };
  //
  const handleVehicleChange = (e) => {
    const element = e.target;

    switch (element.name) {
      case "model":
        safeStateSetter(() => {
          setEnteredModel(element.value);
        });

        const asyncUpdateModel = async (element, cycle) => {
          const returned = await updateVehicle(
            cycle._id,
            enteredYear,
            enteredMake,
            element.value,
            enteredVehicleType,
            "",
            ""
          );
          if (returned) {
            safeStateSetter(() => {
              setEnteredStock("");
              setEnteredVin("");
            });
          } else {
            console.log("updateVehicle failure - could not update model");
          }
        };

        asyncUpdateModel(element, currentStoreVisit.cycle);

        break;

      case "type":
        safeStateSetter(() => {
          setEnteredVehicleType(element.value);
        });

        const asyncUpdateType = async (element, cycle) => {
          const returned = await updateVehicle(
            cycle._id,
            enteredYear,
            enteredMake,
            enteredModel,
            element.value,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(() => {
              setEnteredStock("");
              setEnteredVin("");
            });
          } else {
            console.log("updateVehicle failure - could not update type");
          }
        };

        asyncUpdateType(element, currentStoreVisit.cycle);

        break;

      case "year":
        safeStateSetter(() => {
          setEnteredYear(element.value);
        });

        const asyncUpdateYear = async (element, cycle) => {
          const returned = updateVehicle(
            cycle._id,
            element.value,
            enteredMake,
            "",
            enteredVehicleType,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(async () => {
              setCurrentModels([]);

              if (enteredMake && enteredMake !== "select-make") {
                const res = await callGetModels(element.value, enteredMake);
                if (res) {
                  setEnteredModel("");
                }
              }
            });
          } else {
            console.log("updateVehicle failure - could not update year");
          }
        };

        asyncUpdateYear(element, currentStoreVisit.cycle);

        break;

      case "make":
        safeStateSetter(() => {
          setEnteredMake(element.value);
        });

        const asyncUpdateMake = async (element, cycle) => {
          const returned = updateVehicle(
            cycle._id,
            enteredYear,
            element.value,
            "",
            enteredVehicleType,
            "",
            ""
          );

          if (returned) {
            safeStateSetter(async () => {
              setCurrentModels([]);
              if (enteredYear && enteredYear !== "select-year") {
                const res = await callGetModels(enteredYear, element.value);
                if (res) {
                  setEnteredModel("");
                }
              }
            });
          } else {
            console.log("updateVehicle failure - could not update make");
          }
        };

        asyncUpdateMake(element, currentStoreVisit.cycle);

        break;

      case "stock":
        const stock = element.value;
        safeStateSetter(() => {
          setEnteredStock(stock);
        });

        const asyncStockSearch = async (stock) => {
          const vehicle = await dealershipContext.stockSearch(stock);
          if (vehicle) {
            const returned = await updateVehicle(
              currentStoreVisit.cycle._id,
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.type,
              vehicle.stock,
              vehicle.vin
            );

            if (returned) {
              safeStateSetter(async () => {
                setEnteredVehicleType(vehicle.type);
                setEnteredYear(vehicle.year);
                setEnteredMake(vehicle.make);
                setEnteredVin(vehicle.vin);
                await callGetModels(vehicle.year, vehicle.make);
                setEnteredModel(vehicle.model);
              });
            } else {
              console.log(
                "updateVehicle failure - could not update based on stock number provided"
              );
            }
          } else {
            console.log("stock not found");
            safeStateSetter(() => {
              setEnteredVin("");
            });
          }
        };
        asyncStockSearch(stock);

        break;

      case "vin":
        const vin = element.value;
        safeStateSetter(() => {
          setEnteredVin(vin);
        });

        const asyncVinSearch = async (vin) => {
          const vehicle = await dealershipContext.vinSearch(vin);
          if (vehicle) {
            // console.log("cycle.js: ", vehicle);
            const returned = await updateVehicle(
              currentStoreVisit.cycle._id,
              vehicle.year,
              vehicle.make,
              vehicle.model,
              vehicle.type,
              vehicle.stock,
              vehicle.vin
            );

            if (returned) {
              safeStateSetter(async () => {
                setEnteredVehicleType(vehicle.type);
                setEnteredYear(vehicle.year);
                setEnteredMake(vehicle.make);
                setCurrentModels([]);
                const models = await callGetModels(vehicle.year, vehicle.make);
                if (models) {
                  setEnteredModel(vehicle.model);
                }
              });
            } else {
              console.log(
                "updateVehicle failure - could not update based on vin provided"
              );
            }
          } else {
            console.log("vin not found");
            safeStateSetter(() => {
              setEnteredStock("");
            });
          }
        };
        asyncVinSearch(vin);

        break;

      default:
        console.log("element.name does not match any of the cases");
        break;
    }
  };
  //
  const handleViewCycle = (e) => {
    localStorage.setItem(
      "localCurrentCustomer",
      JSON.stringify(currentStoreVisit.customer)
    );
    localStorage.setItem(
      "localCurrentCycle",
      JSON.stringify(currentStoreVisit.cycle)
    );

    history.push("/cycle");
  };
  //
  const loadStoreVisits = async () => {
    try {
      safeStateSetter(() => {
        setLoading(true);
      });

      const userLoaded = await userContext.getUser();

      if (userLoaded && userLoaded.user) {
        if (!userLoaded.user.active || userLoaded.user.active === "false") {
          window.location.reload();
        }
      }

      let storeVisitsWithData;

      const now = new Date().toLocaleDateString().split("/");
      if (now[0].length < 2) {
        now[0] = `0${now[0]}`;
      }
      if (now[1].length < 2) {
        now[1] = `0${now[1]}`;
      }

      if (!viewDate) {
        let localSvDate = null;
        if (location.state) {
          localSvDate = location.state.svDate;
          // console.log(localSvDate);
        }

        if (localSvDate) {
          const friendlyDate = localSvDate.split("T")[0];

          storeVisitsWithData = await dealershipContext.getStoreVisitsAndData(
            new Date(parseISO(`${friendlyDate}T00:00:00Z`))
          );

          safeStateSetter(() => {
            setViewDate(`${friendlyDate}`);
          });
        } else {
          storeVisitsWithData = await dealershipContext.getStoreVisitsAndData(
            new Date(parseISO(`${now[2]}-${now[0]}-${now[1]}T00:00:00Z`))
          );
          safeStateSetter(() => {
            setViewDate(`${now[2]}-${now[0]}-${now[1]}`);
          });
        }
      } else {
        storeVisitsWithData = await dealershipContext.getStoreVisitsAndData(
          new Date(parseISO(`${viewDate}T00:00:00Z`))
        );
      }

      let salesPersonData;

      salesPersonData = await dealershipContext.getSalesPeople(userLoaded.user);

      if (salesPersonData) {
        const salesPersonIds = [];

        salesPersonData.forEach((salesPersonObj) => {
          salesPersonIds.push(salesPersonObj._id);
        });

        safeStateSetter(() => {
          setCurrentSalesPeople(salesPersonData);
        });
      }

      if (storeVisitsWithData) {
        // counters
        let sold = 0;
        let pending = 0;
        let notSold = 0;
        for (let x = 0; x < storeVisitsWithData.length; x++) {
          if (storeVisitsWithData[x].status === "sold") {
            sold++;
          } else if (storeVisitsWithData[x].status === "pending") {
            pending++;
          } else {
            notSold++;
          }
        }

        storeVisitsWithData.sort((a, b) => {
          if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
            return -1;
          } else {
            return 1;
          }
        });

        safeStateSetter(() => {
          setLoading(false);
          setNumberSold(sold);
          setNumberPending(pending);
          setNumberNotSold(notSold);
          setStoreVisits(storeVisitsWithData);
          setStoreVisitsPopulated(true);
          if (
            userLoaded.user.role === "receptionist" ||
            userLoaded.user.role === "bdc" ||
            userLoaded.user.role === "bdcmanager"
          ) {
            setStoreVisitInteractive(false);
          }
        });
      } else {
        throw new Error("could not get store visits and data");
      }
    } catch (error) {
      console.log("DeskLog.loadStoreVisits: ", error.message);
      history.push("/");
    }
  };
  //
  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };
  //
  const saveNote = (e) => {
    asyncSaveNote();
  };
  //
  const updateViewDate = (e) => {
    safeStateSetter(() => {
      setViewDate(e.target.value);
      setStoreVisitsPopulated(false);
    });
  };

  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <div>
          {viewMainGrid && (
            <main
              className="page-column flex-row-center-center"
              ref={mainBodyRef}
            >
              <div className="grid">
                <div className="grid-row">
                  <div className="grid-item grid-item-short">
                    <h1 className="main-title main-title-smaller dark-blue-text">
                      Desk Log
                    </h1>
                    <input
                      type="date"
                      name="view-date"
                      className="form-field medium-text dark-blue-text"
                      id="view-date"
                      onChange={updateViewDate}
                      value={viewDate && viewDate}
                    />
                  </div>
                  <div className="grid-item grid-item-short">
                    <div className="flex-row-center-center">
                      <div className="flex-column-center-center">
                        <div className="medium-text medium-green-text">
                          Sold
                        </div>
                        <div className="large-text dark-blue-text">
                          {numberSold}
                          {/* 15 */}
                        </div>
                      </div>
                      <div className="flex-column-center-center margin-left-medium margin-right-medium">
                        <div className="medium-text medium-amber-text">
                          Pending
                        </div>
                        <div className="large-text dark-blue-text">
                          {numberPending}
                          {/* 2 */}
                        </div>
                      </div>
                      <div className="flex-column-center-center">
                        <div className="medium-text medium-red-text">
                          Not Sold
                        </div>
                        <div className="large-text dark-blue-text">
                          {numberNotSold}
                          {/* 6 */}
                        </div>
                      </div>
                    </div>
                    <button
                      className="small-text button-neutral button-neutral-smaller"
                      name="view-today-appointments"
                      onClick={handleDisplayChange}
                    >
                      Appointments
                    </button>
                  </div>
                  <div className="grid-item grid-item-short">
                    <div className="medium-text dark-blue-green-text">
                      Sorting:
                    </div>
                    <div className="flex-row-center-center">
                      <label
                        htmlFor="sorting-time-radio"
                        className="small-text medium-blue-green-text margin-right-small"
                      >
                        Time
                      </label>
                      <div className="custom-radio margin-right-small">
                        <input
                          type="radio"
                          name="sorting"
                          id="sorting-time-radio"
                          onChange={handleSortingChange}
                          checked={sorting && sorting === "time"}
                        />
                        <div className="radio-button"></div>
                      </div>
                      <label
                        htmlFor="sorting-salesperson-radio"
                        className="small-text medium-blue-green-text margin-right-small"
                      >
                        Salesperson
                      </label>
                      <div className="custom-radio margin-right-small">
                        <input
                          type="radio"
                          name="sorting"
                          id="sorting-salesperson-radio"
                          onChange={handleSortingChange}
                          checked={sorting && sorting === "salesperson"}
                        />
                        <div className="radio-button"></div>
                      </div>
                      <label
                        htmlFor="sorting-status-radio"
                        className="small-text medium-blue-green-text margin-right-small"
                      >
                        Status
                      </label>
                      <div className="custom-radio">
                        <input
                          type="radio"
                          name="sorting"
                          id="sorting-status-radio"
                          onChange={handleSortingChange}
                          checked={sorting && sorting === "status"}
                        />
                        <div className="radio-button"></div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item grid-item-short">
                    <button
                      className="small-medium-text button-normal button-normal-smaller"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/add-customer");
                      }}
                    >
                      Add Customer
                    </button>
                    <button
                      className="small-medium-text button-elevated button-elevated-smaller"
                      onClick={() => {
                        history.push("/inventory");
                      }}
                    >
                      View Inventory
                    </button>
                  </div>
                </div>
                <div className="grid-row">
                  {storeVisits &&
                    storeVisits.map((sv) => {
                      return storeVisitInteractive ? (
                        <div className="ten-grid-item" key={sv._id}>
                          <div className="ten-grid-item-heading white-text medium-text">
                            Store Visit
                          </div>
                          <div className="ten-grid-item-body">
                            <div className="ten-grid-item-row">
                              <div className="time small-text dark-amber-text">
                                {format(new Date(sv.date), "p")}
                              </div>
                              <div className="sales-name small-text dark-blue-green-text">
                                {`${sv.cycle.primarySales.firstName} ${sv.cycle.primarySales.lastName}`}
                              </div>
                              {/* {console.log(sv)} */}
                              {!sv.cycle.sentToDms ? (
                                <button
                                  className="button-normal button-normal-smallest"
                                  ref={dmsButtonHandle}
                                  onClick={() => {
                                    if (dmsButtonHandle.current) {
                                      dmsButtonHandle.current.style.display =
                                        "none";
                                    }

                                    if (
                                      sv.customer.dealership.toString() ===
                                      "61fe9c5425c57c0016cf649e"
                                    ) {
                                      alert(
                                        "still building integration with Friday Systems"
                                      );
                                    } else if (
                                      sv.customer.dealership.toString() ===
                                      "6509b0684940b2001418b7df"
                                    ) {
                                      alert(
                                        "DMS integration not yet built for ICars"
                                      );
                                    } else {
                                      dmsPush(sv.customer._id);
                                    }
                                  }}
                                >
                                  DMS
                                </button>
                              ) : (
                                <p className="small-text dark-red-text">
                                  {sv.cycle.cplDealNumber
                                    ? sv.cycle.cplDealNumber
                                    : ""}
                                </p>
                              )}
                            </div>
                            <div className="grey-container">
                              {sv.customer.face ? (
                                <div
                                  className="
                  ten-grid-item-row
                  margin-top-small
                "
                                >
                                  <img
                                    className="avatar-medium-small"
                                    alt="customer_face"
                                    src={`data:image/jpeg;base64,${sv.customer.face}`}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="
                  ten-grid-item-row ten-grid-item-row-avatar
                  margin-top-small
                "
                                >
                                  <svg className="icon-smaller icon-customer-avatar">
                                    <use href="../../icons/symbol-defs.svg#icon-user-circle-o"></use>
                                  </svg>
                                </div>
                              )}

                              <div className="ten-grid-item-row">
                                <div className="customer-name small-medium-text dark-blue-text">
                                  {`${sv.customer.firstName} ${sv.customer.lastName}`}
                                </div>
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              <div className="stock small-text medium-blue-text">
                                {sv &&
                                  sv.cycle &&
                                  sv.cycle.vehicle &&
                                  sv.cycle.vehicle.stock}
                              </div>
                              <div className="type small-text medium-blue-text">
                                {sv &&
                                  sv.cycle &&
                                  sv.cycle.vehicle &&
                                  sv.cycle.vehicle.type}
                              </div>
                              <div className="year small-text medium-blue-text">
                                {sv &&
                                  sv.cycle &&
                                  sv.cycle.vehicle &&
                                  sv.cycle.vehicle.year}
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              <div className="make small-text medium-blue-text">
                                {sv &&
                                  sv.cycle &&
                                  sv.cycle.vehicle &&
                                  sv.cycle.vehicle.make}
                              </div>
                              <div className="model small-text medium-blue-text">
                                {sv &&
                                  sv.cycle &&
                                  sv.cycle.vehicle &&
                                  sv.cycle.vehicle.model}
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              <div className="vin small-text medium-blue-text">
                                {sv &&
                                  sv.cycle &&
                                  sv.cycle.vehicle &&
                                  sv.cycle.vehicle.vin}
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              {sv.status === "sold" && (
                                <div className="status medium-text medium-green-text">
                                  Sold
                                </div>
                              )}
                              {sv.status === "pending" && (
                                <div className="status medium-text medium-amber-text">
                                  Pending
                                </div>
                              )}
                              {sv.status === "not-sold" && (
                                <div className="status medium-text medium-red-text">
                                  Not Sold
                                </div>
                              )}
                            </div>
                            <div className="ten-grid-item-row">
                              <button
                                className="
                  button-normal-smaller button-normal
                  medium-text
                  flex-grow
                  margin-left-small margin-right-small
                "
                                name="view-store-visit"
                                id={JSON.stringify(sv)}
                                onClick={handleDisplayChange}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ten-grid-item fifty-opacity cursor-none"
                          key={sv._id}
                        >
                          <div className="ten-grid-item-heading white-text medium-text">
                            Store Visit
                          </div>
                          <div className="ten-grid-item-body">
                            <div className="ten-grid-item-row">
                              <div className="time small-medium-text dark-amber-text">
                                {format(new Date(sv.date), "p")}
                              </div>
                              <div className="sales-name small-medium-text dark-blue-green-text">
                                {`${sv.cycle.primarySales.firstName} ${sv.cycle.primarySales.lastName}`}
                              </div>
                            </div>
                            <div className="grey-container">
                              <div
                                className="
              ten-grid-item-row ten-grid-item-row-avatar
              margin-top-small
            "
                              >
                                <svg className="icon-smaller icon-customer-avatar">
                                  <use href="../../icons/symbol-defs.svg#icon-user-circle-o"></use>
                                </svg>
                              </div>
                              <div className="ten-grid-item-row">
                                <div className="customer-name small-medium-text dark-blue-text">
                                  {`${sv.customer.firstName} ${sv.customer.lastName}`}
                                </div>
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              <div className="stock small-text medium-blue-text">
                                {sv.cycle.vehicle.stock}
                              </div>
                              <div className="type small-text medium-blue-text">
                                {sv.cycle.vehicle.type}
                              </div>
                              <div className="year small-text medium-blue-text">
                                {sv.cycle.vehicle.year}
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              <div className="make small-text medium-blue-text">
                                {sv.cycle.vehicle.make}
                              </div>
                              <div className="model small-text medium-blue-text">
                                {sv.cycle.vehicle.model}
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              <div className="vin small-text medium-blue-text">
                                {sv.cycle.vehicle.vin}
                              </div>
                            </div>
                            <div className="ten-grid-item-row">
                              {sv.status === "sold" && (
                                <div className="status medium-text medium-green-text">
                                  Sold
                                </div>
                              )}
                              {sv.status === "pending" && (
                                <div className="status medium-text medium-amber-text">
                                  Pending
                                </div>
                              )}
                              {sv.status === "not-sold" && (
                                <div className="status medium-text medium-red-text">
                                  Not Sold
                                </div>
                              )}
                            </div>
                            <div className="ten-grid-item-row">
                              <button
                                className="
              button-normal-smaller button-normal
              medium-text
              flex-grow
              margin-left-small margin-right-small
            "
                                name="view-store-visit"
                                id={JSON.stringify(sv)}
                                onClick={handleDisplayChange}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </main>
          )}
          {viewStoreVisit && (
            <div className="popup" id="popup-store-visit" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Store Visit
                  </p>
                  <div onClick={handleCancel} id="hide-store-visit">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="module module-full-width">
                    <div className="module-main">
                      <div className="module-data dark-blue-green-text">
                        {currentStoreVisit &&
                          `${currentStoreVisit.customer.firstName} ${currentStoreVisit.customer.lastName}`}
                      </div>

                      <div className="module-data dark-blue-green-text">
                        {currentStoreVisit &&
                          currentStoreVisit.customer &&
                          currentStoreVisit.customer.phoneNumber &&
                          typeof currentStoreVisit.customer.phoneNumber ===
                            "string" &&
                          parsePhoneNumber(
                            currentStoreVisit.customer.phoneNumber
                          ) &&
                          parsePhoneNumber(
                            currentStoreVisit.customer.phoneNumber
                          ).formatNational("US")}
                      </div>

                      <div className="module-data dark-blue-green-text">
                        {currentStoreVisit &&
                          currentStoreVisit.customer.emailAddress}
                      </div>
                    </div>
                    <div
                      className="module-section module-section-normal"
                      id={JSON.stringify(currentStoreVisit.cycle)}
                      onClick={handleViewCycle}
                    >
                      <div className="white-text">Cycle:</div>
                      <div className="white-text">
                        {currentStoreVisit &&
                          currentStoreVisit.cycle.cycleNumber}
                        {/* {format(
                          new Date(currentStoreVisit.cycle.createdAt),
                          "P"
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <label
                      htmlFor="cycle-type"
                      className="small-text medium-blue-text margin-right-large"
                    >
                      Date:
                    </label>

                    <div className="small-text dark-blue-green-text">
                      {currentStoreVisit &&
                        format(new Date(currentStoreVisit.date), "P")}
                    </div>

                    <label
                      htmlFor="cycle-type"
                      className="small-text medium-blue-text margin-right-large margin-left-medium"
                    >
                      Source:
                    </label>
                    <div className="form-field small-text medium-blue-green-text flex-grow">
                      {currentStoreVisit &&
                      currentStoreVisit.cycle.cycleType !== "internet"
                        ? currentStoreVisit.cycle.cycleType
                        : currentStoreVisit.cycle.internetSource}
                    </div>
                  </div>

                  <div className="form-row">
                    <label
                      htmlFor="appointment-bool"
                      className="medium-blue-text small-text"
                    >
                      Appointment:
                    </label>
                    <div className="small-text margin-left-medium">
                      {currentStoreVisit && currentStoreVisit.hasAppointment ? (
                        <span className="dark-green-text">True</span>
                      ) : (
                        <span className="medium-red-text">False</span>
                      )}
                    </div>
                    <label
                      htmlFor="bdc-bool"
                      className="medium-blue-text small-text margin-left-medium"
                    >
                      BDC:
                    </label>
                    <div className="small-text margin-left-medium">
                      {currentStoreVisit && currentStoreVisit.cycle.isBdc ? (
                        <span className="dark-green-text">True</span>
                      ) : (
                        <span className="medium-red-text">False</span>
                      )}
                    </div>
                    <button
                      className="button-normal button-normal-smaller flex-grow small-text margin-left-medium"
                      name="view-store-visit-notes"
                      id={JSON.stringify(currentStoreVisit)}
                      onClick={handleDisplayChange}
                    >
                      View All Notes
                    </button>
                  </div>

                  <div className="form-row">
                    <label
                      htmlFor="salesperson-select"
                      className="medium-text medium-blue-text margin-right-small"
                    >
                      Salesperson:
                    </label>
                    <select
                      name="salesperson"
                      id="salesperson"
                      className="form-select flex-grow small-text dark-blue-green-text"
                      value={currentSalesPerson}
                      onChange={handleSalesPersonChange}
                    >
                      <option value="">BDC</option>
                      {currentSalesPeople.map((salesperson) => {
                        return (
                          <option key={salesperson._id} value={salesperson._id}>
                            {`${salesperson.firstName} ${salesperson.lastName}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="module module-full-width margin-none">
                    <div className="flex-column-center-evenly flex-grow hide-overflow">
                      <div className="form-row">
                        <input
                          className="form-field form-field-left dark-blue-green-text flex-grow"
                          type="text"
                          size="6"
                          name="stock"
                          placeholder="Stock"
                          value={enteredStock && enteredStock}
                          onChange={handleVehicleChange}
                        />
                        <select
                          className="form-field dark-blue-green-text flex-grow"
                          name="type"
                          value={enteredVehicleType && enteredVehicleType}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-type">Select Type</option>
                          <option value="new">New</option>
                          <option value="used">Used</option>
                        </select>
                        <select
                          className="form-field dark-blue-green-text flex-grow"
                          name="make"
                          value={enteredMake && enteredMake.toLowerCase()}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-make">Select Make</option>
                          {commonMakes &&
                            commonMakes.map((make) => {
                              return (
                                <option
                                  key={make.toLowerCase()}
                                  value={make.toLowerCase()}
                                >
                                  {make.toLowerCase()}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-row form-row-bottom">
                        <select
                          className="form-field form-field-left dark-blue-green-text flex-grow"
                          name="year"
                          value={enteredYear && enteredYear}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-year">Year</option>
                          {years.map((year) => {
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                          <option value="antique">Antique</option>
                        </select>
                        <select
                          className="form-field form-field-left dark-blue-green-text flex-grow"
                          name="model"
                          value={enteredModel && enteredModel.toLowerCase()}
                          onChange={handleVehicleChange}
                        >
                          <option value="select-model">Model</option>

                          {currentModels &&
                            currentModels.map((cur) => {
                              return (
                                <option
                                  key={cur.toLowerCase()}
                                  value={cur.toLowerCase()}
                                >
                                  {cur.toLowerCase()}
                                </option>
                              );
                            })}
                        </select>
                        <input
                          className="form-field dark-blue-green-text flex-grow"
                          type="text"
                          name="vin"
                          placeholder="VIN"
                          size="17"
                          value={enteredVin && enteredVin}
                          onChange={handleVehicleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <label
                      htmlFor="status-sold-spot-radio"
                      className="small-text medium-blue-text"
                    >
                      Sold Spot
                    </label>
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="showroom-status"
                        id="status-sold-spot-radio"
                        onChange={handleStatusChange}
                        checked={status && status === "sold"}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="status-pending-radio"
                      className="small-text medium-blue-text"
                    >
                      Sold Deposit
                    </label>
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="showroom-status"
                        id="status-pending-radio"
                        onChange={handleStatusChange}
                        checked={status && status === "pending"}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="status-not-sold-radio"
                      className="small-text medium-blue-text"
                    >
                      Not Sold
                    </label>
                    <div className="custom-radio">
                      <input
                        type="radio"
                        name="showroom-status"
                        id="status-not-sold-radio"
                        onChange={handleStatusChange}
                        checked={status && status === "not-sold"}
                      />
                      <div className="radio-button"></div>
                    </div>
                  </div>
                  <textarea
                    className="popup-section popup-section-smaller popup-section-margin-bottom small-text dark-blue-green-text"
                    placeholder="Your Note Here..."
                    onChange={handleNoteChange}
                    value={currentNote}
                  ></textarea>
                  <div className="form-row">
                    <button
                      className="button-elevated medium-large-text flex-grow"
                      onClick={handleSaveStoreVisit}
                    >
                      Save
                    </button>
                    {/* <button className="button-negative medium-large-text flex-grow margin-left-medium" onClick={handleDeleteStoreVisit}>
                      Delete
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {viewStoreVisitNotes && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Add Store Visit Note
                  </p>
                  <div onClick={handleCancel} id="hide-store-visit-notes">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <textarea
                    name="new-note"
                    id="new-note"
                    className="popup-body-container medium-large-text dark-blue-green-text"
                    placeholder="Your Note Here..."
                    value={currentNote}
                    onChange={handleNoteChange}
                  ></textarea>
                  <div className="popup-body-container">
                    {currentStoreVisit &&
                      currentStoreVisit.customer.notes.map((note, index) => (
                        <div className="section-row" key={index}>
                          <div className="section-row-info">
                            <div className="section-row-info-row dark-blue-text tiny-text">
                              {format(new Date(note.createdAt), "P")}
                            </div>
                            <div className="section-row-info-row dark-blue-text small-text">
                              {format(new Date(note.createdAt), "p")}
                            </div>
                            <div className="section-row-info-row section-row-info-row-bottom dark-blue-text small-text">
                              {note.writtenBy}
                            </div>
                          </div>
                          <div className="section-row-content note">
                            {note.noteType === "actiondeleted" ||
                            note.noteType === "actionincomplete" ||
                            note.noteType === "actionfailure" ||
                            note.noteType === "appointmentdeleted" ||
                            note.noteType === "cycleinactive" ||
                            note.noteType === "cycledeleted" ||
                            note.noteType === "cyclenothot" ? (
                              <div className="note-heading note-heading-negative">
                                {note.noteType}
                              </div>
                            ) : (
                              <div className="note-heading note-heading-elevated">
                                {note.noteType}
                              </div>
                            )}
                            <div className="note-body">{note.body}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="form-row">
                    {saveNoteButtonViewable ? (
                      <button
                        id="save-new-note"
                        className="button-normal large-text flex-grow"
                        onClick={saveNote}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        id="save-new-note"
                        className="button-normal button-normal-disabled large-text flex-grow"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {viewTodayAppointments && (
            <div className="popup" ref={mainBodyRef}>
              <div className="popup-content">
                <div className="popup-header">
                  <p className="large-text white-text center-text flex-grow">
                    Today's Appointments
                  </p>
                  <div onClick={handleCancel} id="hide-today-appointments">
                    <svg className="icon-small icon-small-back">
                      <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                    </svg>
                  </div>
                </div>
                <div className="popup-body popup-body-evenly">
                  <div className="flex-row-center-evenly three-quarter-width">
                    <label
                      htmlFor="sorting-appointment-time-radio"
                      className="medium-text medium-blue-green-text margin-right-small"
                    >
                      Time
                    </label>
                    <div className="custom-radio margin-right-small">
                      <input
                        type="radio"
                        name="appt-sorting"
                        id="sorting-appointment-time-radio"
                        onChange={handleApptSortingChange}
                        checked={apptSorting && apptSorting === "time"}
                      />
                      <div className="radio-button"></div>
                    </div>
                    <label
                      htmlFor="sorting-appointment-setter-radio"
                      className="medium-text medium-blue-green-text margin-right-small"
                    >
                      Setter
                    </label>
                    <div className="custom-radio margin-right-small">
                      <input
                        type="radio"
                        name="appt-sorting"
                        id="sorting-appointment-setter-radio"
                        onChange={handleApptSortingChange}
                        checked={apptSorting && apptSorting === "setter"}
                      />
                      <div className="radio-button"></div>
                    </div>
                  </div>
                  <div className="full-width">
                    <div className="flex-row-center-between full-width dark-blue-text medium-text">
                      <div>Time</div>
                      <div>User</div>
                      <div>Customer</div>
                      <button className="tiny-text button-normal button-normal-smallest invisible">
                        View Cycle
                      </button>
                    </div>{" "}
                    {todayAppointments &&
                      todayAppointments.map((appt, idx) => {
                        let bg = "";
                        idx % 2 ? (bg = "white") : (bg = "whitesmoke");
                        // console.log(appt);
                        return (
                          <div
                            className="flex-row-center-between full-width padding-small"
                            style={{
                              backgroundColor: `${bg}`,
                            }}
                            key={idx}
                          >
                            {appt.status === "show" && (
                              <div className="tiny-text dark-green-text">
                                {format(new Date(appt.date), "p")}
                              </div>
                            )}
                            {appt.status === "no-show" && (
                              <div className="tiny-text dark-red-text">
                                {format(new Date(appt.date), "p")}
                              </div>
                            )}
                            {appt.status === "pending" && (
                              <div className="tiny-text medium-amber-text">
                                {format(new Date(appt.date), "p")}
                              </div>
                            )}
                            <div className="tiny-text dark-amber-text">
                              {appt.setter}
                            </div>
                            <div className="tiny-text dark-blue-text">
                              {appt.customerName}
                            </div>
                            {appt.cycle ? (
                              <button
                                className="tiny-text button-normal button-normal-smaller"
                                onClick={() => {
                                  localStorage.setItem(
                                    "localCurrentCustomer",
                                    JSON.stringify({ _id: appt.customer })
                                  );
                                  localStorage.setItem(
                                    "localCurrentCycle",
                                    JSON.stringify({
                                      _id: appt.cycle,
                                      customer: appt.customer,
                                    })
                                  );
                                  history.push("/cycle");
                                }}
                              >
                                View Cycle
                              </button>
                            ) : (
                              <button
                                className="medium-text button-normal button-normal-smaller"
                                onClick={() => {
                                  localStorage.setItem(
                                    "localCurrentCustomer",
                                    JSON.stringify({ _id: appt.customer })
                                  );
                                  history.push("/customer");
                                }}
                              >
                                View Customer
                              </button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );

  // end of component
};

export default DeskLog;
