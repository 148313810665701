import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import format from "date-fns/format";
import parsePhoneNumber from "libphonenumber-js";
import DealershipContext from "../context/dealership/dealershipContext";

import UserContext from "../context/users/userContext";
import {
  addStoreVisit,
  createNewCycle,
  mergeNewlyCreatedCustomer,
} from "../functions/customerFunctions";

const DataMaintenance = () => {
  //
  const history = useHistory();
  //
  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);
  //
  const checkEnteredCustomerRef = useRef();
  const possibleDuplicateSection = useRef();
  const mainBodyRef = useRef();
  const mergeButton = useRef();
  //
  const [customersToMerge, setCustomersToMerge] = useState([]);
  const [searchedCustomerState, setSearchedCustomerState] = useState(null);
  const [searchResultsState, setSearchResultsState] = useState(null);
  const [loadingState, setLoadingState] = useState(true);
  const [mergedCustomer, setMergedCustomer] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const [merged, setMerged] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      if (mainBodyRef.current) {
        checkEnteredCustomerRef.current.click();
      }
    }, 5000);

    if (!dataReady) {
      if (dealershipContext && dealershipContext.searchResults) {
        localStorage.setItem(
          "localSearchResults",
          JSON.stringify(dealershipContext.searchResults)
        );
      }

      if (
        JSON.parse(localStorage.getItem("localSearchedCustomer")) &&
        JSON.parse(localStorage.getItem("localSearchResults"))
      ) {
        dealershipContext.setMatches(
          localStorage.getItem("localSearchResults")
        );

        if (mainBodyRef.current) {
          const finalCustomer = JSON.parse(
            localStorage.getItem("localSearchedCustomer")
          ).finalCustomer;

          setSearchedCustomerState(finalCustomer);
          setSearchResultsState(
            JSON.parse(localStorage.getItem("localSearchResults"))
          );
          setLoadingState(false);

          setDataReady(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [dealershipContext, dataReady]);

  const clearResults = () => {
    dealershipContext.clearMatches();
    localStorage.removeItem("localSearchedCustomer");
    localStorage.removeItem("localSearchResults");
  };

  const addCustomer = async () => {
    try {
      // console.log(localStorage.getItem("currentFace"));

      if (mainBodyRef.current) {
        setLoadingState(true);
      }
      let currentLicense = localStorage.getItem("currentLicense");
      let currentFace = localStorage.getItem("currentFace");

      if (JSON.parse(localStorage.getItem("localSearchedCustomer"))) {
        const localSearchedCustomer = JSON.parse(
          localStorage.getItem("localSearchedCustomer")
        );
        if (merged) {
          // console.log(localSearchedCustomer);
          let cycleCreated = null;
          if (
            mergedCustomer &&
            mergedCustomer.propertiesOfFutureCycle &&
            mergedCustomer.propertiesOfFutureCycle.returnCycleProperties
          ) {
            cycleCreated = await createNewCycle(
              localSearchedCustomer.finalCustomer,
              "internet",
              localSearchedCustomer.searchedCustomerVehicle,
              localSearchedCustomer.searchedCustomerSalesPerson._id,
              "",
              "",
              "",
              mergedCustomer.propertiesOfFutureCycle.returnCycleProperties
                .internetSource
            );
          } else {
            cycleCreated = await createNewCycle(
              localSearchedCustomer.finalCustomer,
              "internet",
              localSearchedCustomer.searchedCustomerVehicle,
              localSearchedCustomer.searchedCustomerSalesPerson._id
            );
          }

          if (cycleCreated && cycleCreated.customer) {
            localStorage.setItem(
              "localCurrentCustomer",
              JSON.stringify(localSearchedCustomer.finalCustomer)
            );
            localStorage.setItem(
              "localCurrentCycle",
              JSON.stringify(cycleCreated.cycle)
            );
            if (localSearchedCustomer.searchedCustomerType === "phone") {
              history.push("/appointment-conditional");
            } else {
              asyncAddStoreVisit(
                cycleCreated.cycle,
                localSearchedCustomer.finalCustomer
              );
            }
          } else {
            console.log("DataMaintenance.addCustomer: missing data from API");
            history.push("/add-customer");
          }
        } else {
          // console.log(currentFace);
          const addedCustomer = await dealershipContext.addDealershipCustomer(
            localSearchedCustomer.finalCustomer,
            currentLicense,
            currentFace
          );

          // console.log(localSearchedCustomer);

          const cycleCreated = await createNewCycle(
            addedCustomer,
            localSearchedCustomer.searchedCustomerType,
            localSearchedCustomer.searchedCustomerVehicle,
            localSearchedCustomer.searchedCustomerSalesPerson._id
          );

          if (cycleCreated && cycleCreated.customer) {
            localStorage.setItem(
              "localCurrentCustomer",
              JSON.stringify(addedCustomer)
            );
            localStorage.setItem(
              "localCurrentCycle",
              JSON.stringify(cycleCreated.cycle)
            );
            if (localSearchedCustomer.searchedCustomerType === "phone") {
              history.push("/appointment-conditional");
            } else {
              asyncAddStoreVisit(cycleCreated.cycle, addedCustomer);
            }
          } else {
            console.log(
              "DataMaintenance.addCustomer: do not have searched customer context...therefore cannot add customer"
            );
          }
        }
        localStorage.setItem("currentLicense", "");
        localStorage.setItem("currentFace", "");
        clearResults();
      } else {
        console.log("DataMaintenance.addCustomer: missing data from API");
        history.push("/add-customer");
      }
    } catch (error) {
      console.log("DataMaintenance.addCustomer: ", error);
    }
    // if we reload data maintenance and then click "no duplicate found", we get ERROR: new cycle not returned...
  };
  //
  const asyncAddStoreVisit = async (cycle, customer) => {
    try {
      const userRole = await userContext.checkUserRole(
        JSON.parse(localStorage.getItem("localSearchedCustomer"))
          .searchedCustomerSalesPerson._id
      );

      if (userRole === "bdc" || userRole === "bdcmanager") {
        alert("Please select a salesperson before adding a new Store Visit.");
      } else {
        const storeVisitAdded = await addStoreVisit(cycle._id);

        localStorage.setItem("localCurrentCustomer", JSON.stringify(customer));
        localStorage.setItem("localCurrentCycle", JSON.stringify(cycle));

        if (!storeVisitAdded) {
          alert("could not add store visit");
        }

        history.push("/cycle");
      }
    } catch (error) {
      console.log("Cycle.asyncAddStoreVisit: ", error.message);
    }
  };

  const checkEnteredCustomer = (e) => {
    if (!e.target.value || e.target.value === " ") {
      history.push("/add-customer");
    }
  };

  // const checkSearchResults = (e) => {
  //   if (!e.target.textContent) {
  //     history.push("/add-customer");
  //   }
  // };

  const setMergeButtonDisplay = (e) => {
    if (e.target.classList.contains("checkbox-clickable")) {
      const checkBoxes = Array.from(
        document.getElementsByClassName("checkbox-clickable")
      );
      const newArray = customersToMerge;
      let checkedBox = false;
      let customerId;
      for (let i = 0; i < checkBoxes.length; i++) {
        // console.log("iteration");
        customerId = checkBoxes[i].getAttribute("id");
        if (checkBoxes[i].checked) {
          // iteration for this checked checkbox
          // console.log(customerId, " is checked");
          if (!newArray.includes(customerId)) {
            // console.log(`${customerId} is not already in the array, pushing`);
            newArray.push(customerId);
          } else {
            // console.log(`newArray already has ${customerId} in it`);
          }

          if (
            searchedCustomerState &&
            !newArray.includes(searchedCustomerState._id)
          ) {
            newArray.push(searchedCustomerState._id);
          } else {
            // either searchedCustomerState is null or newArray already has the _id
          }

          // console.log("CUSTOMERS TO MERGE AFTER THIS ITERATION: ", newArray);
          checkedBox = true;
        } else {
          // iteration for an unchecked checkbox
          // here we remove unchecked customers from our customers to merge
          if (newArray.includes(customerId)) {
            newArray.splice(newArray.indexOf(customerId), 1);
          }
        }
      }
      // this is after every checkbox has been iterated through

      if (checkedBox) {
        setCustomersToMerge(newArray);
        mergeButton.current.classList.remove("button-normal-disabled");
      } else {
        // console.log("no box is checked");
        // no box is checked
        if (searchedCustomerState) {
          mergeButton.current.classList.add("button-normal-disabled");
        } else {
          // console.log(
          //   "ERROR: cannot properly set customers to merge without searchedCustomerState"
          // );
        }
      }
    }
  };

  const mergeCustomerHandler = async () => {
    // now we should have the correct array of _ids in customersToMerge
    // let's start by unchecking all currently checked boxes...
    const checkBoxes = Array.from(
      document.getElementsByClassName("checkbox-clickable")
    );
    checkBoxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    });

    // first we need to send our customersToMerge to the server for actual merging of data
    const lSC = JSON.parse(localStorage.getItem("localSearchedCustomer"));

    const mergedCustomerData = await mergeNewlyCreatedCustomer(
      customersToMerge,
      searchedCustomerState,
      {
        salesPerson: lSC.searchedCustomerSalesPerson,
        type: lSC.searchedCustomerType,
        vehicle: lSC.searchedCustomerVehicle,
      }
    );

    //
    // next we need to set the matches without anything in the mergeArray
    // console.log("ABOUT TO SET POSSIBLE DUPLICATES");
    dealershipContext.purgeMerged(customersToMerge);

    if (mergedCustomerData && mainBodyRef.current) {
      setMergedCustomer(mergedCustomerData);
      const prevLSC = JSON.parse(localStorage.getItem("localSearchedCustomer"));
      prevLSC.finalCustomer = mergedCustomerData.newCustomer;
      localStorage.setItem("localSearchedCustomer", JSON.stringify(prevLSC));
      dealershipContext.findMatches(mergedCustomerData.newCustomer);

      setMerged(true);
      setDataReady(false);
    }
    // done!
  };
  //
  // Form input
  //

  //
  // Media
  //

  return (
    <Fragment>
      {loadingState ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
          <input
            className="hidden"
            ref={checkEnteredCustomerRef}
            onClick={checkEnteredCustomer}
            onChange={checkEnteredCustomer}
            value={
              searchedCustomerState ? searchedCustomerState.firstName : " "
            }
          />
        </main>
      ) : (
        <main className="popup" id="popup-data-maintenance" ref={mainBodyRef}>
          <div className="popup-content">
            <div className="popup-header">
              <p className="large-text white-text center-text flex-grow">
                Data Maintenance
              </p>
              <Link
                id="add-customer-exit"
                to={"/dashboard"}
                onClick={clearResults}
              >
                <svg className="icon-small icon-small-back">
                  <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                </svg>
              </Link>
            </div>
            <div className="popup-body popup-body-evenly">
              <div className="medium-text medium-blue-text">
                Current Information:
              </div>

              <div className="module module-full-width">
                <div className="module-main">
                  <div className="module-data dark-blue-green-text">
                    {searchedCustomerState && searchedCustomerState.firstName}{" "}
                    {searchedCustomerState && searchedCustomerState.lastName}
                    <input
                      className="hidden"
                      ref={checkEnteredCustomerRef}
                      onChange={checkEnteredCustomer}
                      onClick={checkEnteredCustomer}
                      value={
                        searchedCustomerState && searchedCustomerState.firstName
                      }
                    />
                  </div>
                  <div className="module-data dark-blue-green-text">
                    {searchedCustomerState &&
                      searchedCustomerState.phoneNumber &&
                      typeof searchedCustomerState.phoneNumber === "string" &&
                      parsePhoneNumber(searchedCustomerState.phoneNumber) &&
                      parsePhoneNumber(
                        searchedCustomerState.phoneNumber
                      ).formatNational()}
                  </div>
                  <div className="module-data dark-blue-green-text">
                    {searchedCustomerState &&
                      searchedCustomerState.emailAddress}
                  </div>
                  <div className="module-data dark-blue-text">
                    {searchedCustomerState && searchedCustomerState.customerId}
                  </div>
                </div>
                <div className="module-section module-section-normal">
                  <div className="tiny-text white-text">Last Updated</div>

                  <div className="tiny-text white-text">
                    {searchedCustomerState && searchedCustomerState.updatedAt
                      ? format(new Date(searchedCustomerState.updatedAt), "P")
                      : "N/A"}
                  </div>
                </div>
              </div>

              <div className="medium-text medium-blue-text">
                Possible Duplicates:
              </div>

              <form
                action=""
                className="popup-section popup-section-taller"
                ref={possibleDuplicateSection}
                onClick={setMergeButtonDisplay}
              >
                {searchResultsState &&
                  searchResultsState.map((result, index) => {
                    return (
                      <div className="module-checkbox-container" key={index}>
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            name="customer-search-results"
                            id={result._id}
                            className="checkbox-clickable"
                          />
                          <div className="checkbox margin-right-small small-text center-text"></div>
                        </div>
                        <div className="module module-full-width">
                          <div className="module-main">
                            {/*
                            <div
                              className="hidden"
                              ref={checkSearchResultsRef}
                              onClick={checkSearchResults}
                            >
                              {result && result.firstName}
                            </div>
                            */}

                            <div className="module-data dark-blue-green-text">
                              {result && result.firstName}{" "}
                              {result && result.lastName}
                            </div>
                            <div className="module-data dark-blue-green-text">
                              {result &&
                                typeof result.phoneNumber === "string" &&
                                parsePhoneNumber(result.phoneNumber) &&
                                parsePhoneNumber(
                                  result.phoneNumber
                                ).formatNational()}
                            </div>

                            <div className="module-data dark-blue-green-text">
                              {result && result.emailAddress}
                            </div>
                            {/* <div className="module-data dark-blue-text">
                         {result && result.customerId}
                       </div>*/}
                          </div>
                          <div className="module-section module-section-normal">
                            <div className="white-text">Last Updated</div>

                            <div className="white-text">
                              {result &&
                                format(new Date(result.updatedAt), "P")}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </form>

              <div className="form-row">
                <Link
                  to="/manual-customer-number"
                  className="button-normal small-medium-text flex-grow margin-right-small"
                >
                  Manual Entry
                </Link>
                <button
                  className="button-normal button-normal-disabled button-disabled small-medium-text flex-grow"
                  ref={mergeButton}
                  onClick={mergeCustomerHandler}
                >
                  Merge With Current
                </button>
              </div>

              <div className="form-row">
                <button
                  onClick={addCustomer}
                  className="button-elevated medium-large-text flex-grow"
                >
                  No Duplicate Found
                </button>
              </div>
            </div>
          </div>
        </main>
      )}
    </Fragment>
  );
};

export default DataMaintenance;
