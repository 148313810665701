import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { replaceActionSchedule } from "../../functions/customerFunctions";

const ActionSchedule = ({
  currentActions,
  currentCustomerId,
  currentCycleId,
  deleteSelectedActionsHandler,
  followUpTemplates,
  handleActionSelect,
  handleCancel,
  handleDisplayChange,
  selectAllActions,
  selectedFutureActionIds,
}) => {
  return (
    <div className="popup" id="popup-action-schedule">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Edit Action Schedule
          </p>
          <div onClick={handleCancel} id="hide-edit-action-schedule">
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div
            className="popup-section popup-section-taller"
            id="action-schedule-list"
          >
            {currentActions &&
              currentActions.map((action) => {
                if (action.status === "incomplete") {
                  return (
                    <div className="module-checkbox-container" key={action._id}>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          name="selected-future-action"
                          className="checkbox-handle"
                          id={action._id}
                          key={action._id}
                          onClick={handleActionSelect}
                        />
                        <div className="checkbox margin-right-small small-text center-text"></div>
                      </div>
                      <div className="module module-full-width module-bottom-margin">
                        <div className="module-section module-section-normal white-text center-text">
                          {action.status}
                        </div>
                        <div className="module-main module-main-right">
                          <div className="dark-blue-text">
                            {action.actionType}
                          </div>

                          <div className="small-text dark-blue-text">
                            Due: {format(new Date(action.dueDate), "P")}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="module-checkbox-container module-checkbox-container-disabled"
                      key={action._id}
                    >
                      <div className="module-checkbox-container">
                        <div className="module module-full-width module-bottom-margin">
                          {action.status === "complete" && (
                            <div className="module-section module-section-elevated white-text center-text">
                              {action.status}
                            </div>
                          )}

                          {action.status === "failure" && (
                            <div className="module-section module-section-negative white-text center-text">
                              {action.status}
                            </div>
                          )}

                          <div className="module-main module-main-right">
                            <div className="dark-blue-text">
                              {action.actionType}
                            </div>

                            <div className="small-text dark-blue-text">
                              Due: {format(new Date(action.dueDate), "P")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div className="form-row">
            <select
              name="follow-up-schedule-select"
              id="follow-up-schedule-select"
              className="form-select small-medium-text dark-blue-green-text flex-grow"
              onChange={(e) => {
                if (e.target.value !== "placeholder") {
                  const confirmed = window.confirm(
                    "replace current action schedule with selected action schedule?"
                  );
                  if (confirmed) {
                    replaceActionSchedule(
                      currentCustomerId,
                      currentCycleId ? currentCycleId : null,
                      e.target.value
                    );
                  }
                }
              }}
            >
              <option value="placeholder">Select New Schedule</option>
              {followUpTemplates.map((t) => (
                <option key={t.followUpTemplateId} value={t.followUpTemplateId}>
                  {t.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            {/* need to code this */}
            <button
              className="button-neutral button-neutral-smaller large-text flex-grow"
              onClick={selectAllActions}
            >
              Select All
            </button>
          </div>
          <div className="form-row">
            {selectedFutureActionIds && selectedFutureActionIds.length ? (
              <button
                onClick={deleteSelectedActionsHandler}
                className="button-negative button-negative-smaller large-text flex-grow"
                id="delete-actions-button"
              >
                {/* here too  */}
                Delete Selected
              </button>
            ) : (
              <button
                className="button-negative button-negative-smaller button-negative-disabled large-text flex-grow"
                id="delete-actions-button"
              >
                Delete Selected
              </button>
            )}
          </div>
          <div className="form-row">
            <button
              onClick={handleDisplayChange}
              className="button-normal button-normal-smaller large-text flex-grow"
              name="view-compose-new-action"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ActionSchedule.propTypes = {
  currentActions: PropTypes.array.isRequired,
  currentCustomerId: PropTypes.string.isRequired,
  currentCycleId: PropTypes.string,
  deleteSelectedActionsHandler: PropTypes.func.isRequired,
  followUpTemplates: PropTypes.array.isRequired,
  handleActionSelect: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDisplayChange: PropTypes.func.isRequired,
  selectAllActions: PropTypes.func.isRequired,
  selectedFutureActionIds: PropTypes.array,
};

export default ActionSchedule;
