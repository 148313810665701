import React, { useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";

const PrivateEmailer = () => {
  const to = useRef();
  const from = useRef();
  const subject = useRef();
  const body = useRef();

  const sendPrivateEmail = async (
    emailTo,
    emailFrom,
    emailSubject,
    emailBody
  ) => {
    try {
      const res = await axios.post(
        "/api/v1/email/send-private-email",
        { emailTo, emailFrom, emailSubject, emailBody },
        axiosConfig
      );

      if (res.data.success) {
        alert("Email Successfully Sent");
        window.location.reload();
      } else {
        throw new Error();
      }
    } catch (error) {
      alert("could not send email");
      window.location.reload();
    }
  };

  const handleSend = (e) => {
    sendPrivateEmail(
      to.current.value,
      from.current.value,
      subject.current.value,
      body.current.value
    );
  };

  return (
    <div className="popup" id="popup-appointment-conditional">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Private Emailer
          </p>
          <Link to="/">
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </Link>
        </div>
        <div className="popup-body">
          <div className="flex-column-center-evenly full-height">
            <input
              className="form-field medium-text"
              type="text"
              placeholder="to"
              ref={to}
            />
            <input
              className="form-field medium-text"
              type="text"
              placeholder="from"
              ref={from}
            />
            <input
              className="form-field medium-text"
              type="text"
              placeholder="subject"
              ref={subject}
            />
            <textarea
              className="popup-section popup-section-taller medium-text "
              type="text"
              placeholder="body"
              ref={body}
            ></textarea>
            <button className="large-text button-normal" onClick={handleSend}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateEmailer;
