import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./components/Landing";
import PublicContact from "./components/PublicContact";
import Routes from "./components/routing/Routes";
import "./App.scss";
import AuthState from "./context/auth/AuthState";
import DealershipState from "./context/dealership/DealershipState";
import UserState from "./context/users/UserState";
import setAuthToken from "./util/setAuthToken";
import ClientTextConsent from "./components/ClientTextConsent";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <AuthState>
      <DealershipState>
        <UserState>
          <Router>
            <Fragment>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/public-contact" component={PublicContact} />
                <Route
                  exact
                  path="/client-text-consent"
                  component={ClientTextConsent}
                />
                <Route component={Routes} />
              </Switch>
            </Fragment>
          </Router>
        </UserState>
      </DealershipState>
    </AuthState>
  );
};

export default App;
