import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import UserContext from "../context/users/userContext";
import DealershipContext from "../context/dealership/dealershipContext";

const Dashboard = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const dealershipContext = useContext(DealershipContext);

  const mainBodyRef = useRef();

  const [userState, setUserState] = useState(null);
  const [completeActionsState, setCompleteActionsState] = useState(null);
  const [incompleteActionsState, setIncompleteActionsState] = useState(null);
  const [urgentActionsState, setUrgentActionsState] = useState(null);
  const [actionLoadingState, setActionLoadingState] = useState(true);
  const [loadingState, setLoadingState] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [dealershipName, setDealershipName] = useState("");
  const [actionsButtonAvailable, setActionsButtonAvailable] = useState(true);
  const [performanceButtonAvailable, setPerformanceButtonAvailable] =
    useState(true);

  // ****** SCENARIO 1 ******
  // Need to load data from API if we don't already have it in the context
  // here, that data is user, complete actions, and incomplete actions
  // no user input

  useEffect(() => {
    // console.log("STEP 1 DASHBOARDSALES");
    if (!dataReady) {
      // console.log("STEP 2 v1 DATA NOT READY");
      // loadUser has to be a separate function because it is async
      // async functions don't work in useEffect
      loadUser();
    } else {
      // console.log("STEP 2 v2 DATA READY");
    }
    // eslint-disable-next-line
  }, []);

  const loadUser = async () => {
    // console.log("STEP 3 LOAD USER");
    let localCompleteActions, localIncompleteActions;

    // console.log("STEP 4 part 1 API CALL");
    const userResult = await userContext.getUserOnly();

    if (userResult && userResult.user && mainBodyRef.current) {
      if (!userResult.user.active || userResult.user.active === "false") {
        window.location.reload();
      }

      // User rights management
      if (userResult.user.role === "receptionist") {
        setActionsButtonAvailable(false);
        setPerformanceButtonAvailable(false);
      }
      if (userResult.user.role === "bdc") {
        setPerformanceButtonAvailable(false);
      }

      setUserState(userResult.user);
      setLoadingState(false);
    }

    const result = await userContext.getFullUser();

    const usersVerified = await dealershipContext.checkUsers();

    if (
      result &&
      result.completeActions &&
      result.incompleteActions &&
      usersVerified
    ) {
      const dealership = await userContext.getDealershipOfUser(
        userResult.user._id
      );

      let urgentActions = [];
      if (result.incompleteActions.length) {
        result.incompleteActions.forEach((incAct) => {
          if (
            incAct.actionType === "text-received" ||
            incAct.actionType === "email-received"
          ) {
            urgentActions.push(incAct);
          }
        });
      }

      localCompleteActions = result.completeActions;
      localIncompleteActions = result.incompleteActions;

      //
      localStorage.setItem("localCurrentActions", null);
      localStorage.setItem("localCurrentCustomer", null);
      localStorage.setItem("localCurrentCycle", null);

      if (mainBodyRef.current) {
        if (dealership) {
          setDealershipName(dealership.shortName);
        }
        setDataReady(true);
        setCompleteActionsState(localCompleteActions);
        setIncompleteActionsState(localIncompleteActions);
        console.log(urgentActions);
        setUrgentActionsState(urgentActions);
        setActionLoadingState(false);
        console.log("urgent actions set from short");
      }
    } else {
      alert("Error: do not have necessary data to load this page");
    }
    // console.log("about to call loadFullUser");
    loadFullUser();
  };

  const loadFullUser = async () => {
    // console.log("STEP 3 LOAD USER");
    let localCompleteActions, localIncompleteActions;

    // console.log("STEP 4 part 1 API CALL");
    const userResult = await userContext.getFullUser();

    if (userResult && userResult.user && mainBodyRef.current) {
      // User rights management
      if (userResult.user.role === "receptionist") {
        setActionsButtonAvailable(false);
        setPerformanceButtonAvailable(false);
      }
      if (userResult.user.role === "bdc") {
        setPerformanceButtonAvailable(false);
      }

      setUserState(userResult.user);
      setLoadingState(false);
    }

    const result = await userContext.getUser();

    const usersVerified = await dealershipContext.checkUsers();

    if (
      result &&
      result.completeActions &&
      result.incompleteActions &&
      usersVerified
    ) {
      const dealership = await userContext.getDealershipOfUser(
        userResult.user._id
      );

      let urgentActions = [];
      if (result.incompleteActions.length) {
        result.incompleteActions.forEach((incAct) => {
          if (
            incAct.actionType === "text-received" ||
            incAct.actionType === "email-received"
          ) {
            urgentActions.push(incAct);
          }
        });
      }

      localCompleteActions = result.completeActions;
      localIncompleteActions = result.incompleteActions;

      //
      localStorage.setItem("localCurrentActions", null);
      localStorage.setItem("localCurrentCustomer", null);
      localStorage.setItem("localCurrentCycle", null);

      if (mainBodyRef.current) {
        if (dealership) {
          setDealershipName(dealership.shortName);
        }
        setDataReady(true);
        setCompleteActionsState(localCompleteActions);
        setIncompleteActionsState(localIncompleteActions);
        setUrgentActionsState(urgentActions);
        console.log("urgent actions set from full");
      }
    } else {
      alert("Critical Error: do not have necessary data to load this page");
    }
    // console.log("done with loadFullUser");
  };

  return (
    <Fragment>
      {loadingState ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <main
          className="flex-column-center-center page-column"
          ref={mainBodyRef}
        >
          <div className="grid">
            <div className="grid-row">
              {userState.role === "superuser" ? (
                <div
                  className="grid-item grid-item-short cursor-pointer"
                  onClick={() => {
                    history.push("/superuser");
                  }}
                >
                  <h1 className="main-title main-title-smaller dark-red-text">
                    Superuser
                  </h1>
                  <p className="medium-text dark-red-text">
                    {userState && userState.firstName && userState.firstName}{" "}
                    {userState && userState.lastName && userState.lastName}
                    {"  "}|{"  "} {dealershipName && dealershipName}
                  </p>
                </div>
              ) : (
                <div className="grid-item grid-item-short grid-item-no-click">
                  <h1 className="main-title main-title-smaller dark-blue-text">
                    Dashboard
                  </h1>
                  <p className="medium-text dark-blue-text">
                    {userState && userState.firstName && userState.firstName}{" "}
                    {userState && userState.lastName && userState.lastName}
                    {"  "}|{"  "} {format(new Date(), "P")}
                  </p>
                </div>
              )}
            </div>
            <div className="grid-row">
              <Link to="/add-customer" className="grid-item">
                <svg className="icon icon-add-customer">
                  <use href="/icons/symbol-defs.svg#icon-user-plus"></use>
                </svg>
                <p className="large-text medium-blue-green-text">
                  Add Customer
                </p>
              </Link>
              <Link to="/customer-search" className="grid-item">
                <svg className="icon icon-my-customers">
                  <use href="/icons/symbol-defs.svg#icon-group1"></use>
                </svg>
                <p className="large-text dark-blue-text">Customer Search</p>
              </Link>
              <Link to="/desk-log" className="grid-item">
                <svg className="icon icon-sales-log">
                  <use href="/icons/symbol-defs.svg#icon-pencil"></use>
                </svg>
                <p className="large-text dark-green-text">Desk Log</p>
              </Link>
            </div>
            <div className="grid-row">
              {actionsButtonAvailable ? (
                <div
                  className="grid-item cursor-pointer"
                  onClick={() => {
                    history.push("/actions");
                  }}
                >
                  <svg className="icon icon-actions">
                    <use href="/icons/symbol-defs.svg#icon-dollar"></use>
                  </svg>
                  <p className="large-text medium-green-text">Actions</p>

                  {actionLoadingState ? (
                    <div className="lds-dual-ring"></div>
                  ) : (
                    <div className="flex-column-center-evenly">
                      {urgentActionsState && urgentActionsState.length && (
                        <p className="medium-text bright-red-text">
                          {urgentActionsState && urgentActionsState.length}{" "}
                          urgent
                        </p>
                      )}
                      <p className="medium-text dark-red-text margin-top-small">
                        {incompleteActionsState &&
                          incompleteActionsState.length}{" "}
                        incomplete
                      </p>
                      <p className="medium-text dark-green-text margin-top-small">
                        {completeActionsState && completeActionsState.length}{" "}
                        complete
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="grid-item grid-item-disabled"
                  onClick={() => {
                    history.push("/actions");
                  }}
                >
                  <svg className="icon icon-actions">
                    <use href="/icons/symbol-defs.svg#icon-dollar"></use>
                  </svg>
                  <p className="large-text medium-green-text">Actions</p>
                </div>
              )}

              {performanceButtonAvailable ? (
                <Link to="/performance" className="grid-item">
                  <svg className="icon icon-performance">
                    <use href="/icons/symbol-defs.svg#icon-graph"></use>
                  </svg>
                  <p className="large-text medium-amber-text">Performance</p>
                </Link>
              ) : (
                <Link to="/" className="grid-item grid-item-disabled">
                  <svg className="icon icon-performance">
                    <use href="/icons/symbol-defs.svg#icon-graph"></use>
                  </svg>
                  <p className="large-text medium-amber-text">Performance</p>
                </Link>
              )}
              <Link to="/inventory" className="grid-item">
                <svg className="icon icon-inventory">
                  <use href="/icons/symbol-defs.svg#icon-automobile"></use>
                </svg>
                <p className="large-text dark-blue-green-text">Inventory</p>
              </Link>
            </div>
          </div>
        </main>
      )}
    </Fragment>
  );

  // end of component
};

export default Dashboard;
