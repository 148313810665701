import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import { AUTHFAIL } from "../types";
import { axiosConfig } from "../../util/axiosConfig";
import setAuthToken from "../../util/setAuthToken";

const AuthState = (props) => {
  // these will be the pieces of state needed by the front end
  const initialState = {
    authCode: null,
    hasAuthCode: false,
    isAuthenticated: false,
    loading: true,
    userName: "",
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const checkAuthenticated = async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      let { data } = await axios.post("/api/v1/user/checkJWT", axiosConfig);
      if (data.success) {
        return {
          authenticated: true,
          hasAuthCode: data.currentUser.authCode ? true : false,
        };
      } else {
        throw new Error("server unable to verify JWT...");
      }
    } catch (error) {
      // console.log("AuthState.checkAuthenticated: ", error);
      dispatch({
        type: AUTHFAIL,
      });
      return false;
    }
  };

  //
  //
  //  LOGIN PART ONE
  //
  //  Components: LoginPartOne
  //
  //  INPUT: {
  //    username: String,
  //    password: String
  //  }
  //
  //  OUTPUT: {
  //    Boolean
  //  }
  //
  //

  const loginPartOne = async (username, password) => {
    try {
      const res = await axios.post(
        "/api/v1/user/login",
        {
          username,
          password,
        },
        axiosConfig
      );

      if (res.data.success) {
        localStorage.setItem("userName", res.data.username);
        return true;
      }
    } catch (error) {
      console.log("AuthState.loginPartOne: ", error.message);
      return false;
    }
  };

  //
  //  LOGIN PART TWO
  //
  //  Components: loginPartTwo
  //
  //  INPUT:
  //
  //  {
  //    enteredAuthCode: String,
  //    userName: String
  //  }
  //
  //
  //

  const loginPartTwo = async (enteredAuthCode, userName) => {
    try {
      const { data } = await axios.post(
        "/api/v1/user/auth",
        {
          enteredAuthCode,
          userName,
        },
        axiosConfig
      );

      if (data.success) {
        return true;
      }
    } catch (error) {
      // console.log("AuthState.loginPartTwo: ", error);
      dispatch({
        type: AUTHFAIL,
      });
      return false;
    }
  };

  const logout = async () => {
    try {
      const { data } = await axios.post("/api/v1/user/logout", {}, axiosConfig);
      if (data.success) {
        return true;
      }
    } catch (error) {
      // console.log("AuthState.logout: ", error);
    }
  };

  const resetPassword = async (currentPassword, newPassword) => {
    try {
      const res = await axios.post(
        "/api/v1/user/reset-password",
        { currentPassword, newPassword },
        axiosConfig
      );

      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("AuthState.resetPassword: ", error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authCode: state.authCode,
        hasAuthCode: state.hasAuthCode,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        userName: state.userName,
        checkAuthenticated,
        loginPartOne,
        loginPartTwo,
        logout,
        resetPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
