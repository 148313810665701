import React from "react";

const ClientTextConsent = () => {
  return (
    <div className="consent-form-parent">
      <div className="medium-large-text consent-form">
        <div className="main-title">Lead Zen</div>
        <div className="underscore">First Name:</div>
        <div className="underscore">Last Name:</div>
        <div className="underscore">Dealership:</div>
        <div className="underscore">Cell Phone #:</div>
        <div className="underscore">Today's Date:</div>
        <div className="consent-main">
          <input type="checkbox" id="consent-checked" />
          <div>
            I consent to receive Two Factor Authentication (2FA) codes and Lead
            Notifications via text message at the number above so that I can use
            the Lead Zen software that my employer is paying for me to be able
            to use.
          </div>
        </div>
        <div className="underscore">Signature:</div>
      </div>
    </div>
  );
};

export default ClientTextConsent;
