import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AppointmentConditional = () => {
  useEffect(() => {
    loadAppointmentConditional();
  }, []);

  const loadAppointmentConditional = () => {
    // here we need to get the currentCycle somehow without context
    // while we don't have it, we link to "/" on cancel
  };

  return (
    <div className="popup" id="popup-appointment-conditional">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Appointment
          </p>
          <Link to="/">
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </Link>
        </div>
        <div className="popup-body popup-body-center">
          <p className="large-text medium-blue-text margin-bottom-large">
            Did you set an appointment?
          </p>

          <div className="form-row flex-row-center-evenly">
            <Link
              className="button-elevated flex-grow large-text margin-top-large margin-right-large"
              to="/appointment-info"
            >
              Yes
            </Link>
            <Link
              className="button-negative flex-grow large-text margin-top-large"
              to="/cycle"
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentConditional;
