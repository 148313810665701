import React, { useReducer } from "react";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import UserContext from "./userContext";
import UserReducer from "./userReducer";
import {
  SETUSER,
  SETNEXTAPPOINTMENT,
  SETACTIONS,
  SETINCOMPLETEACTIONS,
  SETSELECTEDACTION,
} from "../types";

const UserState = (props) => {
  const initialState = {
    user: null,
    incompleteActions: null,
    completeActions: null,
    selectedAction: null,
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const getDealershipsOfSuperUser = async (userId) => {
    try {
      const res = await axios.post(
        "/api/v1/user/get-dealerships-of-superuser",
        { userId },
        axiosConfig
      );

      if (res.data.success) {
        // {
        //   _id: String,
        //   name: String
        // }
        return res.data.cleanDealerships;
      } else {
        throw new Error("invalid response from server");
      }
    } catch (error) {
      console.log("UserState.getDealershipsOfSuperUser:", error);
    }
  };

  const getUser = async (uuid) => {
    try {
      const { data } = await axios.get("/api/v1/user/get-user");

      // console.log(data);
      dispatch({
        type: SETACTIONS,
        payload: {
          incompleteActions: data.incompleteActions,
          completeActions: data.completeActions,
        },
      });

      dispatch({
        type: SETUSER,
        payload: data.user,
      });

      return data;
    } catch (error) {
      // console.log("UserState.getUser: ", error);
      return false;
    }
  };

  const getFullUser = async (uuid) => {
    try {
      const { data } = await axios.get("/api/v1/user/get-full-user");

      // console.log(data);
      dispatch({
        type: SETACTIONS,
        payload: {
          incompleteActions: data.incompleteActions,
          completeActions: data.completeActions,
        },
      });

      dispatch({
        type: SETUSER,
        payload: data.user,
      });

      return data;
    } catch (error) {
      // console.log("UserState.getUser: ", error);
      return false;
    }
  };

  const getUserOnly = async () => {
    try {
      const { data } = await axios.get("/api/v1/user/get-user-only");

      dispatch({
        type: SETUSER,
        payload: data.user,
      });

      return data;
    } catch (error) {
      // console.log("UserState.getUser: ", error);
      return false;
    }
  };

  const getSubdocumentsOfActions = async (actionArray) => {
    try {
      const res = await axios.post(
        "/api/v1/user/get-subdocuments-of-actions",
        actionArray,
        axiosConfig
      );

      dispatch({
        type: SETINCOMPLETEACTIONS,
        payload: res.data.fullActions,
      });

      return res.data.fullActions;
    } catch (error) {
      console.log("UserState.getSubdocumentsOfActions: ", error.message);
    }
  };

  const getSubdocumentsOfAction = async (actionId) => {
    try {
      // console.log(actionId);
      const res = await axios.post(
        "/api/v1/user/get-subdocuments-of-action",
        { actionId },
        axiosConfig
      );

      // console.log(res.data);

      return res.data.action;
    } catch (error) {
      console.log("UserState.getSubdocumentsOfAction: ", error.message);
    }
  };

  const checkUserRole = async (userId) => {
    try {
      const res = await axios.post(
        "/api/v1/user/check-user-role",
        { userId },
        axiosConfig
      );
      return res.data.userType;
    } catch (error) {
      // console.log("UserState.checkUserRole: ", error);
    }
  };

  const setNextCustomerAppointment = (customer, apptInfo) => {
    // console.log("userState.setNextCustomerAppointment", customer, apptInfo);
    //
    try {
      dispatch({
        type: SETNEXTAPPOINTMENT,
        payload: {
          customer,
          apptInfo,
        },
      });
    } catch (error) {
      // console.log(error.message);
    }
  };

  const setSelectedAction = (actionObj) => {
    // console.log("***UserState.setSelectedAction***");
    dispatch({
      type: SETSELECTEDACTION,
      payload: actionObj,
    });
  };

  const addToLastTwoHundred = async (customerId) => {
    try {
      const res = await axios.post(
        "/api/v1/user/add-to-last-two-hundred",
        { customerId },
        axiosConfig
      );
      if (res) {
        dispatch({
          type: SETUSER,
          payload: res.data.user,
        });
      }
    } catch (error) {
      // console.log("UserState.addToLastTwoHundred: ", error);
    }
  };

  const getLastTwoHundredFullObjects = async () => {
    try {
      const res = await axios.get(
        "/api/v1/user/get-last-two-hundred-full-objects",
        axiosConfig
      );
      // console.log("UserState.getLastTwoHundredFullObjects: ", res);
      return res.data.lastTwoHundredObjects;
    } catch (error) {
      console.log("UserState.getLastTwoHundredFullObjects: ", error.message);
    }
  };

  const getDealershipOfUser = async (userId) => {
    // We need this in order to correctly generate the emailData portion of the Note that gets created when we send an email to a customer
    try {
      const res = await axios.post(
        "/api/v1/user/get-dealership-of-user",
        { userId },
        axiosConfig
      );

      return res.data.dealership;
    } catch (error) {
      console.log("UserState.getDealershipOfUser: ", error.message);
    }
  };

  const sendPublicEmail = async (emailBody, contactInfo) => {
    try {
      const res = await axios.post(
        "/api/v1/email/send-public-email",
        { emailBody, contactInfo },
        axiosConfig
      );

      if (res.data.success) {
        return true;
      } else {
        throw new Error("could not send e-mail");
      }
    } catch (error) {
      console.log("UserState.sendPublicEmail: ", error.message);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        incompleteActions: state.incompleteActions,
        completeActions: state.completeActions,
        selectedAction: state.selectedAction,
        getUser,
        getFullUser,
        getUserOnly,
        setNextCustomerAppointment,
        checkUserRole,
        getSubdocumentsOfActions,
        getSubdocumentsOfAction,
        setSelectedAction,
        addToLastTwoHundred,
        getLastTwoHundredFullObjects,
        getDealershipOfUser,
        sendPublicEmail,
        getDealershipsOfSuperUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
