import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const EmailReplyComposer = ({
  currentCustomer,
  currentEmailReply,
  currentEmailReplySubject,
  handleCancel,
  sendReplyHandler,
  special,
  updateCurrentEmailReply,
  updateCurrentEmailReplySubject,
}) => {
  const emailReplyComposerRef = useRef();
  const emailReplyComposerCancel = useRef();

  useEffect(() => {
    if (
      currentCustomer.exactDuplicates &&
      currentCustomer.exactDuplicates.email
    ) {
      alert(
        "Another customer has the same email address - email is not available until this conflict is resolved. Edit one of the customers or merge the two to resolve."
      );
      if (emailReplyComposerRef.current) {
        emailReplyComposerCancel.current.click();
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        special ? "popup-content popup-content-special" : "popup-content"
      }
      ref={emailReplyComposerRef}
    >
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Compose Reply
        </p>
        <div
          onClick={handleCancel}
          id="hide-compose-email-reply"
          ref={emailReplyComposerCancel}
        >
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="dark-blue-text medium-text margin-right-small">
            Subject:{" "}
          </div>
          <input
            className="form-field medium-text dark-blue-green-text flex-grow"
            value={currentEmailReplySubject && currentEmailReplySubject}
            onChange={updateCurrentEmailReplySubject}
          />
        </div>

        <textarea
          className="popup-body-container popup-body-container-taller small-text dark-blue-green-text "
          value={currentEmailReply && currentEmailReply}
          onChange={updateCurrentEmailReply}
        ></textarea>
        <button
          className="button-elevated medium-text"
          onClick={sendReplyHandler}
        >
          Send E-Mail
        </button>
      </div>
    </div>
  );
};

EmailReplyComposer.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  currentEmailReply: PropTypes.string,
  currentEmailReplySubject: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  sendReplyHandler: PropTypes.func.isRequired,
  special: PropTypes.bool.isRequired,
  updateCurrentEmailReply: PropTypes.func.isRequired,
  updateCurrentEmailReplySubject: PropTypes.func.isRequired,
};

export default EmailReplyComposer;
