import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

const LoginPartTwo = () => {
  const authContext = useContext(AuthContext);

  const [authCode, setAuthCode] = useState("");

  const history = useHistory();

  const authCodeChange = (e) => {
    setAuthCode(e.target.value);
  };

  const submitLoginPartTwo = async () => {
    const localUserName = localStorage.getItem("userName");
    if (!localUserName) {
      alert("could not submit form");
      return;
    } else {
      const success = await authContext.loginPartTwo(authCode, localUserName);
      if (success) {
        history.push("/dashboard");
      } else {
        alert("incorrect credentials");
        window.location.reload();
      }
    }
  };

  const handleSubmit = (e) => {
    if (authCode !== "") {
      submitLoginPartTwo();
    }
  };

  return (
    <main
      className="page-column page-column-100-70"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleSubmit(e);
        }
      }}
    >
      <h2 className="main-title dark-blue-text margin-large flex-row-center-center">
        Login
      </h2>
      <div className="margin-large flex-column-center-evenly">
        <input
          type="text"
          name="userName"
          id="user-name"
          placeholder="- - - -"
          className="form-field large-text margin-large"
          onChange={(e) => {
            authCodeChange(e);
          }}
        />
        <button
          to="/dashboard"
          id="login-part-two-btn"
          className="button-elevated large-text margin-large"
          onClick={handleSubmit}
        >
          ENTER
        </button>
      </div>
    </main>
  );
};

export default LoginPartTwo;
