import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../context/auth/authContext";
import DealershipContext from "../context/dealership/dealershipContext";
import UserContext from "../context/users/userContext";
import { v4 as uuidv4 } from "uuid";
import { simpleDecrypt, simpleEncrypt } from "../functions/authFunctions";
import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";

const Account = () => {
  const authContext = useContext(AuthContext);
  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);
  //
  const history = useHistory();
  //
  const mainBodyRef = useRef();
  const passwordResetButton = useRef();
  //
  const [currentDealership, setCurrentDealership] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentTemplateText, setCurrentTemplateText] = useState("");
  const [currentTemplateSubject, setCurrentTemplateSubject] = useState("");
  const [currentTemplateTitle, setCurrentTemplateTitle] = useState("");
  const [currentTemplateUUID, setCurrentTemplateUUID] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [templates, setTemplates] = useState([]);
  const [usersOfDealership, setUsersOfDealership] = useState([]);
  const [viewMainColumn, setViewMainColumn] = useState(true);
  const [viewEditTemplate, setViewEditTemplate] = useState(false);
  const [viewNewTemplate, setViewNewTemplate] = useState(false);
  //
  useEffect(() => {
    if (!currentUser) {
      loadAccount();
    }

    // eslint-disable-next-line
  }, []);
  // async functions
  const asyncResetPassword = async () => {
    try {
      const success = await authContext.resetPassword(
        currentPassword,
        newPassword
      );

      if (success) {
        alert(
          "Password successfully reset! Please use new password to log in."
        );
        authContext.logout();
        history.push("/");
      } else {
        alert("Could not reset password...");
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //
  const asyncSaveTemplate = async () => {
    try {
      const saved = await dealershipContext.saveEmailTemplate(
        currentDealership._id,
        currentTemplateUUID,
        currentTemplateTitle,
        currentTemplateSubject,
        currentTemplateText
      );

      if (saved) {
        alert("Successfully saved template.");
      } else {
        alert("Could not save template...");
      }

      if (mainBodyRef.current) {
        setCurrentUser(null);
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //
  const asyncSaveNewTemplate = async () => {
    try {
      const saved = await dealershipContext.saveNewEmailTemplate(
        currentDealership._id,
        currentTemplateUUID,
        currentTemplateTitle,
        currentTemplateSubject,
        currentTemplateText
      );

      if (saved) {
        alert("Successfully saved template.");
      } else {
        alert("Could not save template...");
      }

      if (mainBodyRef.current) {
        setCurrentUser(null);
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //
  const loadAccount = async () => {
    try {
      if (mainBodyRef.current) {
        setLoading(true);
      }
      const localCurrentUser = await userContext.getUserOnly();
      const localCurrentDealership = await userContext.getDealershipOfUser(
        localCurrentUser.user._id
      );

      if (localCurrentUser && mainBodyRef.current) {
        setCurrentUser(localCurrentUser);
        setCurrentDealership(localCurrentDealership);

        if (
          localCurrentUser.user.role === "administrator" ||
          localCurrentUser.user.role === "superuser"
        ) {
          const users = await dealershipContext.getUsersOfDealership(
            localCurrentUser.user.dealership
          );

          if (users && mainBodyRef.current) {
            users.sort((a, b) => {
              if (a.role === "salesperson") {
                return -1;
              } else {
                return 1;
              }
            });

            users.sort((a, b) => {
              if (a.role === "receptionist") {
                return -1;
              } else {
                return 1;
              }
            });

            users.sort((a, b) => {
              if (a.role === "bdc") {
                return -1;
              } else {
                return 1;
              }
            });

            users.sort((a, b) => {
              if (a.role === "bdcmanager") {
                return -1;
              } else {
                return 1;
              }
            });

            users.sort((a, b) => {
              if (a.role === "manager") {
                return -1;
              } else {
                return 1;
              }
            });

            users.sort((a, b) => {
              if (a.role === "administrator") {
                return -1;
              } else {
                return 1;
              }
            });

            users.sort((a, b) => {
              if (a.role === "superuser") {
                return -1;
              } else {
                return 1;
              }
            });

            setUsersOfDealership(users);
          }
        }

        setLoading(false);
        setTemplates(localCurrentDealership.emailTemplates);
      }
    } catch (error) {
      console.log("Account.loadAccount: could not get current user...");
    }
  };
  // event handlers
  const addNewTemplate = (e) => {
    if (mainBodyRef.current) {
      setCurrentTemplateUUID(uuidv4());
      setViewNewTemplate(true);
      setViewEditTemplate(false);
      setViewMainColumn(false);
    }
  };
  //
  const displayTemplate = (e) => {
    const template = JSON.parse(e.currentTarget.id);

    // console.log(template);

    // template is an object with subject, title, and text

    // ***THIS FORMATTING WILL BE USEFUL LATER***

    //     const localText = `
    // Hi, __customerName__,

    //         ${template.text}

    // Sincerely,

    // ${
    //   currentUser && currentUser.user
    //     ? `${currentUser.user.firstName} ${currentUser.user.lastName}`
    //     : console.log(currentUser.user.firstName)
    // }
    // ${formalRole}

    // ${currentDealership ? `${currentDealership.name}` : ""}
    // ${currentDealership ? `${currentDealership.address}` : ""}
    // ${
    //   currentDealership
    //     ? `${currentDealership.city}, ${currentDealership.state} ${currentDealership.zip}`
    //     : ""
    // }
    //             `;
    //
    const localText = template.text;

    if (mainBodyRef.current) {
      setCurrentTemplateText(localText);
      setCurrentTemplateTitle(template.title);
      setCurrentTemplateSubject(template.subject);
      setCurrentTemplateUUID(template.uuid);
      setViewMainColumn(false);
      setViewEditTemplate(true);
    }
  };
  //
  const deleteUserContact = (e) => {
    const currentUserId = e.currentTarget.id;

    (async function () {
      try {
        const res = await axios.post(
          "/api/v1/user/delete-user-contact-info",
          { currentUserId },
          axiosConfig
        );
        window.location.reload();
      } catch (error) {
        console.log("Account.deleteUserContact: ", error);
      }
    })();
  };
  //
  const handleCancel = (e) => {
    setCurrentTemplateUUID("");
    setCurrentTemplateSubject("");
    setCurrentTemplateTitle("");
    setCurrentTemplateText("");
    setViewEditTemplate(false);
    setViewMainColumn(true);
    setViewNewTemplate(false);
  };
  //
  const handlePasswordChange = (e) => {
    if (mainBodyRef.current) {
      if (e.currentTarget.name === "current-password") {
        setCurrentPassword(e.currentTarget.value);
        if (e.currentTarget.value.length >= 8 && newPassword.length >= 8) {
          passwordResetButton.current.classList.remove(
            "button-normal-disabled"
          );
        } else {
          // console.log(e.currentTarget.value.length, newPassword.length);
          passwordResetButton.current.classList.add("button-normal-disabled");
        }
      } else if (e.currentTarget.name === "new-password") {
        setNewPassword(e.currentTarget.value);
        if (e.currentTarget.value.length >= 8 && currentPassword.length >= 8) {
          passwordResetButton.current.classList.remove(
            "button-normal-disabled"
          );
        } else {
          passwordResetButton.current.classList.add("button-normal-disabled");
        }
      }
    }
  };
  //
  const handleUserActiveChange = (e) => {
    const selectedUser = JSON.parse(
      simpleDecrypt(e.currentTarget.parentElement.id)
    );
    const selectedStatus = e.currentTarget.value === "true";

    // console.log(selectedUser, selectedStatus);
    if (!selectedUser) {
      alert("ERROR: could not update user");
    } else {
      if (selectedUser.role === "superuser") {
        alert(
          'to change a superuser, please request a change using the "Support" link'
        );
        return;
      }
      if (
        currentUser.user.role === "superuser" ||
        currentUser.user.role === "administrator"
      ) {
        if (
          currentUser.user._id === selectedUser._id &&
          selectedStatus !== true
        ) {
          const wantsToDemoteSelf = window.confirm(
            "Are you sure?  You will not be able to undo this without contacting support or a superuser from your dealership!"
          );
          if (!wantsToDemoteSelf) {
            return;
          }
        }

        (async function () {
          try {
            const res = await axios.post(
              "/api/v1/user/change-status-of-user",
              { uid: selectedUser._id, selectedStatus },
              axiosConfig
            );

            if (res.data.success) {
              window.location.reload();
            } else {
              throw new Error("could not update user");
            }
          } catch (error) {
            console.log(error.message);
          }
        })();
      } else {
        // LMAO
        // alert(
        //   `An e-mail has been sent to ${selectedUser.firstName} ${selectedUser.lastName} to confirm your request for their access to be changed`
        // );
        alert("You do not have permission to change user accounts");
      }
    }
  };
  //
  const handleUserRoleChange = (e) => {
    const selectedUser = JSON.parse(
      simpleDecrypt(e.currentTarget.parentElement.id)
    );
    const selectedRole = e.currentTarget.value;
    if (!selectedUser) {
      alert("ERROR: could not update user");
    } else {
      if (selectedUser.role === "superuser") {
        alert(
          'to change a superuser, please request a change using the "Support" link'
        );
        return;
      }
      if (
        currentUser.user.role === "superuser" ||
        currentUser.user.role === "administrator"
      ) {
        if (
          currentUser.user._id === selectedUser._id &&
          selectedRole !== ("superuser" || "administrator")
        ) {
          const wantsToDemoteSelf = window.confirm(
            "Are you sure?  You will not be able to undo this without contacting support or a superuser from your dealership!"
          );
          if (!wantsToDemoteSelf) {
            return;
          }
        }

        (async function () {
          try {
            const res = await axios.post(
              "/api/v1/user/change-role-of-user",
              { uid: selectedUser._id, selectedRole },
              axiosConfig
            );

            if (res.data.success) {
              window.location.reload();
            } else {
              throw new Error("could not update user");
            }
          } catch (error) {
            console.log(error.message);
          }
        })();
      } else {
        // LMAO
        // alert(
        //   `An e-mail has been sent to ${selectedUser.firstName} ${selectedUser.lastName} to confirm your request for their access to be changed`
        // );
        alert("You do not have permission to change user accounts");
      }
    }
  };
  //
  const resetPassword = (e) => {
    if (currentPassword.length >= 8 && newPassword.length >= 8) {
      asyncResetPassword();
    }
  };
  //
  const saveTemplate = (e) => {
    // here we'll need templates in the database on the dealership
    asyncSaveTemplate();
  };
  //
  const saveNewTemplate = (e) => {
    asyncSaveNewTemplate();
  };
  //
  const updateCurrentTemplateText = (e) => {
    if (mainBodyRef.current) {
      setCurrentTemplateText(e.currentTarget.value);
    }
  };
  //
  const updateCurrentTemplateSubject = (e) => {
    if (mainBodyRef.current) {
      setCurrentTemplateSubject(e.currentTarget.value);
    }
  };
  //
  const updateCurrentTemplateTitle = (e) => {
    if (mainBodyRef.current) {
      setCurrentTemplateTitle(e.currentTarget.value);
    }
  };
  //
  return (
    <Fragment>
      {loading ? (
        <main
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </main>
      ) : (
        <div className="page-column page-column-100-70" ref={mainBodyRef}>
          {viewMainColumn && (
            <div className="flex-column-center-center">
              <div className="margin-top-large"></div>
              <p className="main-title dark-blue-text">Account</p>

              <div className="form-row flex-column-center-center margin-top-large blue-green-underline">
                <p className="large-text dark-blue-text margin-top-large">
                  Password Reset:
                </p>
                <input
                  type="password"
                  className="form-field medium-text dark-blue-green-text margin-top-large "
                  placeholder="Current Password"
                  name="current-password"
                  onChange={handlePasswordChange}
                />
                <input
                  type="password"
                  className="form-field medium-text dark-blue-green-text margin-top-large"
                  placeholder="New Password"
                  name="new-password"
                  onChange={handlePasswordChange}
                />
                <button
                  className="button-normal medium-text margin-top-large margin-bottom-large button-normal-disabled"
                  onClick={resetPassword}
                  ref={passwordResetButton}
                >
                  Reset
                </button>
              </div>

              <div className="form-row flex-column-center-center margin-top-large blue-green-underline">
                <p className="large-text dark-blue-text margin-top-large">
                  Dealership E-mail Templates:
                </p>
                <div className="flex-row-center-center breaking-flex-row">
                  {templates &&
                    templates.map((t, idx) => {
                      return (
                        <button
                          className="button-normal small-text margin-top-large margin-left-large margin-right-large"
                          key={idx}
                          id={JSON.stringify(t)}
                          onClick={displayTemplate}
                        >
                          {t.title}
                        </button>
                      );
                    })}
                </div>

                {currentUser &&
                currentUser.user.role !== "salesperson" &&
                currentUser.user.role !== "receptionist" &&
                currentUser.user.role !== "bdc" ? (
                  <button
                    className="button-elevated medium-text margin-top-large margin-bottom-large"
                    onClick={addNewTemplate}
                  >
                    Add New
                  </button>
                ) : (
                  <button className="button-elevated medium-text margin-top-large margin-bottom-large button-elevated-disabled">
                    Add New
                  </button>
                )}
              </div>

              <div className="form-row flex-column-center-center margin-top-large blue-green-underline">
                <p className="large-text dark-blue-text margin-top-large margin-bottom-large">
                  User Administration:
                </p>
                <div className="flex-column-center-center three-quarter-width">
                  {usersOfDealership &&
                    usersOfDealership.map((uod, idx) => {
                      if (
                        idx % 2 === 0 &&
                        uod.userPhone !== "" &&
                        uod.userEmail !== ""
                      ) {
                        return (
                          <div
                            className="form-row padding-tiny"
                            key={idx}
                            id={(function () {
                              return simpleEncrypt(JSON.stringify(uod));
                            })()}
                          >
                            <p className="tiny-text dark-blue-text half-width">
                              {uod.firstName} {uod.lastName}
                            </p>
                            <select
                              className="form-select dark-blue-green-text tiny-text"
                              value={uod.role}
                              onChange={handleUserRoleChange}
                            >
                              <option value="superuser">superuser</option>
                              <option value="administrator">
                                administrator
                              </option>
                              <option value="manager">manager</option>
                              <option value="bdcmanager">bdcmanager</option>
                              <option value="bdc">bdc</option>
                              <option value="receptionist">receptionist</option>
                              <option value="salesperson">salesperson</option>
                            </select>

                            <select
                              className="form-select dark-blue-green-text tiny-text"
                              value={uod.active}
                              onChange={handleUserActiveChange}
                            >
                              <option value="true">Active</option>
                              <option value="false">Deactivated</option>
                            </select>

                            {!uod.active && (
                              <div
                                className="button-negative button-negative-smaller tiny-text dark-red-text"
                                id={uod._id}
                                onClick={deleteUserContact}
                              >
                                Delete Contact Info
                              </div>
                            )}
                          </div>
                        );
                      } else if (
                        idx % 2 !== 0 &&
                        uod.userPhone !== "" &&
                        uod.userEmail !== ""
                      ) {
                        return (
                          <div
                            className="form-row padding-tiny"
                            key={idx}
                            id={(function () {
                              return simpleEncrypt(JSON.stringify(uod));
                            })()}
                            style={{ backgroundColor: "gainsboro" }}
                          >
                            <p className="tiny-text dark-blue-text half-width">
                              {uod.firstName} {uod.lastName}
                            </p>
                            <select
                              className="form-select dark-blue-green-text tiny-text"
                              value={uod.role}
                              onChange={handleUserRoleChange}
                            >
                              <option value="superuser">superuser</option>
                              <option value="administrator">
                                administrator
                              </option>
                              <option value="manager">manager</option>
                              <option value="bdcmanager">bdcmanager</option>
                              <option value="bdc">bdc</option>
                              <option value="receptionist">receptionist</option>
                              <option value="salesperson">salesperson</option>
                            </select>

                            <select
                              className="form-select dark-blue-green-text tiny-text"
                              value={uod.active}
                              onChange={handleUserActiveChange}
                            >
                              <option value="true">Active</option>
                              <option value="false">Deactivated</option>
                            </select>
                            {!uod.active && (
                              <div
                                className="button-negative button-negative-smaller tiny-text dark-red-text"
                                id={uod._id}
                                onClick={deleteUserContact}
                              >
                                Delete Contact Info
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div className="small-text dark-red-text" key={idx}>
                            [deleted user]
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="margin-top-large margin-bottom-large"></div>
              <div className="margin-top-large margin-bottom-large"></div>
              <div className="margin-top-large margin-bottom-large"></div>
            </div>
          )}

          {viewEditTemplate && (
            <div className="popup-content">
              <div className="popup-header">
                <p className="large-text white-text center-text flex-grow">
                  Edit E-Mail Template
                </p>
                <div onClick={handleCancel} id="view-compose-new-cancel">
                  <svg className="icon-small icon-small-back">
                    <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                  </svg>
                </div>
              </div>
              <div className="popup-body popup-body-evenly">
                <div className="form-row">
                  <div className="dark-blue-text medium-text margin-right-small">
                    Title:{" "}
                  </div>
                  <input
                    className="form-field medium-text dark-blue-green-text flex-grow"
                    value={currentTemplateTitle && currentTemplateTitle}
                    onChange={updateCurrentTemplateTitle}
                    placeholder="- - - -"
                  />
                </div>
                <div className="form-row">
                  <div className="dark-blue-text medium-text margin-right-small">
                    Subject:{" "}
                  </div>
                  <input
                    className="form-field medium-text dark-blue-green-text flex-grow"
                    value={currentTemplateSubject && currentTemplateSubject}
                    onChange={updateCurrentTemplateSubject}
                    placeholder="- - - -"
                  />
                </div>

                <textarea
                  className="popup-body-container popup-body-container-taller popup-body-container-prewrap small-text dark-blue-green-text "
                  value={currentTemplateText && currentTemplateText}
                  onChange={updateCurrentTemplateText}
                  placeholder="Type E-Mail body here..."
                ></textarea>
                {currentUser &&
                currentUser.user &&
                (currentUser.user.role === "administrator" ||
                  currentUser.user.role === "superuser" ||
                  currentUser.user.role === "salesperson") ? (
                  <button
                    className="button-elevated large-text"
                    onClick={saveTemplate}
                  >
                    Save
                  </button>
                ) : (
                  <button className="button-elevated button-elevated-disabled large-text">
                    Save
                  </button>
                )}
              </div>
            </div>
          )}

          {viewNewTemplate && (
            <div className="popup-content">
              <div className="popup-header">
                <p className="large-text white-text center-text flex-grow">
                  New E-Mail Template
                </p>
                <div onClick={handleCancel} id="view-compose-new-cancel">
                  <svg className="icon-small icon-small-back">
                    <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                  </svg>
                </div>
              </div>
              <div className="popup-body popup-body-evenly">
                <div className="form-row">
                  <div className="dark-blue-text medium-text margin-right-small">
                    Title:{" "}
                  </div>
                  <input
                    className="form-field medium-text dark-blue-green-text flex-grow"
                    value={currentTemplateTitle && currentTemplateTitle}
                    onChange={updateCurrentTemplateTitle}
                    placeholder="- - - -"
                  />
                </div>
                <div className="form-row">
                  <div className="dark-blue-text medium-text margin-right-small">
                    Subject:{" "}
                  </div>
                  <input
                    className="form-field medium-text dark-blue-green-text flex-grow"
                    value={currentTemplateSubject && currentTemplateSubject}
                    onChange={updateCurrentTemplateSubject}
                    placeholder="- - - -"
                  />
                </div>

                <textarea
                  className="popup-body-container popup-body-container-taller popup-body-container-prewrap small-text dark-blue-green-text "
                  value={currentTemplateText}
                  onChange={updateCurrentTemplateText}
                  placeholder="Type E-Mail body here..."
                ></textarea>
                {currentUser &&
                currentUser.user &&
                (currentUser.user.role === "administrator" ||
                  currentUser.user.role === "superuser" ||
                  currentUser.user.role === "salesperson") ? (
                  <button
                    className="button-elevated large-text"
                    onClick={saveNewTemplate}
                  >
                    Save
                  </button>
                ) : (
                  <button className="button-elevated button-elevated-disabled large-text">
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Account;
