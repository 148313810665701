const commonMakes = [
  "Acura",
  "Alfa Romeo",
  "AMC",
  "Ariel",
  "Audi",
  "Bentley",
  "BMW",
  "Bugatti",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Citroen",
  "Daewoo",
  "Datsun",
  "Dodge",
  "Eagle",
  "Ferrari",
  "Fiat",
  "Fisker",
  "Ford",
  "Genesis",
  "Geo",
  "GMC",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "Kia",
  "Koenigsegg",
  "Lada",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Lexus",
  "Lincoln",
  "Maserati",
  "Maybach",
  "Mazda",
  "McLaren",
  "Mercedes-Benz",
  "Mercury",
  "MG",
  "Mini",
  "Mitsubishi",
  "Morgan",
  "Nissan",
  "Oldsmobile",
  "Opel",
  "Pagani",
  "Panoz",
  "Plymouth",
  "Pontiac",
  "Porsche",
  "RAM",
  "Renault",
  "Rolls Royce",
  "Saab",
  "Saleen",
  "Saturn",
  "Scion",
  "SRT",
  "Subaru",
  "Suzuki",
  "Tesla",
  "Toyota",
  "Triumph",
  "Volkswagen",
  "Volvo",
];

export default commonMakes;
