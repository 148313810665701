import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DealershipContext from "../context/dealership/dealershipContext";

const Inventory = () => {
  //
  const dealershipContext = useContext(DealershipContext);
  //
  const mainBodyRef = useRef();
  const searchTypeAllInput = useRef();
  //
  const history = useHistory();
  //
  const [inventoryLoaded, setInventoryLoaded] = useState(false);
  const [minDisplayPrice, setMinDisplayPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxDisplayPrice, setMaxDisplayPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [vehicleSearchType, setVehicleSearchType] = useState("");
  const [vehicleResults, setVehicleResults] = useState([]);
  //
  useEffect(() => {
    if (!inventoryLoaded) {
      loadInventory();
    }

    // eslint-disable-next-line
  }, [inventoryLoaded]);
  //
  const copy = async (text) => {
    await navigator.clipboard.writeText(text);
    alert("Stock Number copied to clipboard!");
  };
  //
  const handleSearchCategoryChange = (e) => {
    safeStateSetter(() => {
      setSearchCategory(e.currentTarget.value);
    });
  };
  //
  const handleSearchTextChange = (e) => {
    safeStateSetter(() => {
      setSearchText(e.currentTarget.value);
    });
  };
  //
  const handleVehicleSearchTypeRadio = (e) => {
    safeStateSetter(() => {
      if (e.currentTarget.id === "vehicle-search-type-new") {
        setVehicleSearchType("new");
      } else if (e.currentTarget.id === "vehicle-search-type-used") {
        setVehicleSearchType("used");
      } else {
        setVehicleSearchType("all");
      }
    });
  };
  //
  const loadInventory = async () => {
    safeStateSetter(() => {
      setMinPrice("0");
      setMinDisplayPrice("$0");
      setMaxPrice("100000");
      setMaxDisplayPrice("$100,000");
      if (!vehicleSearchType || vehicleSearchType === "") {
        setVehicleSearchType("all");
        searchTypeAllInput.current.click();
      }
      if (!searchCategory || searchCategory === "") {
        setSearchCategory("all-fields");
      }
      setInventoryLoaded(true);
    });
  };
  //
  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };
  //
  const searchVehicles = async () => {
    safeStateSetter(() => {
      setVehicleResults([]);
    });
    // need to have an array returned here
    const results = await dealershipContext.vehicleSearch(
      vehicleSearchType,
      minPrice,
      maxPrice,
      searchCategory,
      searchText
    );

    safeStateSetter(() => {
      if (results) {
        setVehicleResults(results);
      } else {
        alert("error searching for vehicles");
      }
    });
  };
  //
  const handlePriceRangeChange = (e) => {
    if (e.currentTarget.name === "min-price") {
      if (parseInt(e.target.value) < parseInt(maxPrice ? maxPrice : "100000")) {
        const minValues = e.target.value.split("");

        const finalValues = [];

        for (let i = minValues.length; i > 0; i--) {
          if (finalValues.length < 3) {
            finalValues.push(minValues[i - 1]);
          } else if (finalValues.length === 3) {
            finalValues.push(",");
            i++;
          } else {
            finalValues.push(minValues[i - 1]);
          }

          if (i === 1) {
            finalValues.push("$");
          }
        }

        setMinDisplayPrice(finalValues.reverse().join(""));
        setMinPrice(e.currentTarget.value);
      } else {
        // alert("the minimum price cannot be higher than the maximum price");
      }
    } else if (e.currentTarget.name === "max-price") {
      if (parseInt(e.target.value) > parseInt(minPrice ? minPrice : "0")) {
        const maxValues = e.target.value.split("");

        const finalValues = [];

        for (let i = maxValues.length; i > 0; i--) {
          if (finalValues.length < 3) {
            finalValues.push(maxValues[i - 1]);
          } else if (finalValues.length === 3) {
            finalValues.push(",");
            i++;
          } else {
            finalValues.push(maxValues[i - 1]);
          }

          if (i === 1) {
            finalValues.push("$");
          }
        }

        setMaxDisplayPrice(finalValues.reverse().join(""));
        setMaxPrice(e.target.value);
      } else {
        // alert("the maximum price cannot be lower than the minimum price");
      }
    } else {
      alert("invalid input");
    }
  };
  //
  return (
    <div className="popup" id="popup-appointment-conditional" ref={mainBodyRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Inventory
          </p>
          <div
            onClick={() => {
              history.goBack();
            }}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="form-row">
            <div className="flex-row-center-evenly flex-grow">
              {" "}
              <label
                htmlFor="vehicle-search-type-new"
                className="medium-text dark-blue-text"
              >
                New
              </label>
              <div className="custom-radio ">
                <input
                  type="radio"
                  name="vehicle-search-type"
                  id="vehicle-search-type-new"
                  onChange={handleVehicleSearchTypeRadio}
                />
                <div className="radio-button"></div>
              </div>
            </div>
            <div className="flex-row-center-evenly flex-grow">
              <label
                htmlFor="vehicle-search-type-used"
                className="medium-text dark-green-text"
              >
                Used
              </label>
              <div className="custom-radio ">
                <input
                  type="radio"
                  name="vehicle-search-type"
                  id="vehicle-search-type-used"
                  onChange={handleVehicleSearchTypeRadio}
                />
                <div className="radio-button"></div>
              </div>
            </div>
            <div className="flex-row-center-evenly flex-grow">
              <label
                htmlFor="vehicle-search-type-all"
                className="medium-text dark-blue-green-text"
              >
                All
              </label>
              <div className="custom-radio  ">
                <input
                  type="radio"
                  name="vehicle-search-type"
                  id="vehicle-search-type-all"
                  ref={searchTypeAllInput}
                  onChange={handleVehicleSearchTypeRadio}
                />
                <div className="radio-button"></div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <input
              className="form-field flex-grow margin-right-small small-text dark-green-text"
              placeholder="min. price"
              value={minDisplayPrice && minDisplayPrice}
              onChange={(e) => {
                safeStateSetter(() => {
                  setMinDisplayPrice(e.currentTarget.value);
                  setMinPrice(e.currentTarget.value);
                });
              }}
            />
            <p className="small-text dark-blue-green-text">to</p>
            <input
              className="form-field flex-grow margin-left-small small-text dark-green-text"
              placeholder="max. price"
              value={maxDisplayPrice && maxDisplayPrice}
              onChange={(e) => {
                safeStateSetter(() => {
                  setMaxDisplayPrice(e.currentTarget.value);
                  setMaxPrice(e.currentTarget.value);
                });
              }}
            />
          </div>
          <div className="form-row">
            <input
              type="range"
              name="min-price"
              id="min-price"
              min="0"
              max="100000"
              value={minPrice && minPrice}
              onChange={handlePriceRangeChange}
              className="flex-grow margin-left-small margin-right-small"
            />

            <input
              type="range"
              name="max-price"
              id="max-price"
              min="0"
              max="100000"
              value={maxPrice && maxPrice}
              onChange={handlePriceRangeChange}
              className="flex-grow margin-left-small margin-right-small"
            />
          </div>

          <div className="form-row">
            <select
              name="search-filter"
              id="search-filter"
              className="form-select form-select-left medium-text dark-blue-green-text flex-grow"
              value={searchCategory}
              onChange={handleSearchCategoryChange}
            >
              <option value="all-fields">All Fields</option>
              <option value="year">Year</option>
              <option value="make">Make</option>
              <option value="model">Model</option>
              <option value="vin">VIN</option>
              <option value="stock">Stock</option>
            </select>
            <input
              type="text"
              className="form-field form-field-right medium-text dark-blue-green-text flex-grow"
              placeholder="Enter Search Text..."
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </div>

          <div className="flex-row-center-center medium-large-text dark-blue-text">
            <p> Results: </p>
          </div>

          <div className="popup-section popup-section-taller">
            {vehicleResults &&
              vehicleResults.map((v, idx) => {
                return (
                  <div
                    className="module module-vehicle"
                    key={idx}
                    onClick={() => {
                      if (v.stock && v.stock !== "") {
                        copy(v.stock);
                      }
                    }}
                  >
                    <div className="module-section module-section-vehicle-left white-text">
                      <div>{v.stock}</div>
                      <svg className="icon icon-tiny icon-inventory">
                        <use href="/icons/symbol-defs.svg#icon-automobile"></use>
                      </svg>
                      <div>Price: {v.publicPrice}</div>
                    </div>
                    <div className="module-section module-section-vehicle-right dark-amber-text">
                      <div>
                        {v.type} {v.year}
                      </div>
                      <div>
                        {v.make} {v.model}
                      </div>
                      <div>{v.vin}</div>
                      <div>Invoice: {v.invoicePrice}</div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="form-row">
            <button
              className="button-elevated flex-grow medium-large-text"
              onClick={searchVehicles}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;
