import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";
import { parseISO } from "date-fns";
import UserContext from "../../context/users/userContext";
import {
  checkTexts,
  getSubdocumentsOfCustomer,
} from "../../functions/customerFunctions";

const TextConversationViewer = ({
  currentCustomer,
  currentTextBody,
  handleCancel,
  sendTextHandler,
  setTextCheckerStatus,
  updateCurrentTextBody,
  textCheckerStatus,
}) => {
  const userContext = useContext(UserContext);

  const history = useHistory();

  const bottomOfTexts = useRef();
  const cancelTextConversationViewer = useRef();
  const textConversationRef = useRef();

  const [currentTexts, setCurrentTexts] = useState([]);
  const [fullCustomer, setFullCustomer] = useState(null);
  const [textConversationLoaded, setTextConversationLoaded] = useState(false);

  useEffect(() => {
    if (!textConversationLoaded) {
      loadTextConversation();
    } else {
      if (fullCustomer.exactDuplicates && fullCustomer.exactDuplicates.phone) {
        alert(
          "Another customer has the same phone number - texting is not available until this conflict is resolved. Edit one of the customers or merge the two to resolve."
        );
        safeStateSetter(() => {
          cancelTextConversationViewer.current.click();
        });
      }
    }

    // we need to calculate bottomOfTexts in here too
    if (bottomOfTexts.current) {
      bottomOfTexts.current.click();
    }
    // eslint-disable-next-line
  }, [textConversationLoaded]);

  const asyncCheckTexts = async (customerObj) => {
    const newTextFound = await checkTexts(currentCustomer);

    safeStateSetter(async () => {
      if (newTextFound) {
        newTextFound.sort((a, b) => {
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return 1;
          } else {
            return -1;
          }
        });
        const localCustomer = await getSubdocumentsOfCustomer(customerObj._id);

        safeStateSetter(() => {
          setCurrentTexts(newTextFound);
          setFullCustomer(localCustomer);
        });
      }
    });
  };

  const getCurrentActionSpecial = async () => {
    const updatedActions = await userContext.getFullUser();
    if (updatedActions) {
      const actionIds = [];
      fullCustomer.actions.forEach((act) => {
        actionIds.push(act._id);
      });

      let matchingAction = null;
      if (
        updatedActions.incompleteActions &&
        updatedActions.incompleteActions[0]
      ) {
        updatedActions.incompleteActions.forEach((incAct) => {
          actionIds.forEach((actId) => {
            if (incAct._id.toString() === actId.toString()) {
              matchingAction = incAct;
            }
          });
        });
        if (matchingAction) {
          return matchingAction;
        } else {
          console.log("no matchingAction found...");
          return null;
        }
      } else {
        return null;
      }
    }
  };

  const loadTextConversation = async () => {
    // we are going to redundantly get the currentCustomer again here and use a local variable
    const loadedCustomer = await getSubdocumentsOfCustomer(currentCustomer._id);

    // same with currentTexts
    let localTexts = [];

    if (loadedCustomer.texts[0]) {
      // these texts are the full objects
      localTexts = loadedCustomer.texts;
      localTexts.sort((a, b) => {
        if (parseISO(a.createdAt) > parseISO(b.createdAt)) {
          return 1;
        } else {
          return -1;
        }
      });
    }

    safeStateSetter(() => {
      setCurrentTexts(localTexts);
      setFullCustomer(loadedCustomer);
      setTextCheckerStatus(
        setInterval(() => {
          const localTextCheckerCountStr = localStorage.getItem(
            "localTextCheckerCount"
          );

          let localTextCheckerCount;

          if (!localTextCheckerCountStr || localTextCheckerCountStr === "200") {
            localStorage.setItem("localTextCheckerCount", "1");
            localTextCheckerCount = 1;
          } else {
            localTextCheckerCount = parseInt(localTextCheckerCountStr);
            localTextCheckerCount++;
            localStorage.setItem(
              "localTextCheckerCount",
              `${localTextCheckerCount}`
            );
          }

          if (localTextCheckerCount < 200) {
            if (history.location.pathname === ("/cycle" || "/customer")) {
              asyncCheckTexts(loadedCustomer);
            } else {
              localStorage.setItem("localTextCheckerCount", "1");
              clearInterval(textCheckerStatus);
              window.location.reload();
            }
          } else {
            clearInterval(textCheckerStatus);
            window.location.reload();
          }
        }, 3500)
      );
      setTextConversationLoaded(true);
    });
  };

  const safeStateSetter = (fn) => {
    if (textConversationRef.current) {
      fn();
    }
  };

  return !textConversationLoaded ? (
    <div
      className="full-page-center full-page-center-with-navbar"
      ref={textConversationRef}
    >
      <div className="lds-dual-ring"></div>
    </div>
  ) : (
    <div className="popup" ref={textConversationRef}>
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            Text Conversation
          </p>
          <div
            onClick={handleCancel}
            id="hide-text-conversation"
            ref={cancelTextConversationViewer}
          >
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          <div className="form-row margin-none">
            <label
              htmlFor="text-to"
              className="small-text dark-blue-text margin-right-large"
            >
              Customer Phone:
            </label>
            <input
              className="form-field small-text dark-blue-green-text flex-grow"
              id="text-to"
              value={
                fullCustomer &&
                fullCustomer.phoneNumber &&
                typeof fullCustomer.phoneNumber === "string" &&
                parsePhoneNumber(fullCustomer.phoneNumber)
                  ? parsePhoneNumber(fullCustomer.phoneNumber).formatNational(
                      "US"
                    )
                  : fullCustomer.phoneNumber
              }
              disabled
            />
          </div>
          <div className="popup-section small-text">
            {currentTexts &&
              currentTexts.map((cur, index) => {
                return cur.direction === "incoming" ? (
                  <div className="form-row" key={index}>
                    <div className="incoming-text">{cur.body}</div>
                    <div></div>
                  </div>
                ) : (
                  <div className="form-row" key={index}>
                    <div></div>
                    <div className="outgoing-text">{cur.body}</div>
                  </div>
                );
              })}
            <div
              ref={bottomOfTexts}
              onClick={(e) => {
                // it was a pain in the ass to figure out how to do this
                const target = e.currentTarget;
                target.scrollIntoView({ behavior: "smooth" });
              }}
            ></div>
          </div>
          <textarea
            name="text-content"
            id="text-content"
            className="popup-section popup-section-short small-text medium-blue-text"
            placeholder="Type Here..."
            value={currentTextBody}
            onChange={updateCurrentTextBody}
          ></textarea>
          <div className="form-row" id="text-conversation-send">
            <button
              className="button-elevated large-text flex-grow"
              onClick={(e) => {
                const parentElement = document.getElementById(
                  "text-conversation-send"
                );
                parentElement.style.display = "none";
                (async function (e) {
                  const action = await getCurrentActionSpecial();

                  sendTextHandler(e, action);
                })(e);
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TextConversationViewer.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  currentTextBody: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  sendTextHandler: PropTypes.func.isRequired,
  setTextCheckerStatus: PropTypes.func.isRequired,
  // textCheckerStatus is null when we load this, but it must become a function
  // textCheckerStatus: PropTypes.func.isRequired,
  updateCurrentTextBody: PropTypes.func.isRequired,
};

export default TextConversationViewer;
