import axios from "axios";
import { axiosConfig } from "./axiosConfig";

const propertiesToDelete = [
  "CardRevisionDate",
  "ClassificationCode",
  "ComplianceType",
  "Country",
  "CountryCode",
  "DocumentType",
  "EndorsementCodeDescription",
  "EndoresmentsCode",
  "ExpirationDate",
  "EyeColor",
  "FullName",
  "HAZMATExpDate",
  "HairColor",
  "Height",
  "IIN",
  "IssueDate",
  "IssuedBy",
  "LicenseNumber",
  "LimitedDurationDocument",
  "OrganDonor",
  "Race",
  "RestrictionCode",
  "RestrictionCodeDescription",
  "VehicleClassCode",
  "VehicleClassCodeDescription",
  "WeightKG",
  "WeightLBS",
];

export const postLicenseData = async (result) => {
  try {
    const { data } = await axios.post(
      "/api/v1/customer/scan-license-back",
      { result },
      axiosConfig
    );
    const { customerInfo } = data;
    for (let i = 0; i < propertiesToDelete.length; i++) {
      if (customerInfo[propertiesToDelete[i]]) {
        delete customerInfo[propertiesToDelete[i]];
      }
    }
    //
    for (const item in customerInfo) {
      if (customerInfo[item]) {
        let itemComponents = customerInfo[item].split(" ");
        itemComponents.forEach((itemComponent, index) => {
          const firstChar = itemComponent.substring(0, 1);
          let restOfChars = itemComponent.substring(1, itemComponent.length);
          restOfChars = restOfChars.toLowerCase();
          itemComponents[index] = `${firstChar}${restOfChars}`;
        });
        itemComponents = itemComponents.join(" ");
        customerInfo[item] = itemComponents;
      }
    }
    let address = "";
    if (customerInfo.Address2 !== "") {
      address = `${customerInfo.Address1} ${customerInfo.Address2}`;
    } else {
      address = customerInfo.Address1;
    }
    return {
      success: true,
      enteredAddress: address,
      enteredFirst: customerInfo.FirstName,
      enteredLast: customerInfo.LastName,
      enteredZipCode: customerInfo.PostalCode.substring(0, 5),
      enteredCity: customerInfo.City,
      enteredState: customerInfo.JurisdictionCode.toUpperCase(),
    };
  } catch (error) {
    // console.log("addCustomerApiCalls.postLicenseData", error);
    return false;
  }
};

export const getModels = async (year, make) => {
  try {
    // console.log("hello from getModels");
    const models = [];
    const { data } = await axios.post(
      "/api/v1/customer/get-models-for-make",
      { year, make },
      axiosConfig
    );
    data.models.forEach((model) => {
      const trimmedModel = model.substring(0, 16);
      models.push(trimmedModel);
    });
    let uniqueModels = [...new Set(models)];
    uniqueModels.sort();

    // console.log("about to return uniqueModels");
    return uniqueModels;
  } catch (error) {
    // console.log("addCustomerApiCalls.getModels: ", error);
  }
};

export const getCityStateFromZip = async (zip) => {
  try {
    const { data } = await axios.post(
      "/api/v1/customer/lookup-city-state",
      { zip },
      axiosConfig
    );
    return data;
  } catch (error) {
    // console.log("addCustomerApiCalls.getCityStateFromZip: ", error);
  }
};
